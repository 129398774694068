import styles from "./syncSuccessMsg.module.css";

export declare type ErrorMessageProps = {
  message: string | undefined;
  id: string;
};

const SyncSuccessMessage = (props: ErrorMessageProps) => {
  const { message, id } = props;

  return message ? (
    <div id={id} className={styles.errorMessage}>
      <span dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  ) : null;
};

export default SyncSuccessMessage;
