import React from "react";
function ClientsIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 26 26"
      name="ClientsIcon"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M11.19 9.089a4.531 4.531 0 0 0 4.528-4.524A4.53 4.53 0 0 0 11.191.042a4.529 4.529 0 0 0-4.525 4.523 4.53 4.53 0 0 0 4.525 4.524Zm0-8.396a3.878 3.878 0 0 1 3.877 3.872 3.879 3.879 0 0 1-3.876 3.873 3.878 3.878 0 0 1-3.874-3.873A3.877 3.877 0 0 1 11.191.693Zm6.828 6.864c-.619 0-1.2-.208-1.678-.6a.325.325 0 1 0-.413.504c.588.481 1.33.747 2.091.747a3.31 3.31 0 0 0 3.309-3.305 3.31 3.31 0 0 0-5.4-2.557.325.325 0 0 0 .413.503 2.65 2.65 0 0 1 1.678-.6 2.658 2.658 0 0 1 2.657 2.654 2.659 2.659 0 0 1-2.657 2.654Zm-2.144 3.894c-1.176-1.96-3.646-1.714-3.716-1.704h-2.012c-.089-.001-2.076-.053-3.244 1.624-.648.928-1.599 4.489-1.933 5.97a.325.325 0 1 0 .635.144c.352-1.556 1.28-4.949 1.833-5.742.96-1.38 2.673-1.342 2.697-1.345l2.059-.001c.021-.003 2.176-.21 3.132 1.404a.337.337 0 0 0 .025.037c.586.74 1.569 4.07 1.93 5.648a.326.326 0 0 0 .635-.146c-.284-1.237-1.29-4.913-2.04-5.889ZM4.362 8.208c.762 0 1.505-.266 2.093-.747a.326.326 0 1 0-.413-.504 2.658 2.658 0 0 1-4.336-2.054A2.658 2.658 0 0 1 4.362 2.25c.62 0 1.2.207 1.678.6a.326.326 0 0 0 .414-.504 3.306 3.306 0 0 0-5.4 2.557 3.31 3.31 0 0 0 3.308 3.305Zm17.821 4.572c-.347-1.564-.697-2.608-1.041-3.102-.856-1.231-2.322-1.193-2.372-1.192l-1.4.002c-.071-.01-1.753-.176-2.653 1.098a.325.325 0 1 0 .532.375c.676-.957 2.036-.828 2.085-.824h1.447c.012-.008 1.17-.031 1.826.912.203.292.53 1.027.94 2.871a.327.327 0 0 0 .636-.14ZM7.211 10.084a.325.325 0 1 0 .56-.332C6.912 8.3 5.09 8.48 5.048 8.486H3.623c-.059 0-1.528-.04-2.384 1.192-.346.497-.696 1.54-1.04 3.102a.326.326 0 0 0 .636.14c.317-1.436.65-2.456.94-2.87.648-.936 1.776-.913 1.837-.913l1.47-.002c.015-.001 1.484-.14 2.13.949Z"
      />
    </svg>
  );
}

export default ClientsIcon;
