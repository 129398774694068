import { useMemo, useCallback } from "react";
import Input from "../../atom/Input/Input";
import styles from "./selectClientsModal.module.scss";
import Button from "../../atom/Button/Button";
import { ButtonVariantEnum } from "../../types/enums";
import { TabSelectedClientsProps } from "../../types/props";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { ClientBGIcon, ClientsBGIcon } from "../../atom/Icon";

const TabSelectedClients = ({
  onSelectedClientsChange,
  tempSelectedClients,
}: TabSelectedClientsProps) => {
  const { t } = useTranslation();
  const [filterText, setFilterText] = useState("");

  const tempSelectedClientsRef = useRef(tempSelectedClients);

  if (tempSelectedClients) {
    if (filterText !== "") {
      tempSelectedClientsRef.current = tempSelectedClients.filter((sc) => {
        return (
          sc.clientName.toLowerCase().indexOf(filterText) !== -1 ||
          sc.clientId.toLowerCase().indexOf(filterText) !== -1
        );
      });
    } else tempSelectedClientsRef.current = tempSelectedClients;
  }

  const isClientSelected = useMemo(
    () => (client: any) => {
      if (!tempSelectedClientsRef.current) return false;
      const matchingArray = tempSelectedClientsRef.current.filter((sc: any) => {
        return sc.id === client.id;
      });
      return matchingArray.length !== 0;
    },
    []
  );

  const toggleRowSelection = useCallback(
    (client: any) => () => {
      if (tempSelectedClientsRef.current && onSelectedClientsChange) {
        const wasSelectedBefore = isClientSelected(client);

        let newSelectedClients: number[] = [];

        if (wasSelectedBefore) {
          newSelectedClients = tempSelectedClientsRef.current.filter(
            (sc: any) => {
              return sc.id !== client.id && sc.payeeId !== client.id;
            }
          );
          onSelectedClientsChange(newSelectedClients);
        }
      }
    },
    [isClientSelected, onSelectedClientsChange]
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterText(e.target.value);
    },
    []
  );

  const handleDeselectAllBtn = useCallback(() => {
    onSelectedClientsChange && onSelectedClientsChange([]);
  }, [onSelectedClientsChange]);

  const renderClientIcon = (depth: number) => {
    if (depth === 0) return <i>{ClientsBGIcon}</i>;
    else if (depth >= 1)
      return (
        <i className="inline-block" style={{ paddingLeft: 26 * depth }}>
          {ClientBGIcon}
        </i>
      );
  };

  if (!tempSelectedClientsRef.current) return <></>;

  return (
    <div className={styles.tabContentContainer}>
      <div className={styles.searchFilterContainer}>
        <div className={styles.searchContainer}>
          <Input
            id="searchInput"
            placeholder={t(
              "clientSelection.tabSelectedClients.searchPlaceholder"
            )}
            className={styles.searchInput}
            autoComplete="off"
            value={filterText}
            onChange={handleSearchChange}
            inputClassName="selectedClientsTabSearch_GTM"
          />
          {filterText.length === 0 && <i className={styles.iconSearch}></i>}
          {filterText.length !== 0 && (
            <i className={styles.iconClear} onClick={() => setFilterText("")}>
              <span></span>
            </i>
          )}
        </div>
        <div className={styles.deselectBtnContainer}>
          <Button
            variant={ButtonVariantEnum.cleanCta}
            onClick={handleDeselectAllBtn}
            className="deselectAll_GTM"
          >
            {t("clientSelection.tabSelectedClients.deselectAll")}
          </Button>
        </div>
      </div>
      <div className={styles.dataTableContainer}>
        <div className={styles.scrollable} style={{ height: "330px" }}>
          {tempSelectedClientsRef.current.length === 0 && (
            <span className="relative top-1/2 block text-center">
              {t("clientSelection.tabSelectedClients.noClientsSelected")}
            </span>
          )}
          {tempSelectedClientsRef.current.length !== 0 &&
            tempSelectedClientsRef.current?.map((client: any, index) => {
              if (!isClientSelected(client)) return <></>;
              else
                return (
                  <div
                    key={index}
                    className={`${styles.row} ${styles.selected}`}
                    onClick={toggleRowSelection(client)}
                  >
                    <div className={styles.colId}>{client.clientId}</div>
                    <div>{client.currencyId}</div>
                    <div>
                      {renderClientIcon(client.depth)} {client.clientName}
                    </div>
                  </div>
                );
            })}
        </div>
      </div>
    </div>
  );
};
export default TabSelectedClients;
