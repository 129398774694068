import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAnalysisTerritories,
  downloadAnalysisData,
} from "../recAnalysisAPI";
import { RootState } from "../../../../app/redux/store";

export interface RecAnalysisTerritoryState {
  analysisTerritoryPageState: {
    data: {
      periodIds: Array<number>;
      clientIds: any;
      clientLists: any;
    };
  };
  territories: {
    allTerritoriesRoyalty: any;
    allTerritoriesTopProducts: any;
    territories: Array<any>;
  };
  analysisTerritoryStatus: "init" | "idle" | "loading" | "failed";
  downloadStatus: "loading" | "idle" | "failed";
}

const initialState: RecAnalysisTerritoryState = {
  analysisTerritoryPageState: {
    data: {
      periodIds: [],
      clientIds: [],
      clientLists: [],
    },
  },
  territories: {
    allTerritoriesRoyalty: {},
    allTerritoriesTopProducts: {},
    territories: [],
  },
  analysisTerritoryStatus: "init",
  downloadStatus: "idle",
};

export const fetchAnalysisTerritoriesThunk = createAsyncThunk(
  "recording/analysisTerritories",
  async (payload: any, thunkAPI: any) => {
    const response = await fetchAnalysisTerritories(payload, thunkAPI);
    return response;
  }
);

export const recAnalysisTerritoryDownload = createAsyncThunk(
  "recAnalysis/downloadTerritory",
  async (payload: any, thunkAPI) => {
    const response = await downloadAnalysisData(payload, thunkAPI);
    return response;
  }
);

export const recAnalysisTerritoriesSlice = createSlice({
  name: "recAnalysisTerritory",
  initialState,
  reducers: {
    updateAnalysisTerritoryPageState: (state: any, action: any) => {
      state.analysisTerritoryPageState.data.periodIds =
        action.payload.data.periodIds;
      state.analysisTerritoryPageState.data.clientIds =
        action.payload.data.clientIds;
      state.analysisTerritoryPageState.data.clientLists =
        action.payload.data.clientLists;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAnalysisTerritoriesThunk.fulfilled,
        (state: any, action: any) => {
          state.analysisTerritoryStatus = "idle";
          state.territories.allTerritoriesRoyalty =
            action.payload?.allTerritoriesRoyalty;
          state.territories.allTerritoriesTopProducts =
            action.payload?.allTerritoriesTopProducts;
          state.territories.territories = action.payload?.territories;
        }
      )
      .addCase(fetchAnalysisTerritoriesThunk.pending, (state) => {
        state.analysisTerritoryStatus = "loading";
      })
      .addCase(fetchAnalysisTerritoriesThunk.rejected, (state) => {
        state.analysisTerritoryStatus = "failed";
      })
      .addCase(recAnalysisTerritoryDownload.fulfilled, (state) => {
        state.downloadStatus = "idle";
      })
      .addCase(recAnalysisTerritoryDownload.pending, (state) => {
        state.downloadStatus = "loading";
      })
      .addCase(recAnalysisTerritoryDownload.rejected, (state) => {
        state.downloadStatus = "failed";
      });
  },
});

export const recAnalysisTerritoryPageStateSelector = (state: RootState) =>
  state.recAnalysisTerritory.analysisTerritoryPageState;
export const recAnalysisTerritoriesSelector = (state: RootState) =>
  state.recAnalysisTerritory.territories;
export const recAnalysisDownloadStatusSelector = (state: RootState) =>
  state.recAnalysisTerritory.downloadStatus;
export const recAnalysisTerritoryStatusSelector = (state: RootState) =>
  state.recAnalysisTerritory.analysisTerritoryStatus;

export const {
  updateAnalysisTerritoryPageState: updateAnalysisTerritoryPageStateAction,
} = recAnalysisTerritoriesSlice.actions;

export default recAnalysisTerritoriesSlice.reducer;
