import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import { fetchSync, fetchSyncOverview, downloadSyncData } from "./pubSyncAPI";

export declare type PubSyncPageStateProps = {
  data: {
    clientIds: any;
    clientLists: any;
  };
  documentType: string;
  count: number;
  activeTab: PubSyncTabEntry;
  tabsState: PubSyncTabsState;
};

export declare type PubSyncOverviewPageStateProps = {
  licensesByMedia: [] | null;
  licensesByStatus: [] | null;
  mostRecentLicenses: [] | null;
  mostRequestedSongs: [] | null;
};

export type PubSyncTabEntry =
  | "ALL"
  | "QUOTING"
  | "LICENSING"
  | "RECEIVED"
  | "COMPLETED"
  | "UNKNOWN"; // unknown = overview tab

export type PubSyncTabData = {
  timestamp?: string;
  error?: string;
  path?: string;
  data: any[];
  total: number;
  noDataFlag?: string;
};

type PubSyncTabTabData = {
  ALL: PubSyncTabData;
  QUOTING: PubSyncTabData;
  LICENSING: PubSyncTabData;
  RECEIVED: PubSyncTabData;
  COMPLETED: PubSyncTabData;
  UNKNOWN?: PubSyncTabData;
};

type PubSyncTabState = {
  tabClientData: {
    clientIds: any;
    clientLists: any;
    periodIds?: number[];
  };
  status?: string;
  start?: number;
  filterText?: string;
  sortColumn?: string;
  isAsc?: boolean;
};

type PubSyncTabsState = {
  [key: string]: PubSyncTabState;
};

type PubSyncTabProps = {
  [key: string]: PubSyncTabTabData | PubSyncTabEntry | undefined;
  data: PubSyncTabTabData;
};

export interface PubSyncState {
  pubSyncPageState: PubSyncPageStateProps;
  pubSyncOverview: PubSyncOverviewPageStateProps;
  pubSyncTab: PubSyncTabProps;
  status: "idle" | "loading" | "failed";
  downloadStatus: "idle" | "loading" | "failed";
  hasError: boolean;
}

const initialState: PubSyncState = {
  pubSyncPageState: {
    data: {
      clientIds: [],
      clientLists: [],
    },
    documentType: "PUBLISHING",
    activeTab: "UNKNOWN",
    count: 100,
    tabsState: {
      ALL: {
        tabClientData: {
          clientIds: [],
          clientLists: [],
        },
        start: 0,
        filterText: "",
        isAsc: false,
        status: "",
        sortColumn: "statusLastModifiedDate",
      },
      QUOTING: {
        tabClientData: {
          clientIds: [],
          clientLists: [],
        },
        start: 0,
        filterText: "",
        isAsc: false,
        status: "",
        sortColumn: "statusLastModifiedDate",
      },
      LICENSING: {
        tabClientData: {
          clientIds: [],
          clientLists: [],
        },
        start: 0,
        filterText: "",
        isAsc: false,
        status: "",
        sortColumn: "statusLastModifiedDate",
      },
      RECEIVED: {
        tabClientData: {
          clientIds: [],
          clientLists: [],
        },
        start: 0,
        filterText: "",
        isAsc: false,
        status: "",
        sortColumn: "statusLastModifiedDate",
      },
      COMPLETED: {
        tabClientData: {
          clientIds: [],
          clientLists: [],
          periodIds: undefined,
        },
        start: 0,
        filterText: "",
        isAsc: false,
        status: "",
        sortColumn: "statusLastModifiedDate",
      },
    },
  },
  pubSyncOverview: {
    licensesByMedia: null,
    licensesByStatus: null,
    mostRecentLicenses: null,
    mostRequestedSongs: null,
  },
  pubSyncTab: {
    data: {
      ALL: { data: [], total: 0, noDataFlag: undefined },
      QUOTING: { data: [], total: 0, noDataFlag: undefined },
      LICENSING: { data: [], total: 0, noDataFlag: undefined },
      RECEIVED: { data: [], total: 0, noDataFlag: undefined },
      COMPLETED: { data: [], total: 0, noDataFlag: undefined },
    },
  },
  downloadStatus: "idle",
  status: "idle",
  hasError: false,
};

export const fetchPubSyncOverviewThunk = createAsyncThunk(
  "pubSync/syncoverview",
  async (payload: any, thunkAPI) => {
    const response = await fetchSyncOverview(payload, thunkAPI);
    return response;
  }
);

export const fetchPubSyncThunk = createAsyncThunk(
  "pubSync/sync",
  async (payload: any, thunkAPI) => {
    const response = await fetchSync(payload, thunkAPI);
    return response;
  }
);

export const fetchMorePubSyncThunk = createAsyncThunk(
  "pubSync/moresync",
  async (payload: any, thunkAPI) => {
    const response = await fetchSync(payload, thunkAPI);
    return response;
  }
);

export const pubSyncDownloadThunk = createAsyncThunk(
  "pubSync/download",
  async (payload: any, thunkAPI) => {
    const response = await downloadSyncData(payload, thunkAPI);
    return response;
  }
);

export const pubSyncSlice = createSlice({
  name: "pubSync",
  initialState,
  reducers: {
    updatePubSyncPageState: (state, action: any) => {
      state.pubSyncPageState = {
        ...state.pubSyncPageState,
        ...action.payload,
      };
    },
    updatePubSyncActiveTab: (state, action: { payload: PubSyncTabEntry }) => {
      const activeTab = action.payload;
      state.pubSyncPageState.activeTab = activeTab;
    },
    resetPubSyncSearch: (state) => {
      const activeTab = state.pubSyncPageState.activeTab;
      state.pubSyncTab.data = {
        ...state.pubSyncTab.data,
        [activeTab]: { data: [] },
      };
    },
    resetPubSyncTabTabsState: (state) => {
      state.pubSyncTab.data = initialState.pubSyncTab.data;
      state.pubSyncPageState.tabsState =
        initialState.pubSyncPageState.tabsState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPubSyncOverviewThunk.fulfilled, (state, action) => {
        state.status = "idle";
        state.pubSyncOverview.licensesByMedia = action.payload?.licensesByMedia;
        state.pubSyncOverview.licensesByStatus =
          action.payload?.licensesByStatus;
        state.pubSyncOverview.mostRecentLicenses =
          action.payload?.mostRecentLicenses;
        state.pubSyncOverview.mostRequestedSongs =
          action.payload?.mostRequestedSongs;

        // update count values
        action.payload?.licensesByStatus?.forEach((licence: any) => {
          if (licence.status !== "UNKNOWN") {
            const currentStatus = licence.status as string;
            const currentTab = state.pubSyncTab.data[
              currentStatus.toUpperCase() as PubSyncTabEntry
            ] as PubSyncTabData;
            currentTab.total = licence.count;
          }
        });
      })
      .addCase(fetchPubSyncThunk.fulfilled, (state, action) => {
        const activeTab = state.pubSyncPageState.activeTab;
        state.status = "idle";
        state.pubSyncTab.data = {
          ...state.pubSyncTab?.data,
          [activeTab]: {
            ...state.pubSyncTab?.data?.[activeTab],
            ...action.payload,
            noDataFlag:
              action.payload?.total === 0
                ? (state?.pubSyncPageState.tabsState[activeTab]?.filterText
                    ?.length || 0) > 0
                  ? "noSearchData"
                  : "noData"
                : "",
          },
        };
      })
      .addCase(fetchMorePubSyncThunk.fulfilled, (state, action) => {
        const activeTab = state.pubSyncPageState.activeTab;
        state.status = "idle";
        state.pubSyncTab = {
          ...state.pubSyncTab,
          data: {
            ...state.pubSyncTab?.data,
            [activeTab]: {
              ...state.pubSyncTab?.data?.[activeTab],
              data: [
                ...(state.pubSyncTab?.data?.[activeTab]?.data || []),
                ...(action.payload.data || []),
              ],
            },
          },
        };
      })
      .addCase(fetchPubSyncOverviewThunk.rejected, (state) => {
        state.status = "failed";
        state.pubSyncOverview = initialState.pubSyncOverview;
      })
      // download
      .addCase(pubSyncDownloadThunk.fulfilled, (state, action: any) => {
        state.downloadStatus = "idle";
      })
      .addCase(pubSyncDownloadThunk.pending, (state) => {
        state.downloadStatus = "loading";
      })
      .addCase(pubSyncDownloadThunk.rejected, (state) => {
        state.downloadStatus = "failed";
      })
      .addMatcher(
        isAnyOf(
          fetchPubSyncOverviewThunk.pending,
          fetchPubSyncThunk.pending,
          fetchMorePubSyncThunk.pending
        ),
        (state) => {
          state.status = "loading";
          state.hasError = false;
        }
      )
      .addMatcher(isAnyOf(fetchPubSyncOverviewThunk.rejected), (state) => {
        state.status = "failed";
        state.hasError = true;
      });
  },
});

export const pubSyncPageStateSelector = (state: RootState) =>
  state.pubSync.pubSyncPageState;
export const pubSyncOverviewSelector = (state: RootState) =>
  state.pubSync.pubSyncOverview;
export const pubSyncStatusSelector = (state: RootState) => state.pubSync.status;
export const pubSyncTabSelector = (state: RootState) =>
  state.pubSync.pubSyncTab;
export const pubSyncCurrentTabSelector = (state: RootState) =>
  state.pubSync.pubSyncPageState.activeTab;

export const {
  updatePubSyncPageState: updatePubSyncPageStateAction,
  updatePubSyncActiveTab: updatePubSyncActiveTabAction,
  resetPubSyncSearch: resetPubSyncSearchAction,
  resetPubSyncTabTabsState: resetPubSyncTabTabsStateAction,
} = pubSyncSlice.actions;

export default pubSyncSlice.reducer;
