import React from "react";
function CloseIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="611 432 25 37"
      name="CloseIcon"
      {...props}
    >
      <text
        fill="none"
        fontFamily="Times-Roman, Times"
        fontSize="30"
        opacity=".5"
      >
        <tspan x="615.041" y="461" fill="#000">
          ×
        </tspan>
      </text>
    </svg>
  );
}

export default CloseIcon;
