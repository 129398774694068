import { useCallback } from "react";

export declare type DropdownOptionProps = {
  label?: React.ReactNode;
  value?: string;
  type?: string;
  isSelected?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  onSelectCallback?: (selectedValue: string) => void;
  className?: string;
};

export const DropdownOption = (props: DropdownOptionProps) => {
  const {
    value,
    label,
    type,
    isSelected = false,
    iconLeft,
    iconRight,
    onSelectCallback,
    className,
  } = props;

  const handleClick = useCallback(() => {
    if (onSelectCallback) {
      const returnValue = value ? value : "";
      onSelectCallback(returnValue);
    }
  }, [onSelectCallback, value]);

  return (
    <button
      className={`${isSelected ? "dropdown-item active" : "dropdown-item"}${
        className ? ` ${className}` : ""
      }`}
      type="button"
      onClick={handleClick}
    >
      {iconLeft && <i className="icon icon-left">{iconLeft}</i>}
      <span>{label}</span>
      {type === "static" && iconRight && (
        <i className="icon icon-right">{iconRight}</i>
      )}
    </button>
  );
};
