import { useTranslation } from "react-i18next";
import styles from "./../analysis.module.scss";
import type { RecAnalysisTrackBreakdowTerritoryData } from "../../../../features/recording/analysis/track/recAnalysisTrackBreakdownSlice";

const AnalysisTrackBreakdownTerritory = ({
  data,
  status,
}: {
  data: RecAnalysisTrackBreakdowTerritoryData | null;
  status: "noData" | "loading" | "idle" | "failed";
}): JSX.Element => {
  const { t } = useTranslation();

  if (status === "noData" || status === "loading")
    return <p className="pl-2 pt-4">{t("app.loading")}</p>;

  if (!data || !data.territories || data?.territories?.length === 0) {
    if (status === "idle")
      return (
        <p className="pl-2 pt-4">{t("analysis.songs.breakdown.noData")}</p>
      );
    else return <p className="pl-2 pt-4">{t("app.loading")}</p>;
  }

  return (
    <div className={`smallScrollbar ${styles.territoryStatsContainer}`}>
      {data.territories.map((territory: any, i: number) => (
        <div className={styles.statRow} key={i}>
          <div
            className={styles.bar}
            style={{ width: territory.royalty.percent + "%" }}
          ></div>
          <div className={styles.info}>
            <span>{territory.countryName} </span>
          </div>
          <div className={styles.value}>
            <span className={styles.royalty}>
              {territory.royalty.currency} {territory.royalty.formattedShort}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
export default AnalysisTrackBreakdownTerritory;
