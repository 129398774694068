import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import { fetchSendForgotPasswordPayload } from "./forgotPasswordAPI";
import type { ForgotPasswordPayload } from "../../../app/types/payload";

export interface ForgotPasswordState {
  retieveStatus: "idle" | "loading" | "failed";
  retieveSuccess: boolean;
}

const initialState: ForgotPasswordState = {
  retieveStatus: "idle",
  retieveSuccess: false,
};

export const fetchSubmitForgotPasswordThunk = createAsyncThunk(
  "forgot/password",
  async (payload: ForgotPasswordPayload, thunkAPI?: any) => {
    const response = await fetchSendForgotPasswordPayload(payload, thunkAPI);
    return response;
  }
);

export const useForgotPasswordSlice = createSlice({
  name: "userForgotPassword",
  initialState,
  reducers: {
    resetForgotPasswordForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubmitForgotPasswordThunk.fulfilled, (state) => {
        state.retieveStatus = "idle";
        state.retieveSuccess = true;
      })
      .addCase(fetchSubmitForgotPasswordThunk.pending, (state) => {
        state.retieveStatus = "loading";
      })
      .addCase(fetchSubmitForgotPasswordThunk.rejected, (state) => {
        state.retieveStatus = "failed";
        state.retieveSuccess = false;
      });
  },
});

export const retrieveStatusSelector = (state: RootState) =>
  state.userForgotPassword.retieveStatus;

export const retrieveSuccessSelector = (state: RootState) =>
  state.userForgotPassword.retieveSuccess;

export const { resetForgotPasswordForm: resetForgotPasswordFormAction } =
  useForgotPasswordSlice.actions;

export default useForgotPasswordSlice.reducer;
