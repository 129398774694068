import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import { fetchContacts, fetchForms, downloadContactForms } from "./contactsAPI";

export interface ContactsState {
  contactsPageState: {
    documentType: string;
    filterText: string;
  };
  contactUsFormPageState: {
    isOpen: boolean;
  };
  contacts: {
    list: any[];
  };
  contactsStatus: "idle" | "loading" | "failed";
  contactForms: any[];
  downloadStatus: "loading" | "idle" | "failed";
}

const initialState: ContactsState = {
  contactsPageState: {
    documentType: "",
    filterText: "",
  },
  contactUsFormPageState: {
    isOpen: false,
  },
  contacts: {
    list: [],
  },
  contactsStatus: "idle",
  contactForms: [],
  downloadStatus: "idle",
};

export const fetchContactsThunk = createAsyncThunk(
  "contacts/contacts",
  async (payload: any, thunkAPI) => {
    const response = await fetchContacts(payload, thunkAPI);
    return response?.resArr;
  }
);

export const fetchFormsThunk = createAsyncThunk(
  "contacts/forms",
  async (payload: any, thunkAPI) => {
    const response = await fetchForms(payload, thunkAPI);
    return response?.resArr;
  }
);

export const pubContactFormsDownload = createAsyncThunk(
  "contacts/downloadForms",
  async (payload: any, thunkAPI) => {
    const response = await downloadContactForms(payload, thunkAPI);
    return response;
  }
);

export const pubContactsSlice = createSlice({
  name: "pubContacts",
  initialState,
  reducers: {
    updateContactsListPageState: (state, action: any) => {
      state.contactsPageState.documentType = action.payload.documentType;
      state.contactsPageState.filterText = action.payload.filterText;
    },
    updateContactUsFormPageState: (state, action: any) => {
      state.contactUsFormPageState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactsThunk.fulfilled, (state, action: any) => {
        state.contactsStatus = "idle";
        state.contacts.list = action.payload;
      })
      .addCase(fetchFormsThunk.fulfilled, (state, action: any) => {
        state.contactsStatus = "idle";
        state.contactForms = action.payload;
      })
      .addCase(pubContactFormsDownload.fulfilled, (state) => {
        state.contactsStatus = "idle";
      })
      .addMatcher(isAnyOf(fetchContactsThunk.pending), (state) => {
        state.contactsStatus = "loading";
      })
      .addMatcher(isAnyOf(fetchContactsThunk.rejected), (state) => {
        state.contactsStatus = "failed";
      })
      .addMatcher(isAnyOf(fetchFormsThunk.pending), (state) => {
        state.contactsStatus = "loading";
      })
      .addMatcher(isAnyOf(fetchFormsThunk.rejected), (state) => {
        state.contactsStatus = "failed";
      })
      .addMatcher(isAnyOf(pubContactFormsDownload.pending), (state) => {
        state.contactsStatus = "loading";
      })
      .addMatcher(isAnyOf(pubContactFormsDownload.rejected), (state) => {
        state.contactsStatus = "failed";
      });
  },
});

export const contactsPageStateSelector = (state: RootState) =>
  state.pubContacts.contactsPageState;
export const contactUsFormPageStateSelector = (state: RootState) =>
  state.pubContacts.contactUsFormPageState;
export const contactsListSelector = (state: RootState) =>
  state.pubContacts.contacts;
export const contactsStatusSelector = (state: RootState) =>
  state.pubContacts.contactsStatus;
export const contactsFormsSelector = (state: RootState) =>
  state.pubContacts.contactForms;

export const {
  updateContactsListPageState: updateContactsListPageStateAction,
  updateContactUsFormPageState: updateContactUsFormPageStateAction,
} = pubContactsSlice.actions;

export default pubContactsSlice.reducer;
