import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import {
  meSelector,
  meStatus,
  sidebarPermissionsSelector,
} from "../../features/appMain/appMainSlice";
import { userRoutes } from "../../features/routes/userRoutes";
import { ROUTES } from "../../features/routes/routes.const";
import {
  PATH_CONTACTUS,
  PATH_WELCOME_TOUR,
} from "../../features/routes/paths.const";

function useCustomRoutes() {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const { pathname, state: locationHooks } = useLocation();
  const sidebarPermissions = useAppSelector(sidebarPermissionsSelector);
  const { isShowWelcomeTour, isGuided } = useAppSelector(meSelector);
  const userStatus = useAppSelector(meStatus);

  const isDashboardAccessPermitted = (userPermissions: Array<any>): boolean =>
    userPermissions.indexOf("DASHBOARD_LINK") > -1 ||
    userPermissions.indexOf("DASHBOARD_TITLE") > -1;

  const getAllUserRoutes = useCallback(() => {
    const allUserRoutes = userRoutes[0].children.find(
      (route) => route.path === "user"
    );

    const routes: Array<any> = [];

    const getRoutes = (children: Array<any>, path?: string) => {
      children.forEach((route) => {
        if (route.element?.props?.requiredPermission) {
          routes.push({
            path: path ? `${path}/` + route.path : route.path,
            permissions:
              typeof route.element?.props?.requiredPermission === "string"
                ? [route.element?.props?.requiredPermission]
                : route.element?.props?.requiredPermission,
            permissionGroup: route.element?.props.permissionGroup,
          });
        } else if (route.children) {
          getRoutes(
            route.children,
            path ? `${path}/` + route.path : route.path
          );
        }
      });
    };

    allUserRoutes?.children &&
      getRoutes(allUserRoutes?.children, allUserRoutes?.path);

    return routes;
  }, []);

  const getDefaultSecureRoute = useCallback(
    (userPermissions: Array<any>, permissionGroup: string) => {
      const allUserRoutes = getAllUserRoutes();
      let defaultSecureRoute = "";
      allUserRoutes?.forEach((userRoute) => {
        if (userRoute.permissionGroup !== permissionGroup) return;
        userRoute.permissions?.forEach((permission: string) => {
          if (
            userPermissions?.indexOf(permission) > -1 &&
            defaultSecureRoute === ""
          ) {
            defaultSecureRoute = `/${ROUTES.mybmg}/${userRoute.path}`;
          }
        });
      });
      return defaultSecureRoute;
    },
    [getAllUserRoutes]
  );

  const hasCurrRoutePermission = useCallback(
    (currRoute: string) => {
      let currRoutePermission = "false";
      if (
        pathname === "/" ||
        pathname === PATH_WELCOME_TOUR ||
        pathname === PATH_CONTACTUS
      )
        return currRoutePermission;
      const allUserRoutes = getAllUserRoutes();
      const route = allUserRoutes?.filter((userRoute) => {
        const userRoutePath = `/${ROUTES.mybmg}/${userRoute.path}`;
        const currRouteSlugs = currRoute.split("/");
        return userRoutePath.indexOf(currRouteSlugs.join("/")) > -1;
      });

      if (route.length === 0) {
        currRoutePermission = "noRoute";
        return currRoutePermission;
      } else if (route.length > 1) {
        currRoutePermission = "false";
        return currRoutePermission;
      }
      const permissionGroup:
        | "publishing"
        | "recording"
        | "threeSixty"
        | "mechanical"
        | "more" = route?.[0]?.permissionGroup;
      route?.[0]?.permissions?.find((routePermission: string) => {
        if (sidebarPermissions) {
          return sidebarPermissions[permissionGroup]?.find(
            (sidebarePermission) => {
              if (sidebarePermission === routePermission) {
                currRoutePermission = "true";
                return true;
              } else return false;
            }
          );
        } else return false;
      });
      return currRoutePermission;
    },
    [getAllUserRoutes, pathname, sidebarPermissions]
  );

  const getFirstPage = useCallback(() => {
    let defaultSecureRoute = "";
    if (sidebarPermissions?.publishing) {
      if (isDashboardAccessPermitted(sidebarPermissions?.publishing)) {
        defaultSecureRoute = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.dashboard}`;
      } else {
        defaultSecureRoute = getDefaultSecureRoute(
          sidebarPermissions?.publishing,
          "publishing"
        );
      }
    } else if (sidebarPermissions?.recording) {
      if (isDashboardAccessPermitted(sidebarPermissions?.recording)) {
        defaultSecureRoute = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.dashboard}`;
      } else {
        defaultSecureRoute = getDefaultSecureRoute(
          sidebarPermissions?.recording,
          "recording"
        );
      }
    } else if (sidebarPermissions?.mechanical) {
      if (isDashboardAccessPermitted(sidebarPermissions?.mechanical)) {
        defaultSecureRoute = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.mechanical}/${ROUTES.dashboard}`;
      } else {
        defaultSecureRoute = getDefaultSecureRoute(
          sidebarPermissions?.mechanical,
          "mechanical"
        );
      }
    }

    return (
      locationHooks?.locationlocationHooks?.originalUri || defaultSecureRoute
    );
  }, [
    getDefaultSecureRoute,
    locationHooks?.locationlocationHooks?.originalUri,
    sidebarPermissions?.mechanical,
    sidebarPermissions?.publishing,
    sidebarPermissions?.recording,
  ]);

  const routeNextPage = useCallback(() => {
    if (isShowWelcomeTour === true && !isGuided) {
      navigate(PATH_WELCOME_TOUR);
      return;
    }
    const currRoutePermission = hasCurrRoutePermission(pathname);
    if (currRoutePermission === "noRoute") {
      navigate(pathname, { replace: true });
    } else if (currRoutePermission === "false" && !!sidebarPermissions) {
      const firstPage = getFirstPage();
      if (firstPage) {
        navigate(firstPage);
      }
    }
  }, [
    getFirstPage,
    hasCurrRoutePermission,
    isGuided,
    isShowWelcomeTour,
    navigate,
    pathname,
    sidebarPermissions,
  ]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      if (locationHooks?.originalUri?.length) {
        navigate(`/${ROUTES.mybmg}/${ROUTES.login}`);
      } else if (
        userStatus === "idle" &&
        pathname !== `/${ROUTES.mybmg}/${ROUTES.login}`
      ) {
        routeNextPage();
      }
    }
  }, [
    authState?.isAuthenticated,
    locationHooks?.originalUri?.length,
    navigate,
    pathname,
    routeNextPage,
    userStatus,
  ]);

  return;
}

export default useCustomRoutes;
