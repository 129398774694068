import { useOktaAuth } from "@okta/okta-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import {
  resetForgotUsernameFormAction,
  retrieveSuccessSelector as retrieveUsernameSuccessSelector,
} from "../forgotUsername/forgotUsernameSlice";
import {
  resetForgotPasswordFormAction,
  retrieveSuccessSelector,
} from "../forgotPassword/forgotPasswordSlice";
import {
  resetSuccessSelector,
  resetResetPasswordFormAction,
} from "../resetPassword/resetPasswordSlice";
import {
  registrationSuccessStatusSelector,
  resetRegistrationFormAction,
} from "../register/registerSlice";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ExclamationIcon } from "../../../app/atom/Icon";
import { ILogin } from "../../../app/types/interfaces";
import { ROUTES } from "../../routes/routes.const";

const useLogin = (): ILogin => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { state: locationHooks } = useLocation();
  const { authState } = useOktaAuth();
  const logoutMessage = useRef<React.ReactNode>(null);
  const [isKeepLoggedInChecked, setIsKeepLoggedInChecked] = useState(false);

  const registrationSuccess = useAppSelector(registrationSuccessStatusSelector);
  const forgotUsernameSuccess = useAppSelector(retrieveUsernameSuccessSelector);
  const forgotPasswordRetrieveSuccess = useAppSelector(retrieveSuccessSelector);
  const resetPasswordSuccess = useAppSelector(resetSuccessSelector);

  const resetStatus = useCallback(() => {
    if (
      registrationSuccess ||
      forgotUsernameSuccess ||
      resetPasswordSuccess ||
      forgotPasswordRetrieveSuccess
    ) {
      if (registrationSuccess) {
        dispatch(resetRegistrationFormAction());
      }
      if (forgotUsernameSuccess) {
        dispatch(resetForgotUsernameFormAction());
      }
      if (forgotPasswordRetrieveSuccess) {
        dispatch(resetForgotPasswordFormAction());
      }
      if (resetPasswordSuccess) {
        dispatch(resetResetPasswordFormAction());
      }
    }
  }, [
    dispatch,
    forgotPasswordRetrieveSuccess,
    forgotUsernameSuccess,
    registrationSuccess,
    resetPasswordSuccess,
  ]);

  const toggleCheckbox = () => {
    setIsKeepLoggedInChecked(!isKeepLoggedInChecked);
  };

  useEffect(() => {
    // reset registration status on page reload
    if (
      registrationSuccess &&
      locationHooks?.originalUri &&
      locationHooks?.originalUri === `/${ROUTES.mybmg}/${ROUTES.login}`
    ) {
      dispatch(resetRegistrationFormAction());
    }
  }, [dispatch, locationHooks?.originalUri, registrationSuccess]);

  useEffect(() => {
    const logoutReason = localStorage.getItem("logoutReason");
    if (logoutReason) {
      if (logoutReason === "declineTOU")
        logoutMessage.current = (
          <div className="error-message">
            <div>
              <ExclamationIcon className="h-10 w-10 fill-red-600 pr-2" />
              <span>{t("login.logoutMessage.declineTOU")}</span>
            </div>
          </div>
        );
    }

    return () => {
      localStorage.removeItem("logoutReason");
    };
  });

  return {
    registrationSuccess,
    forgotUsernameSuccess,
    forgotPasswordRetrieveSuccess,
    resetPasswordSuccess,
    isKeepLoggedInChecked,
    authState,
    logoutMessage,
    resetStatus,
    toggleCheckbox,
  };
};

export default useLogin;
