import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import { fetchPeriods } from "./periodsAPI";
import getPeriod from "../../utils/getPeriod";

export declare type PeriodsStateProps = {
  data: {
    clientIds: any;
    clientLists: any;
    withPipeline: boolean;
    yearsBack: number;
    documentType: "PUBLISHING" | "RECORDING";
  };
};

export interface PeriodsState {
  periodsState: PeriodsStateProps;
  periods: Array<Period>;
  status: "idle" | "loading" | "failed";
}

export interface Period {
  year: number;
  month: string;
  periodStr: string;
  periodNum: number;
}

const initialState: PeriodsState = {
  periodsState: {
    data: {
      clientIds: [],
      clientLists: [],
      withPipeline: true,
      yearsBack: 0,
      documentType: "PUBLISHING",
    },
  },
  periods: [],
  status: "idle",
};

export const fetchPeriodsThunk = createAsyncThunk(
  "all/periodSelector",
  async (payload: any, thunkAPI) => {
    const response = await fetchPeriods(payload, thunkAPI);
    return response;
  }
);

const getPeriodArrayFromYears = (years: Array<any>): Array<Period> => {
  const periodArr: Array<Period> = [];
  years?.forEach((periods) => {
    if (periods.periods)
      periods.periods.forEach((period: number) => {
        const periodYear = Number(period.toString().substring(0, 4));
        const periodObj: Period = {
          year: periodYear,
          month: "",
          periodNum: period,
          periodStr: getPeriod(period),
        };
        periodArr.push(periodObj);
      });
  });
  return periodArr;
};

export const periodsSlice = createSlice({
  name: "periods",
  initialState,
  reducers: {
    resetPeriods: () => initialState,
    updatePeriodsState: (state, action: any) => {
      state.periodsState.data.clientIds = action.payload.data.clientIds;
      state.periodsState.data.clientLists = action.payload.data.clientLists;
      state.periodsState.data.withPipeline = action.payload.data.withPipeline;
      state.periodsState.data.yearsBack = action.payload.data.yearsBack;
      state.periodsState.data.documentType = action.payload.data.documentType;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPeriodsThunk.fulfilled, (state, action: any) => {
        state.status = "idle";
        state.periods = getPeriodArrayFromYears(action.payload.years);
      })
      .addCase(fetchPeriodsThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPeriodsThunk.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const PeriodSelector = (state: RootState) => state.periods;

export default periodsSlice.reducer;
