import React from "react";
function DownloadIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="100%" height="100%" name="DownloadIcon" {...props}>
      <path d="M7.802 9.842a.312.312 0 0 0 .441 0l3.658-3.625a.313.313 0 1 0-.442-.445L8.333 8.869V.314a.314.314 0 1 0-.627 0v8.549L4.588 5.772a.314.314 0 1 0-.441.445l3.655 3.625ZM15.537 7a.31.31 0 0 0-.309.31v6.07H.62V7.31a.31.31 0 1 0-.62 0v6.38c0 .171.139.31.31.31h15.227a.31.31 0 0 0 .31-.31V7.31a.31.31 0 0 0-.31-.31Z" />
    </svg>
  );
}

export default DownloadIcon;
