import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export function fetchSongRegistration(
  params: {
    data: {
      clientSelectionRequest: {
        clientIds: Array<number>;
        clientList: Array<any>;
      };
      searchTags?: Array<{
        searchTerm: string;
        categoryType: string;
      }>;
    };
  },
  thunkAPI?: any
) {
  return request(`${USER_API_RESOURCE_BASE_URLS.songregistration}`, {
    method: "POST",
    body: JSON.stringify(params?.data),
    signal: thunkAPI?.signal,
  });
}

export function fetchSongRegistrationBreakdown(
  params: {
    data: {
      clientSelectionRequest: {
        clientIds: Array<number>;
      };
      searchTags?: Array<{
        searchTerm: string;
        categoryType: string;
      }>;
    };
  },
  thunkAPI?: any
) {
  return request(`${USER_API_RESOURCE_BASE_URLS.songregistrationBreakdown}`, {
    method: "POST",
    body: JSON.stringify(params?.data),
    signal: thunkAPI?.signal,
  });
}

export function fetchSongRegistrationSearch(
  params: {
    searchTerm: string;
    data: {
      clientIds: Array<number>;
    };
  },
  thunkAPI?: any
) {
  const { searchTerm } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.songregistrationSearch}?searchTerm=${searchTerm}`,
    {
      method: "POST",
      body: JSON.stringify(params?.data),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchSongRegistrationDownload(
  params: {
    data: {
      clientSelectionRequest: {
        clientIds: Array<number>;
      };
      searchTags: Array<{
        searchTerm: string;
        categoryType: string;
      }>;
    };
    fileName: string;
  },
  thunkAPI?: any
) {
  const { data, fileName } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.songregistrationDownload}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
    isDownload: true,
    fileName,
  });
}
