/* eslint-disable no-prototype-builtins */
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import { fetchClientPeriodIncome, fetchClients } from "./recrootAPI";
import { fetchClients as fetchClientPageClients } from "../clients/clientsAPI";
import {
  fetchClientSelectionClientLists,
  fetchClientSelectionParents,
  fetchSelectedClientList,
  fetchSelectedClients,
  saveClientList,
  selectClients,
  updateClientList,
} from "../../appMain/appMainAPI";
import {
  ClientSelectionStateProps,
  ClientSelectionTabParentsPageStateProps,
} from "../../../app/types/props";
import { deleteClientList } from "../../appMain/appMainAPI";
import type { FetchClientsPayload } from "../../../app/types/interfaces";
import { fetchSubClients } from "../../publishing/pubroot/pubrootAPI";
import { updateDashboardPeriodsToInitialStateAction } from "../dashboard/dashboardSlice";
import deepCompare from "../../../app/utils/deepCompare";

export interface RecRootState {
  advanceRequestPageState: {
    level1IsOpen: boolean;
    level2IsOpen: boolean;
    selectedClient: any;
  };
  clientPeriodIncomePageState: {
    data: {
      clientIds: any;
      clientLists: any;
    };
    currentClientPeriodIncome: {
      hasData: boolean;
      latestPeriodId: number;
      previousPeriodId: number;
      periodsWithIncome: Array<number>;
    };
  };
  advanceRequestClient: {
    list: any;
    total: number | null;
  };
  advanceRequestClientStatus: "idle" | "loading" | "failed";
  clientSelection: ClientSelectionStateProps;
  clientPeriodIncome: {
    clientIds: any;
    hasData: boolean;
    latestPeriodId: number;
    previousPeriodId: number;
    periodsWithIncome: Array<number>;
  };
  selectedClientIds: number[] | null;
  clientPeriodIncomeStatus: "noData" | "idle" | "loading" | "failed";
  hasError: boolean;
}

const initialState: RecRootState = {
  advanceRequestPageState: {
    level1IsOpen: false,
    level2IsOpen: false,
    selectedClient: null,
  },
  clientPeriodIncomePageState: {
    data: {
      clientIds: [],
      clientLists: [],
    },
    currentClientPeriodIncome: {
      hasData: false,
      latestPeriodId: 0,
      previousPeriodId: 0,
      periodsWithIncome: [],
    },
  },
  advanceRequestClient: {
    list: [],
    total: null,
  },
  advanceRequestClientStatus: "idle",
  clientSelection: {
    tempParentsClientsToBeSelected: [],
    selectedClients: [],
    selectedClientsStatus: "initData",
    selectedClientList: null,
    selectedClientListStatus: "initData",
    tabAllClientsPageState: {
      data: {
        withSubClients: true,
      },
      start: 0,
      count: 200,
      sortColumn: "path",
      filterText: "",
      isAsc: true,
      currencyId: "",
      documentType: "RECORDING",
    },
    tabAllClients: {
      list: [],
      total: null,
    },
    tabAllClientsStatus: "idle",
    clientListsPageState: {
      start: 0,
      count: 200,
      sortColumn: "name",
      filterText: "",
      isAsc: true,
    },
    clientLists: {
      list: [],
      total: null,
    },
    clientListsStatus: "initData",
    tabParentsPageState: {
      sortColumn: "path",
      filterText: "",
      isAsc: true,
      currencyId: "",
      clientDocumentType: "RECORDING",
    },
    parents: [],
    parentsStatus: "idle",
    subClients: [],
    subClientsStatus: "initData",
    selectedParents: [],
    selectedParentsStatus: "idle",
  },
  clientPeriodIncome: {
    clientIds: [],
    hasData: false,
    latestPeriodId: 0,
    previousPeriodId: 0,
    periodsWithIncome: [],
  },
  selectedClientIds: null,
  clientPeriodIncomeStatus: "noData",
  hasError: false,
};

export const fetchAdvanceRequestClientThunk = createAsyncThunk(
  "recording/advanceRequestClient",
  async (payload: any, thunkAPI) => {
    const response = await fetchClients(payload, thunkAPI);
    return response;
  }
);

export const recFetchAllClientsTabThunk = createAsyncThunk(
  "recording/recClientSelectionAllClientsTab",
  async (payload: any, thunkAPI) => {
    const response = await fetchClients(payload, thunkAPI);
    return response;
  }
);

export const recFetchMoreAllClientsTabThunk = createAsyncThunk(
  "recording/recMoreClientSelectionAllClientsTab",
  async (payload: any, thunkAPI) => {
    const response = await fetchClients(payload, thunkAPI);
    return response;
  }
);

export const recFetchSubClientsThunk = createAsyncThunk(
  "recording/recClientSelectionSubClients",
  async (payload: any, thunkAPI) => {
    const params = { ...payload, documentType: "RECORDING" };
    const response = await fetchSubClients(params, thunkAPI);
    return response?.resArr;
  }
);

export const fetchClientSelectionClientListsThunk = createAsyncThunk(
  "recording/clientSelectionClientLists",
  async (thunkAPI) => {
    const params = { clientDocumentType: "RECORDING" };
    const response = await fetchClientSelectionClientLists(params, thunkAPI);
    // maybe return .resArr
    return response;
  }
);

export const fetchClientSelectionSelectedClientListThunk = createAsyncThunk(
  "recording/clientSelectionSelectedClientList",
  async (thunkAPI) => {
    const params = { clientDocumentType: "RECORDING" };
    const response = await fetchSelectedClientList(params, thunkAPI);
    return response.resArr;
  }
);

export const fetchSelectedClientIdsThunk = createAsyncThunk(
  "recording/selectedClientsIds",
  async (payload: any, thunkAPI) => {
    const selectedClientIds = await fetchSelectedClients(payload, thunkAPI);
    return selectedClientIds?.resArr;
  }
);

export const fetchSelectedClientsThunk = createAsyncThunk(
  "recording/selectedClients",
  async (payload: any, thunkAPI) => {
    const scparams: FetchClientsPayload = {
      data: { clientIds: payload.selectedClientIds },
      documentType: "RECORDING",
    };

    const selectedClients = await fetchClients(scparams, thunkAPI);
    const state: any = thunkAPI.getState();
    const previousSelectedClients =
      state.recRoot.clientSelection.selectedClients;
    if (!deepCompare(previousSelectedClients, selectedClients.data)) {
      thunkAPI.dispatch(updateDashboardPeriodsToInitialStateAction());
    }

    return selectedClients.data;
  }
);

export const selectClientsThunk = createAsyncThunk(
  "recording/selectClients",
  async (params: any, thunkAPI) => {
    const data = {
      clientIds: params.clientIds,
      parentIds: params.parentIds ? params.parentIds : [],
      documentType: "RECORDING",
    };
    const state: any = thunkAPI.getState();
    const previousSelectedClientIds =
      state.recRoot.clientSelection.selectedClients.map(
        (client: any) => client.id
      );
    if (!deepCompare(previousSelectedClientIds, params.clientIds)) {
      thunkAPI.dispatch(updateDashboardPeriodsToInitialStateAction());
    }
    const response = await selectClients(data, thunkAPI);
    return response;
  }
);

export const selectClientListThunk = createAsyncThunk(
  "recording/selectClientList",
  async (params: any, thunkAPI) => {
    let clientIdsInTheList;

    // clientListId is "-1"
    if (
      params?.selectedList?.id === "-1" ||
      params?.selectedList?.id === null
    ) {
      const allClients = await fetchClientPageClients(
        {
          documentType: "RECORDING",
          data: {
            clientIds: [],
          },
        },
        thunkAPI
      );
      if (allClients.data.length === 0) return;
      clientIdsInTheList = allClients.data.map((cl: any) => cl.id);
    }

    // standard way
    if (
      params?.selectedList?.id !== "-1" ||
      params?.selectedList?.id !== null
    ) {
      const selectParams = {
        selectedClientListDto: params?.selectedList?.id
          ? {
              clientListId: params?.selectedList?.id,
              type: params?.selectedList?.type,
            }
          : null,
        documentType: "RECORDING",
      };
      clientIdsInTheList = await selectClients(selectParams, thunkAPI);
    }

    const clParams: FetchClientsPayload = {
      data: { clientIds: clientIdsInTheList },
      documentType: "RECORDING",
    };

    const clientsInTheList = await fetchClients(clParams, thunkAPI);

    const state: any = thunkAPI.getState();
    const previousSelectedClients =
      state.recRoot.clientSelection.selectedClients;
    if (!deepCompare(previousSelectedClients, clientsInTheList.data)) {
      thunkAPI.dispatch(updateDashboardPeriodsToInitialStateAction());
    }

    return {
      selectedList: params.selectedList,
      selectedClients: clientsInTheList.data,
    };
  }
);

export const createClientListThunk = createAsyncThunk(
  "recording/saveClientList",
  async (params: any, thunkAPI) => {
    const response = await saveClientList(params, thunkAPI);
    return response;
  }
);

export const updateClientListThunk = createAsyncThunk(
  "recording/updateClientList",
  async (params: any, thunkAPI) => {
    const response = await updateClientList(params, thunkAPI);
    return response;
  }
);

export const fetchClientSelectionParentsThunk = createAsyncThunk(
  "recording/clientSelectionParents",
  async (params: ClientSelectionTabParentsPageStateProps, thunkAPI) => {
    const response = await fetchClientSelectionParents(params, thunkAPI);
    return response;
  }
);

export const fetchClientsByParentThunk = createAsyncThunk(
  "recording/clientsByParent",
  async (params: any, thunkAPI) => {
    const response = await fetchClients(params, thunkAPI);
    return response;
  }
);

export const ClientSelectionClientListDeleteThunk = createAsyncThunk(
  "recording/ClientSelectionDeleteClientList",
  async (params: any, thunkAPI) => {
    const response = await deleteClientList(params, thunkAPI);
    return response;
  }
);

export const FetchClientPeriodIncome_Thunk = createAsyncThunk(
  "recording/FetchClientPeriodIncome",
  async (params: any, thunkAPI) => {
    const response = await fetchClientPeriodIncome(params, thunkAPI);
    const { hasData, latestPeriodId, previousPeriodId, periodsWithIncome } =
      response;
    return { hasData, latestPeriodId, previousPeriodId, periodsWithIncome };
  }
);

export const recRootSlice = createSlice({
  name: "recRoot",
  initialState,
  reducers: {
    updateAdvanceRequestPageState: (state, action: any) => {
      const { payload } = action;
      if (payload.hasOwnProperty("level1IsOpen"))
        state.advanceRequestPageState.level1IsOpen = payload.level1IsOpen;
      if (payload.hasOwnProperty("level2IsOpen"))
        state.advanceRequestPageState.level2IsOpen = payload.level2IsOpen;
      if (payload.hasOwnProperty("selectedClient"))
        state.advanceRequestPageState.selectedClient = payload.selectedClient;
    },
    updateTabAllClientsPageState: (state, action: any) => {
      state.clientSelection.tabAllClientsPageState.data.withSubClients =
        action.payload.data.withSubClients;
      state.clientSelection.tabAllClientsPageState.start = action.payload.start;
      state.clientSelection.tabAllClientsPageState.sortColumn =
        action.payload.sortColumn;
      state.clientSelection.tabAllClientsPageState.filterText =
        action.payload.filterText;
      state.clientSelection.tabAllClientsPageState.isAsc = action.payload.isAsc;
      state.clientSelection.tabAllClientsPageState.count = action.payload.count;
      state.clientSelection.tabAllClientsPageState.currencyId =
        action.payload.currencyId;
      state.clientSelection.tabAllClientsPageState.documentType =
        action.payload.documentType;
    },
    updateTabParentsPageState: (state, action: any) => {
      state.clientSelection.tabParentsPageState.sortColumn =
        action.payload.sortColumn;
      state.clientSelection.tabParentsPageState.filterText =
        action.payload.filterText;
      state.clientSelection.tabParentsPageState.isAsc = action.payload.isAsc;
      state.clientSelection.tabParentsPageState.currencyId =
        action.payload.currencyId;
      state.clientSelection.tabParentsPageState.clientDocumentType =
        action.payload.clientDocumentType;
    },
    updateClientListsPageState: (state, action: any) => {
      state.clientSelection.clientListsPageState.filterText =
        action.payload.filterText;
      state.clientSelection.clientListsPageState.isAsc = action.payload.isAsc;
    },
    updateClientPeriodIncomePageState: (state, action: any) => {
      state.clientPeriodIncomePageState.data = action.payload?.data;
      state.clientPeriodIncomePageState.currentClientPeriodIncome =
        action.payload?.currentClientPeriodIncome;
    },
    unselectClientList: (state) => {
      state.clientSelection.selectedClientList = null;
      state.clientSelection.selectedClientListStatus = "idle";
    },
    unselectParentsList: (state, action) => {
      const { selectedParentIds } = action.payload.data || {};
      if (!state.clientSelection.parents?.length || !selectedParentIds?.length)
        return;

      state.clientSelection.parents = state.clientSelection.parents.map(
        (parent) => {
          const isSelected = selectedParentIds.includes(parent.id);
          return {
            ...parent,
            selected: isSelected,
          };
        }
      );
    },
    removeTempParentsClientsToBeSelected: (state) => {
      state.clientSelection.tempParentsClientsToBeSelected = [];
    },
    resetRecSubClients: (state) => {
      state.clientSelection.subClients = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAdvanceRequestClientThunk.fulfilled,
        (state, action: any) => {
          state.advanceRequestClientStatus = "idle";
          state.advanceRequestClient.list = action.payload.data;
          state.advanceRequestClient.total = action.payload.total;
        }
      )
      .addCase(fetchAdvanceRequestClientThunk.pending, (state) => {
        state.advanceRequestClientStatus = "loading";
      })
      .addCase(fetchAdvanceRequestClientThunk.rejected, (state) => {
        state.advanceRequestClientStatus = "failed";
      })
      .addCase(recFetchAllClientsTabThunk.fulfilled, (state, action: any) => {
        state.clientSelection.tabAllClientsStatus = "idle";
        state.clientSelection.tabAllClients.list = action.payload.data;
        state.clientSelection.tabAllClients.total = action.payload.total;
      })
      .addCase(recFetchAllClientsTabThunk.pending, (state) => {
        state.clientSelection.tabAllClientsStatus = "loading";
      })
      .addCase(recFetchAllClientsTabThunk.rejected, (state) => {
        state.clientSelection.tabAllClientsStatus = "failed";
      })
      .addCase(
        recFetchMoreAllClientsTabThunk.fulfilled,
        (state, action: any) => {
          state.clientSelection.tabAllClientsStatus = "idle";
          state.clientSelection.tabAllClients.list = [
            ...state.clientSelection.tabAllClients.list,
            ...action.payload.data,
          ];
          state.clientSelection.tabAllClients.total = action.payload.total;
        }
      )
      .addCase(recFetchMoreAllClientsTabThunk.pending, (state) => {
        state.clientSelection.tabAllClientsStatus = "loading";
      })
      .addCase(recFetchMoreAllClientsTabThunk.rejected, (state) => {
        state.clientSelection.tabAllClientsStatus = "failed";
      })
      .addCase(recFetchSubClientsThunk.fulfilled, (state, action: any) => {
        state.clientSelection.subClientsStatus = "idle";
        state.clientSelection.subClients = action.payload;
      })
      .addCase(recFetchSubClientsThunk.pending, (state) => {
        state.clientSelection.subClientsStatus = "loading";
      })
      .addCase(recFetchSubClientsThunk.rejected, (state) => {
        state.clientSelection.subClientsStatus = "failed";
      })
      .addCase(
        fetchClientSelectionClientListsThunk.fulfilled,
        (state, action: any) => {
          state.clientSelection.clientListsStatus = "idle";
          state.clientSelection.clientLists.list = action.payload.data;
          state.clientSelection.clientLists.total = action.payload.total;
        }
      )
      .addCase(fetchClientSelectionClientListsThunk.pending, (state) => {
        state.clientSelection.clientListsStatus = "loading";
      })
      .addCase(fetchClientSelectionClientListsThunk.rejected, (state) => {
        state.clientSelection.clientListsStatus = "failed";
      })
      .addCase(
        fetchClientSelectionSelectedClientListThunk.fulfilled,
        (state, action: any) => {
          state.clientSelection.selectedClientList =
            action.payload && action.payload.length !== 0
              ? action.payload[0]
              : null;
          state.clientSelection.selectedClientListStatus = "idle";
        }
      )
      .addCase(fetchClientSelectionSelectedClientListThunk.pending, (state) => {
        state.clientSelection.selectedClientListStatus = "loading";
      })
      .addCase(
        fetchClientSelectionSelectedClientListThunk.rejected,
        (state) => {
          state.clientSelection.selectedClientListStatus = "failed";
        }
      )
      .addCase(selectClientsThunk.fulfilled, (state, action: any) => {
        state.clientSelection.selectedClients = action.meta.arg.clients;
        state.selectedClientIds = action.meta.arg.clients.map(
          (client: any) => client.id
        );
        state.clientSelection.selectedParents = action.meta.arg.parents;
        state.clientSelection.selectedClientsStatus = "idle";
      })
      .addCase(selectClientsThunk.pending, (state, action: any) => {
        state.clientSelection.selectedClientsStatus = "loading";
      })
      .addCase(selectClientsThunk.rejected, (state, action: any) => {
        state.clientSelection.selectedClientsStatus = "failed";
      })
      .addCase(selectClientListThunk.fulfilled, (state, action: any) => {
        state.clientSelection.selectedClientList = action.payload?.selectedList;
        state.clientSelection.selectedClients = action.payload?.selectedClients;
        state.selectedClientIds = action.payload?.selectedClients.map(
          (client: any) => client.id
        );
        state.clientSelection.selectedClientsStatus = "idle";
      })
      .addCase(selectClientListThunk.pending, (state) => {
        state.clientSelection.selectedClientsStatus = "loading";
      })
      .addCase(selectClientListThunk.rejected, (state) => {
        state.clientSelection.selectedClientsStatus = "failed";
      })
      .addCase(fetchSelectedClientIdsThunk.fulfilled, (state, action: any) => {
        state.selectedClientIds = action.payload;
        state.clientSelection.selectedClientsStatus = "idle";
      })
      .addCase(fetchSelectedClientIdsThunk.pending, (state, action: any) => {
        state.clientSelection.selectedClientsStatus = "loading";
      })
      .addCase(fetchSelectedClientIdsThunk.rejected, (state, action: any) => {
        state.clientSelection.selectedClientsStatus = "failed";
      })
      .addCase(fetchSelectedClientsThunk.fulfilled, (state, action: any) => {
        state.clientSelection.selectedClients = action.payload;
        state.clientSelection.selectedClientsStatus = "idle";
      })
      .addCase(fetchSelectedClientsThunk.pending, (state) => {
        state.clientSelection.selectedClientsStatus = "loading";
      })
      .addCase(fetchSelectedClientsThunk.rejected, (state, action: any) => {
        state.clientSelection.selectedClientsStatus = "failed";
      })
      .addCase(
        fetchClientSelectionParentsThunk.fulfilled,
        (state, action: any) => {
          const parents = action.payload?.data ?? [];
          state.clientSelection.parents = parents;
          state.clientSelection.selectedParents = parents.filter(
            (p: any) => p.selected
          );
          state.clientSelection.parentsStatus = "idle";
        }
      )
      .addCase(
        fetchClientSelectionParentsThunk.pending,
        (state, action: any) => {
          state.clientSelection.parentsStatus = "loading";
        }
      )
      .addCase(
        fetchClientSelectionParentsThunk.rejected,
        (state, action: any) => {
          state.clientSelection.parentsStatus = "failed";
        }
      )
      //fetchClientsByParentThunk
      .addCase(fetchClientsByParentThunk.fulfilled, (state, action: any) => {
        state.clientSelection.tempParentsClientsToBeSelected =
          action.payload?.data;
        state.clientSelection.selectedClientsStatus = "idle";
      })
      .addCase(fetchClientsByParentThunk.pending, (state) => {
        state.clientSelection.selectedClientsStatus = "loading";
      })
      .addCase(fetchClientsByParentThunk.rejected, (state) => {
        state.clientSelection.selectedClientsStatus = "failed";
      })
      .addCase(
        ClientSelectionClientListDeleteThunk.fulfilled,
        (state, action) => {
          state.clientSelection.clientLists.list =
            state.clientSelection.clientLists.list.filter(
              (list: any) => Number(list.id) !== action.meta.arg.id
            );
          if (state.clientSelection.clientLists.total)
            state.clientSelection.clientLists.total -= 1;
          state.clientSelection.selectedClientList = null;
          state.clientSelection.clientListsStatus = "idle";
        }
      )
      .addCase(ClientSelectionClientListDeleteThunk.pending, (state) => {
        state.clientSelection.clientListsStatus = "loading";
      })
      .addCase(ClientSelectionClientListDeleteThunk.rejected, (state) => {
        state.clientSelection.clientListsStatus = "failed";
      })
      .addCase(createClientListThunk.fulfilled, (state, action: any) => {
        state.clientSelection.clientListsStatus = "idle";
        state.clientSelection.clientLists.list.push(action.payload);
        if (state.clientSelection.clientLists.total)
          state.clientSelection.clientLists.total += 1;
        state.clientSelection.selectedClientList = action.payload;
      })
      .addCase(createClientListThunk.pending, (state, action: any) => {
        state.clientSelection.clientListsStatus = "loading";
      })
      .addCase(createClientListThunk.rejected, (state, action: any) => {
        state.clientSelection.clientListsStatus = "failed";
      })
      .addCase(updateClientListThunk.fulfilled, (state, action: any) => {
        state.clientSelection.clientListsStatus = "idle";
        const { id, name, clientIds } = action.meta.arg;
        let selectedClientList = null;
        const newList =
          state.clientSelection?.clientLists?.list?.map((cl: any) => {
            if (cl.id === id) {
              selectedClientList = { ...cl, name, clientIds };
              return selectedClientList;
            }
            return cl;
          }) || [];

        state.clientSelection.clientLists.list = newList;
        state.clientSelection.selectedClientList = selectedClientList;
      })
      .addCase(updateClientListThunk.pending, (state, action: any) => {
        state.clientSelection.clientListsStatus = "loading";
      })
      .addCase(updateClientListThunk.rejected, (state, action: any) => {
        state.clientSelection.clientListsStatus = "failed";
      })
      .addCase(FetchClientPeriodIncome_Thunk.fulfilled, (state, action) => {
        const { clientIds } = action.meta.arg?.data ?? {};
        state.clientPeriodIncomeStatus = "idle";
        state.clientPeriodIncome = { ...action.payload, clientIds };
      })
      .addCase(FetchClientPeriodIncome_Thunk.pending, (state) => {
        state.clientPeriodIncomeStatus = "loading";
        state.clientPeriodIncome = {
          clientIds: [],
          hasData: false,
          latestPeriodId: 0,
          previousPeriodId: 0,
          periodsWithIncome: [],
        };
      })
      .addCase(FetchClientPeriodIncome_Thunk.rejected, (state) => {
        state.clientPeriodIncomeStatus = "failed";
      })
      // new
      .addMatcher(
        isAnyOf(
          updateClientListThunk.fulfilled,
          createClientListThunk.fulfilled,
          ClientSelectionClientListDeleteThunk.fulfilled,
          fetchClientSelectionParentsThunk.fulfilled,
          fetchSelectedClientsThunk.fulfilled,
          selectClientsThunk.fulfilled,
          fetchClientSelectionSelectedClientListThunk.fulfilled,
          fetchClientSelectionClientListsThunk.fulfilled,
          recFetchAllClientsTabThunk.fulfilled,
          fetchAdvanceRequestClientThunk.fulfilled
        ),
        (state) => {
          let isFailed = false;
          if (state.advanceRequestClientStatus === "failed") isFailed = true;
          if (state.clientSelection.selectedClientsStatus === "failed")
            isFailed = true;
          if (state.clientSelection.tabAllClientsStatus === "failed")
            isFailed = true;
          if (state.clientSelection.clientListsStatus === "failed")
            isFailed = true;
          if (state.clientSelection.parentsStatus === "failed") isFailed = true;
          if (state.clientSelection.selectedParentsStatus === "failed")
            isFailed = true;

          state.hasError = isFailed;
        }
      )
      .addMatcher(isAnyOf(updateClientListThunk.rejected), (state) => {
        state.hasError = true;
      });
  },
});

export const advanceRequestPageStateSelector = (state: RootState) =>
  state.recRoot.advanceRequestPageState;
export const contactsClientStatusSelector = (state: RootState) =>
  state.recRoot.advanceRequestClientStatus;
export const advanceRequestClientSelector = (state: RootState) =>
  state.recRoot.advanceRequestClient;
export const recClientSelectionSelector = (state: RootState) =>
  state.recRoot.clientSelection;
export const recSelectedClientIdsSelector = (state: RootState) =>
  state.recRoot.selectedClientIds;
export const recRootHasErrorSelector = (state: RootState) =>
  state.recRoot.hasError;
export const recClientPeriodIncomeStatusSelector = (state: RootState) =>
  state.recRoot.clientPeriodIncomeStatus;
export const recClientPeriodIncomeSelector = (state: RootState) =>
  state.recRoot.clientPeriodIncome;
export const recClientPeriodIncomePageStateSelector = (state: RootState) =>
  state.recRoot.clientPeriodIncomePageState;

export const {
  updateAdvanceRequestPageState: updateAdvanceRequestPageStateAction,
  updateTabAllClientsPageState: updateTabAllClientsPageStateAction,
  updateTabParentsPageState: updateTabParentsPageStateAction,
  updateClientPeriodIncomePageState: updateClientPeriodIncomePageStateAction,
  unselectClientList: unselectClientListAction,
  unselectParentsList: unselectParentsListAction,
  removeTempParentsClientsToBeSelected: removeTempClientsToBeSelectedAction,
  resetRecSubClients: resetRecSubClientsAction,
} = recRootSlice.actions;

export default recRootSlice.reducer;
