import { useTranslation } from "react-i18next";
import { ButtonVariantEnum } from "../../../types/enums";
import Button from "../../../atom/Button/Button";
import Tooltip from "../../../atom/Tooltip/Tooltip";
import styles from "./syncTableView.module.scss";

const SyncTableViewHead = (props: any): JSX.Element => {
  const {
    documentType,
    onColumnSorting,
    status,
    topTargetIsOnScreen,
    bottomTargetPageCount,
  } = props;
  const { t } = useTranslation();

  return (
    <thead
      className={`sticky top-12 z-[5] bg-white pt-3 ${
        topTargetIsOnScreen === false && bottomTargetPageCount > 0
          ? "shadow-lg"
          : ""
      }`}
    >
      <tr className="hidden border-b border-gray-100 md:table-row">
        <th className={styles.songColumn}>
          <Button
            variant={ButtonVariantEnum.textLink}
            onClick={onColumnSorting}
            className="px-0 text-xs font-normal text-gray-400 no-underline"
            name="songTitle"
          >
            {documentType === "PUBLISHING"
              ? t("sync.table.song")
              : t("sync.table.trackTitle")}
          </Button>
        </th>
        <th className={styles.composersColumn}>
          <Button
            variant={ButtonVariantEnum.textLink}
            onClick={onColumnSorting}
            className="px-0 text-xs font-normal text-gray-400 no-underline"
            name="songWriters"
          >
            {documentType === "PUBLISHING"
              ? t("sync.table.composers")
              : t("sync.table.artists")}
          </Button>
        </th>
        <th className={styles.projectColumn}>
          <Button
            variant={ButtonVariantEnum.textLink}
            onClick={onColumnSorting}
            className="px-0 text-xs font-normal text-gray-400 no-underline"
            name="projectName"
          >
            {t("sync.table.project")}
          </Button>
        </th>
        <th className={styles.feeColumn}>
          <Button
            variant={ButtonVariantEnum.textLink}
            onClick={onColumnSorting}
            className="px-0 text-xs font-normal text-gray-400 no-underline"
            name="fee"
          >
            {t("sync.table.fee")}
          </Button>
          <Tooltip text={t("sync.table.feeInfo")} id="fee" />
        </th>
        {status === "ALL" && (
          <th className={styles.statusColumn}>
            <Button
              variant={ButtonVariantEnum.textLink}
              onClick={onColumnSorting}
              className="px-0 text-xs font-normal text-gray-400 no-underline"
              name="status"
            >
              {t("sync.table.status")}
            </Button>
            <Tooltip text={t("sync.table.statusInfo")} id="status" />
          </th>
        )}
        {status !== "COMPLETED" && (
          <th className={styles.dateColumn}>
            <Button
              variant={ButtonVariantEnum.textLink}
              onClick={onColumnSorting}
              className="px-0 text-xs font-normal text-gray-400 no-underline"
              name="statusLastModifiedDate"
            >
              {t("sync.table.date")}
            </Button>
          </th>
        )}
        {status === "COMPLETED" && (
          <>
            <th className={styles.royalityColumn}>
              <Button
                variant={ButtonVariantEnum.textLink}
                onClick={onColumnSorting}
                className="px-0 text-xs font-normal text-gray-400 no-underline"
                name="royality"
              >
                {t("sync.table.royalities")}
              </Button>
              <Tooltip text={t("sync.table.royalitiesInfo")} id="status" />
            </th>
            <th className={styles.periodColumn}>
              <Button
                variant={ButtonVariantEnum.textLink}
                onClick={onColumnSorting}
                className="px-0 text-xs font-normal text-gray-400 no-underline"
                name="period"
              >
                {t("sync.table.period")}
              </Button>
              <Tooltip text={t("sync.table.periodInfo")} id="status" />
            </th>
          </>
        )}
      </tr>
    </thead>
  );
};

export default SyncTableViewHead;
