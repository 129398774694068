import React from "react";

function SearchIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 30"
      name="SearchIcon"
      {...props}
    >
      <g>
        <path d="M22.5172695,22.2228276 L18.9258716,18.4482351 C18.8900293,18.4098726 18.8431085,18.3934316 18.8033561,18.3619195 C22.299609,13.9707875 22.091072,7.40258553 18.1666667,3.27725053 C16.1562398,1.16388977 13.4830564,0 10.6397849,0 C7.79651352,0 5.12333007,1.16388977 3.11290323,3.27725053 C-1.03763441,7.6402958 -1.03763441,14.7400919 3.11290323,19.1024521 C5.12333007,21.2164979 7.79651352,22.3803877 10.6397849,22.3803877 C13.1643858,22.3803877 15.5521342,21.4590035 17.463506,19.7737952 C17.4921799,19.8148979 17.5078201,19.863536 17.5436624,19.9012134 L21.1344086,23.6758059 C21.3253503,23.8765238 21.5755947,23.9765402 21.825839,23.9765402 C22.0760834,23.9765402 22.3263278,23.8765238 22.5172695,23.6758059 C22.8991528,23.2743701 22.8991528,22.6235783 22.5172695,22.2228276 M10.6397849,20.3252557 C8.31850766,20.3252557 6.1360378,19.3744146 4.49511241,17.6494738 C1.10703812,14.08793 1.10703812,8.29314274 4.49511241,4.73022886 C6.1360378,3.00528806 8.31850766,2.05513202 10.6397849,2.05513202 C12.9610622,2.05513202 15.1435321,3.00528806 16.7844575,4.73022886 C20.1725318,8.29314274 20.1725318,14.08793 16.7844575,17.6494738 C15.1428804,19.3744146 12.9610622,20.3252557 10.6397849,20.3252557" />
      </g>
    </svg>
  );
}

export default SearchIcon;
