import request from "../../../../app/utils/requestUtils";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export const getClientLists = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.clients}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const getLinkedUsers = (data: any) => {
  const id = data.id;
  const payload = data.params;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.clients}/${id}/users`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};
