import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export function fetchAnalysisDigital(params: any, thunkAPI?: any) {
  const {
    data,
    start = 0,
    count = 200,
    sortColumn = "",
    sortAscending = true,
    filterText = "",
  } = params;

  return request(
    `${USER_API_RESOURCE_BASE_URLS.recordingDigital}?start=${start}&count=${count}&sortColumn=${sortColumn}&isAsc=${sortAscending}&filterText=${filterText}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchAnalysisSources(params: any, thunkAPI?: any) {
  const {
    data,
    start = 0,
    count = 200,
    sortColumn = "",
    sortAscending = true,
    filterText = "",
  } = params;

  return request(
    `${USER_API_RESOURCE_BASE_URLS.recordingSources}?start=${start}&count=${count}&sortColumn=${sortColumn}&isAsc=${sortAscending}&filterText=${filterText}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchAnalysisChannel(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.channels}`, {
    method: "POST",
    body: JSON.stringify(params.data),
    signal: thunkAPI?.signal,
  });
}

export function downloadAnalysisData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.analysisDownload}?clientDocumentType=RECORDING`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
      isDownload: true,
      fileName,
    }
  );
}

export function fetchAnalysisTracks(params: any, thunkAPI?: any) {
  const {
    data,
    start = 0,
    count = 200,
    sortColumn = "",
    sortAscending = true,
    filterColumn = "",
    filterText = "",
  } = params;

  return request(
    `${USER_API_RESOURCE_BASE_URLS.tracks}?start=${start}&count=${count}&sortColumn=${sortColumn}&isAsc=${sortAscending}&filterText=${filterText}&filterColumn=${filterColumn}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchAnalysisTerritories(params: any, thunkAPI?: any) {
  const { data } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.recordingTerritories}`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function fetchAnalysisAlbums(params: any, thunkAPI?: any) {
  const {
    data,
    start = 0,
    count = 200,
    sortColumn = "",
    sortAscending = true,
    filterColumn = "",
    filterText = "",
  } = params;

  return request(
    `${USER_API_RESOURCE_BASE_URLS.albums}?start=${start}&count=${count}&sortColumn=${sortColumn}&isAsc=${sortAscending}&filterText=${filterText}&filterColumn=${filterColumn}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}
