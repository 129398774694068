import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "../../atom/Dropdown/Dropdown";
import {
  SortDownBGIcon,
  SortUpBGIcon,
  SortUpDownBGIcon,
} from "../../atom/Icon";
import Input from "../../atom/Input/Input";
import { PubClientSelectionTabParentsProps } from "../../types/props";
import styles from "./selectClientsModal.module.scss";

const TabParents = ({
  sortColumn,
  isSortAsc,
  filterText,
  onHeaderClick,
  parents,
  currencyOptions,
  selectedCurrency,
  onCurrencyChange,
  setFilterText,
  selectedParents,
  onParentSelectionChange,
}: PubClientSelectionTabParentsProps) => {
  const { t } = useTranslation();

  const handleHeaderClick = (columnName: string) => () => {
    onHeaderClick(columnName);
  };

  const isParentSelected = useMemo(
    () => (parent: any) => {
      if (!selectedParents) return false;
      const matchingArray = selectedParents.filter((sp: any) => {
        return sp.id === parent.id;
      });
      return matchingArray.length !== 0;
    },
    [selectedParents]
  );
  const handleCurrencySelection = useCallback(
    (currencyId: string) => {
      if (currencyOptions && onCurrencyChange) {
        const selectedOption = currencyOptions.find((option: any) => {
          return option.value === currencyId;
        });
        onCurrencyChange({
          id: selectedOption.value,
          name: selectedOption.label,
        });
      }
    },
    [currencyOptions, onCurrencyChange]
  );

  const toggleRowSelection = useCallback(
    (parent: any) => () => {
      if (onParentSelectionChange) {
        const wasSelectedBefore = isParentSelected(parent);

        const addedClientIds =
          wasSelectedBefore === false ? parent.clientIds : null;
        const removedClientIds =
          wasSelectedBefore === true ? parent.clientIds : null;

        if (!wasSelectedBefore) {
          handleCurrencySelection(parent.currency);
        }
        onParentSelectionChange(addedClientIds, removedClientIds);
      }
    },
    [handleCurrencySelection, isParentSelected, onParentSelectionChange]
  );

  const renderColumnHeader = (headerVal: string, headerName: string) => {
    let icon = null;

    if (sortColumn === headerVal) {
      if (isSortAsc) icon = SortDownBGIcon;
      else icon = SortUpBGIcon;
    } else icon = SortUpDownBGIcon;

    return (
      <div className={styles.header} onClick={handleHeaderClick(headerVal)}>
        {headerName} <i className={styles.headerIcon}>{icon}</i>
      </div>
    );
  };

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (setFilterText) setFilterText(e.target.value);
    },
    [setFilterText]
  );

  return (
    <div className={styles.tabContentContainer}>
      <div className={styles.searchFilterContainer}>
        <Input
          id="searchInput"
          placeholder={t("clientSelection.tabParents.searchPlaceholder")}
          className={styles.searchInput}
          autoComplete="off"
          value={filterText}
          onChange={handleSearchChange}
        />
        <div className={styles.currencyFilterContainer}>
          {currencyOptions && (
            <Dropdown
              id="currencyFilter"
              options={currencyOptions}
              text={
                selectedCurrency
                  ? selectedCurrency.name
                  : t("clientSelection.allCurrencies")
              }
              onSelectOption_Callback={handleCurrencySelection}
              className={styles.currencyFilter}
            ></Dropdown>
          )}
        </div>
      </div>
      <div className={styles.dataTableContainer}>
        <div className={styles.row}>
          {renderColumnHeader(
            "currency",
            t("clientSelection.tabParents.colCurrency")
          )}
          <div></div>
          {renderColumnHeader("name", t("clientSelection.tabParents.colName"))}
        </div>
        <div className={styles.scrollable}>
          {parents &&
            parents?.map((parent: any, index) => {
              const isRowSelected = isParentSelected(parent);
              return (
                <div
                  key={index}
                  className={`${styles.row} ${
                    isRowSelected ? styles.selected : ""
                  }`}
                  onClick={toggleRowSelection(parent)}
                >
                  <div>{parent.currency}</div>
                  <div></div>
                  <div>{parent.name}</div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default TabParents;
