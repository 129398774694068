import request from "../../../../app/utils/requestUtils";
import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export const createClientCommunication = (payload: any) => {
  return request(
    `${
      ADMIN_API_RESOURCE_BASE_URLS.clientCommunication.clientCommunication
    }/${payload.selectedSystem.toLocaleLowerCase()}`,
    {
      method: "POST",
      body: payload.formData,
      isContentTypeMultipartFormData: true,
    }
  );
};

export const createClientCommunicationPreview = (payload: any) => {
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.clientCommunication.sendPreview}`,
    {
      method: "POST",
      body: payload.formData,
      isContentTypeMultipartFormData: true,
    }
  );
};

export const getPublishingTableData = (payload: any) => {
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.clientCommunication.publishingClients}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );
};

export const getRecordingTableData = (payload: any) => {
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.clientCommunication.recordingClients}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );
};

export const getMechanicalTableData = (payload: any) => {
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.clientCommunication.mechanicalClients}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );
};

export const getCompanyCodes = (payload: any) => {
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.companyCodes}?owningSystem=${payload}`,
    {
      method: "GET",
    }
  );
};

export const getOwningSystem = (payload: any) => {
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.clientOwning}-${payload.apiPath}`,
    {
      method: "GET",
    }
  );
};

export const getOwningSite = () => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.clientOwningSites}`, {
    method: "GET",
  });
};

export const getEmailFooter = (payload: any) => {
  const { clientDocumentType, owningSystem } = payload;
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.emailFooters.emailFooters}?clientDocumentType=${clientDocumentType}&owningSystem=${owningSystem}`,
    { method: "GET" }
  );
};
