import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { CommonRequestBodyTableState } from "../../../../app/types/props";
import {
  SortDirectionEnum,
  StatusEnum,
  UserAccountTypeEnum,
} from "../../../../app/types/enums";

import { useFetch } from "../../common/hooks/useFetch";
import { DEFAULT_ROWS_PER_PAGE } from "../../common/adminEndpoints.const";

import { tableHeadData } from "./components/AdminAccountsTable/AdminAccountsTable.const";

import { getAdminAccountsList } from "./AdminAccountsAPI";

export type AdminAccountsListItem = {
  id: number;
  userAccountType: UserAccountTypeEnum;
  username: string;
  email: string;
  createdOn: string;
  modifiedOn: string;
  modifiedBy: string;
  mechanical: boolean;
  groups: string[];
};

type AdminAccountsInitialState = {
  adminAccountsPageState: CommonRequestBodyTableState;
  tableData: {
    list: AdminAccountsListItem[] | null;
    count: number | null;
    totalCount: number | null;
  };
  status: StatusEnum;
};

const initialState: AdminAccountsInitialState = {
  adminAccountsPageState: {
    filterText: "",
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn:
            tableHeadData?.find(
              (tableHeadItem) => tableHeadItem.isInitialSorting
            )?.filteringInfo || tableHeadData?.[0].filteringInfo,
          direction: SortDirectionEnum.Descending,
        },
      ],
    },
  },
  tableData: {
    list: null,
    count: 0,
    totalCount: 0,
  },
  status: StatusEnum.Idle,
};

export const getAdminAccountsThunk = createAsyncThunk(
  "admin/admin-users",
  async (payload: any, { rejectWithValue }) => {
    try {
      const getAdminAccounts = await useFetch();
      const response = await getAdminAccounts({
        data: payload,
        fetchFunction: getAdminAccountsList,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const adminAccountsSlice = createSlice({
  name: "adminAccounts",
  initialState,
  reducers: {
    updateAdminAccountsPageState: (state, action: any) => {
      state.adminAccountsPageState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminAccountsThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableData.list = action.payload.data;
        state.tableData.totalCount = action.payload.totalCount;
        state.tableData.count = action.payload.count;
      })
      .addCase(getAdminAccountsThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getAdminAccountsThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
        state.tableData.list = [];
        state.tableData.totalCount = null;
        state.tableData.count = null;
      });
  },
});

export const AdminAccountsSelector = (state: RootState) => state.adminAccounts;
export const AdminAccountsPageStateSelector = (state: RootState) =>
  state.adminAccounts.adminAccountsPageState;

export default adminAccountsSlice.reducer;
