import React from "react";
function PlusCircledIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width="100%"
      height="100%"
      name="PlusCircledIcon"
      {...props}
    >
      <path fill="none" d="M0 0h256v256H0z" />
      <circle
        cx="128"
        cy="128"
        r="96"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12"
      />
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12"
        d="M88 128h80M128 88v80"
      />
    </svg>
  );
}

export default PlusCircledIcon;
