import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { SingleGroup } from "../../../../app/types/props";
import { StatusEnum } from "../../../../app/types/enums";

import { useFetch } from "../../common/hooks/useFetch";

import {
  AddEditAdminGroupRequestBody,
  addNewAdminGroup,
  deleteAdminGroup,
  editAdminGroup,
  fetchAdminGroupByID,
} from "./AddEditAdminGroupAPI";

export type SingleAdminGroupFormData = Pick<
  SingleGroup,
  "name" | "email" | "emailName" | "description" | "language" | "phone"
>;

export type EditAdminGroupProps = {
  id: number;
  data: SingleAdminGroupFormData;
};

export type AddEditAdminGroupState = {
  singleGroup: SingleGroup;
  status: StatusEnum;
};

type AdminGroupRequestData = {
  id: number;
};

type AddNewAdminGroupRequestDataProps = {
  data: AddEditAdminGroupRequestBody;
};

type EditNewAdminGroupRequestDataProps = {
  data: AddEditAdminGroupRequestBody;
  id: number;
};

const initialState: AddEditAdminGroupState = {
  singleGroup: {
    id: null,
    name: null,
    email: null,
    emailName: null,
    description: null,
    language: null,
    phone: null,
  },
  status: StatusEnum.Idle,
};

export const fetchAdminGroupByIDThunk = createAsyncThunk(
  "admin/addEditAdminGroup",
  async (id: number, { rejectWithValue }) => {
    try {
      const requestData = {
        id,
      } as AdminGroupRequestData;

      const fetchGroup = await useFetch();
      const response = await fetchGroup<AdminGroupRequestData>({
        data: requestData,
        fetchFunction: fetchAdminGroupByID,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewAdminGroupThunk = createAsyncThunk(
  "admin/addEditAdminGroup/addNewAdminGroup",
  async (payload: SingleAdminGroupFormData, { rejectWithValue }) => {
    try {
      const requestData = {
        data: {
          ...payload,
        },
      } as AddNewAdminGroupRequestDataProps;

      const addNewGroup = await useFetch();
      const response = await addNewGroup<AddNewAdminGroupRequestDataProps>({
        data: requestData,
        fetchFunction: addNewAdminGroup,
        customSuccessMsg:
          "adminSection.common.commonAddEditGroups.messages.successAddGroupMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editAdminGroupThunk = createAsyncThunk(
  "admin/addEditAdminGroup/editAdminGroup",
  async (payload: EditAdminGroupProps, { rejectWithValue }) => {
    try {
      const requestData = {
        data: {
          ...payload.data,
        },
        id: payload.id,
      } as EditNewAdminGroupRequestDataProps;

      const editGroup = await useFetch();
      const response = await editGroup<EditNewAdminGroupRequestDataProps>({
        data: requestData,
        fetchFunction: editAdminGroup,
        customSuccessMsg: "adminSection.common.successEditMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAdminGroupThunk = createAsyncThunk(
  "admin/addEditAdminGroup/deleteAdminGroup",
  async (id: number, { rejectWithValue }) => {
    try {
      const requestData = {
        id,
      } as AdminGroupRequestData;

      const deleteGroup = await useFetch();
      const response = await deleteGroup<AdminGroupRequestData>({
        data: requestData,
        fetchFunction: deleteAdminGroup,
        customSuccessMsg:
          "adminSection.common.commonAddEditGroups.messages.successDeleteGroupMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addEditAdminGroupSlice = createSlice({
  name: "addEditAdminGroup",
  initialState,
  reducers: {
    resetGroup: (state) => {
      state.singleGroup = initialState.singleGroup;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminGroupByIDThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(fetchAdminGroupByIDThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.singleGroup.id = action.payload?.id;
        state.singleGroup.name = action.payload?.name;
        state.singleGroup.email = action.payload?.email;
        state.singleGroup.emailName = action.payload?.emailName;
        state.singleGroup.description = action.payload?.description;
        state.singleGroup.language = action.payload?.language;
        state.singleGroup.phone = action.payload?.phone;
        state.singleGroup.admin = action.payload?.admin;
        state.singleGroup.groupType = action.payload?.groupType;
      })
      .addCase(fetchAdminGroupByIDThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(addNewAdminGroupThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(addNewAdminGroupThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(addNewAdminGroupThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(editAdminGroupThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(editAdminGroupThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(editAdminGroupThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(deleteAdminGroupThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(deleteAdminGroupThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(deleteAdminGroupThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      });
  },
});

export const addEditAdminGroupSelector = (state: RootState) =>
  state.addEditAdminGroup;

export default addEditAdminGroupSlice.reducer;
