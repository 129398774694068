import { useTranslation } from "react-i18next";
import Button from "../../atom/Button/Button";
import { ButtonVariantEnum } from "../../types/enums";
import { ConfirmationSwitchCurrencyProps } from "../../types/props";
import Modal from "../modal/Modal";
import styles from "./confirmationSwitchCurrency.module.scss";

const ConfirmationSwitchCurrency = (
  props: ConfirmationSwitchCurrencyProps
): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal
      id="switchCurrencyConfirmation_scm"
      isModalOpen={props.isVisible}
      className={styles.confirmationSwitchCurrency}
    >
      <h3 className={styles.title}>
        {t("confirmationSwitchCurrency.switchCurrency")}
      </h3>
      <p>{t("confirmationSwitchCurrency.areYouSure")}</p>
      <div className={styles.buttonContainer}>
        <Button
          variant={ButtonVariantEnum.outlineCta}
          onClick={props.onCancelClick}
        >
          {t("confirmationSwitchCurrency.no")}
        </Button>
        <Button
          variant={ButtonVariantEnum.primaryNarrowCta}
          onClick={props.onYesClick}
        >
          {t("confirmationSwitchCurrency.yes")}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationSwitchCurrency;
