import { useTranslation } from "react-i18next";
import styles from "./../analysis.module.scss";

const AnalysisTrackBreakdownSource = ({
  data,
  status,
}: {
  data: any;
  status: "noData" | "loading" | "idle" | "failed";
}): JSX.Element => {
  const { t } = useTranslation();

  if (status === "noData" || status === "loading") {
    return <p className="pl-2 pt-4">{t("app.loading")}</p>;
  }

  if (!data || !data.sources || data?.sources?.length === 0) {
    if (status === "idle")
      return (
        <p className="pl-2 pt-4">{t("analysis.songs.breakdown.noData")}</p>
      );
    else return <p className="pl-2 pt-4">{t("app.loading")}</p>;
  }

  return (
    <div className={`smallScrollbar ${styles.sourcesContainer}`}>
      {data.sources.map((source: any, i: number) => (
        <div className={styles.statRow} key={i}>
          <span>{source.name}</span>
          <span className={styles.royalty}>
            {source.revenue.currency} {source.revenue.formattedShort}
          </span>
        </div>
      ))}
    </div>
  );
};

export default AnalysisTrackBreakdownSource;
