import { useTranslation } from "react-i18next";
import Button from "../../atom/Button/Button";
import { ButtonVariantEnum } from "../../types/enums";
import { ConfirmationDeleteClientListProps } from "../../types/props";
import Modal from "../modal/Modal";
import styles from "./confirmationDeleteClientList.module.scss";

const ConfirmationDeleteClientList = (
  props: ConfirmationDeleteClientListProps
): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal
      id="deleteClientListConfirmation_scm"
      isModalOpen={props.isVisible}
      className={styles.confirmationDeleteClientList}
    >
      <h3 className={styles.title}>{t(`clients.deleteList`)}</h3>
      <p>{t(`clients.deleteAsk`)}</p>
      <div className={styles.buttonContainer}>
        <Button
          variant={ButtonVariantEnum.outlineCta}
          onClick={props.onCancelClick}
          className="dontDelete_GTM"
        >
          {t(`clients.deleteNo`)}
        </Button>
        <Button
          variant={ButtonVariantEnum.primaryNarrowCta}
          onClick={props.onYesClick}
          className="yesDelete_GTM"
        >
          {t(`clients.deleteYes`)}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationDeleteClientList;
