import React from "react";
function PhoneIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      name="PhoneIcon"
      width="100%"
      height="100%"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m20.605 16.072-3.863-3.588a1.157 1.157 0 0 0-1.618.036l-1.683 1.768c-.375.398-.934.148-2.708-1.211a23.914 23.914 0 0 1-3.47-3.268C6.3 8.683 6.066 8.092 6.404 7.728l.456-.393 1.423-1.384a.438.438 0 0 0 .06-.075c.452-.626-.25-1.26-.544-1.508a.317.317 0 0 1-.018-.02L3.986.651 3.978.643C3.528.22 2.892.241 2.46.697L1.18 2.094l-.343.4c-.034.045-3.26 4.555 4.87 12.396 4.626 4.466 8.097 5.45 10.256 5.45.082 0 .162-.002.24-.005 1.41-.047 2.329-.528 2.673-.911l1.731-1.763c.218-.231.337-.547.323-.865a1.013 1.013 0 0 0-.324-.724ZM3.007 1.205c.075-.08.155-.119.238-.119.075 0 .151.033.223.1l.004-.003c.185.189.89.877 3.754 3.666.016.021.035.04.056.057.456.383.46.522.45.542L6.599 6.552C5.833 5.81 2.872 2.935 2.11 2.194l.896-.99Zm15.33 17.705c-.196.216-.9.637-2.16.68-1.63.06-5.098-.549-9.955-5.237C-.134 8.222.61 4.037 1.416 2.96l.194-.214c.373.362 1.432 1.392 4.437 4.308l-.156.134c-.969 1.032.186 2.381.807 3.106a24.698 24.698 0 0 0 3.582 3.373c1.546 1.185 2.768 2.122 3.7 1.134l.086-.09 4.314 4.155-.043.043Zm1.732-1.765-1.163 1.193-4.327-4.166 1.084-1.138c.14-.15.395-.16.548-.024l.013.011 3.88 3.603a.288.288 0 0 1 .081.203.44.44 0 0 1-.116.318Z"
        opacity=".5"
      />
    </svg>
  );
}

export default PhoneIcon;
