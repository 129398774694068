import React from "react";
function ExpandIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      name="collapseIcon"
      {...props}
    >
      <g fill="#999" fillRule="evenodd">
        <path
          fillRule="nonzero"
          d="M9.221 17.55a8.55 8.55 0 1 1 0-17.1 8.55 8.55 0 0 1 0 17.1Zm0-.9a7.65 7.65 0 1 0 0-15.3 7.65 7.65 0 0 0 0 15.3Z"
        />
        <path d="M8.835 6.3v2.314H6.521v.772h2.314V11.7h.772V9.386h2.314v-.772H9.607V6.3z" />
      </g>
    </svg>
  );
}

export default ExpandIcon;
