import { TableHeadProps } from "../../../../../../app/types/props";

export const tableHeaderData: TableHeadProps = [
  {
    id: 1,
    title: "adminSection.clientsPage.clientsPageTableHeader.clientCode",
    filteringInfo: "clientId",
  },
  {
    id: 2,
    title: "adminSection.clientsPage.clientsPageTableHeader.siteCode",
    filteringInfo: "siteCode",
  },
  {
    id: 3,
    title: "adminSection.clientsPage.clientsPageTableHeader.name",
    filteringInfo: "name",
  },
  {
    id: 4,
    title: "adminSection.clientsPage.clientsPageTableHeader.email",
    filteringInfo: "email",
  },
];

export const tableHeaderPublishingData: TableHeadProps = [
  {
    id: 1,
    title: "adminSection.clientsPage.clientsPageTableHeader.id",
    filteringInfo: "id",
  },
  {
    id: 2,
    title: "adminSection.clientsPage.clientsPageTableHeader.name",
    filteringInfo: "name",
  },
  {
    id: 3,
    title: "adminSection.clientsPage.clientsPageTableHeader.email",
    filteringInfo: "email",
  },
];

export const tableHeaderMechanicalData: TableHeadProps = [
  {
    id: 1,
    title: "adminSection.clientsPage.clientsPageTableHeader.clientCode",
    filteringInfo: "id",
  },
  {
    id: 2,
    title: "adminSection.clientsPage.clientsPageTableHeader.siteCode",
    filteringInfo: "siteCode",
  },
  {
    id: 3,
    title: "adminSection.clientsPage.clientsPageTableHeader.name",
    filteringInfo: "name",
  },
];
