import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import { useOktaAuth } from "@okta/okta-react";
import { windowScrollToTop } from "../../../../app/utils/scrolling";
import { useWindowSize } from "../../../../app/hooks";
import debounce from "../../../../app/utils/debounce";
import deepCompare from "../../../../app/utils/deepCompare";
import {
  recAnalysisAlbumPageStateSelector,
  fetchAnalysisAlbumsThunk,
  fetchMoreAnalysisAlbumsThunk,
  updateAnalysisAlbumPageStateAction,
  resetAnalysisAlbumsAction,
  recAnalysisAlbumsSelector,
  recAnalysisAlbumsDownload,
  recAnalysisAlbumStatusSelector,
  recAnalysisDownloadStatusSelector,
  recAnalysisNoDataFlagSelector,
  RecAnalysisAlbumPageInitialState,
} from "./recAnalysisAlbumSlice";
import { recAnalysisHeaderSelector } from "../recAnalysisHeaderSlice";
import { recClientSelectionSelector } from "../../recroot/recrootSlice";
import useInfiniteScroll from "../../../../app/hooks/useInfiniteScroll";
import Grid from "../../../../app/atom/Grid/Grid";
import Loader from "../../../../app/atom/Loader/Loader";
import AnalysisAlbumLeftSide from "../../../../app/molecules/analysis/analysisAlbum/AnalysisAlbumLeftSide";
import AnalysisAlbumRightSide from "../../../../app/molecules/analysis/analysisAlbum/AnalysisAlbumRightSide";
import RecAnalysisHeader from "../RecAnalysisHeader";
import Search from "../../../../app/molecules/search/Search";
import { getNow, getToday } from "../../../../app/utils/dateTime";
import styles from "../../../../app/molecules/analysis/analysis.module.scss";

const RecAnalysisAlbum = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  const { width: windowWidth } = useWindowSize();

  // observers
  const observerTopTarget = useRef<HTMLDivElement | null>(null);
  const observerBottomTarget = useRef<HTMLDivElement | null>(null);

  const { isOnScreen: topTargetIsOnScreen } =
    useInfiniteScroll(observerTopTarget);
  const {
    pageCount: bottomTargetPageCount,
    setPageCount: setBottomTargetPageCount,
  } = useInfiniteScroll(observerBottomTarget);

  // dispatch refs
  const dispatchedAnalysisAlbum = useRef<any>();
  const dispatchedAnalysisMoreAlbum = useRef<any>();
  const dispatchedDownload = useRef<any>();

  // selectors
  const clientSelection = useAppSelector(recClientSelectionSelector);
  const recAnalysisAlbumPageState = useAppSelector(
    recAnalysisAlbumPageStateSelector
  );
  const albums = useAppSelector(recAnalysisAlbumsSelector);
  const recAnalysisAlbumStatus = useAppSelector(recAnalysisAlbumStatusSelector);
  const recAnalysisDownloadStatus = useAppSelector(
    recAnalysisDownloadStatusSelector
  );
  const recAnalysisNoDataFlag = useAppSelector(recAnalysisNoDataFlagSelector);
  const recAnalysisHeaderState = useAppSelector(recAnalysisHeaderSelector);

  const [selectedAlbum, setSelectedAlbum] = useState<any>(undefined);
  const [analysisAlbumStart, setAnalysisAlbumStart] = useState(
    recAnalysisAlbumPageState.start
  );
  const [analysisAlbumSortColumn, setAnalysisAlbumSortColumn] = useState(
    recAnalysisAlbumPageState.sortColumn
  );
  const [analysisAlbumAscOrder, setAnalysisAlbumAscOrder] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState(
    recAnalysisAlbumPageState.filterText
  );
  const [loaderFlag, setLoaderFlag] = useState(true);
  const [totalAmount, setTotalAmount] = useState({
    currency: albums.data?.totalRoyalty?.currency,
    value: albums.data?.totalRoyalty?.formattedLong,
  });
  const [isSortingLoading, setIsSortingLoading] = useState(false);

  // abort functions
  const abortDispatchedDownload = useCallback(() => {
    if (dispatchedDownload.current) dispatchedDownload.current.abort();
  }, []);
  const abortDispatchedAnalysisAlbum = () => {
    if (dispatchedAnalysisAlbum.current)
      dispatchedAnalysisAlbum.current.abort();
  };
  const abortDispatchedAnalysisMoreAlbum = () => {
    if (dispatchedAnalysisMoreAlbum.current)
      dispatchedAnalysisMoreAlbum.current.abort();
  };

  const handleAnalysisAlbumsFetch = useMemo(
    () =>
      debounce((pageState: any) => {
        if (pageState.start === 0) {
          abortDispatchedAnalysisAlbum();
          dispatchedAnalysisAlbum.current = dispatch(
            fetchAnalysisAlbumsThunk(pageState)
          );
        } else {
          abortDispatchedAnalysisMoreAlbum();
          dispatchedAnalysisMoreAlbum.current = dispatch(
            fetchMoreAnalysisAlbumsThunk(pageState)
          );
        }
      }, 500),
    [dispatch]
  );

  const resetAnalysisAlbums = useCallback(() => {
    dispatch(resetAnalysisAlbumsAction({}));
  }, [dispatch]);

  const filterAnalysisAlbumsWith = useCallback(
    (columnName: string) => {
      setIsSortingLoading(true);
      resetAnalysisAlbums();
      setLoaderFlag(true);
      setAnalysisAlbumSortColumn(columnName);
      if (recAnalysisAlbumPageState.sortColumn !== columnName) {
        setAnalysisAlbumAscOrder(true);
      } else {
        setAnalysisAlbumAscOrder(!analysisAlbumAscOrder);
      }
    },
    [
      analysisAlbumAscOrder,
      recAnalysisAlbumPageState.sortColumn,
      resetAnalysisAlbums,
    ]
  );

  const handleSearch = useCallback(
    (newSearchText: string) => {
      setLoaderFlag(true);
      resetAnalysisAlbums();
      setSearchText(newSearchText);
      window?.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    [resetAnalysisAlbums]
  );

  const handleAlbumClick = (clickedAlbum: any) => {
    !selectedAlbum ||
    (selectedAlbum && selectedAlbum.productKey !== clickedAlbum.productKey)
      ? setSelectedAlbum(clickedAlbum)
      : setSelectedAlbum(undefined);
  };

  const handlePeriodSelection = () => {
    setSelectedAlbum(undefined);
  };

  const fetchMoreData = useCallback(() => {
    setAnalysisAlbumStart(
      () => (bottomTargetPageCount - 1) * recAnalysisAlbumPageState.count
    );
    setLoaderFlag(false);
  }, [bottomTargetPageCount, recAnalysisAlbumPageState.count]);

  useEffect(() => {
    return () => {
      abortDispatchedAnalysisAlbum();
      abortDispatchedDownload();
      // clear analysis song page state (keep)
      dispatch(
        updateAnalysisAlbumPageStateAction(RecAnalysisAlbumPageInitialState)
      );
    };
  }, [abortDispatchedDownload, dispatch]);

  useEffect(() => {
    const hasMore = albums?.data?.albums?.length < albums?.total;
    if (hasMore && bottomTargetPageCount > 1) {
      fetchMoreData();
    }
  }, [
    albums?.data?.albums?.length,
    albums?.total,
    bottomTargetPageCount,
    fetchMoreData,
  ]);

  useEffect(() => {
    if (
      !authState?.isAuthenticated ||
      clientSelection.selectedClients?.length === 0 ||
      recAnalysisHeaderState.selectedPeriods?.length === 0
    ) {
      return;
    }
    const recAnalysisAlbumState = {
      ...recAnalysisAlbumPageState,
      data: {
        periodIds: recAnalysisHeaderState.selectedPeriods,
        clientIds: clientSelection?.selectedClients?.map((c) => c.id) || [],
        clientLists: [],
      },
      start: analysisAlbumStart,
      sortColumn: analysisAlbumSortColumn,
      filterText: searchText,
      sortAscending: analysisAlbumAscOrder,
    };
    if (!deepCompare(recAnalysisAlbumState, recAnalysisAlbumPageState)) {
      dispatch(updateAnalysisAlbumPageStateAction(recAnalysisAlbumState));
      handleAnalysisAlbumsFetch(recAnalysisAlbumState);
      setSelectedAlbum(undefined);
    }
    if (
      !deepCompare(
        //ignore start value in deep compare
        { ...recAnalysisAlbumState, start: null },
        { ...recAnalysisAlbumPageState, start: null }
      )
    ) {
      setAnalysisAlbumStart(0);
      setBottomTargetPageCount(1);
    }
  }, [
    analysisAlbumAscOrder,
    analysisAlbumSortColumn,
    analysisAlbumStart,
    authState?.isAuthenticated,
    clientSelection.selectedClients,
    dispatch,
    handleAnalysisAlbumsFetch,
    recAnalysisHeaderState.selectedPeriods,
    recAnalysisAlbumPageState,
    searchText,
    setBottomTargetPageCount,
  ]);

  useEffect(() => {
    if (albums?.data?.albums?.length) {
      setIsSortingLoading(false);
    }
  }, [albums]);

  useEffect(() => {
    if (!isSortingLoading) {
      setTotalAmount({
        currency: albums?.data?.totalRoyalty?.currency,
        value: albums?.data?.totalRoyalty?.formattedLong,
      });
    }
  }, [albums, isSortingLoading]);

  const handleDownloadBtn = useCallback(() => {
    const params = {
      data: {
        clientIds: clientSelection?.selectedClients?.map((c) => c.id) || [],
        periodIds: recAnalysisHeaderState.selectedPeriods,
        analyzeBy: "BY_ALBUMS",
      },
      fileName: `${t("analysis.albums.royalties")}_${t(
        "analysis.albums.byAlbums"
      )}_${getToday()}-${getNow()}.xlsx`,
    };
    abortDispatchedDownload();
    dispatchedDownload.current = dispatch(recAnalysisAlbumsDownload(params));
  }, [
    abortDispatchedDownload,
    clientSelection.selectedClients,
    dispatch,
    recAnalysisHeaderState.selectedPeriods,
    t,
  ]);

  return (
    <Grid>
      {(recAnalysisAlbumStatus === "loading" ||
        recAnalysisDownloadStatus === "loading") &&
        loaderFlag && <Loader />}
      <div className={styles.analysis}>
        <RecAnalysisHeader
          tab="album"
          documentType="RECORDING"
          handlePeriodSelection={handlePeriodSelection}
          onDownloadBtnClick={handleDownloadBtn}
          isDownloadBtnDisabled={albums?.total === 0 ? true : false}
        />
        <div ref={observerTopTarget} id="observerTopTargetId" />
        {recAnalysisNoDataFlag !== undefined && (
          <div
            id="tab-header"
            className={`sticky top-0 z-[5] ${styles.tabHeader} ${
              topTargetIsOnScreen === false && bottomTargetPageCount > 0
                ? "shadow-lg"
                : ""
            }`}
          >
            <div className={styles.title}>
              <p className={styles.period}>
                {
                  recAnalysisHeaderState.tabSelectedPeriodTitle[
                    recAnalysisHeaderState.selectedPeriodText?.titleVariant
                  ]
                }{" "}
                <span>{recAnalysisHeaderState.selectedPeriodText.details}</span>
              </p>
              {(!!albums?.data?.albums?.length || isSortingLoading) && (
                <p className={styles.totalAmount}>
                  {totalAmount.currency}
                  <strong>{` ${totalAmount.value}`}</strong>
                </p>
              )}
            </div>
            <div className={styles.actions}>
              <Search
                placeholderText={t("analysis.albums.searchTextPlaceholder")}
                searchText={recAnalysisAlbumPageState.filterText}
                resultsCount={albums?.total}
                onSearchChange={handleSearch}
                showBackToTop={!topTargetIsOnScreen}
                onBackToTopClick={windowScrollToTop}
                status={recAnalysisAlbumStatus}
                className={styles.search}
              />
            </div>
            {(!!albums?.data?.albums?.length || isSortingLoading) && (
              <p className={styles.info}>
                {t("analysis.albums.selectAlbumToGetDetails")}
              </p>
            )}
          </div>
        )}
        {recAnalysisNoDataFlag && recAnalysisNoDataFlag?.length && (
          <div className="pl-10 text-xs sm:text-sm">
            {t(`analysis.${recAnalysisNoDataFlag}`)}
          </div>
        )}
        {recAnalysisNoDataFlag === "" && (
          <div className={styles.analysisAlbum}>
            <div className={styles.contentContainer}>
              <div
                className={`${styles.leftSide} ${
                  selectedAlbum ? styles.withSelection : ""
                }`}
              >
                <AnalysisAlbumLeftSide
                  bottomTargetPageCount={bottomTargetPageCount}
                  selectedClients={clientSelection.selectedClients}
                  filterAnalysisAlbumsWith={filterAnalysisAlbumsWith}
                  handleAlbumClick={handleAlbumClick}
                  selectedPeriods={recAnalysisHeaderState?.selectedPeriods}
                  selectedAlbum={selectedAlbum}
                  albums={albums}
                  windowWidth={windowWidth}
                />
              </div>
              {selectedAlbum && windowWidth && windowWidth >= 1200 && (
                <AnalysisAlbumRightSide
                  selectedAlbum={selectedAlbum}
                  topTargetIsOnScreen={topTargetIsOnScreen}
                  selectedPeriods={recAnalysisHeaderState?.selectedPeriods}
                  selectedClients={clientSelection.selectedClients.map(
                    (c) => c.id
                  )}
                  handleBreakdownClose={handleAlbumClick}
                />
              )}
            </div>
          </div>
        )}
        <div
          className={`${
            albums?.data?.albums?.length === albums?.total ? "hidden" : ""
          }`}
          ref={observerBottomTarget}
        ></div>
      </div>
    </Grid>
  );
};

export default RecAnalysisAlbum;
