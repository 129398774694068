import { useEffect, useState } from "react";
import Button from "../../atom/Button/Button";
import { ButtonVariantEnum } from "../../types/enums";
import Modal from "../modal/Modal";
import Tabs, { Tab } from "../tabs/Tabs";
import TermsDE from "./TermsDE";
import TermsFR from "./TermsFR";
import TermsInternational from "./TermsInternational";
import TermsSV from "./TermsSV";
import TermsUK from "./TermsUK";
import TermsUS from "./TermsUS";
import styles from "./termsOfUseModal.module.scss";
import { TermsOfUseModalProps } from "../../types/props";
import Loader from "../../atom/Loader/Loader";
import { useTranslation } from "react-i18next";

const TermsOfUseModal = (props: TermsOfUseModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [language, setLanguage] = useState(props.userLanguage);

  const { t } = useTranslation();

  useEffect(() => {
    setLanguage(props.userLanguage);
  }, [props.userLanguage]);

  const handleAcceptBtn = () => {
    props.onAcceptTerms(language);
    setIsModalOpen(false);
  };

  const handleCancelBtn = () => {
    setIsModalOpen(false);
    props.onDeclineTerms();
  };

  const handleLanguageChange = (selectedLang: string) => () => {
    setLanguage(selectedLang);
  };

  const getTabIndexByLanguage = (lang: string) => {
    switch (lang) {
      case "international":
        return 0;
      case "en_GB":
        return 1;
      case "en_US":
        return 2;
      case "de_DE":
        return 3;
      case "fr_FR":
        return 4;
      case "sv_SV":
        return 5;
    }
  };

  const initTabIndex = getTabIndexByLanguage(language);

  return (
    <Modal
      id="termsOfUse"
      isModalOpen={isModalOpen}
      className={styles.termsModal}
      disableBackdropClick={true}
    >
      {props.status === "loading" && <Loader />}
      <h2>{t("termsOfUseModal.title")}</h2>
      <Tabs selectedTabIndex={initTabIndex}>
        <Tab
          title={t("termsOfUseModal.international")}
          onTabClick={handleLanguageChange("international")}
        >
          <TermsInternational />
        </Tab>
        <Tab
          title={t("termsOfUseModal.en_uk")}
          onTabClick={handleLanguageChange("en_GB")}
        >
          <TermsUK />
        </Tab>
        <Tab
          title={t("termsOfUseModal.en_us")}
          onTabClick={handleLanguageChange("en_US")}
        >
          <TermsUS />
        </Tab>
        <Tab
          title={t("termsOfUseModal.de_de")}
          onTabClick={handleLanguageChange("de_DE")}
        >
          <TermsDE />
        </Tab>
        <Tab
          title={t("termsOfUseModal.fr_fr")}
          onTabClick={handleLanguageChange("fr_FR")}
        >
          <TermsFR />
        </Tab>
        <Tab
          title={t("termsOfUseModal.sv_sv")}
          onTabClick={handleLanguageChange("sv_SV")}
        >
          <TermsSV />
        </Tab>
      </Tabs>
      <footer>
        <Button
          dataTestId="termsOfUseCancelBtn"
          variant={ButtonVariantEnum.cleanCta}
          onClick={handleCancelBtn}
          disabled={props.status === "loading"}
          className="termsOfUseCancel_GTM w-full xs:w-auto"
        >
          {t("termsOfUseModal.cancelBtn")}
        </Button>
        <Button
          dataTestId="termsOfUseAcceptBtn"
          variant={ButtonVariantEnum.primaryCta}
          onClick={handleAcceptBtn}
          disabled={props.status === "loading"}
          className="termsOfUseAccept_GTM w-full xs:w-auto"
        >
          {t("termsOfUseModal.acceptBtn")}
        </Button>
      </footer>
    </Modal>
  );
};
export default TermsOfUseModal;
