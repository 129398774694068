import React from "react";
import { ButtonProps } from "../../types/props";
import { ButtonVariantEnum } from "../../types/enums";

const BtnContent = ({
  text,
  icon,
  iconRight,
}: {
  text: React.ReactNode;
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
}) => (
  <div className="pointer-events-none flex flex-row items-center justify-between gap-2">
    {icon && icon}
    <span>{text}</span>
    {iconRight && iconRight}
  </div>
);

export default function Button(props: ButtonProps): JSX.Element {
  const {
    onClick,
    children,
    variant = ButtonVariantEnum.primaryCta,
    className = "",
    icon,
    iconRight,
    disabled,
    type = "button",
    ariaLabel,
    dataTestId,
    name,
  } = props;

  const addClassNames = () => {
    const addClasses = ["button"];
    if (className) addClasses.push(className);
    if (variant) addClasses.push(variant);

    return addClasses.length > 0
      ? { className: addClasses.join(" ") }
      : undefined;
  };

  return (
    <button
      {...addClassNames()}
      onClick={onClick}
      disabled={disabled}
      type={type}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      name={name}
    >
      <BtnContent text={children} icon={icon} iconRight={iconRight} />
    </button>
  );
}
