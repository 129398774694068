import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import { fetchSync, fetchSyncOverview, downloadSyncData } from "./recSyncAPI";

export declare type RecSyncPageStateProps = {
  data: {
    clientIds: any;
    clientLists: any;
  };
  documentType: string;
  count: number;
  activeTab: RecSyncTabEntry;
  tabsState: RecSyncTabsState;
};

export declare type RecSyncOverviewPageStateProps = {
  licensesByMedia: [] | null;
  licensesByStatus: [] | null;
  mostRecentLicenses: [] | null;
  mostRequestedSongs: [] | null;
};

export type RecSyncTabEntry =
  | "ALL"
  | "QUOTING"
  | "LICENSING"
  | "RECEIVED"
  | "COMPLETED"
  | "UNKNOWN"; // unknown = overview tab

export type RecSyncTabData = {
  timestamp?: string;
  error?: string;
  path?: string;
  data: any[];
  total: number;
  noDataFlag?: string;
};

type RecSyncTabTabData = {
  ALL: RecSyncTabData;
  QUOTING: RecSyncTabData;
  LICENSING: RecSyncTabData;
  RECEIVED: RecSyncTabData;
  COMPLETED: RecSyncTabData;
  UNKNOWN?: RecSyncTabData;
};

type RecSyncTabState = {
  tabClientData: {
    clientIds: any;
    clientLists: any;
    periodIds?: number[];
  };
  status?: string;
  start?: number;
  filterText?: string;
  sortColumn?: string;
  isAsc?: boolean;
};

type RecSyncTabsState = {
  [key: string]: RecSyncTabState;
};

type RecSyncTabProps = {
  [key: string]: RecSyncTabTabData | RecSyncTabEntry | undefined;
  data: RecSyncTabTabData;
};

export interface RecSyncState {
  recSyncPageState: RecSyncPageStateProps;
  recSyncOverview: RecSyncOverviewPageStateProps;
  recSyncTab: RecSyncTabProps;
  status: "idle" | "loading" | "failed";
  downloadStatus: "idle" | "loading" | "failed";
  hasError: boolean;
}

const initialState: RecSyncState = {
  recSyncPageState: {
    data: {
      clientIds: [],
      clientLists: [],
    },
    documentType: "RECORDING",
    activeTab: "UNKNOWN",
    count: 100,
    tabsState: {
      ALL: {
        tabClientData: {
          clientIds: [],
          clientLists: [],
        },
        start: 0,
        filterText: "",
        isAsc: false,
        status: "",
        sortColumn: "statusLastModifiedDate",
      },
      QUOTING: {
        tabClientData: {
          clientIds: [],
          clientLists: [],
        },
        start: 0,
        filterText: "",
        isAsc: false,
        status: "",
        sortColumn: "statusLastModifiedDate",
      },
      LICENSING: {
        tabClientData: {
          clientIds: [],
          clientLists: [],
        },
        start: 0,
        filterText: "",
        isAsc: false,
        status: "",
        sortColumn: "statusLastModifiedDate",
      },
      RECEIVED: {
        tabClientData: {
          clientIds: [],
          clientLists: [],
        },
        start: 0,
        filterText: "",
        isAsc: false,
        status: "",
        sortColumn: "statusLastModifiedDate",
      },
      COMPLETED: {
        tabClientData: {
          clientIds: [],
          clientLists: [],
          periodIds: undefined,
        },
        start: 0,
        filterText: "",
        isAsc: false,
        status: "",
        sortColumn: "statusLastModifiedDate",
      },
    },
  },
  recSyncOverview: {
    licensesByMedia: null,
    licensesByStatus: null,
    mostRecentLicenses: null,
    mostRequestedSongs: null,
  },
  recSyncTab: {
    data: {
      ALL: { data: [], total: 0, noDataFlag: undefined },
      QUOTING: { data: [], total: 0, noDataFlag: undefined },
      LICENSING: { data: [], total: 0, noDataFlag: undefined },
      RECEIVED: { data: [], total: 0, noDataFlag: undefined },
      COMPLETED: { data: [], total: 0, noDataFlag: undefined },
    },
  },
  status: "idle",
  downloadStatus: "idle",
  hasError: false,
};

export const fetchRecSyncOverviewThunk = createAsyncThunk(
  "recording/syncoverview",
  async (payload: any, thunkAPI) => {
    const response = await fetchSyncOverview(payload, thunkAPI);
    return response;
  }
);

export const fetchRecSyncThunk = createAsyncThunk(
  "recording/sync",
  async (payload: any, thunkAPI) => {
    const response = await fetchSync(payload, thunkAPI);
    return response;
  }
);

export const fetchMoreRecSyncThunk = createAsyncThunk(
  "recording/moresync",
  async (payload: any, thunkAPI) => {
    const response = await fetchSync(payload, thunkAPI);
    return response;
  }
);

export const recSyncDownloadThunk = createAsyncThunk(
  "recording/sync/download",
  async (payload: any, thunkAPI) => {
    const response = await downloadSyncData(payload, thunkAPI);
    return response;
  }
);

export const recSyncSlice = createSlice({
  name: "recSync",
  initialState,
  reducers: {
    updateRecSyncPageState: (state, action: any) => {
      state.recSyncPageState = { ...state.recSyncPageState, ...action.payload };
    },
    updateRecSyncActiveTab: (state, action: any) => {
      const activeTab = action.payload;
      state.recSyncTab.activeTab = activeTab;
    },
    resetRecSyncSearch: (state) => {
      const activeTab = state.recSyncPageState.activeTab;
      state.recSyncTab.data = {
        ...state.recSyncTab.data,
        [activeTab]: { data: [] },
      };
    },
    resetRecSyncTabTabsState: (state) => {
      state.recSyncTab.data = initialState.recSyncTab.data;
      state.recSyncPageState.tabsState =
        initialState.recSyncPageState.tabsState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecSyncOverviewThunk.fulfilled, (state, action) => {
        state.status = "idle";
        state.recSyncOverview.licensesByMedia = action.payload?.licensesByMedia;
        state.recSyncOverview.licensesByStatus =
          action.payload?.licensesByStatus;
        state.recSyncOverview.mostRecentLicenses =
          action.payload?.mostRecentLicenses;
        state.recSyncOverview.mostRequestedSongs =
          action.payload?.mostRequestedTracks;

        // update count values
        action.payload?.licensesByStatus?.forEach((licence: any) => {
          if (licence.status !== "UNKNOWN") {
            const currentStatus = licence.status as string;
            const currentTab = state.recSyncTab.data[
              currentStatus.toUpperCase() as RecSyncTabEntry
            ] as RecSyncTabData;
            currentTab.total = licence.count;
          }
        });
      })
      .addCase(fetchRecSyncThunk.fulfilled, (state, action) => {
        const activeTab = state.recSyncPageState.activeTab;
        state.status = "idle";
        state.recSyncTab.data = {
          ...state.recSyncTab?.data,
          [activeTab]: {
            ...state.recSyncTab?.data?.[activeTab],
            ...action.payload,
            noDataFlag:
              action.payload?.total === 0
                ? (state?.recSyncPageState.tabsState[activeTab]?.filterText
                    ?.length || 0) > 0
                  ? "noSearchData"
                  : "noData"
                : "",
          },
        };
      })
      .addCase(fetchMoreRecSyncThunk.fulfilled, (state, action) => {
        const activeTab = state.recSyncPageState.activeTab;
        state.status = "idle";
        state.recSyncTab = {
          ...state.recSyncTab,
          data: {
            ...state.recSyncTab?.data,
            [activeTab]: {
              ...(state.recSyncTab?.data?.[activeTab] || []),
              data: [
                ...(state.recSyncTab?.data?.[activeTab]?.data || []),
                ...(action.payload.data || []),
              ],
            },
          },
        };
      })
      .addCase(fetchRecSyncOverviewThunk.rejected, (state) => {
        state.status = "failed";
        state.recSyncOverview = initialState.recSyncOverview;
      })
      // download
      .addCase(recSyncDownloadThunk.fulfilled, (state, action: any) => {
        state.downloadStatus = "idle";
      })
      .addCase(recSyncDownloadThunk.pending, (state) => {
        state.downloadStatus = "loading";
      })
      .addCase(recSyncDownloadThunk.rejected, (state) => {
        state.downloadStatus = "failed";
      })
      .addMatcher(
        isAnyOf(
          fetchRecSyncOverviewThunk.pending,
          fetchRecSyncThunk.pending,
          fetchMoreRecSyncThunk.pending
        ),
        (state) => {
          state.status = "loading";
          state.hasError = false;
        }
      )
      .addMatcher(isAnyOf(fetchRecSyncOverviewThunk.rejected), (state) => {
        state.status = "failed";
        state.hasError = true;
      });
  },
});

export const recSyncPageStateSelector = (state: RootState) =>
  state.recSync.recSyncPageState;
export const recSyncOverviewSelector = (state: RootState) =>
  state.recSync.recSyncOverview;
export const recSyncStatusSelector = (state: RootState) => state.recSync.status;
export const recSyncTabSelector = (state: RootState) =>
  state.recSync.recSyncTab;
export const recSyncCurrentTabSelector = (state: RootState) =>
  state.recSync.recSyncPageState.activeTab;

export const {
  updateRecSyncPageState: updateRecSyncPageStateAction,
  updateRecSyncActiveTab: updateRecSyncActiveTabAction,
  resetRecSyncSearch: resetRecSyncSearchAction,
  resetRecSyncTabTabsState: resetRecSyncTabTabsStateAction,
} = recSyncSlice.actions;

export default recSyncSlice.reducer;
