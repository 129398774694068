import request from "../../../app/utils/requestUtils";
import type { FetchClientsPayload } from "../../../app/types/interfaces";
import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";

export function fetchClients(params: FetchClientsPayload, thunkAPI?: any) {
  const {
    data,
    start = 0,
    count = 0,
    documentType = "RECORDING",
    sortColumn = "path",
    isAsc = true,
    filterText = "",
  } = params;

  const docTypeReq = "clientDocumentType";

  // todo: isAsc should be sortAscending (but does not work yet)
  const response = request(
    `${USER_API_RESOURCE_BASE_URLS.clients}?start=${start}&count=${count}&${docTypeReq}=${documentType}&sortColumn=${sortColumn}&isAsc=${isAsc}&filterText=${filterText}`,
    { method: "POST", body: JSON.stringify(data), signal: thunkAPI?.signal }
  );
  return response;
}

export function fetchClientLists(params: any, thunkAPI?: any) {
  const {
    start = 0,
    count = 1000,
    documentType = "RECORDING",
    sortColumn = "name",
    isAsc = true,
    filterText = "",
  } = params;
  const response = request(
    `${USER_API_RESOURCE_BASE_URLS.clientLists}?start=${start}&count=${count}&documentType=${documentType}&sortColumn=${sortColumn}&isAsc=${isAsc}&filterText=${filterText}`,
    { method: "GET", signal: thunkAPI?.signal }
  );
  return response;
}
