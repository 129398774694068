import React from "react";
import { useTranslation } from "react-i18next";
import { SyncIcon } from "../../atom/Icon";
import Block from "./Block";
import BlockItem from "./BlockItem";
import styles from "./sync.module.scss";

export declare type LicenseProp = {
  fee: {
    moneyValue: string;
    currencySign: string;
  };
  mediaType: string;
  projectName: string;
  licenseGenId: string;
  licenseEntryId: number;
  uniqueLicenseEntryId: string;
  status: string;
  statusLastModifiedDate: string;
};

export declare type LicensesBlockProps = {
  mostRecentLicenses: Array<LicenseProp> | null;
};

const MostRecentLicensesBlock = (props: LicensesBlockProps): JSX.Element => {
  const { mostRecentLicenses } = props;
  const { t } = useTranslation();

  if (mostRecentLicenses === null) {
    return <></>;
  }
  return (
    <Block
      title={t("sync.overviewTab.licenses.title")}
      subTitle={t("sync.overviewTab.licenses.subTitle")}
    >
      <ul className="px-4">
        {mostRecentLicenses?.length === 0 ? (
          <span className={styles.nodata}>
            {t("sync.overviewTab.licenses.noData")}
          </span>
        ) : (
          <>
            {mostRecentLicenses?.map((license: LicenseProp, index: number) => (
              <BlockItem
                key={license.licenseEntryId}
                isLastRow={index === mostRecentLicenses.length - 1}
                icon={SyncIcon}
                title={license.projectName}
                contentLeft={
                  <>
                    <div className="text-xs sm:text-base">
                      <span className="mr-1 font-light ">
                        {license.fee.currencySign}
                      </span>
                      <span>{license.fee.moneyValue}</span>
                    </div>
                    <div className="text-xs sm:text-base">
                      <span className="mr-2 font-medium">
                        {t("sync.overviewTab.licenses.media")}:
                      </span>
                      <span>{license.mediaType}</span>
                    </div>
                  </>
                }
                contentRight={
                  <div className="text-xs sm:text-base">
                    <div>{license.status}</div>
                    <div>{license.statusLastModifiedDate}</div>
                  </div>
                }
              />
            ))}
          </>
        )}
      </ul>
    </Block>
  );
};

export default MostRecentLicensesBlock;
