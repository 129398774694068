import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { StatusEnum } from "../../../../app/types/enums";
import { limitDataProps } from "../../../../app/types/props";

import { useFetch } from "../../common/hooks/useFetch";

import {
  getAdvanceRequestSettings,
  updateAdvanceRequestSettings,
} from "./AdvanceRequestConfigAPI";

export interface advanceRequestState {
  limitData: limitDataProps;
  status: StatusEnum;
}

const initialState: advanceRequestState = {
  limitData: {
    lowerLimit: 0,
    upperLimit: 0,
  },
  status: StatusEnum.Idle,
};

export const getAdvanceRequestSettingsThunk = createAsyncThunk(
  "admin/advance-request/settings",
  async (_, { rejectWithValue }) => {
    try {
      const fetchAdvanceRequestSettings = await useFetch();
      const response = await fetchAdvanceRequestSettings({
        fetchFunction: getAdvanceRequestSettings,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAdvanceRequestSettingsThunk = createAsyncThunk(
  "admin/advance-request/",
  async (params: any, { rejectWithValue }) => {
    try {
      const updateAdvanceRequest = await useFetch();
      const response = await updateAdvanceRequest({
        data: params,
        fetchFunction: updateAdvanceRequestSettings,
        customSuccessMsg: "adminSection.common.successEditMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const advanceRequestConfigSlice = createSlice({
  name: "advanceRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getAdvanceRequestSettingsThunk.fulfilled,
        (state, action: any) => {
          state.status = StatusEnum.Idle;
          state.limitData = action.payload;
        }
      )
      .addCase(getAdvanceRequestSettingsThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getAdvanceRequestSettingsThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(
        updateAdvanceRequestSettingsThunk.fulfilled,
        (state, action: any) => {
          state.status = StatusEnum.Idle;
          state.limitData.lowerLimit = action.payload?.lowerLimit;
          state.limitData.upperLimit = action.payload?.upperLimit;
        }
      )
      .addCase(updateAdvanceRequestSettingsThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(updateAdvanceRequestSettingsThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      });
  },
});

export const advanceRequestSelector = (state: RootState) =>
  state.advanceRequest.limitData;

export default advanceRequestConfigSlice.reducer;
