import { USER_API_RESOURCE_BASE_URLS } from "../../../../app/config/userEndpoints.const";
import request from "../../../../app/utils/requestUtils";

export function fetchLanguages() {
  return request(`${USER_API_RESOURCE_BASE_URLS.languages}`);
}

export function setUserLanguage(params: any, thunkAPI?: any) {
  const { data } = params;

  return request(`${USER_API_RESOURCE_BASE_URLS.users}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
  });
}
