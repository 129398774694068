import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";

export interface SiteLayoutState {
  isHamburgerOpen: boolean;
  isClientModalOpen: boolean;
}

const initialState: SiteLayoutState = {
  isHamburgerOpen: false,
  isClientModalOpen: false,
};

export const siteLayoutSlice = createSlice({
  name: "sitelayout",
  initialState,
  reducers: {
    setHamburgerState: (state, action: any) => {
      state.isHamburgerOpen = action.payload;
    },
    setClientModalState: (state, action: any) => {
      state.isClientModalOpen = action.payload;
    },
  },
});

export const isHamburgerOpenSelector = (state: RootState) =>
  state.sitelayout.isHamburgerOpen;
export const isClientModalOpenSelector = (state: RootState) =>
  state.sitelayout.isClientModalOpen;

export const {
  setHamburgerState: setHamburgerStateAction,
  setClientModalState: setClientModalStateAction,
} = siteLayoutSlice.actions;

export default siteLayoutSlice.reducer;
