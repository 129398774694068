import { memo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./../analysis.module.scss";

type AnalysisSongBreakdownDetailDataSongEntry = {
  cae: number;
  controlled: boolean;
  interestedPartyName: string;
  level: number;
  mechanicalControlled: string;
  mechanicalOwned: string;
  performanceControlled: string;
  performanceOwned: string;
};

export type AnalysisSongBreakdownDetailDataProps = {
  composer: string;
  id: number;
  resStatus: number;
  songId: number;
  songIpEntities: AnalysisSongBreakdownDetailDataSongEntry[];
  songTitle: string;
};

const AnalysisSongBreakdownDetail = ({
  data,
  status,
}: {
  data: AnalysisSongBreakdownDetailDataProps | null;
  status: "noData" | "loading" | "idle" | "failed";
}): JSX.Element => {
  const { t } = useTranslation();

  if (status === "noData" || status === "loading")
    <p className="pl-2 pt-4">{t("app.loading")}</p>;

  if (!data || !data.songIpEntities) {
    if (status === "idle")
      return (
        <p className="pl-2 pt-4">{t("analysis.songs.breakdown.noData")}</p>
      );
    else return <p className="pl-2 pt-4">{t("app.loading")}</p>;
  }

  const renderStatRow = (
    i: number,
    song: AnalysisSongBreakdownDetailDataSongEntry
  ) => {
    return (
      <tr key={i}>
        <td className={`${styles.colLeft} ${styles.colFixedWidth}`}>
          {song.interestedPartyName}
        </td>
        <td className={`text-right`}>
          {song.controlled === true
            ? t("analysis.songs.breakdown.songDetailsView.yes")
            : t("analysis.songs.breakdown.songDetailsView.no")}
        </td>
        <td className="text-right">{song.cae}</td>
        <td>---</td>
        <td className="border-l border-dotted text-right">
          {song.mechanicalControlled}
        </td>
        <td className="text-right">{song.mechanicalOwned}</td>
        <td className="border-l border-dotted text-right">
          {song.performanceControlled}
        </td>
        <td className="text-right">{song.performanceOwned}</td>
      </tr>
    );
  };

  return (
    <div className={`${styles.detailsContainer} smallScrollbar`}>
      <table>
        <thead>
          <tr className={styles.colHeaders}>
            <th className={`${styles.colLeft} ${styles.colFixedWidth}`}>
              {t("analysis.songs.breakdown.songDetailsView.name")}
            </th>
            <th>{t("analysis.songs.breakdown.songDetailsView.controlled")}</th>
            <th>{t("analysis.songs.breakdown.songDetailsView.cae_ipi")}</th>
            <th>
              {t("analysis.songs.breakdown.songDetailsView.contribution")}
            </th>
            <th colSpan={2} className={styles.colCentered}>
              {t("analysis.songs.breakdown.songDetailsView.mechanical")}
            </th>
            <th colSpan={2} className={styles.colCentered}>
              {t("analysis.songs.breakdown.songDetailsView.performance")}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.colSubHeaders}>
            <td colSpan={4}></td>
            <td className={`border-l border-dotted`}>
              {t("analysis.songs.breakdown.songDetailsView.owned")}
            </td>
            <td>{t("analysis.songs.breakdown.songDetailsView.collected")}</td>
            <td className={`border-l border-dotted`}>
              {t("analysis.songs.breakdown.songDetailsView.owned")}
            </td>
            <td>{t("analysis.songs.breakdown.songDetailsView.collected")}</td>
          </tr>

          {data.songIpEntities.map(
            (song: AnalysisSongBreakdownDetailDataSongEntry, i: number) => {
              return renderStatRow(i, song);
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

export default memo(AnalysisSongBreakdownDetail);
