import { memo } from "react";
import deepCompare from "../../../app/utils/deepCompare";
import useClientSelection from "./useClientSelection";
import SelectClientsModal from "../../molecules/selectClientsModal/SelectClientsModal";
import type { ClientSelectionProps } from "../../../app/types/props";

const ClientSelection = ({
  documentType,
  type,
  editListName,
  editListId,
  page,
  editListType,
}: ClientSelectionProps) => {
  const {
    appState,
    addTempClientsToBeSelected,
    removeTempParentsClientsToBeSelected,
    clientSelection,
    currencies,
    isLoading,
    handleShowDataBtn,
    setSelectedCurrency,
    deleteCurrentClientList,
    tabAllClientsData,
    tabParentsData,
    modalOpen,
    setModalOpen,
    modalTabSubClientsFetchData,
    abortModalTabSubClientsFetchData,
    modalTabResetSubClientsData,
  } = useClientSelection({
    documentType,
    type,
    editListName,
    editListId,
    page,
    editListType,
  });

  if (documentType === "NONE") return <div></div>;

  return (
    <>
      <div className={`${appState?.containerClassName}`}>
        {appState?.button}
      </div>
      {modalOpen === true && type && (
        <SelectClientsModal
          id={type}
          isOpen={modalOpen}
          callbackCloseModal={() => setModalOpen(false)}
          currencies={currencies}
          setSelectedCurrency={setSelectedCurrency}
          tabAllClients={tabAllClientsData}
          tabParents={tabParentsData}
          tabSelectedClients={{}}
          onShowDataClick={handleShowDataBtn}
          isLoading={isLoading}
          showTab={appState.showTab}
          editListName={appState.editListName}
          showDataBtnText={appState.showDataBtnText}
          isDeleteBtnVisible={appState.isDeleteBtnVisible}
          deleteCurrentClientList={deleteCurrentClientList}
          titleTooltipText={appState.titleTooltipText}
          sectionType={documentType}
          addTempClientsToBeSelected={addTempClientsToBeSelected}
          removeTempParentsClientsToBeSelected={
            removeTempParentsClientsToBeSelected
          }
          tempParentsClientsToBeSelected={
            clientSelection.tempParentsClientsToBeSelected
          }
          subClientsFetchData={modalTabSubClientsFetchData}
          abortSubClientsFetchData={abortModalTabSubClientsFetchData}
          resetSubClientsData={modalTabResetSubClientsData}
          subClients={clientSelection.subClients}
        />
      )}
    </>
  );
};

export default memo(
  ClientSelection,
  (prevProps: ClientSelectionProps, nextProps: ClientSelectionProps) =>
    deepCompare(prevProps, nextProps)
);
