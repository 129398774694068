import { useTranslation } from "react-i18next";
import styles from "../analysis.module.scss";
import { useEffect, useState } from "react";

interface InfiniteTableHeaderProps {
  sortAnalysisDigitalBy: (columnName: string) => void;
  documentType: "PUBLISHING" | "RECORDING";
}

const AnalysisDigitalInfiniteTableHeader = (
  props: InfiniteTableHeaderProps
) => {
  const { t } = useTranslation();
  const { sortAnalysisDigitalBy, documentType } = props;

  const [sourceColClassName, setSourceColClassName] = useState("");
  const [sourceColTitle, setSourceColTitle] = useState<any>();
  const [sourceColSortByValue, setSourceColSortByValue] = useState("");

  useEffect(() => {
    if (documentType === "PUBLISHING") {
      setSourceColClassName(styles.digitalCol);
      setSourceColTitle(t("analysis.digital.digitalSource"));
      setSourceColSortByValue("sourceNameAndCode");
    } else {
      setSourceColClassName(styles.sourceCol);
      setSourceColTitle(t("analysis.digital.source"));
      setSourceColSortByValue("originalSource");
    }
  }, [documentType, t]);

  return (
    <div id="table-header" className={styles.headContainer}>
      <table className={`w-full`}>
        <thead>
          <tr>
            <th
              className={sourceColClassName}
              onClick={() => sortAnalysisDigitalBy(sourceColSortByValue)}
            >
              {sourceColTitle}
            </th>
            <th
              className={`sortByRoyalties_GTM ${styles.royaltiesCol}`}
              onClick={() => sortAnalysisDigitalBy("royalties")}
            >
              {t("analysis.digital.royalties")}
            </th>
            <th></th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default AnalysisDigitalInfiniteTableHeader;
