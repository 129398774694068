import { createPortal } from "react-dom";

function ModalPortal(props: { children: React.ReactNode; wrapperId?: string }) {
  const { children, wrapperId = "modal-root" } = props;

  return createPortal(
    children,
    document.getElementById(wrapperId) as HTMLElement
  );
}
export default ModalPortal;
