import request from "../../../../app/utils/requestUtils";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

import { NewsPayload } from "./NewsSlice";

export type NewsRequestBody = Pick<NewsPayload, "pagination" | "filterText"> & {
  searchCriteria: {
    includeExpired: boolean;
  };
};

export const getNews = (payload: NewsRequestBody) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.news}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};
