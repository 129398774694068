import request from "../../../../app/utils/requestUtils";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";
import {
  EditAdminAccountProps,
  FormDataState,
} from "./AddEditAdminAccountsSlice";

export const getCountriesList = () => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.countries}`, {
    method: "GET",
  });
};

export const getAdminAccountById = (payload: { id: number }) => {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}/${id}`, {
    method: "GET",
  });
};

export const deleteAdminAccountById = (payload: { id: number }) => {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}/${id}`, {
    method: "DELETE",
  });
};

export const createAdminAccount = (payload: FormDataState) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });
};

export const updateAdminAccount = (payload: EditAdminAccountProps) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}/${payload.id}`, {
    method: "PUT",
    body: JSON.stringify(payload.filteredData),
  });
};

export const resendWelcomeEmail = (payload: { id: number }) => {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}/${id}/welcome-email`, {
    method: "PUT",
  });
};

export const restoreAdminAccount = (payload: { id: number }) => {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}/${id}/deleted`, {
    method: "PUT",
  });
};
