import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import {
  FetchAnalysisTrackBreakdown_Trend,
  FetchAnalysisTrackBreakdown_Territory,
  FetchAnalysisTrackBreakdown_Source,
  downloadTrackBreakdownData,
} from "./recAnalysisTrackBreakdownAPI";

export declare type RecAnalysisTrackBreakdownProps =
  | {
      periodIds: Array<number>;
      productKey: number | null; // can be null.
      isrc: string; // productKey is not given on all 3 sources. So we use isrc
      data:
        | RecAnalysisTrackBreakdowTrendData[]
        | RecAnalysisTrackBreakdowTerritoryData[]
        | RecAnalysisTrackBreakdowSourceData[]
        | null;
    }
  | null
  | undefined;

export type RecAnalysisTrackBreakdowTrendData = {
  title: string;
  artist: string;
  isrc: string;
  royaltyTrendsData: {
    totalRoyalty: {
      currency: string;
      raw: number;
      formattedShort: string;
      formattedLong: string;
      formattedPercent: string;
      percent: number;
    };
    perYear: boolean;
    royaltiesPerPeriodOrYear: {
      periodOrYear: string;
      royalty: {
        currency: string;
        raw: number;
        formattedShort: string;
        formattedLong: string;
        formattedPercent: string;
        percent: number;
      };
    }[];
  };
};

export type RecAnalysisTrackBreakdowTerritoryData = {
  title: string;
  artist: string;
  configDesc: string;
  productKey: number;
  isrc: string;
  totalRoyalty: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
  territories: {
    countryName: string;
    countryCode: string;
    royalty: {
      currency: string;
      raw: number;
      formattedShort: string;
      formattedLong: string;
      formattedPercent: string;
      percent: number;
    }[];
  }[];
};

export type RecAnalysisTrackBreakdowSourceData = {
  productKey: number;
  title: string;
  artist: string;
  configDesc: string;
  isrc: string;
  totalRoyalty: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
  sources: {
    name: string;
    revenue: {
      currency: string;
      raw: number;
      formattedShort: string;
      formattedLong: string;
      formattedPercent: string;
      percent: number;
    }[];
    zero: boolean;
  };
};

export interface recAnalysisTrackBreakdownState {
  trackBreakdownTrend: {
    dataArray: Array<RecAnalysisTrackBreakdowTrendData>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  trackBreakdownTerritory: {
    dataArray: Array<RecAnalysisTrackBreakdowTerritoryData>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  trackBreakdownSource: {
    dataArray: Array<RecAnalysisTrackBreakdownProps>; // todo
    status: "noData" | "idle" | "loading" | "failed";
  };
  trackBreakdownDownloadStatus: "idle" | "loading" | "failed";
}

const initialState: recAnalysisTrackBreakdownState = {
  trackBreakdownTrend: { dataArray: [], status: "noData" },
  trackBreakdownTerritory: { dataArray: [], status: "noData" },
  trackBreakdownSource: { dataArray: [], status: "noData" },
  trackBreakdownDownloadStatus: "idle",
};

export const FetchRecAnalysisTrackBreakdown_Trend_Thunk = createAsyncThunk(
  "recording/FetchRecAnalysisTrackBreakdown_Trend_Thunk",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisTrackBreakdown_Trend(params, thunkAPI);
  }
);

export const FetchRecAnalysisTrackBreakdown_Territory_Thunk = createAsyncThunk(
  "recording/FetchRecAnalysisTrackBreakdown_Territory_Thunk",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisTrackBreakdown_Territory(params, thunkAPI);
  }
);

export const FetchRecAnalysisTrackBreakdown_Source_Thunk = createAsyncThunk(
  "recording/FetchRecAnalysisTrackBreakdown_Source_Thunk",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisTrackBreakdown_Source(params, thunkAPI);
  }
);

export const recAnalysisTrackBreakdown_Download_Thunk = createAsyncThunk(
  "recAnalysis/breakdown/downloadTracks",
  async (payload: any, thunkAPI) => {
    const response = await downloadTrackBreakdownData(payload, thunkAPI);
    return response;
  }
);

export const FindRecAnalysisTrackBreakdown = (
  tracks: any[],
  productKey: number | null,
  isrc: string
): any => {
  const breakdown = tracks?.find((track) => {
    return productKey && track?.productKey
      ? track?.productKey === productKey
      : track?.isrc === isrc;
  });
  return breakdown ? breakdown : null;
};

export const recAnalysisTrackBreakdownSlice = createSlice({
  name: "recAnalysisTrackBreakdown",
  initialState,
  reducers: {
    clearTrendBreakdownData: (state, action: any) => {
      state.trackBreakdownTrend.dataArray = [];
      state.trackBreakdownTrend.status = "noData";
    },
    clearTerritoryBreakdownData: (state, action: any) => {
      state.trackBreakdownTerritory.dataArray = [];
      state.trackBreakdownTerritory.status = "noData";
    },
    clearSourceBreakdownData: (state, action: any) => {
      state.trackBreakdownSource.dataArray = [];
      state.trackBreakdownSource.status = "noData";
    },
  },
  extraReducers: (builder) => {
    builder
      // trend
      .addCase(
        FetchRecAnalysisTrackBreakdown_Trend_Thunk.fulfilled,
        (state, action) => {
          const typeData = action.payload;
          typeData.productKey = action.meta.arg.productKey;
          state.trackBreakdownTrend.dataArray.push(typeData);
          state.trackBreakdownTrend.status = "idle";
          return state;
        }
      )
      .addCase(FetchRecAnalysisTrackBreakdown_Trend_Thunk.pending, (state) => {
        state.trackBreakdownTrend.status = "loading";
      })
      .addCase(FetchRecAnalysisTrackBreakdown_Trend_Thunk.rejected, (state) => {
        state.trackBreakdownTrend.status = "failed";
      })
      // territory
      .addCase(
        FetchRecAnalysisTrackBreakdown_Territory_Thunk.fulfilled,
        (state, action) => {
          const territoryData = action.payload;
          territoryData.productKey = action.meta.arg.productKey;
          state.trackBreakdownTerritory.dataArray.push(territoryData);
          state.trackBreakdownTerritory.status = "idle";
          return state;
        }
      )
      .addCase(
        FetchRecAnalysisTrackBreakdown_Territory_Thunk.pending,
        (state) => {
          state.trackBreakdownTerritory.status = "loading";
        }
      )
      .addCase(
        FetchRecAnalysisTrackBreakdown_Territory_Thunk.rejected,
        (state) => {
          state.trackBreakdownTerritory.status = "failed";
        }
      )
      // source
      .addCase(
        FetchRecAnalysisTrackBreakdown_Source_Thunk.fulfilled,
        (state, action) => {
          const sourceData = action.payload;
          sourceData.songId = action.meta.arg.songId;
          state.trackBreakdownSource.dataArray.push(sourceData);
          state.trackBreakdownSource.status = "idle";
          return state;
        }
      )
      .addCase(FetchRecAnalysisTrackBreakdown_Source_Thunk.pending, (state) => {
        state.trackBreakdownSource.status = "loading";
      })
      .addCase(
        FetchRecAnalysisTrackBreakdown_Source_Thunk.rejected,
        (state) => {
          state.trackBreakdownSource.status = "failed";
        }
      )
      // download
      .addCase(recAnalysisTrackBreakdown_Download_Thunk.fulfilled, (state) => {
        state.trackBreakdownDownloadStatus = "idle";
      })
      .addCase(recAnalysisTrackBreakdown_Download_Thunk.pending, (state) => {
        state.trackBreakdownDownloadStatus = "loading";
      })
      .addCase(recAnalysisTrackBreakdown_Download_Thunk.rejected, (state) => {
        state.trackBreakdownDownloadStatus = "failed";
      });
  },
});

export const recAnalysisTrackBreakdownTrendSelector = (state: RootState) =>
  state.recAnalysisTrackBreakdown.trackBreakdownTrend;
export const recAnalysisTrackBreakdownTerritorySelector = (state: RootState) =>
  state.recAnalysisTrackBreakdown.trackBreakdownTerritory;
export const recAnalysisTrackBreakdownSourceSelector = (state: RootState) =>
  state.recAnalysisTrackBreakdown.trackBreakdownSource;
export const recAnalysisTrackBreakdownDownloadStatusSelector = (
  state: RootState
) => state.recAnalysisTrackBreakdown.trackBreakdownDownloadStatus;

export const {
  clearTrendBreakdownData: clearTypeBreakdownDataAction,
  clearTerritoryBreakdownData: clearTerritoryBreakdownDataAction,
  clearSourceBreakdownData: clearSourceBreakdownDataAction,
} = recAnalysisTrackBreakdownSlice.actions;

export default recAnalysisTrackBreakdownSlice.reducer;
