import { TitleVariantEnum } from "../../../../app/types/enums";
import { AdminHeaderProps } from "../../../../app/types/props";
import { TableHeadProps } from "../../../../app/types/props";

export const parentsHeaderData: AdminHeaderProps = {
  titleData: {
    text: "adminSection.parents.title",
    variant: TitleVariantEnum.primaryTitle,
  },
};

export const tableHeaderData: TableHeadProps = [
  {
    id: 0,
    title: "adminSection.common.linkedClientsTableHeader.name",
    filteringInfo: "name",
  },
  {
    id: 1,
    title: "adminSection.common.tableHeaders.currency",
    filteringInfo: "currency",
  },
  {
    id: 2,
    title: "adminSection.common.tableHeaders.description",
    filteringInfo: "description",
  },
  {
    id: 3,
    title: "adminSection.common.linkedClientsTableHeader.linkedClients",
    filteringInfo: "linkedClients",
  },
];

export const PublishingTableHeaderData: TableHeadProps = [
  {
    id: 0,
    title: "adminSection.common.linkedClientsTableHeader.id",
    filteringInfo: "id",
  },
  {
    id: 1,
    title: "adminSection.common.linkedClientsTableHeader.name",
    filteringInfo: "name",
  },
  {
    id: 2,
    title: "adminSection.common.linkedClientsTableHeader.territory",
    filteringInfo: "territory",
  },
  {
    id: 3,
    title: "adminSection.common.linkedClientsTableHeader.email",
    filteringInfo: "email",
  },
];

export const RecordingTableHeaderData: TableHeadProps = [
  {
    id: 0,
    title: "adminSection.common.linkedClientsTableHeader.clientCode",
    filteringInfo: "clientCode",
  },
  {
    id: 1,
    title: "adminSection.common.linkedClientsTableHeader.name",
    filteringInfo: "name",
  },
  {
    id: 2,
    title: "adminSection.common.linkedClientsTableHeader.siteCode",
    filteringInfo: "siteCode",
  },
  {
    id: 3,
    title: "adminSection.common.linkedClientsTableHeader.email",
    filteringInfo: "email",
  },
];
