import { IColumn } from "../../../../app/types/interfaces";

export const calculateOffsetDays = (
  systemClosingDate: Date | null,
  newClosingDate: Date | null
) => {
  const newClosingDateFormatted =
    newClosingDate !== null
      ? new Date(
          newClosingDate.getUTCFullYear(),
          newClosingDate.getUTCMonth(),
          newClosingDate.getUTCDate()
        )
      : null;
  if (systemClosingDate !== null && newClosingDateFormatted !== null) {
    const diffInMillis = Math.abs(
      systemClosingDate.getTime() - newClosingDateFormatted.getTime()
    );
    return Math.floor(diffInMillis / (1000 * 60 * 60 * 24));
  }
};

type OwningSite = {
  siteCode: string;
  siteName: string;
};

interface IResponse {
  owningSystems?: string[];
  owningSites?: OwningSite[];
  paymentCycles: number[];
  paymentDays: number[];
}

interface IFormattedResponse {
  owningSystems?: IColumn[];
  owningSites?: OwningSite[];
  paymentCycles: number[];
  paymentDays: number[];
}

export const formattedResponseOptions = (
  response: IResponse
): IFormattedResponse => {
  const formattedResponse: IFormattedResponse = {
    paymentCycles: response.paymentCycles,
    paymentDays: response.paymentDays,
  };

  if (response.owningSystems) {
    formattedResponse.owningSystems = response.owningSystems.map((system) => ({
      label: system,
      value: system,
    }));
  } else if (response.owningSites) {
    formattedResponse.owningSystems = response.owningSites.map((site) => ({
      label: site.siteName,
      value: site.siteCode,
    }));
  }

  return formattedResponse;
};
