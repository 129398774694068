import {
  TitleVariantEnum,
  ButtonVariantEnum,
  TableVariantEnum,
} from "../../../../app/types/enums";
import { AdminHeaderProps } from "../../../../app/types/props";
import { DropdownVariantEnum } from "../../../../app/atom/Dropdown/Dropdown";
import { CalendarIcon } from "../../../../app/atom/Icon";

export enum TableColumnsVariantEnum {
  systemCode = "systemCode",
  paymentCycle = "paymentCycle",
  paymentDays = "paymentDays",
  systemClosingDate = "systemClosingDate",
  newClosingDate = "newClosingDate",
  status = "status",
  createdBy = "createdBy",
  amendedBy = "amendedBy",
  amendedDate = "amendedDate",
}

export const systemClosingDatesHeaderData: AdminHeaderProps = {
  titleData: {
    text: "adminSection.systemClosingDates.title",
    variant: TitleVariantEnum.primaryTitle,
  },
  selectsData: [
    {
      id: "select",
      label: "select",
      defaultText: TableVariantEnum.publishing,
      variant: DropdownVariantEnum.selectDropdown,
      handleSelectChange: () => {
        // eslint-disable-next-line no-console
        console.log("Change Select");
      },
      options: [],
    },
  ],
  btnsData: [
    {
      text: "adminSection.systemClosingDates.btnTitle",
      variant: ButtonVariantEnum.primaryCta,
      icon: CalendarIcon,
      handleBtnClick: () => {
        // eslint-disable-next-line no-console
        console.log("Click Btn 1");
      },
    },
  ],
};

export const filterBy = [
  {
    value: TableVariantEnum.publishing,
    label: TableVariantEnum.publishing,
  },
  {
    value: TableVariantEnum.recording,
    label: TableVariantEnum.recording,
  },
  {
    value: TableVariantEnum.mechanical,
    label: TableVariantEnum.mechanical,
  },
];

export const DefaultActiveColumnyStemClosingDatesTable = "status";
