import {
  ComponentType,
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
} from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  PeriodSelector,
  fetchPeriodsThunk,
  periodsSlice,
} from "./periodSelectorSlice";
import { pubClientSelectionSelector } from "../../../features/publishing/pubroot/pubrootSlice";
import { recClientSelectionSelector } from "../../../features/recording/recroot/recrootSlice";
import deepCompare from "../../utils/deepCompare";
import debounce from "../../utils/debounce";

const withPeriodSelectorData =
  <P extends object>(WrappedComponent: ComponentType<P>) =>
  (props: P) => {
    const { documentType = "PUBLISHING" } = props as any;
    const { authState } = useOktaAuth();
    const dispatch = useAppDispatch();
    const dispatchedPeriods = useRef<any>();

    const [clientSelection, setClientSelection] = useState<any>(null);

    const periodSelector = useAppSelector(PeriodSelector);
    const pubClientSelection = useAppSelector(pubClientSelectionSelector);
    const recClientSelection = useAppSelector(recClientSelectionSelector);

    const abortDispatchedPeriods = useCallback(() => {
      if (dispatchedPeriods.current) dispatchedPeriods.current?.abort();
    }, []);

    const handlePeriodsFetch = useMemo(
      () =>
        debounce((pageState: any) => {
          abortDispatchedPeriods();
          dispatchedPeriods.current = dispatch(fetchPeriodsThunk(pageState));
        }, 100),
      [abortDispatchedPeriods, dispatch]
    );

    useEffect(() => {
      if (documentType === "PUBLISHING") {
        setClientSelection(pubClientSelection);
      } else {
        setClientSelection(recClientSelection);
      }

      return () => {
        setClientSelection(null);
      };
    }, [clientSelection, documentType, pubClientSelection, recClientSelection]);

    useEffect(() => {
      document.body.classList.add("periodSelection");
      return () => {
        document.body.classList.remove("periodSelection");
        abortDispatchedPeriods();
      };
    }, [abortDispatchedPeriods, dispatch]);

    useEffect(() => {
      if (
        !authState?.isAuthenticated ||
        !clientSelection?.selectedClients?.length
      )
        return;

      const periodsState = {
        data: {
          ...periodSelector.periodsState.data,
          clientIds:
            clientSelection?.selectedClients?.map((c: any) => c.id) || [],
          clientLists: [],
          withPipeline: true,
          yearsBack: 15,
          documentType: documentType,
        },
      };

      if (!deepCompare(periodsState, periodSelector.periodsState)) {
        dispatch(periodsSlice.actions.updatePeriodsState(periodsState));
        handlePeriodsFetch(periodsState);
      }
    }, [
      authState?.isAuthenticated,
      clientSelection,
      dispatch,
      documentType,
      handlePeriodsFetch,
      periodSelector.periodsState,
    ]);

    return <WrappedComponent {...(props as P)} />;
  };

export default withPeriodSelectorData;
