import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import { getCookie, setCookie } from "../../../app/utils/cookies";
import { fetchHelpPage, fetchHelpWithSearchtext } from "./helpAPI";
import { ROUTES } from "../../routes/routes.const";
import { HELP_SEARCH_IMAGEURL } from "./help.const";
import i18n from "../../../app/scripts/i18n";
import type { fetchHelpParams } from "./helpAPI";

export interface HelpState {
  helpStatus: "idle" | "loading" | "failed";
  contactUsFormPageState: {
    isOpen: boolean;
  };
  tag: string | null;
  page: string | null;
  searchText: string;
  locale: string | null;
  htmlContent: any;
}

export const fetchMoreHelpThunk = createAsyncThunk(
  "more/help",
  async (payload: fetchHelpParams, thunkAPI: any) => {
    if (!payload.locale || payload.locale === "")
      return { __html: "", total: 0 };

    // is search by text?
    const response =
      payload.searchText.length > 0
        ? await fetchHelpWithSearchtext(payload, thunkAPI)
        : await fetchHelpPage(payload, thunkAPI);

    const backendHtmlString = response?.resBody;

    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(backendHtmlString, "text/html");
    const section = htmlDoc.getElementsByTagName("section");
    const allAnchor = htmlDoc.querySelectorAll("a.help_see_all");
    const contactAnchors = htmlDoc.querySelectorAll("[href*='user/contact']");
    const backAnchor = htmlDoc.getElementsByClassName("help__back");
    const listAnchors = htmlDoc.querySelectorAll("li > a:not(.help_see_all)");
    const contentAnchors = htmlDoc.querySelectorAll("p > a");
    const allImages = htmlDoc.querySelectorAll("img");

    // get total count from data-search-count attribute
    const total =
      section.length > 0
        ? parseInt(section[0].getAttribute("data-search-count") as string) || 0
        : 0;

    // change anchar tag Url of "See all help topics"
    if (allAnchor.length > 0) {
      allAnchor.forEach((anchor) => {
        const hrefPath = anchor.getAttribute("href");
        if (!hrefPath) return;

        anchor.setAttribute(
          "href",
          `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.help}?page=` //&tag=${payload.tag}
        );
      });
    }

    // change anchar tag Url of "Back to Help topics"
    if (backAnchor.length > 0) {
      backAnchor[0].setAttribute(
        "href",
        `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.help}?page=` // &tag=${payload.tag}
      );
    }

    // change anchar tag Url of download links
    if (contentAnchors.length > 0) {
      contentAnchors.forEach((anchor) => {
        const hrefPath = anchor.getAttribute("href");
        const hrefFirstLevel = anchor.getAttribute("href")?.split("/")[1];
        if (!hrefFirstLevel || !hrefPath || hrefFirstLevel !== "dam") return;

        anchor.setAttribute("href", HELP_SEARCH_IMAGEURL + hrefPath);
      });
    }

    // remove locale from all links
    if (listAnchors.length > 0) {
      listAnchors.forEach((anchor) => {
        const hrefPath = anchor.getAttribute("href");
        const hrefLocale = anchor.getAttribute("href")?.split("/")[2];
        if (!hrefLocale || !hrefPath) return;

        let hrefNewPath = "/" + ROUTES.mybmg + "/" + ROUTES.user + hrefPath;

        // remove locale from href
        hrefNewPath = hrefNewPath.replace("/" + hrefLocale, "");

        // remove tag
        hrefNewPath = hrefNewPath.replace("?tag=" + payload.tag, "");

        // remove locale from href
        anchor.setAttribute("href", hrefNewPath);
        anchor.setAttribute("target", "_self");
      });
    }

    // contact anchors
    if (contactAnchors.length > 0) {
      contactAnchors.forEach((anchor) => {
        anchor.setAttribute(
          "href",
          "/" + ROUTES.mybmg + "/" + ROUTES.user + "/" + ROUTES.contacts
        );
      });
    }

    // update source path of all images
    if (allImages.length > 0) {
      allImages.forEach((image) => {
        const srcPath = image.getAttribute("src");
        if (!srcPath) return;
        image.setAttribute("src", HELP_SEARCH_IMAGEURL + srcPath);
      });
    }

    const serializer = new XMLSerializer();
    let htmlString = serializer.serializeToString(htmlDoc);

    // Translate phrases
    const ARR = [
      {
        search: "Back to Help topics",
        i18nKey: "help.backButton",
      },
      {
        search: "Useful topics",
        i18nKey: "help.usefulTopics",
      },
    ];

    ARR.forEach(({ search, i18nKey }) => {
      htmlString = htmlString.replaceAll(search, i18n.t(i18nKey));
    });

    // if is seach, set cookie with search matches count
    if (
      payload.searchText.length &&
      parseInt(getCookie("helpSearchMatches")) !== payload.searchText.length
    ) {
      setCookie("helpSearchMatches", total.toString(), 1 / 12);
    }

    // update tag cookie
    setCookie(
      "helpRadioTag",
      payload.tag || "360,publishing,recording",
      1 / 12
    );

    return { __html: htmlString };
  }
);

const initialState: HelpState = {
  helpStatus: "idle",
  contactUsFormPageState: {
    isOpen: false,
  },
  page: null,
  tag: null,
  searchText: "",
  locale: null,
  htmlContent: null,
};

export const morHelpSlice = createSlice({
  name: "help",
  initialState,
  reducers: {
    updateHelpSearchPageState: (state, action: any) => {
      state = action.payload;
      return state;
    },
    updateContactUsFormPageState: (state, action: any) => {
      state.contactUsFormPageState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMoreHelpThunk.fulfilled, (state: any, action: any) => {
        state.helpStatus = "idle";
        state.htmlContent = action.payload;
      })

      .addMatcher(isAnyOf(fetchMoreHelpThunk.pending), (state) => {
        state.helpStatus = "loading";
      })
      .addMatcher(
        isAnyOf(fetchMoreHelpThunk.rejected),
        (state, action: any) => {
          state.helpStatus = "failed";
        }
      );
  },
});

export const helpPageStateSelector = (state: RootState) => state.morHelp;
export const helpPageContentSelector = (state: RootState) =>
  state.morHelp.htmlContent;
export const contactUsFormPageStateSelector = (state: RootState) =>
  state.morHelp.contactUsFormPageState;

export const {
  updateHelpSearchPageState: updateHelpSearchPageStateAction,
  updateContactUsFormPageState: updateContactUsFormPageStateAction,
} = morHelpSlice.actions;

export default morHelpSlice.reducer;
