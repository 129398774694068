import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import { downloadAnalysisData, fetchAnalysisSongs } from "../pubAnalysisAPI";

export interface PubAnalysisSongProps {
  data: {
    songDescriptions: Array<any>;
    totalRoyalty: Record<string, any>;
  };
  total: number;
}

export interface PubAnalysisSongState {
  analysisSongPageState: {
    data: {
      periodIds: Array<number>;
      clientIds: any;
      clientLists: any;
    };
    start: number;
    count: number;
    sortColumn: string;
    isAsc: boolean;
    filterText: string;
  };
  songs: PubAnalysisSongProps;
  analysisSongStatus: "init" | "idle" | "loading" | "failed";
  downloadStatus: "loading" | "idle" | "failed";
  noDataFlag?: "" | "noData" | "noPeriodData" | "noSearchData";
}

export const PubAnalysisSongPageInitialState = {
  data: {
    periodIds: [],
    clientIds: [],
    clientLists: [],
  },
  start: 0,
  count: 200,
  sortColumn: "royalties",
  isAsc: true,
  filterText: "",
};

const initialState: PubAnalysisSongState = {
  analysisSongPageState: PubAnalysisSongPageInitialState,
  songs: {
    data: {
      songDescriptions: [],
      totalRoyalty: {},
    },
    total: 0,
  },
  analysisSongStatus: "init",
  downloadStatus: "idle",
  noDataFlag: undefined,
};

export const fetchAnalysisSongsThunk = createAsyncThunk(
  "publishing/analysisSongs",
  async (payload: any, thunkAPI: any) => {
    const response = await fetchAnalysisSongs(payload, thunkAPI);
    return response;
  }
);

export const fetchMoreAnalysisSongsThunk = createAsyncThunk(
  "publishing/moreAnalysisSongs",
  async (payload: any, thunkAPI: any) => {
    const response = await fetchAnalysisSongs(payload, thunkAPI);
    return response;
  }
);

export const pubAnalysisSongsDownload = createAsyncThunk(
  "pubAnalysis/downloadSongs",
  async (payload: any, thunkAPI) => {
    const response = await downloadAnalysisData(payload, thunkAPI);
    return response;
  }
);

export const pubAnalysisSongSlice = createSlice({
  name: "pubAnalysisSong",
  initialState,
  reducers: {
    resetAnalysisSongs: (state, action: any) => {
      state.songs = initialState.songs;
    },
    updateAnalysisSongPageState: (state: any, action: any) => {
      state.analysisSongPageState.data.periodIds =
        action.payload.data.periodIds;
      state.analysisSongPageState.data.clientIds =
        action.payload.data.clientIds;
      state.analysisSongPageState.data.clientLists =
        action.payload.data.clientLists;
      state.analysisSongPageState.start = action.payload.start;
      state.analysisSongPageState.sortColumn = action.payload.sortColumn;
      state.analysisSongPageState.filterText = action.payload.filterText;
      state.analysisSongPageState.isAsc = action.payload.isAsc;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalysisSongsThunk.fulfilled, (state: any, action: any) => {
        state.analysisSongStatus = "idle";
        state.songs.data = action.payload?.data;
        state.songs.total = action.payload?.total;
        state.noDataFlag =
          action.payload?.total === 0
            ? state?.analysisSongPageState?.filterText?.length > 0
              ? "noSearchData"
              : state?.analysisSongPageState?.data?.periodIds?.length === 1
              ? "noPeriodData"
              : "noData"
            : "";
        return state;
      })
      .addCase(fetchMoreAnalysisSongsThunk.fulfilled, (state, action) => {
        state.analysisSongStatus = "idle";
        state.songs.data.songDescriptions = [
          ...state.songs.data.songDescriptions,
          ...action.payload.data.songDescriptions,
        ];
        state.songs.data.totalRoyalty = action.payload.data.totalRoyalty;
        state.songs.total = action.payload.total;
        return state;
      })
      .addCase(pubAnalysisSongsDownload.fulfilled, (state) => {
        state.downloadStatus = "idle";
      })
      .addCase(pubAnalysisSongsDownload.pending, (state) => {
        state.downloadStatus = "loading";
      })
      .addCase(pubAnalysisSongsDownload.rejected, (state) => {
        state.downloadStatus = "failed";
      })
      .addMatcher(
        isAnyOf(
          fetchAnalysisSongsThunk.pending,
          fetchMoreAnalysisSongsThunk.pending
        ),
        (state) => {
          state.analysisSongStatus = "loading";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchAnalysisSongsThunk.rejected,
          fetchMoreAnalysisSongsThunk.rejected
        ),
        (state) => {
          state.analysisSongStatus = "failed";
        }
      );
  },
});

export const pubAnalysisSongPageStateSelector = (state: RootState) =>
  state.pubAnalysisSong.analysisSongPageState;
export const pubAnalysisSongsSelector = (state: RootState) =>
  state.pubAnalysisSong.songs;
export const pubAnalysisSongStatusSelector = (state: RootState) =>
  state.pubAnalysisSong.analysisSongStatus;
export const pubAnalysisSongPeriodIdsSelector = (state: RootState) =>
  state.pubAnalysisSong.analysisSongPageState.data.periodIds;
export const pubAnalysisDownloadStatusSelector = (state: RootState) =>
  state.pubAnalysisSong.downloadStatus;
export const pubAnalysisNoDataFlagSelector = (state: RootState) =>
  state.pubAnalysisSong.noDataFlag;

export const {
  resetAnalysisSongs: resetAnalysisSongsAction,
  updateAnalysisSongPageState: updateAnalysisSongPageStateAction,
} = pubAnalysisSongSlice.actions;

export default pubAnalysisSongSlice.reducer;
