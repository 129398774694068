import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import { fetchStatements, fetchStatementsFiles } from "./statementsAPI";

export declare type StatementsPageStateProps = {
  data: {
    clientIds: any[];
    clientLists: any[];
  };
  payeeOnly: boolean;
  periodId: number | null;
};

export interface PubStatementsState {
  pubStatementsPageState: StatementsPageStateProps;
  statements: {
    nextPeriod?: number;
    list: Array<any>;
    total: number | null;
    lastDownloadedStatementPeriod: number | null;
    lastDownloadedStatementIndex: number | null;
    lastDownloadedStatementExtention: string | null;
  };
  status: "idle" | "loading" | "failed";
}

const initialState: PubStatementsState = {
  pubStatementsPageState: {
    data: {
      clientIds: [],
      clientLists: [],
    },
    payeeOnly: false,
    periodId: null,
  },
  statements: {
    nextPeriod: undefined,
    list: [],
    total: null,
    lastDownloadedStatementPeriod: null,
    lastDownloadedStatementIndex: null,
    lastDownloadedStatementExtention: null,
  },
  status: "idle",
};

export const fetchStatementsThunk = createAsyncThunk(
  "publishing/statements",
  async (payload: any, thunkAPI) => {
    const response = await fetchStatements(payload, thunkAPI);
    return response;
  }
);

export const fetchMoreStatementsThunk = createAsyncThunk(
  "publishing/morestatements",
  async (payload: any, thunkAPI) => {
    const response = await fetchStatements(payload, thunkAPI);
    return response;
  }
);

export const fetchStatementsFilesThunk = createAsyncThunk(
  "publishing/statementsFiles",
  async (payload: any, thunkAPI) => {
    const response = await fetchStatementsFiles(payload, thunkAPI);
    return response;
  }
);

export const pubStatementsSlice = createSlice({
  name: "pubStatements",
  initialState,
  reducers: {
    resetPubStatements: () => initialState,
    updateStatementsPageState: (state, action: any) => {
      state.pubStatementsPageState.data.clientIds =
        action.payload.data.clientIds;
      state.pubStatementsPageState.payeeOnly = action.payload.payeeOnly;
      state.pubStatementsPageState.periodId = action.payload.periodId;
    },
    updateStatementsDownloadState: (state, action: any) => {
      state.statements.lastDownloadedStatementPeriod =
        action.payload.lastDownloadedStatementPeriod;
      state.statements.lastDownloadedStatementIndex =
        action.payload.lastDownloadedStatementIndex;
      state.statements.lastDownloadedStatementExtention =
        action.payload.lastDownloadedStatementExtention;
    },
    updateStatementsList: (state, action: any) => {
      state.statements.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatementsThunk.fulfilled, (state, action: any) => {
        state.status = "idle";
        state.statements.list = action.payload?.statements;
        state.statements.nextPeriod = action.payload?.nextPeriod;
        state.statements.total = action.payload?.total;
      })
      .addCase(fetchMoreStatementsThunk.fulfilled, (state, action: any) => {
        const statements = action.payload?.statements || [];
        state.status = "idle";
        state.statements.list = [...state.statements.list, ...statements];
        state.statements.nextPeriod = action.payload?.nextPeriod || null;
        state.statements.total = action.payload?.total;
      })
      .addCase(fetchStatementsFilesThunk.fulfilled, (state, action: any) => {
        state.status = "idle";
      })
      .addMatcher(
        isAnyOf(
          fetchStatementsThunk.pending,
          fetchMoreStatementsThunk.pending,
          fetchStatementsFilesThunk.pending
        ),
        (state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchStatementsThunk.rejected,
          fetchMoreStatementsThunk.rejected,
          fetchStatementsFilesThunk.rejected
        ),
        (state) => {
          state.status = "failed";
        }
      );
  },
});

export const statementsSelector = (state: RootState) => state.pubStatements;
export const lastDownloadedStatementPeriodSelector = (state: RootState) =>
  state.pubStatements.statements.lastDownloadedStatementPeriod;
export const lastDownloadedStatementIndexSelector = (state: RootState) =>
  state.pubStatements.statements.lastDownloadedStatementIndex;
export const lastDownloadedStatementExtensionSelector = (state: RootState) =>
  state.pubStatements.statements.lastDownloadedStatementExtention;

export default pubStatementsSlice.reducer;
