import React from "react";

function PaperPlaneIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      name="PaperPlaneIcon"
      {...props}
    >
      <path
        fill="#7ED321"
        stroke="#7ED321"
        strokeWidth=".998"
        d="M18.374.756c.128.176.188.479.043.911l-5.382 16.147c-.126.378-.287.537-.39.606a.61.61 0 0 1-.032.02l.213-.42c.034-.068.068-.148.1-.243l5.142-15.425-.827-.511-8.703 8.703-.324.323.295.35c.248.295.446.592.574.859l2.755 5.768c.043.09.086.166.13.23l.264.392a.552.552 0 0 1-.083-.043c-.107-.068-.263-.211-.415-.529L9.798 13.84l-.203-.424-.435.176-4.48 1.815h-.003l-.002.002a1.487 1.487 0 0 1-.466.082c-.237 0-.403-.074-.515-.185-.166-.167-.266-.49-.102-.981v-.002l.001-.002 1.815-4.48.176-.436-.424-.202-4.054-1.936c-.335-.16-.477-.323-.54-.431a.535.535 0 0 1-.033-.066l.395.264c.063.043.138.086.227.128l5.77 2.756c.267.127.563.326.858.573l.35.295.324-.324L17.16 1.76l-.511-.827L1.223 6.075a1.791 1.791 0 0 0-.243.1l-.424.215a.535.535 0 0 1 .042-.063c.076-.1.237-.244.588-.361L17.334.584c.177-.06.334-.085.468-.085.233 0 .4.076.51.184l.005.006a.566.566 0 0 1 .057.067Zm-.037-.05Zm-13.718 14.6.017-.006.017-.007 4.4-1.782.492-.199-.228-.478-.338-.708c-.194-.407-.535-.839-.9-1.204-.366-.366-.798-.706-1.204-.9l-.708-.338-.479-.229-.199.492-1.782 4.4-.007.017-.006.018c-.077.24-.096.469-.037.647l.08.235.234.079c.178.06.407.04.648-.037Z"
      />
    </svg>
  );
}

export default PaperPlaneIcon;
