import {
  useEffect,
  useCallback,
  useState,
  RefObject,
  useRef,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DownloadIcon } from "../../../atom/Icon";
import { getNow, getToday } from "../../../utils/dateTime";
import { ButtonVariantEnum } from "../../../types/enums";
import {
  FetchPubAnalysisDigitalBreakdown_Territory_Thunk,
  FetchPubAnalysisDigitalBreakdown_Trend_Thunk,
  FindPubAnalysisDigitalBreakdown,
  pubAnalysisDigitalBreakdown_Download_Thunk,
  pubAnalysisDigitalBreakdownDownloadStatusSelector,
  pubAnalysisDigitalBreakdownTrendSelector,
  pubAnalysisDigitalBreakdownTerritorySelector,
} from "../../../../features/publishing/analysis/digital/pubAnalysisDigitalBreakdownSlice";
import {
  FetchRecAnalysisDigitalBreakdown_Products_Thunk,
  FetchRecAnalysisDigitalBreakdown_Territory_Thunk,
  FetchRecAnalysisDigitalBreakdown_Trend_Thunk,
  FindRecAnalysisDigitalBreakdown,
  recAnalysisDigitalBreakdownDownloadStatusSelector,
  recAnalysisDigitalBreakdown_Download_Thunk,
  recAnalysisDigitalBreakdownProductsSelector,
  recAnalysisDigitalBreakdownTerritorySelector,
  recAnalysisDigitalBreakdownTrendSelector,
} from "../../../../features/recording/analysis/digital/recAnalysisDigitalBreakdownSlice";
import Button from "../../../atom/Button/Button";
import ChevronIcon from "../../../atom/Icon/ChevronIcon";
import Loader from "../../../atom/Loader";
import AnalysisDigitalBreakdownProducts from "./AnalysisDigitalBreakdown_Products";
import AnalysisDigitalBreakdownTerritory from "./AnalysisDigitalBreakdown_Territory";
import AnalysisDigitalBreakdownTrend from "./AnalysisDigitalBreakdown_Trend";
import type {
  PubAnalysisDigitalBreakdownTrendTerritoryParams,
  PubAnalysisDigitalData,
} from "../../../../features/publishing/analysis/digital/pubAnalysisDigitalBreakdownAPI";
import type {
  RecAnalysisDigitalBreakdownTrendTerritoryProductsParams,
  RecAnalysisDigitalData,
} from "../../../../features/recording/analysis/digital/recAnalysisDigitalBreakdownAPI";
import styles from "../analysis.module.scss";
import { PeriodSelector } from "../../periodSelector/periodSelectorSlice";
import debounce from "../../../utils/debounce";

export declare type PubAnalysisDigitalBreakdownViewProps =
  PubAnalysisDigitalBreakdownTrendTerritoryParams & {
    mobileView: boolean;
    documentType: "PUBLISHING" | "RECORDING";
    handleClose?: (clickedSong: any) => void;
  };

export declare type RecAnalysisDigitalBreakdownViewProps =
  RecAnalysisDigitalBreakdownTrendTerritoryProductsParams & {
    mobileView: boolean;
    documentType: "PUBLISHING" | "RECORDING";
    handleClose?: (clickedSong: any) => void;
  };

const AnalysisDigitalBreakdown = (
  props:
    | PubAnalysisDigitalBreakdownViewProps
    | RecAnalysisDigitalBreakdownViewProps
): JSX.Element => {
  const { t } = useTranslation();
  const {
    documentType,
    sourceCode,
    sourceName,
    clientIds,
    periodIds,
    isSelected,
  } = props;
  const dispatch = useDispatch<any>();

  const productsBreakdownPosRef = useRef<HTMLParagraphElement>(null);
  const territoryBreakdownPosRef = useRef<HTMLParagraphElement>(null);
  const trendBreakdownPosRef = useRef<HTMLParagraphElement>(null);

  const [productsData, setProductsData] =
    useState<RecAnalysisDigitalData | null>(null);
  const [territoryData, setTerritoryData] =
    useState<PubAnalysisDigitalData | null>(null);
  const [trendData, setTrendData] = useState<PubAnalysisDigitalData | null>(
    null
  );

  // dispatch refs
  const dispatchedProducts = useRef<any>();
  const dispatchedTerritory = useRef<any>();
  const dispatchedTrend = useRef<any>();
  const dispatchedDownload = useRef<any>();

  // abort functions
  const abortDispatchedProducts = useCallback(() => {
    if (dispatchedProducts.current) dispatchedProducts.current.abort();
  }, []);
  const abortDispatchedTerritory = useCallback(() => {
    if (dispatchedTerritory.current) dispatchedTerritory.current.abort();
  }, []);
  const abortDispatchedTrend = useCallback(() => {
    if (dispatchedTrend.current) dispatchedTrend.current.abort();
  }, []);
  const abortDispatchedDownload = useCallback(() => {
    if (dispatchedDownload.current) dispatchedDownload.current.abort();
  }, []);

  const digitalBreakdown_Products = useSelector(
    recAnalysisDigitalBreakdownProductsSelector
  );

  const allPeriods = useSelector(PeriodSelector);

  const digitalBreakdown_Territories = useSelector(
    documentType === "PUBLISHING"
      ? pubAnalysisDigitalBreakdownTerritorySelector
      : recAnalysisDigitalBreakdownTerritorySelector
  );

  const digitalBreakdown_Trend = useSelector(
    documentType === "PUBLISHING"
      ? pubAnalysisDigitalBreakdownTrendSelector
      : recAnalysisDigitalBreakdownTrendSelector
  );

  const digitalBreakdownDownloadStatus = useSelector(
    documentType === "PUBLISHING"
      ? pubAnalysisDigitalBreakdownDownloadStatusSelector
      : recAnalysisDigitalBreakdownDownloadStatusSelector
  );

  const findFunction = useMemo(() => {
    return documentType === "PUBLISHING"
      ? FindPubAnalysisDigitalBreakdown
      : FindRecAnalysisDigitalBreakdown;
  }, [documentType]);

  const fetchProductsFunction = FetchRecAnalysisDigitalBreakdown_Products_Thunk;

  const fetchTerritoryFunction = useMemo(() => {
    return documentType === "PUBLISHING"
      ? FetchPubAnalysisDigitalBreakdown_Territory_Thunk
      : FetchRecAnalysisDigitalBreakdown_Territory_Thunk;
  }, [documentType]);

  const fetchTrendFunction = useMemo(() => {
    return documentType === "PUBLISHING"
      ? FetchPubAnalysisDigitalBreakdown_Trend_Thunk
      : FetchRecAnalysisDigitalBreakdown_Trend_Thunk;
  }, [documentType]);

  const downloadFunction = useMemo(() => {
    return documentType === "PUBLISHING"
      ? pubAnalysisDigitalBreakdown_Download_Thunk
      : recAnalysisDigitalBreakdown_Download_Thunk;
  }, [documentType]);

  const handleProductsFunctionFetch = useMemo(
    () =>
      debounce((currentParams: any) => {
        abortDispatchedProducts();
        dispatchedProducts.current = dispatch(
          fetchProductsFunction(currentParams)
        );
      }, 500),
    [abortDispatchedProducts, dispatch, fetchProductsFunction]
  );

  const handleTerritoryFunctionFetch = useMemo(
    () =>
      debounce((currentParams: any) => {
        abortDispatchedTerritory();
        dispatchedTerritory.current = dispatch(
          fetchTerritoryFunction(currentParams)
        );
      }, 500),
    [abortDispatchedTerritory, dispatch, fetchTerritoryFunction]
  );

  const handleTrendFunctionFetch = useMemo(
    () =>
      debounce((currentParams: any) => {
        abortDispatchedTrend();
        dispatchedTrend.current = dispatch(fetchTrendFunction(currentParams));
      }, 500),
    [abortDispatchedTrend, dispatch, fetchTrendFunction]
  );

  const assignProductsData = useCallback(() => {
    const currentProductsBreakdown = findFunction(
      digitalBreakdown_Products.dataArray,
      sourceCode || sourceName
    );

    if (currentProductsBreakdown) setProductsData(currentProductsBreakdown);
    else if (
      (documentType === "PUBLISHING" &&
        productsData?.sourceCode !== sourceCode) ||
      (documentType === "RECORDING" && productsData?.sourceName !== sourceName)
    ) {
      setProductsData({
        sourceCode: sourceCode,
        sourceName: sourceName,
        sourcesByTerritory: null,
        totalRoyalty: null,
      });

      handleProductsFunctionFetch({
        clientIds: clientIds,
        sourceCode: sourceCode,
        sourceName: sourceName,
        periodIds: periodIds,
      });
    }
  }, [
    clientIds,
    digitalBreakdown_Products.dataArray,
    documentType,
    findFunction,
    handleProductsFunctionFetch,
    periodIds,
    productsData?.sourceCode,
    productsData?.sourceName,
    sourceCode,
    sourceName,
  ]);

  const assignTerritoryData = useCallback(() => {
    const currentTerritoryBreakdown = findFunction(
      digitalBreakdown_Territories.dataArray,
      sourceCode || sourceName
    );
    if (currentTerritoryBreakdown) setTerritoryData(currentTerritoryBreakdown);
    else if (
      (documentType === "PUBLISHING" &&
        territoryData?.sourceCode !== sourceCode) ||
      (documentType === "RECORDING" && territoryData?.sourceName !== sourceName)
    ) {
      setTerritoryData({
        sourceCode: sourceCode,
        sourceName: sourceName,
        sourcesByTerritory: null,
        totalRoyalty: null,
      });

      handleTerritoryFunctionFetch({
        clientIds: clientIds,
        sourceCode: sourceCode,
        sourceName: sourceName,
        periodIds: periodIds,
      });
    }
  }, [
    findFunction,
    digitalBreakdown_Territories.dataArray,
    sourceCode,
    sourceName,
    documentType,
    territoryData?.sourceCode,
    territoryData?.sourceName,
    handleTerritoryFunctionFetch,
    clientIds,
    periodIds,
  ]);

  const assignTrendData = useCallback(() => {
    const currentTrendBreakdown = findFunction(
      digitalBreakdown_Trend.dataArray,
      sourceCode || sourceName
    );

    if (currentTrendBreakdown) {
      setTrendData(currentTrendBreakdown);
    } else if (
      (documentType === "PUBLISHING" && trendData?.sourceCode !== sourceCode) ||
      (documentType === "RECORDING" && trendData?.sourceName !== sourceName)
    ) {
      setTrendData({
        sourceCode: sourceCode,
        sourceName: sourceName,
        sourcesByTerritory: null,
        totalRoyalty: null,
      });

      handleTrendFunctionFetch({
        clientIds: clientIds,
        sourceCode: sourceCode,
        sourceName: sourceName,
        periodIds: allPeriods?.periods?.map((p) => p.periodNum) || [],
      });
    }
  }, [
    allPeriods?.periods,
    clientIds,
    digitalBreakdown_Trend.dataArray,
    documentType,
    findFunction,
    handleTrendFunctionFetch,
    sourceCode,
    sourceName,
    trendData?.sourceCode,
    trendData?.sourceName,
  ]);

  useEffect(() => {
    return () => {
      abortDispatchedProducts();
      abortDispatchedTerritory();
      abortDispatchedTrend();
      abortDispatchedDownload();
    };
  }, [
    abortDispatchedProducts,
    abortDispatchedDownload,
    abortDispatchedTerritory,
    abortDispatchedTrend,
  ]);

  useEffect(() => {
    if (documentType === "RECORDING") {
      assignProductsData();
    }
    assignTerritoryData();
    assignTrendData();
  }, [
    assignProductsData,
    assignTerritoryData,
    assignTrendData,
    documentType,
    sourceCode,
    sourceName,
  ]);

  const handleDownloadBtn = useCallback(() => {
    const analyzeBy =
      documentType === "PUBLISHING"
        ? "BY_DIGITAL_SOURCE_TERRITORY_DETAILS"
        : "BY_REC_DIGITAL_SOURCES_TERRITORY_DETAILS";
    const sourceSearchTerm =
      documentType === "PUBLISHING" ? sourceCode : sourceName;

    const params = {
      data: {
        periodIds: allPeriods?.periods?.map((p) => p.periodNum) || [],
        clientIds: clientIds,
        analyzeBy,
        sourceSearchTerm,
      },
      fileName:
        "Royalties_" + analyzeBy + "_" + getToday() + "-" + getNow() + ".xlsx",
    };
    abortDispatchedDownload();
    dispatchedDownload.current = dispatch(downloadFunction(params));
  }, [
    abortDispatchedDownload,
    allPeriods.periods,
    clientIds,
    dispatch,
    documentType,
    downloadFunction,
    sourceCode,
    sourceName,
  ]);

  const scrollToBreakdown =
    (refObject: RefObject<HTMLParagraphElement>) => () => {
      refObject.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    };

  const handleCloseBreakdown = useCallback(() => {
    props.handleClose && props.handleClose("");
  }, [props]);

  return (
    <div className={styles.breakDown}>
      {(digitalBreakdown_Trend.status === "loading" ||
        digitalBreakdown_Products.status === "loading" ||
        digitalBreakdown_Territories.status === "loading" ||
        digitalBreakdownDownloadStatus === "loading") && <Loader />}
      <header
        id="rightScrollableHeader"
        className="sticky top-0 z-[4] bg-white pt-5"
      >
        <div className="flex justify-between">
          {!props.mobileView && <h3 className={styles.title}>{sourceName}</h3>}
          {props.handleClose && (
            <Button
              className={`${styles.unselectBtn} ${styles.breakdownUnselectBtn} self-start md:mr-2`}
              variant={ButtonVariantEnum.cleanCta}
              onClick={handleCloseBreakdown}
            >
              +
            </Button>
          )}
        </div>
        <div className={styles.breakdownButtons}>
          <Button
            icon={<DownloadIcon />}
            className={`downloadData_GTM ${styles.downloadBtn}`}
            disabled={digitalBreakdownDownloadStatus === "loading"}
            onClick={handleDownloadBtn}
            variant={ButtonVariantEnum.cleanCta}
          >
            {t("analysis.songs.breakdown.downloadData")}
          </Button>
          {documentType === "RECORDING" && (
            <Button
              onClick={scrollToBreakdown(productsBreakdownPosRef)}
              variant={ButtonVariantEnum.textLink}
              className={`${styles.scrollNavBtn} ${styles.chevron}`}
              icon={<ChevronIcon />}
            >
              {t("analysis.digital.breakdown.products")}
            </Button>
          )}
          <Button
            onClick={scrollToBreakdown(territoryBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={`${styles.scrollNavBtn}`}
            icon={<ChevronIcon />}
          >
            {t("analysis.songs.breakdown.territory")}
          </Button>
          <Button
            onClick={scrollToBreakdown(trendBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={`${styles.scrollNavBtn}`}
            icon={<ChevronIcon />}
          >
            {t("analysis.songs.breakdown.trend")}
          </Button>
        </div>
      </header>
      <div
        className={`${styles.scrollable} ${styles.breakDownContent} ${
          isSelected ? styles.isOpen : ""
        }`}
      >
        <section>
          <div className={styles.percentageList}>
            {documentType === "RECORDING" && (
              <>
                <p
                  ref={productsBreakdownPosRef}
                  className={styles.breakdownTitle}
                >
                  {t("analysis.digital.breakdown.breakdownByProducts")}
                </p>
                <AnalysisDigitalBreakdownProducts
                  data={productsData}
                  status={digitalBreakdown_Products.status}
                />
              </>
            )}

            <p ref={territoryBreakdownPosRef} className={styles.breakdownTitle}>
              {t("analysis.songs.breakdown.breakdownByTerritory")}
            </p>
            <AnalysisDigitalBreakdownTerritory
              data={territoryData}
              status={digitalBreakdown_Territories.status}
              documentType={documentType}
            />

            <p ref={trendBreakdownPosRef} className={styles.breakdownTitle}>
              {t("analysis.songs.breakdown.breakdownByTrend")}
            </p>
            <AnalysisDigitalBreakdownTrend
              data={trendData}
              status={digitalBreakdown_Trend.status}
              documentType={documentType}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default AnalysisDigitalBreakdown;
