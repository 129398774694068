import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../app/redux/store";
import { fetchCombinedRoyaltiesTrend } from "./combinedDashboardAPI";

export interface CombinedDashboardState {
  combinedDashboardPageState: {
    data: any;
  };
  royaltiesTrend: {
    userType: string;
    periodDataList: Array<any>;
    allPeriodsData: any;
  };
  combinedDashboardStatus: "idle" | "loading" | "failed";
}

const initialState: CombinedDashboardState = {
  combinedDashboardPageState: {
    data: {},
  },
  royaltiesTrend: {
    userType: "",
    periodDataList: [],
    allPeriodsData: {},
  },
  combinedDashboardStatus: "idle",
};

export const fetchCombinedRoyaltiesTrendThunk = createAsyncThunk(
  "combinedDashboard/royaltiesTrend",
  async () => {
    const response = await fetchCombinedRoyaltiesTrend({});
    return response;
  }
);

export const combinedDashboardSlice = createSlice({
  name: "combinedDashboard",
  initialState,
  reducers: {
    updateCombinedDashboardPageState: (state, action) => {
      state.combinedDashboardPageState.data = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCombinedRoyaltiesTrendThunk.fulfilled, (state, action) => {
        state.combinedDashboardStatus = "idle";
        state.royaltiesTrend.userType = action.payload.userType;
        state.royaltiesTrend.periodDataList = action.payload.periodDataList;
        state.royaltiesTrend.allPeriodsData = action.payload.allPeriodsData;
      })
      .addMatcher(
        isAnyOf(fetchCombinedRoyaltiesTrendThunk.pending),
        (state) => {
          state.combinedDashboardStatus = "loading";
        }
      )
      .addMatcher(
        isAnyOf(fetchCombinedRoyaltiesTrendThunk.rejected),
        (state) => {
          state.combinedDashboardStatus = "failed";
        }
      );
  },
});

export const combinedDashboardPageStateSelector = (state: RootState) =>
  state.combinedDashboard.combinedDashboardPageState;
export const combinedDashboardStatusSelector = (state: RootState) =>
  state.combinedDashboard.combinedDashboardStatus;
export const combinedDashboardRoyaltiesTrendSelector = (state: RootState) =>
  state.combinedDashboard.royaltiesTrend;

export const {
  updateCombinedDashboardPageState: updateCombinedDashboardPageStateAction,
} = combinedDashboardSlice.actions;

export default combinedDashboardSlice.reducer;
