import { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Link from "../../atom/Link";
import Image from "../../atom/Image";
import { LogoHD } from "../../img";
import Footer from "../../molecules/footer";
import { LoginVisual, LoginVisualMobile } from "../../img";
import { useWindowSize } from "../../hooks";
import SideBasicTeaserSection from "./SideBasicTeaserSection";
import { FooterVariantEnum } from "../../types/enums";
import styles from "./sideBasic.module.scss";

interface IProps {
  children: React.ReactNode;
}

const SideBasicWrapper: FC<IProps> = ({ children }) => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();

  const [isScrolled, setIsScrolled] = useState(false);

  const scrollPage = useCallback(() => {
    if (isScrolled === false) setIsScrolled(true);
  }, [isScrolled]);

  useEffect(() => {
    window.addEventListener("scroll", scrollPage);

    return () => {
      window.removeEventListener("scroll", scrollPage);
    };
  });

  return (
    <>
      <div
        className={`${styles.topSection} col-span-full`}
        style={{
          backgroundImage: `url(${
            windowWidth && windowWidth < 768 ? LoginVisualMobile : LoginVisual
          })`,
          backgroundPosition: `url(${
            windowWidth && windowWidth < 768 ? "center top" : "right top"
          })`,
          minHeight: `url(${
            windowWidth && windowWidth < 768 ? "auto" : "100vh"
          })`,
        }}
      >
        <header>
          <Link
            to="/"
            className="logoLink_GTM"
            text={<Image src={LogoHD} alt="logo" className="absolute h-14" />}
          ></Link>
        </header>
        <Link
          to="/mybmg/contactus"
          text={t("layoutBasic.contact")}
          className={`contactUs_GTM ${styles.contactLink}`}
        />
        {children}
        {!isScrolled && <div className={styles.scrollIndicator}></div>}
      </div>
      <SideBasicTeaserSection />
      <Footer footerVariant={FooterVariantEnum.linksAndCopyright} />
    </>
  );
};

export default SideBasicWrapper;
