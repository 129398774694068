import { useTranslation } from "react-i18next";
import LoginFairnessIcon from "../../atom/Icon/LoginFairnessIcon";
import LoginTransparencyIcon from "../../atom/Icon/LoginTransparencyIcon";
import LoginServiceIcon from "../../atom/Icon/LoginServiceIcon";
import styles from "./sideBasicTeaser.module.scss";

const SideBasicTeaserSection = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.infoTeaserSection}>
      <h2>myBMG</h2>
      <div className={styles.infoTeaserContainer}>
        <div className={styles.infoTeaserItem}>
          <div className={styles.icon}>
            <LoginFairnessIcon />
          </div>
          <div className={styles.title}>{t("login.infoTeaser.fairness")}</div>
          <div className={styles.content}>
            {t("login.infoTeaser.fairnessContent")}
          </div>
        </div>
        <div className={styles.infoTeaserItem}>
          <div className={styles.icon}>
            <LoginTransparencyIcon />
          </div>
          <div className={styles.title}>
            {t("login.infoTeaser.transparency")}
          </div>
          <div className={styles.content}>
            {t("login.infoTeaser.transparencyContent")}
          </div>
        </div>
        <div className={styles.infoTeaserItem}>
          <div className={styles.icon}>
            <LoginServiceIcon />
          </div>
          <div className={styles.title}>{t("login.infoTeaser.service")}</div>
          <div className={styles.content}>
            {t("login.infoTeaser.serviceContent")}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SideBasicTeaserSection;
