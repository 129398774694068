export const DefaultActiveColumnActivityLogTable = "date";

export enum TableColumnsVariantEnum {
  date = "date",
  userEmail = "useremail",
  detail = "detail",
  agent = "agent",
  operatingSystem = "operatingSystem",
  host = "host",
  userGroups = "userGroups",
  event = "event",
}

export enum RadioSearchType {
  exact = "EXACT",
  contains = "CONTAINS",
}

export const DefaultRadioSearchType = "CONTAINS";

export const columns = [
  {
    label: "adminSection.activityLog.activityLogTableHeader.userEmail",
    value: TableColumnsVariantEnum.userEmail,
  },
  {
    label: "adminSection.activityLog.activityLogTableHeader.date",
    value: TableColumnsVariantEnum.date,
  },
  {
    label: "adminSection.activityLog.activityLogTableHeader.detail",
    value: TableColumnsVariantEnum.detail,
  },
  {
    label: "adminSection.activityLog.activityLogTableHeader.agent",
    value: TableColumnsVariantEnum.agent,
  },
  {
    label: "adminSection.activityLog.activityLogTableHeader.operatingSystem",
    value: TableColumnsVariantEnum.operatingSystem,
  },
  {
    label: "adminSection.activityLog.activityLogTableHeader.host",
    value: TableColumnsVariantEnum.host,
  },
  {
    label: "adminSection.activityLog.activityLogTableHeader.userGroups",
    value: TableColumnsVariantEnum.userGroups,
  },
  {
    label: "adminSection.activityLog.activityLogTableHeader.event",
    value: TableColumnsVariantEnum.event,
  },
];
