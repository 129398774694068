import React from "react";
function MusicNoteIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 14 18"
      fill="none"
      name="MusicNoteIcon"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-32.000000, -1474.000000)" fill="#999999">
          <g transform="translate(10.000000, 1371.000000)">
            <g transform="translate(0.826350, 19.700652)">
              <g transform="translate(0.560362, 63.085661)">
                <g transform="translate(20.613287, 20.072141)">
                  <g transform="translate(0.355093, 0.434703)">
                    <path d="M4.02813299,16.3917019 C2.23043478,16.3917019 0.767263427,14.9710807 0.767263427,13.224 C0.767263427,11.4769193 2.23043478,10.0562981 4.02813299,10.0562981 C5.8258312,10.0562981 7.28900256,11.4769193 7.28900256,13.224 C7.28900256,14.9710807 5.8258312,16.3917019 4.02813299,16.3917019 L4.02813299,16.3917019 Z M13.1815857,6.6724472 L13.7209719,6.14250932 L7.31278772,0.000149068323 L7.31815857,0.805118012 L7.31278772,0.805118012 L7.31278772,10.9730683 C6.5831202,9.97132919 5.38618926,9.31095652 4.02813299,9.31095652 C1.80690537,9.31095652 0,11.066236 0,13.224 C0,15.381764 1.80690537,17.1370435 4.02813299,17.1370435 C6.11969309,17.1370435 7.82378517,15.5748075 8.01790281,13.5921988 L8.03248082,13.5921988 C8.03248082,13.5921988 8.0370844,13.071205 8.03248082,12.9936894 L8.03248082,1.73604969 L13.1815857,6.6724472 Z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default MusicNoteIcon;
