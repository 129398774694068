import React from "react";
function StatementsIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 18 22"
      fill="none"
      name="StatementsIcon"
      {...props}
    >
      <path d="M12.77 13.483H2.313a.378.378 0 0 0 0 .755H12.77a.378.378 0 1 0 0-.755Zm-1.891-7.224h3.828a.378.378 0 0 0 .222-.69L9.55.112a.379.379 0 0 0-.647.265v3.907c0 1.09.887 1.975 1.977 1.975Zm-1.22-4.96 4.145 4.204H10.88c-.673 0-1.22-.547-1.22-1.219V1.3Zm3.11 14.753H2.314a.378.378 0 0 0 0 .755H12.77a.377.377 0 1 0 0-.755ZM2.314 3.958h4.802a.378.378 0 0 0 0-.755H2.313a.378.378 0 0 0 0 .755Zm12.394 2.48c-.209 0-.378.17-.378.379v11.086c0 .74-.602 1.342-1.343 1.342H2.097c-.74 0-1.341-.602-1.341-1.342V2.059A1.337 1.337 0 0 1 2.098.755h6.251a.378.378 0 0 0 0-.755h-6.25C.957 0 .036.894.001 2.047L0 17.903A2.1 2.1 0 0 0 2.097 20h10.89a2.1 2.1 0 0 0 2.097-2.097V6.817a.378.378 0 0 0-.377-.378Zm-12.394.09h4.802a.378.378 0 0 0 0-.756H2.313a.378.378 0 0 0 0 .756ZM12.77 8.343H2.313a.378.378 0 0 0 0 .755H12.77a.378.378 0 1 0 0-.755Zm0 2.57H2.313a.378.378 0 0 0 0 .755H12.77a.378.378 0 1 0 0-.756Z" />
    </svg>
  );
}

export default StatementsIcon;
