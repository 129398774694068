import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { SingleGroup } from "../../../../app/types/props";
import { GroupType, StatusEnum } from "../../../../app/types/enums";

import { useFetch } from "../../common/hooks/useFetch";
import {
  addNewGroup,
  deleteGroup,
  editGroup,
  fetchGroupByID,
} from "./AddEditGroupAPI";

export type EditGroupProps = {
  id: number;
  data: SingleGroup;
};

export interface AddEditGroupState {
  singleGroup: SingleGroup;
  groupType: GroupType;
  status: StatusEnum;
}

const initialState: AddEditGroupState = {
  singleGroup: {
    id: null,
    name: null,
    email: null,
    emailName: null,
    description: null,
    language: null,
    phone: null,
    pubAdvanceRequestEmail: null,
    recAdvanceRequestEmail: null,
    groupContacts: [],
    permissions: [],
    groupType: null,
    admin: null,
    readOnlyAdmin: null,
    superAdmin: null,
  },
  groupType: GroupType.Publishing,
  status: StatusEnum.Idle,
};

type GroupRequestData = {
  id: number;
};

type AddNewGroupRequestDataProps = {
  data: SingleGroup;
};

type EditGroupRequestDataProps = {
  data: SingleGroup;
  id: number;
};

export const fetchGroupByIDThunk = createAsyncThunk(
  "admin/addEditGroup",
  async (id: number, { rejectWithValue }) => {
    try {
      const requestData = {
        id,
      } as GroupRequestData;

      const fetch = await useFetch();
      const response = await fetch<GroupRequestData>({
        data: requestData,
        fetchFunction: fetchGroupByID,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewGroupThunk = createAsyncThunk(
  "admin/addEditAdminGroup/addNewAdminGroup",
  async (payload: SingleGroup, { rejectWithValue }) => {
    try {
      const requestData = {
        data: payload,
      } as AddNewGroupRequestDataProps;

      const fetch = await useFetch();
      const response = await fetch<AddNewGroupRequestDataProps>({
        data: requestData,
        fetchFunction: addNewGroup,
        customSuccessMsg:
          "adminSection.common.commonAddEditGroups.messages.successAddGroupMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editGroupThunk = createAsyncThunk(
  "admin/addEditAdminGroup/editAdminGroup",
  async (payload: EditGroupProps, { rejectWithValue }) => {
    try {
      const requestData = {
        data: {
          ...payload.data,
        },
        id: payload.id,
      } as EditGroupRequestDataProps;

      const fetch = await useFetch();
      const response = await fetch<EditGroupRequestDataProps>({
        data: requestData,
        fetchFunction: editGroup,
        customSuccessMsg: "adminSection.common.successEditMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteGroupThunk = createAsyncThunk(
  "admin/addEditAdminGroup/deleteAdminGroup",
  async (id: number, { rejectWithValue }) => {
    try {
      const requestData = {
        id,
      } as GroupRequestData;

      const fetch = await useFetch();
      const response = await fetch<GroupRequestData>({
        data: requestData,
        fetchFunction: deleteGroup,
        customSuccessMsg:
          "adminSection.common.commonAddEditGroups.messages.successDeleteGroupMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addEditGroupSlice = createSlice({
  name: "addEditGroup",
  initialState,
  reducers: {
    resetGroup: (state) => {
      state.singleGroup = initialState.singleGroup;
    },
    changeGroupType: (state, action: any) => {
      state.groupType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupByIDThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(fetchGroupByIDThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.singleGroup = action.payload;
        state.groupType = action.payload?.groupType;
      })
      .addCase(fetchGroupByIDThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(addNewGroupThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(addNewGroupThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(addNewGroupThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(editGroupThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(editGroupThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(editGroupThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(deleteGroupThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(deleteGroupThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(deleteGroupThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      });
  },
});

export const addEditGroupSelector = (state: RootState) => state.addEditGroup;

export default addEditGroupSlice.reducer;
