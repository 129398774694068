import request from "../../../../app/utils/requestUtils";

import { GroupsRequestBody } from "../../../../app/types/props";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export type AdminGroupsRequestBody = Omit<GroupsRequestBody, "searchCriteria">;

export const getAdminGroups = (payload: AdminGroupsRequestBody) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.adminGroups}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};
