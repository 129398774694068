const Terms_International = (): JSX.Element => {
  return (
    <div>
      <h1>Terms of Use</h1>
      <h2>for BMG’s Online Client Platform at my.bmg.com</h2>

      <p>
        Welcome to the Online Client Platform of BMG (the “Platform”) at
        my.bmg.com (the “Site”), which BMG Rights Management GmbH, Germany,
        operates on behalf of itself and the BMG group of companies, including
        its affiliates and subsidiaries, (individually and collectively “BMG”,
        “we”, “us”, “our”) for use by songwriters, publishers and other BMG
        clients (collectively, “BMG Client(s)”).
      </p>
      <p>
        THESE TERMS OF USE CONSTITUTE A LEGAL AGREEMENT BETWEEN BMG AND YOU,
        INDIVIDUALLY AS A BMG CLIENT, AND, IF YOU ARE ACCESSING OR USING THE
        PLATFORM OR THE SITE ON BEHALF OF A BMG CLIENT WITHIN THE SCOPE OF YOUR
        ENGAGEMENT OR RETENTION WITH THAT BMG CLIENT, BETWEEN BMG AND SUCH BMG
        CLIENT, AS A USER OF THE PLATFORM AND SITE (THE OR THESE “TERMS OF
        USE”). YOU REPRESENT AND WARRANT THAT YOU ARE AUTHORIZED TO BIND
        YOURSELF AND SUCH BMG CLIENT AS A CONTRACTING PARTY TO THESE TERMS OF
        USE. UNLESS OTHERWISE INDICATED IN THESE TERMS OF USE, THROUGHOUT THE
        REMAINDER OF THESE TERMS OF USE, THE WORDS “YOU” OR “YOUR” SHALL BE
        DEEMED TO REFER INDIVIDUALLY AND COLLECTIVELY TO YOU AND, IF APPLICABLE,
        THE BMG CLIENT ON WHOSE BEHALF YOU ARE ACCESSING OR USING THE PLATFORM
        OR THE SITE.
      </p>
      <p>
        BY REGISTERING, ACCESSING, BROWSING OR USING THE PLATFORM OR THE SITE,
        YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY
        THESE TERMS OF USE, INCLUDING ANY CHANGES MADE HERETO THAT EXIST WHEN
        YOU RE-ACCESS THE PLATFORM OR THE SITE. WE MAY UPDATE THESE TERMS OF USE
        FROM TIME TO TIME WITHOUT NOTICE. UNLESS OTHERWISE SPECIFIED, SUCH
        CHANGES WILL BE EFFECTIVE WHEN THEY ARE POSTED.
      </p>
      <p>
        IF YOU DO NOT AGREE WITH THESE TERMS OF USE OR ANY GUIDELINES, POLICIES
        OR RULES POSTED ON THE SITE, THEN DO NOT USE THE PLATFORM OR THE SITE.
      </p>

      <h3>1. Scope of Application</h3>
      <p>These Terms of Use govern your use of the Platform and Site.</p>
      <h3>2. Login Credentials</h3>
      <p>
        <span className="text-red-800">2.1</span> Using the Platform is an
        option for BMG Clients. We, therefore, do not simply send all BMG
        Clients user names and passwords (“Login Credentials”), but rather ask
        BMG Clients whether they want us to generate Login Credentials because
        they have decided that using the Platform is something that they are
        interested in. If you have asked us to generate Login Credentials for
        you, we will do so and send the Login Credentials to you.
      </p>
      <p>
        <span className="text-red-800">2.2</span> It is your responsibility to
        keep the Login Credentials, especially your password, secret at all
        times and you are solely responsible to BMG for all activity that occurs
        via your account for the Platform and Site. If you lose the Login
        Credentials, or if you discover or suspect that your Login Credentials
        are being used by a third party, then you must immediately notify us to
        this effect. We will then block further use of the Login Credentials.
        Your Login Credentials should not be shared and you will remain fully
        responsible for the use of your account on the Platform any and all
        activities that occur under your account or as a result of your access
        to the Platform or the Site. BMG may require that you change your Login
        Credentials or certain parts of your account information at any time for
        any reason. In the event that your entity requires additional Login
        Credentials for other users, please contact BMG via email at{" "}
        <a href="mailto:my.bmg@bmg.com">my.bmg@bmg.com</a>.
      </p>
      <h3>3. Your Settings</h3>
      <p>
        In the “My Settings” section of the Platform we may allow you to edit
        certain data about yourself, such as your postal address. If you do so,
        we will, thereafter, use the edited data to communicate with you,
        including all communications made in connection with Contractual
        Documents (as defined below in section 4.1). The statements we send you
        by regular mail, will, for example, thereafter, only be sent to the new
        address. We are not liable for misdirected communications that result
        from your edits.
      </p>
      <h3>4. Accuracy of License and Royalty Data</h3>
      <p>
        <span className="text-red-800">4.1</span> The Platform is designed,
        maintained and made available to you for information purposes only. The
        data and other information on the Platform cannot and shall not be a
        substitute for the official figures and statements. BMG will continue to
        send you, by means other than through the Platform, the actual binding
        royalty statements as required by your contracts with BMG pursuant to
        which you are to receive royalty statements (“Contractual Documents”).
      </p>
      <p>
        <span className="text-red-800">4.2</span> No guarantee, statement,
        warranty or representation is given or made, nor is any liability
        whatsoever assumed by BMG, either explicitly or implicitly, regarding
        the reasonableness, correctness, accuracy or completeness of any song,
        license, royalty data, royalty statement or other data, information,
        materials or documents you may access, view, download or retrieve on the
        Platform.
      </p>
      <h3>
        5. Industrial and Intellectual Property Rights; Use of the Platform
      </h3>
      <p>
        <span className="text-red-800">5.1</span> The services on our Platform
        are, amongst other things, based on a database. This database is (inter
        alia) protected under the sui generis right as per sections 87a et seq.
        of the German Copyright Act. The Site, Platform, the underlying
        software, database and other components of the Site and Platform, text,
        photos, illustrations, graphs, (trade) names, logos, trade and services
        brands (collectively, “Intellectual Property”) are fully owned or under
        license by BMG, and are protected by copyright, trademark rights and/or
        any other intellectual property rights. You may, in particular, not
        duplicate, transmit, distribute or make publicly available any
        Intellectual Property to third parties, without the prior written
        consent of BMG.
      </p>
      <p>
        <span className="text-red-800">5.2</span> The contents of the Platform
        and Site may only be used for your personal informational purposes.
        Except as expressly provided in these Terms of Use, no part of the
        Platform or Site and no content may be copied, reproduced, republished,
        uploaded, posted, publicly displayed, encoded, translated, transmitted
        or distributed in any way (including "mirroring") to any other computer,
        server, website or other medium for publication or distribution or for
        any commercial enterprise, without BMG’s prior written consent.
      </p>
      <p>
        <span className="text-red-800">5.3</span> You must not attempt to gain
        unauthorized access to any portion or feature of the Platform or the
        Site, or any other systems or networks connected to the Site or to any
        BMG server, or to any of the services offered on or through the Platform
        or the Site, by hacking, password “mining” or any other illegitimate or
        illegal means. Without limitation, you must not use the Platform or the
        Site to access or attempt to access data provided by any other BMG
        Client or use the Platform or the Site to violate the rights of any
        other BMG Client or other third party.
      </p>
      <p>
        <span className="text-red-800">5.4</span> You must not forge headers or
        otherwise manipulate identifiers in order to disguise the origin of any
        message or transmittal you send to BMG on or through the Platform or the
        Site or any service offered on or through the Platform or the Site. You
        must not pretend that you are, or that you represent, someone else, or
        impersonate any other individual or entity.
      </p>
      <h3>6. Notice of Claimed Copyright Infringement</h3>
      <p>
        BMG respects the intellectual property rights of others and expects its
        users to do the same. In accordance with the Digital Millennium
        Copyright Act, the name and address for BMG’s designated agent to
        receive notification of claimed infringement is:
      </p>
      <p>
        Keith C. Hauprich Esq.,
        <br />
        BMG Rights Management (US) LLC
        <br />
        1745 Broadway, New York, NY 10019, USA
        <br />
        Telephone: +1 212 561 3000
        <br />
        Fax: +1 212 683 2040
        <br />
        Email:{" "}
        <a href="mailto:keith.hauprich@bmg.com">keith.hauprich@bmg.com</a>
      </p>
      <p>
        If you believe that your work has been used or copied in a way that
        constitutes copyright infringement and such infringement is occurring on
        the Site, written notices of claimed infringement of copyright should be
        sent to the name and address listed above. Please be aware that the
        Digital Millennium Copyright Act (17 U.S.C. § 512(c)(3)) requires that
        notices of claimed infringement must include certain minimal
        information. For more information about copyright law, the Digital
        Millennium Copyright Act and the elements required in a notice of
        claimed infringement, please visit the U.S. Copyright Office website at{" "}
        <a href="https://www.copyright.gov/title17/92chap5.html#512">
          https://www.copyright.gov/title17/92chap5.html#512
        </a>
        .
      </p>
      <h3>7. Maintenance of the Platform</h3>
      <p>
        We will, from time to time, perform maintenance work on the Platform and
        the Site. There may be times when you will not be able to access the
        Platform or the Site due to maintenance work being performed. Such
        downtime is necessary and does not constitute a breach of contract.
      </p>
      <h3>8. Disclaimer of Warranties, Representations, and Liability</h3>
      <p>
        Your use and browsing of, and any reliance by you upon, the platform or
        the site is at your own risk. If you are dissatisfied with the platform
        or the site, or with any of the terms and conditions of these terms of
        use, your sole and exclusive remedy is to discontinue accessing and
        using the platform and the site. To the maximum extent permitted by
        applicable law, the platform and the site and all content, information,
        software, documents and materials accessible from and/or through the
        platform or the site are provided “as is”, “as available”, with all
        faults and without any, and bmg hereby disclaims all, representations
        and warranties of any kind, whether express, implied or statutory.
      </p>
      <p>
        Because of the inherent hazards and uncertainties of electronic
        distribution, there may be delays, omissions, inaccuracies or other
        problems with such information contained on the site and platform. You
        understand that you are solely responsible for any damage to your
        computer system or loss of data that results from any materials and/or
        data downloaded from or otherwise provided through the site and
        platform.
      </p>
      <p>
        Without limitation of the foregoing, we do not warrant or represent the
        accuracy, completeness, currentness, non-infringement, merchantability
        or fitness for a particular purpose of the information available through
        the site or platform or of the site or platform themselves, nor do we
        guarantee that the site and platform will be error-free or continuously
        available, or that the site and platform will be free of viruses or
        other harmful components. Under no circumstances will we be liable to
        you or anyone else for any damages arising out of use of the site or
        platform, including, without limitation, liability for consequential,
        special, incidental, indirect or similar damages, even if we are advised
        beforehand of the possibility of such damages. Because some
        jurisdictions do not allow the exclusion or limitation of certain
        categories of damages, you agree that our liability, not withstanding
        the foregoing, is limited in such jurisdictions to the fullest extent
        permitted by applicable law, if any, arising out of any kind of legal
        claim in any way connected to the site or platform and shall not exceed
        the amount, if any, you paid to us for the use of the site and platform.
      </p>
      <h3>9. Indemnity</h3>
      <p>
        You agree to indemnify, defend and hold harmless BMG and its officers,
        directors, employees, agents, licensors and suppliers, from and against
        any and all losses, expenses, damages and costs (including reasonable
        attorneys' fees) resulting from any violation of these Terms of Use or
        any activity related to your account (including, without limitation,
        negligent or wrongful conduct) by you or any other person accessing the
        Site or Platform using your account.
      </p>
      <h3>10. Privacy</h3>
      <p>
        For information about BMG’s data protection and collection practices,
        please read the Privacy Policy, which is incorporated herein by
        reference and made a part of these Terms of Use. You agree to BMG’s use
        of your data in accordance with the Privacy Policy.
      </p>
      <h3>11. General</h3>
      <p>
        <span className="text-red-800">11.1</span> If any of these terms are
        illegal, invalid or otherwise unenforceable by reason of the laws of any
        country in which these terms are intended to be effective, then to the
        extent and within the jurisdiction in which that term is illegal,
        invalid or unenforceable, it shall be severed and deleted from these
        terms and the remaining terms shall survive, remain in full force and
        effect and continue to be binding and enforceable.
      </p>
      <p>
        <span className="text-red-800">11.2</span> We may change, restrict
        access to, suspend or discontinue permanently the Platform, or any
        portion of the Platform or the Site, at any time.
      </p>
      <p>
        <span className="text-red-800">11.3</span> In the event of any conflict
        between any terms and conditions contained in these Terms of Use and any
        terms and conditions contained in any Contractual Documents, the terms
        and conditions contained in the applicable Contractual Document(s) will
        be deemed to control and take precedence.
      </p>
      <p>
        <span className="text-red-800">11.4</span> The contractual relationship
        between BMG and you covered by these Terms of Use shall be exclusively
        governed by the laws of the Federal Republic of Germany, excluding the
        German and/or the EU law provisions on conflict of laws.
      </p>
      <p>
        <span className="text-red-800">11.5</span> BMG reserves the right to
        terminate your access to the Platform in the event of a breach of these
        Terms of Use.
      </p>
      <p>
        <span className="text-red-800">11.6</span> The courts of Berlin,
        Germany, shall have exclusive jurisdiction on any dispute out of or in
        connection with the contractual relationship covered by these Terms of
        Use as well as these Terms of Use themselves, if you are a merchant
        within the meaning of Section 1 of the German Commercial Code.
      </p>
      <h3>12. Contact Us</h3>
      <p>
        For questions or comments on these Terms of Use, you may contact us at:{" "}
        <a href="mailto:my.bmg@bmg.com">my.bmg@bmg.com</a>
      </p>
      <p>
        <small>Effective Date: March 2013</small>
      </p>
    </div>
  );
};

export default Terms_International;
