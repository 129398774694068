import React from "react";

function RankSameIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="8px"
      height="8px"
      viewBox="0 0 8 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Rectangle" fill="#D8D8D8">
          <rect x="0" y="0" width="8" height="8"></rect>
        </g>
      </g>
    </svg>
  );
}
export default RankSameIcon;

/*  <svg
   xmlns="http://www.w3.org/2000/svg"
   width="100%"
   height="100%"
   viewBox="-10 -6 26 26"
   name="ChevronIcon"
   {...props}
 >
   <path
     fillRule="evenodd"
     d="m7.636 7.093-6.004 6.69-1.144-1.275 4.86-5.415-4.86-5.415L1.632.404z"
   />
 </svg>; */
