import { Link as RLink } from "react-router-dom";
import { LinkProps } from "../../types/props";

export default function Link(props: LinkProps): JSX.Element {
  const {
    to,
    isInternalLink = true,
    internalState = null,
    className = "",
    target,
    onClick,
    id,
    iconRight,
    iconLeft,
    variant = "",
  } = props;

  const text =
    typeof props.text == "string" ? <span>{props.text}</span> : props.text;

  const getClassNames = () => {
    const classNames = ["link"];
    if (variant) classNames.push(variant);
    if (className) classNames.push(className);
    return classNames.join(" ");
  };

  if (isInternalLink)
    return (
      <RLink
        to={to}
        state={internalState}
        onClick={onClick}
        className={getClassNames()}
        id={id}
        target={target}
      >
        {iconLeft && iconLeft}
        {text}
        {iconRight && iconRight}
      </RLink>
    );
  else
    return (
      <a
        href={to === "" ? undefined : (to as string)}
        target={target}
        className={getClassNames()}
        onClick={onClick}
      >
        {iconLeft && iconLeft}
        {text}
        {iconRight && iconRight}
      </a>
    );
}
