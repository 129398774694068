import React from "react";

function ResetIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      name="ResetIcon"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.913 8.639C19.248 3.759 15.064 0 10.003 0 4.478 0 0 4.478 0 10.003 0 15.419 4.306 19.83 9.682 20v-.91A9.093 9.093 0 1 1 18.994 8.64h-1.717l2.273 2.728 2.274-2.728h-1.911Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default ResetIcon;
