import React from "react";
function AddClientIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 25 24"
      fill="none"
      name="AddClientIcon"
      {...props}
    >
      <defs>
        <mask
          id="a"
          width="30.105"
          height="24"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        />
      </defs>
      <use mask="url(#a)" />
      <path d="M8.827 5.838v2.475H6.352v.583h2.475v2.475h.583V8.896h2.475v-.583H9.41V5.838zM18.897 11.777c1.962 0 3.558-1.514 3.558-3.374 0-1.861-1.596-3.375-3.558-3.375-1.961 0-3.557 1.514-3.557 3.375 0 1.86 1.596 3.374 3.557 3.374Zm0-6.269c1.682 0 3.051 1.299 3.051 2.895 0 1.595-1.369 2.894-3.051 2.894-1.682 0-3.05-1.299-3.05-2.894 0-1.596 1.368-2.895 3.05-2.895Zm5.286 12.434c-.224-.924-1.016-3.669-1.606-4.397-.923-1.46-2.864-1.277-2.92-1.27h-1.582c-.066.001-1.631-.04-2.55 1.211-.509.696-1.256 3.352-1.519 4.457a.24.24 0 0 0 .191.287.252.252 0 0 0 .303-.181c.276-1.161 1.006-3.693 1.442-4.289.756-1.03 2.104-1.004 2.124-1.004l1.619-.002c.017-.002 1.713-.156 2.465 1.049.006.01.012.019.02.027.461.553 1.234 3.04 1.52 4.22a.25.25 0 0 0 .246.186.263.263 0 0 0 .056-.006.24.24 0 0 0 .19-.288Z" />
    </svg>
  );
}
export default AddClientIcon;
