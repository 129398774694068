import { useTranslation } from "react-i18next";
import styles from "../analysis.module.scss";
interface InfiniteTableHeaderProps {
  filterAnalysisAlbumsWith: (columnName: string) => void;
}

const AnalysisAlbumInfiniteTableHeader = (props: InfiniteTableHeaderProps) => {
  const { t } = useTranslation();
  const { filterAnalysisAlbumsWith } = props;
  return (
    <div id="table-header" className={styles.headContainer}>
      <table>
        <thead>
          <tr>
            <th
              className={styles.albumCol}
              onClick={() => {
                filterAnalysisAlbumsWith("title");
              }}
            >
              {t("analysis.albums.album")}
            </th>
            <th
              className={styles.configDescCol}
              onClick={() => {
                filterAnalysisAlbumsWith("configDesc");
              }}
            >
              {t("analysis.albums.configuration")}
            </th>
            <th
              className={styles.artistCol}
              onClick={() => {
                filterAnalysisAlbumsWith("artist");
              }}
            >
              {t("analysis.albums.artist")}
            </th>
            <th
              className={`sortByRoyalties_GTM ${styles.royaltiesCol}`}
              onClick={() => {
                filterAnalysisAlbumsWith("royalties");
              }}
            >
              {t("analysis.albums.royalties")}
            </th>
            <th className={styles.digitalUnitsCol}>
              {t("analysis.albums.digitalUnits")}
            </th>
            <th className={styles.physicalUnitsCol}>
              {t("analysis.albums.physicalUnits")}
            </th>
            <th></th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default AnalysisAlbumInfiniteTableHeader;
