import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import {
  FetchAnalysisDigitalBreakdown_Products,
  FetchAnalysisDigitalBreakdown_Territory,
  FetchAnalysisDigitalBreakdown_Trend,
  downloadDigitalBreakdownData,
  RecAnalysisDigitalData,
} from "./recAnalysisDigitalBreakdownAPI";

export declare type RecAnalysisDigitalBreakdownProps =
  | RecAnalysisDigitalData
  | null
  | undefined;

export interface recAnalysisBreakdownState {
  digitalBreakdownProducts: {
    dataArray: Array<RecAnalysisDigitalBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  digitalBreakdownTerritory: {
    dataArray: Array<RecAnalysisDigitalBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  digitalBreakdownTrend: {
    dataArray: Array<RecAnalysisDigitalBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  digitalBreakdownDownloadStatus: "idle" | "loading" | "failed";
}

const initialState: recAnalysisBreakdownState = {
  digitalBreakdownProducts: { dataArray: [], status: "noData" },
  digitalBreakdownTerritory: { dataArray: [], status: "noData" },
  digitalBreakdownTrend: { dataArray: [], status: "noData" },
  digitalBreakdownDownloadStatus: "idle",
};

export const FetchRecAnalysisDigitalBreakdown_Products_Thunk = createAsyncThunk(
  "recording/FetchRecAnalysisDigitalBreakdown_Products",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisDigitalBreakdown_Products(params, thunkAPI);
  }
);

export const FetchRecAnalysisDigitalBreakdown_Territory_Thunk =
  createAsyncThunk(
    "recording/FetchRecAnalysisDigitalBreakdown_Territory",
    async (params: any, thunkAPI) => {
      return await FetchAnalysisDigitalBreakdown_Territory(params, thunkAPI);
    }
  );

export const FetchRecAnalysisDigitalBreakdown_Trend_Thunk = createAsyncThunk(
  "recording/FetchRecAnalysisDigitalBreakdown_Type",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisDigitalBreakdown_Trend(params, thunkAPI);
  }
);

export const recAnalysisDigitalBreakdown_Download_Thunk = createAsyncThunk(
  "recAnalysis/breakdown/downloadDigital",
  async (payload: any, thunkAPI) => {
    const response = await downloadDigitalBreakdownData(payload, thunkAPI);
    return response;
  }
);

export const FindRecAnalysisDigitalBreakdown = (
  digital: Array<RecAnalysisDigitalBreakdownProps>,
  sourceCode: string
): RecAnalysisDigitalData | null => {
  const breakdown = digital.find((digital) => {
    return digital?.sourceName === sourceCode;
  });
  return breakdown ? breakdown : null;
};

export const recAnalysisDigitalBreakdownSlice = createSlice({
  name: "recAnalysisDigitalBreakdown",
  initialState,
  reducers: {
    clearProductsBreakdownData: (state, action: any) => {
      state.digitalBreakdownProducts.dataArray = [];
      state.digitalBreakdownProducts.status = "noData";
    },
    clearTerritoryBreakdownData: (state, action: any) => {
      state.digitalBreakdownTerritory.dataArray = [];
      state.digitalBreakdownTerritory.status = "noData";
    },
    clearTrendBreakdownData: (state, action: any) => {
      state.digitalBreakdownTrend.dataArray = [];
      state.digitalBreakdownTrend.status = "noData";
    },
  },
  extraReducers: (builder) => {
    builder
      // download
      .addCase(
        recAnalysisDigitalBreakdown_Download_Thunk.fulfilled,
        (state) => {
          state.digitalBreakdownDownloadStatus = "idle";
        }
      )
      .addCase(recAnalysisDigitalBreakdown_Download_Thunk.pending, (state) => {
        state.digitalBreakdownDownloadStatus = "loading";
      })
      .addCase(recAnalysisDigitalBreakdown_Download_Thunk.rejected, (state) => {
        state.digitalBreakdownDownloadStatus = "failed";
      })
      // FetchRecAnalysisDigitalBreakdown_Products_Thunk
      .addCase(
        FetchRecAnalysisDigitalBreakdown_Products_Thunk.fulfilled,
        (state, action) => {
          const productData = action.payload;
          productData.sourceName = action.meta.arg.sourceName;
          state.digitalBreakdownProducts.dataArray.push(productData);
          state.digitalBreakdownProducts.status = "idle";
          return state;
        }
      )
      .addCase(
        FetchRecAnalysisDigitalBreakdown_Products_Thunk.pending,
        (state) => {
          state.digitalBreakdownProducts.status = "loading";
        }
      )
      .addCase(
        FetchRecAnalysisDigitalBreakdown_Products_Thunk.rejected,
        (state) => {
          state.digitalBreakdownProducts.status = "failed";
        }
      )
      .addCase(
        FetchRecAnalysisDigitalBreakdown_Territory_Thunk.fulfilled,
        (state, action) => {
          const territoryData = action.payload;
          territoryData.sourceName = action.meta.arg.sourceName;
          state.digitalBreakdownTerritory.dataArray.push(territoryData);
          state.digitalBreakdownTerritory.status = "idle";
          return state;
        }
      )
      .addCase(
        FetchRecAnalysisDigitalBreakdown_Territory_Thunk.pending,
        (state) => {
          state.digitalBreakdownTerritory.status = "loading";
        }
      )
      .addCase(
        FetchRecAnalysisDigitalBreakdown_Territory_Thunk.rejected,
        (state) => {
          state.digitalBreakdownTerritory.status = "failed";
        }
      )
      .addCase(
        FetchRecAnalysisDigitalBreakdown_Trend_Thunk.fulfilled,
        (state, action) => {
          const trendData = action.payload;
          trendData.sourceCode = action.meta.arg.sourceCode;
          trendData.sourceName = action.meta.arg.sourceName;
          state.digitalBreakdownTrend.dataArray.push(trendData);
          state.digitalBreakdownTrend.status = "idle";
          return state;
        }
      )
      .addCase(
        FetchRecAnalysisDigitalBreakdown_Trend_Thunk.pending,
        (state) => {
          state.digitalBreakdownTrend.status = "loading";
        }
      )
      .addCase(
        FetchRecAnalysisDigitalBreakdown_Trend_Thunk.rejected,
        (state) => {
          state.digitalBreakdownTrend.status = "failed";
        }
      );
  },
});

export const recAnalysisDigitalBreakdownProductsSelector = (state: RootState) =>
  state.recAnalysisDigitalBreakdown.digitalBreakdownProducts;
export const recAnalysisDigitalBreakdownTerritorySelector = (
  state: RootState
) => state.recAnalysisDigitalBreakdown.digitalBreakdownTerritory;
export const recAnalysisDigitalBreakdownTrendSelector = (state: RootState) =>
  state.recAnalysisDigitalBreakdown.digitalBreakdownTrend;
export const recAnalysisDigitalBreakdownDownloadStatusSelector = (
  state: RootState
) => state.recAnalysisDigitalBreakdown.digitalBreakdownDownloadStatus;

export const {
  clearProductsBreakdownData: clearProductsBreakdownDataAction,
  clearTerritoryBreakdownData: clearTerritoryBreakdownDataAction,
  clearTrendBreakdownData: clearTrendBreakdownDataAction,
} = recAnalysisDigitalBreakdownSlice.actions;

export default recAnalysisDigitalBreakdownSlice.reducer;
