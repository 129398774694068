import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { SortDirectionEnum, StatusEnum } from "../../../../app/types/enums";

import { DEFAULT_ROWS_PER_PAGE } from "../../common/adminEndpoints.const";
import { useFetch } from "../../common/hooks/useFetch";

import { tableHeaderData } from "./components/ClientsTable/ClientsTable.const";

import { getClientLists, getLinkedUsers } from "./clientsPageRequestAPI";
import { CommonRequestBodyTableState } from "../../../../app/types/props";

export type ClientsListItem = {
  id: number;
  name: string;
  email: string;
  siteCode: string;
  depth: number;
  clientId: string;
  territory: string;
};

export type LinkedUsersItem = {
  id: number;
  username: string;
  groups: string;
  email: string;
  requestedDate: string;
  createdDate: string;
  deleted: string;
};

type ClientsPageInitialState = {
  tableClients: {
    clientsPageState: CommonRequestBodyTableState;
    tableData: {
      list: ClientsListItem[] | null;
      count: number | null;
      totalCount: number | null;
    };
  };
  tableLinkedUsers: {
    clientsPageState: {
      id: number | null;
      params: CommonRequestBodyTableState;
    };
    tableData: {
      list: LinkedUsersItem[];
      count: number | null;
      totalCount: number | null;
    };
  };
  status: StatusEnum;
};

const initialState: ClientsPageInitialState = {
  tableClients: {
    clientsPageState: {
      searchCriteria: {
        clientDocumentType: "",
      },
      filterText: "",
      pagination: {
        page: 0,
        size: DEFAULT_ROWS_PER_PAGE,
        sortingCriteria: [
          {
            sortColumn: tableHeaderData?.[0].filteringInfo,
            direction: SortDirectionEnum.Ascending,
          },
        ],
      },
    },
    tableData: {
      list: null,
      count: 0,
      totalCount: 0,
    },
  },
  tableLinkedUsers: {
    clientsPageState: {
      id: null,
      params: {
        searchCriteria: {
          clientDocumentType: "",
        },
        filterText: "",
        pagination: {
          page: 0,
          size: DEFAULT_ROWS_PER_PAGE,
          sortingCriteria: [
            {
              sortColumn: tableHeaderData?.[0].filteringInfo,
              direction: SortDirectionEnum.Ascending,
            },
          ],
        },
      },
    },
    tableData: {
      list: [],
      count: 0,
      totalCount: 0,
    },
  },
  status: StatusEnum.Idle,
};

export const getClientsThunk = createAsyncThunk(
  "getClientLists",
  async (payload: any, { rejectWithValue }) => {
    try {
      const getClients = await useFetch();
      const response = await getClients({
        data: payload,
        fetchFunction: getClientLists,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLinkedUsersThunk = createAsyncThunk(
  "getLinkedUsers",
  async (payload: any, { rejectWithValue }) => {
    try {
      const getUsers = await useFetch();
      const response = await getUsers({
        data: payload,
        fetchFunction: getLinkedUsers,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientsPageSlice = createSlice({
  name: "clientsPage",
  initialState,
  reducers: {
    updateTableClientsPageState: (state, action: any) => {
      state.tableClients.clientsPageState = action.payload;
    },
    updateTableLinkedUsersPageState: (state, action: any) => {
      state.tableLinkedUsers.clientsPageState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientsThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableClients.tableData.list = action.payload.data;
        state.tableClients.tableData.totalCount = action.payload.totalCount;
        state.tableClients.tableData.count = action.payload.count;
      })
      .addCase(getClientsThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getLinkedUsersThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableLinkedUsers.tableData.list = action.payload.data;
        state.tableLinkedUsers.tableData.totalCount = action.payload.totalCount;
        state.tableLinkedUsers.tableData.count = action.payload.count;
      })
      .addCase(getLinkedUsersThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getLinkedUsersThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
        state.tableLinkedUsers.tableData.list = [];
        state.tableLinkedUsers.tableData.totalCount = null;
        state.tableLinkedUsers.tableData.count = null;
      });
  },
});
export const clientsPageSelector = (state: RootState) => state.clientsPage;

export const {
  updateTableClientsPageState: updateTableClientsPageStateAction,
  updateTableLinkedUsersPageState: updateTableLinkedUsersPageStateAction,
} = clientsPageSlice.actions;

export default clientsPageSlice.reducer;
