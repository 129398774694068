import { Fragment } from "react";
import { ButtonVariantEnum } from "../../../types/enums";
import Button from "../../../atom/Button";
import AnalysisDigitalBreakdown from "../analysisDigitalBreakdown/AnalysisDigitalBreakdown";
import styles from "../analysis.module.scss";

interface LeftSideItemProps {
  digital: any;
  digitalId: string;
  isSelected: boolean;
  sourceCode?: string;
  sourceName: string;
  handleDigitalClick: (clickedDigital: any) => void;
  documentType: "PUBLISHING" | "RECORDING";
  selectedPeriods: number[];
  selectedClients: any[];
  windowWidth: number | undefined;
}

const AnalysisDigitalLeftSideItem = (props: LeftSideItemProps) => {
  const {
    digital,
    digitalId,
    isSelected,
    sourceCode,
    sourceName,
    handleDigitalClick,
    documentType,
    selectedPeriods,
    selectedClients,
    windowWidth,
  } = props;

  return (
    <Fragment key={digitalId}>
      <tr
        onClick={() => handleDigitalClick(digital)}
        className={`${styles.desktopRow} ${isSelected ? styles.selected : ""}`}
      >
        <td className={styles.sourceCol}>
          <p>{digital.sourceName}</p>
        </td>
        <td className={styles.royaltiesCol}>
          {digital.royalty?.currency} {digital.royalty?.formattedLong}
        </td>
        <td className="text-right">
          <Button
            className={`${isSelected ? "block" : "hidden"} ${
              styles.unselectBtn
            }`}
            variant={ButtonVariantEnum.cleanCta}
            onClick={() => handleDigitalClick(digitalId)}
          >
            +
          </Button>
        </td>
      </tr>
      <tr
        className={`${styles.mobileRow} ${isSelected ? styles.selected : ""}`}
        onClick={() => handleDigitalClick(digital)}
      >
        <td className={styles.mobileCol}>
          <div className={"relative"}>
            <p>{digital.sourceName}</p>
            <Button
              className={`${isSelected ? "block" : "hidden"} ${
                styles.unselectBtn
              }`}
              variant={ButtonVariantEnum.cleanCta}
              onClick={() => handleDigitalClick(digitalId)}
            >
              +
            </Button>
          </div>
          <dl>
            <dt>{digital.royalty?.currency} </dt>
            <dd>{digital.royalty?.formattedLong}</dd>
          </dl>
        </td>
      </tr>
      {isSelected && (
        <tr className={`${styles.selectedContent} ${styles.mobileRow}`}>
          <td colSpan={4}>
            {windowWidth && windowWidth < 1200 && (
              <div className="block w-full">
                <AnalysisDigitalBreakdown
                  documentType={documentType}
                  isSelected={digital}
                  sourceCode={sourceCode || sourceName}
                  sourceName={sourceName}
                  periodIds={selectedPeriods}
                  clientIds={selectedClients}
                  mobileView={true}
                  handleClose={handleDigitalClick}
                />
              </div>
            )}
          </td>
        </tr>
      )}
    </Fragment>
  );
};

export default AnalysisDigitalLeftSideItem;
