import React from "react";
function RecJournalIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 18 22"
      fill="none"
      name="RecJournalIcon"
      {...props}
    >
      <path d="M12.812 0C13.468 0 14 .532 14 1.188v14.624c0 .656-.532 1.188-1.188 1.188H1.188A1.188 1.188 0 0 1 0 15.812V1.188C0 .532.532 0 1.188 0zm0 .8H1.188a.388.388 0 0 0-.38.31L.8 1.188v14.624c0 .188.133.344.31.38l.078.008h11.624a.388.388 0 0 0 .38-.31l.008-.078V1.188a.388.388 0 0 0-.31-.38z" />
      <path d="M11 13.668a.4.4 0 0 1 .08.792l-.08.008H3a.4.4 0 0 1-.08-.792l.08-.008zm0-2.376a.4.4 0 0 1 .08.792l-.08.008H3a.4.4 0 0 1-.08-.792l.08-.008zm0-2.376a.4.4 0 0 1 .08.792l-.08.008H3a.4.4 0 0 1-.08-.792L3 8.916z" />
    </svg>
  );
}

export default RecJournalIcon;
