import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export function fetchAnalysisSongs(params: any, thunkAPI?: any) {
  const {
    data,
    start = 0,
    count = 5,
    documentType = "PUBLISHING",
    sortColumn = "",
    isAsc = true,
    filterText = "",
  } = params;

  return request(
    `${USER_API_RESOURCE_BASE_URLS.songs}?start=${start}&count=${count}&documentType=${documentType}&sortColumn=${sortColumn}&isAsc=${isAsc}&filterText=${filterText}`,
    { method: "POST", body: JSON.stringify(data), signal: thunkAPI?.signal }
  );
}

export function fetchAnalysisTerritories(params: any, thunkAPI?: any) {
  const { data } = params;

  return request(`${USER_API_RESOURCE_BASE_URLS.territories}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
  });
}

export function fetchAnalysisDigital(params: any, thunkAPI?: any) {
  const {
    data,
    start = 0,
    count = 200,
    documentType = "PUBLISHING",
    sortColumn = "",
    isAsc = true,
    filterText = "",
  } = params;

  return request(
    `${USER_API_RESOURCE_BASE_URLS.digital}?start=${start}&count=${count}&documentType=${documentType}&sortColumn=${sortColumn}&isAsc=${isAsc}&filterText=${filterText}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchAnalysisSource(params: any, thunkAPI?: any) {
  const {
    data,
    start = 0,
    count = 200,
    documentType = "PUBLISHING",
    sortColumn = "",
    isAsc = true,
    filterText = "",
  } = params;

  return request(
    `${USER_API_RESOURCE_BASE_URLS.sources}?start=${start}&count=${count}&documentType=${documentType}&sortColumn=${sortColumn}&isAsc=${isAsc}&filterText=${filterText}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchAnalysisType(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.types}`, {
    method: "POST",
    body: JSON.stringify(params.data),
    signal: thunkAPI?.signal,
  });
}

export function downloadAnalysisData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.analysisDownload}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
    isDownload: true,
    fileName,
  });
}
