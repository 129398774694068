import { RefObject, useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../atom/Button/Button";
import ChevronIcon from "../../../atom/Icon/ChevronIcon";
import { useDispatch, useSelector } from "react-redux";
import { DownloadIcon } from "../../../atom/Icon";
import { ButtonVariantEnum } from "../../../types/enums";
import {
  FetchPubAnalysisSongBreakdown_Details_Thunk,
  FetchPubAnalysisSongBreakdown_Source_Thunk,
  FetchPubAnalysisSongBreakdown_Territory_Thunk,
  FetchPubAnalysisSongBreakdown_Type_Thunk,
  pubAnalysisSongBreakdown_Download_Thunk,
  FindPubAnalysisSongBreakdown,
  PubAnalysisSongBreakdownProps,
  pubAnalysisSongBreakdownDetailSelector,
  pubAnalysisSongBreakdownSourceSelector,
  pubAnalysisSongBreakdownTerritorySelector,
  pubAnalysisSongBreakdownTypeSelector,
  pubAnalysisSongBreakdownDownloadStatusSelector,
} from "../../../../features/publishing/analysis/song/pubAnalysisSongBreakdownSlice";
import Loader from "../../../atom/Loader";
import AnalysisSongBreakdownType from "./AnalysisSongBreakdown_Type";
import AnalysisSongBreakdownTerritory from "./AnalysisSongBreakdown_Territory";
import AnalysisSongBreakdownSource from "./AnalysisSongBreakdown_Source";
import AnalysisSongBreakdownDetail from "./AnalysisSongBreakdown_Detail";
import styles from "./../analysis.module.scss";
import { getNow, getToday } from "../../../utils/dateTime";
import type { AnalysisSongBreakdownDetailDataProps } from "./AnalysisSongBreakdown_Detail";
import { PeriodSelector } from "../../periodSelector/periodSelectorSlice";
import debounce from "../../../utils/debounce";

export declare type AnalysisSongBreakdownViewProps = {
  isSelected: boolean;
  song: {
    songId: number;
    name: string;
    composer: string;
  };
  periodIds: Array<number>;
  clientIds: Array<number>;
  mobileView: boolean;
  handleClose?: (clickedSong: any) => void;
};

const AnalysisSongBreakdown = (props: AnalysisSongBreakdownViewProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();

  const allPeriods = useSelector(PeriodSelector);
  const songBreakdown_Types = useSelector(pubAnalysisSongBreakdownTypeSelector);

  const songBreakdown_Territories = useSelector(
    pubAnalysisSongBreakdownTerritorySelector
  );
  const songBreakdown_Sources = useSelector(
    pubAnalysisSongBreakdownSourceSelector
  );
  const songBreakdown_Details = useSelector(
    pubAnalysisSongBreakdownDetailSelector
  );
  const songBreakdown_DownloadsStatus = useSelector(
    pubAnalysisSongBreakdownDownloadStatusSelector
  );

  const typeBreakdownPosRef = useRef<HTMLParagraphElement>(null);
  const territoryBreakdownPosRef = useRef<HTMLParagraphElement>(null);
  const sourceBreakdownPosRef = useRef<HTMLParagraphElement>(null);
  const songDetailsPosRef = useRef<HTMLParagraphElement>(null);

  const typeData = useRef<PubAnalysisSongBreakdownProps>(null);
  const territoryData = useRef<PubAnalysisSongBreakdownProps>(null);
  const sourceData = useRef<PubAnalysisSongBreakdownProps>(null);
  const detailsData = useRef<PubAnalysisSongBreakdownProps>(null);

  // dispatch refs
  const dispatchedDetails = useRef<any>();
  const dispatchedSource = useRef<any>();
  const dispatchedTerritory = useRef<any>();
  const dispatchedType = useRef<any>();
  const dispatchedDownload = useRef<any>();

  // abort functions
  const abortDispatchedDownload = useCallback(() => {
    if (dispatchedDownload.current) dispatchedDownload.current.abort();
  }, []);
  const abortDispatchedDetails = useCallback(() => {
    if (dispatchedDetails.current) dispatchedDetails.current.abort();
  }, []);
  const abortDispatchedSource = useCallback(() => {
    if (dispatchedSource.current) dispatchedSource.current.abort();
  }, []);
  const abortDispatchedTerritory = useCallback(() => {
    if (dispatchedTerritory.current) dispatchedTerritory.current.abort();
  }, []);
  const abortDispatchedType = useCallback(() => {
    if (dispatchedType.current) dispatchedType.current.abort();
  }, []);

  const findFunction = useMemo(() => {
    return FindPubAnalysisSongBreakdown;
  }, []);

  const fetchTypeFunction = useMemo(() => {
    return FetchPubAnalysisSongBreakdown_Type_Thunk;
  }, []);

  const fetchTerritoryFunction = useMemo(() => {
    return FetchPubAnalysisSongBreakdown_Territory_Thunk;
  }, []);

  const fetchSourceFunction = useMemo(() => {
    return FetchPubAnalysisSongBreakdown_Source_Thunk;
  }, []);

  const fetchDetailsFunction = useMemo(() => {
    return FetchPubAnalysisSongBreakdown_Details_Thunk;
  }, []);

  const downloadFunction = useMemo(() => {
    return pubAnalysisSongBreakdown_Download_Thunk;
  }, []);

  const handleDownloadDataBtnClick = () => {
    const analyzeBy = "BY_SONG_DETAILS";
    const params = {
      data: {
        periodIds: allPeriods?.periods?.map((p) => p.periodNum) || [],
        clientIds: currentParams.clientIds,
        analyzeBy,
        songId: currentParams.songId,
      },
      fileName:
        "Royalties_" + analyzeBy + "_" + getToday() + "-" + getNow() + ".xlsx",
    };
    abortDispatchedDownload();
    dispatchedDownload.current = dispatch(downloadFunction(params));
  };

  const handleTypeFunctionFetch = useMemo(
    () =>
      debounce((currentParams: any) => {
        abortDispatchedType();
        dispatchedType.current = dispatch(fetchTypeFunction(currentParams));
      }, 500),
    [abortDispatchedType, dispatch, fetchTypeFunction]
  );

  const handleTerritoryFunctionFetch = useMemo(
    () =>
      debounce((currentParams: any) => {
        abortDispatchedTerritory();
        dispatchedTerritory.current = dispatch(
          fetchTerritoryFunction(currentParams)
        );
      }, 500),
    [abortDispatchedTerritory, dispatch, fetchTerritoryFunction]
  );

  const handleSourceFunctionFetch = useMemo(
    () =>
      debounce((currentParams: any) => {
        abortDispatchedSource();
        dispatchedSource.current = dispatch(fetchSourceFunction(currentParams));
      }, 500),
    [abortDispatchedSource, dispatch, fetchSourceFunction]
  );

  const handleDetailsFunctionFetch = useMemo(
    () =>
      debounce((currentParams: any) => {
        abortDispatchedDetails();
        dispatchedDetails.current = dispatch(
          fetchDetailsFunction(currentParams)
        );
      }, 500),
    [abortDispatchedDetails, dispatch, fetchDetailsFunction]
  );

  const currentParams = useMemo(() => {
    return {
      clientIds: props.clientIds,
      songId: props.song.songId,
      periodIds: props.periodIds,
    };
  }, [props.clientIds, props.song.songId, props.periodIds]);

  const assignTypeData = useCallback(() => {
    const currentTypeBreakdown = findFunction(
      songBreakdown_Types.dataArray,
      currentParams.songId
    );
    if (currentTypeBreakdown) typeData.current = currentTypeBreakdown;
    else if (typeData.current?.songId !== currentParams.songId) {
      typeData.current = {
        data: null,
        songId: currentParams.songId,
        periodIds: currentParams.periodIds,
      };
      handleTypeFunctionFetch(currentParams);
    }
  }, [
    currentParams,
    findFunction,
    handleTypeFunctionFetch,
    songBreakdown_Types.dataArray,
  ]);

  const assignTerritoryData = useCallback(() => {
    const currentTerritoryBreakdown = findFunction(
      songBreakdown_Territories.dataArray,
      currentParams.songId
    );

    if (currentTerritoryBreakdown)
      territoryData.current = currentTerritoryBreakdown;
    else if (territoryData.current?.songId !== currentParams.songId) {
      territoryData.current = {
        data: null,
        songId: currentParams.songId,
        periodIds: currentParams.periodIds,
      };
      handleTerritoryFunctionFetch(currentParams);
    }
  }, [
    currentParams,
    findFunction,
    handleTerritoryFunctionFetch,
    songBreakdown_Territories.dataArray,
  ]);

  const assignSourceData = useCallback(() => {
    const currentSourceBreakdown = findFunction(
      songBreakdown_Sources.dataArray,
      currentParams.songId
    );

    if (currentSourceBreakdown) sourceData.current = currentSourceBreakdown;
    else if (sourceData.current?.songId !== currentParams.songId) {
      sourceData.current = {
        data: null,
        songId: currentParams.songId,
        periodIds: currentParams.periodIds,
      };
      handleSourceFunctionFetch(currentParams);
    }
  }, [
    currentParams,
    findFunction,
    handleSourceFunctionFetch,
    songBreakdown_Sources.dataArray,
  ]);

  const assignDetailsData = useCallback(() => {
    const currentDetailsBreakdown = findFunction(
      songBreakdown_Details.dataArray,
      currentParams.songId
    );
    if (currentDetailsBreakdown) detailsData.current = currentDetailsBreakdown;
    else if (detailsData.current?.songId !== currentParams.songId) {
      detailsData.current = {
        data: null,
        songId: currentParams.songId,
        periodIds: currentParams.periodIds,
      };
      handleDetailsFunctionFetch(currentParams);
    }
  }, [
    currentParams,
    findFunction,
    handleDetailsFunctionFetch,
    songBreakdown_Details.dataArray,
  ]);

  useEffect(() => {
    return () => {
      abortDispatchedDetails();
      abortDispatchedSource();
      abortDispatchedTerritory();
      abortDispatchedType();
      abortDispatchedDownload();
    };
  }, [
    abortDispatchedDetails,
    abortDispatchedDownload,
    abortDispatchedSource,
    abortDispatchedTerritory,
    abortDispatchedType,
  ]);

  useEffect(() => {
    assignTypeData();
    assignTerritoryData();
    assignSourceData();
    assignDetailsData();
  }, [
    assignDetailsData,
    assignSourceData,
    assignTerritoryData,
    assignTypeData,
  ]);

  const scrollToBreakdown =
    (refObject: RefObject<HTMLParagraphElement>) => () => {
      refObject.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    };

  const handleCloseBreakdown = useCallback(() => {
    props.handleClose && props.handleClose("");
  }, [props]);

  return (
    <div className={styles.breakDown}>
      {(songBreakdown_Types.status === "loading" ||
        songBreakdown_Territories.status === "loading" ||
        songBreakdown_Sources.status === "loading" ||
        songBreakdown_Details.status === "loading" ||
        songBreakdown_DownloadsStatus === "loading") && <Loader />}
      <header
        id="rightScrollableHeader"
        className="sticky top-0 z-[4] bg-white pt-5"
      >
        <div className="flex justify-between">
          {!props.mobileView && (
            <h3 className={styles.title}>{props.song.name}</h3>
          )}
          {props.handleClose && (
            <Button
              className={`closeBreakdown_GTM ${styles.unselectBtn} ${styles.breakdownUnselectBtn} self-start md:mr-2`}
              variant={ButtonVariantEnum.cleanCta}
              onClick={handleCloseBreakdown}
            >
              +
            </Button>
          )}
        </div>
        {!props.mobileView && (
          <div className={styles.description}>
            <p>{props.song.composer}</p>
            <p>ID: {props.song.songId}</p>
          </div>
        )}
        <div className={styles.breakdownButtons}>
          <Button
            icon={<DownloadIcon />}
            className={`downloadData_GTM ${styles.downloadBtn}`}
            disabled={songBreakdown_DownloadsStatus === "loading"}
            onClick={handleDownloadDataBtnClick}
            variant={ButtonVariantEnum.cleanCta}
          >
            {t("analysis.songs.breakdown.downloadData")}
          </Button>
          <Button
            onClick={scrollToBreakdown(typeBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={`incomeTypeBreakdown_GTM ${styles.scrollNavBtn}`}
            icon={<ChevronIcon />}
          >
            {t("analysis.songs.breakdown.type")}
          </Button>
          <Button
            onClick={scrollToBreakdown(territoryBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={`territoryBreakdown_GTM ${styles.scrollNavBtn}`}
            icon={<ChevronIcon />}
          >
            {t("analysis.songs.breakdown.territory")}
          </Button>
          <Button
            onClick={scrollToBreakdown(sourceBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={`${styles.scrollNavBtn}`}
            icon={<ChevronIcon />}
          >
            {t("analysis.songs.breakdown.source")}
          </Button>
          <Button
            onClick={scrollToBreakdown(songDetailsPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={`detailsBreakdown_GTM ${styles.scrollNavBtn}`}
            icon={<ChevronIcon />}
          >
            {t("analysis.songs.breakdown.details")}
          </Button>
        </div>
      </header>
      <div
        className={`${styles.scrollable} ${styles.breakDownContent} ${
          props.isSelected ? styles.isOpen : ""
        }`}
      >
        <section>
          <p ref={typeBreakdownPosRef} className={styles.breakdownTitle}>
            {t("analysis.songs.breakdown.breakdownByType")}
          </p>
          <AnalysisSongBreakdownType
            data={typeData.current}
            status={songBreakdown_Types.status}
          />
          <p ref={territoryBreakdownPosRef} className={styles.breakdownTitle}>
            {t("analysis.songs.breakdown.breakdownByTerritory")}
          </p>
          <AnalysisSongBreakdownTerritory
            data={territoryData.current}
            status={songBreakdown_Territories.status}
          />
          <p ref={sourceBreakdownPosRef} className={styles.breakdownTitle}>
            {t("analysis.songs.breakdown.breakdownBySource")}
          </p>
          <AnalysisSongBreakdownSource
            data={sourceData.current}
            status={songBreakdown_Sources.status}
          />
          <p ref={songDetailsPosRef} className={styles.breakdownTitle}>
            {t("analysis.songs.breakdown.songDetails")}
          </p>
          <AnalysisSongBreakdownDetail
            data={
              (detailsData.current as AnalysisSongBreakdownDetailDataProps | null) ||
              null
            }
            status={songBreakdown_Details.status}
          />
        </section>
      </div>
    </div>
  );
};
export default AnalysisSongBreakdown;
