import { useTranslation } from "react-i18next";
import styles from "./../analysis.module.scss";
import { RecAnalysisChannelProductsBreakdownProps } from "../../../../features/recording/analysis/channel/recAnalysisChannelBreakdownSlice";

const AnalysisChannelBreakdownProducts = ({
  data,
  status,
}: {
  data: RecAnalysisChannelProductsBreakdownProps;
  status: "noData" | "loading" | "idle" | "failed";
}): JSX.Element => {
  const { t } = useTranslation();

  if (!data || !data.resArr) {
    if (status === "idle")
      return (
        <p className="pl-2 pt-4">{t("analysis.songs.breakdown.noData")}</p>
      );
    else return <p className="pl-2 pt-4">{t("app.loading")}</p>;
  }

  return data?.resArr?.length ? (
    <div className={`smallScrollbar ${styles.productsContainer}`}>
      <div className={styles.statRow}>
        <div>
          <strong>{t("analysis.channel.breakdown.product")}</strong>
        </div>
        <div>
          <strong>{t("analysis.channel.breakdown.configuration")}</strong>
        </div>
        <div>
          <strong>{t("analysis.channel.breakdown.royalties")}</strong>
        </div>
      </div>
      {data?.resArr?.map((rowData: any) => (
        <div key={rowData.productKey} className={styles.statRow}>
          <div>
            <p>
              <strong>{rowData.title}</strong>
            </p>
            <p>
              <small>Product code: {rowData.productCode}</small>
            </p>
            {rowData.barCode && (
              <p>
                <small>Bar code: {rowData.barCode}</small>
              </p>
            )}
          </div>
          <div>{rowData.configDesc}</div>
          <div>
            {rowData.royalty?.currency} {rowData.royalty?.formattedLong}
          </div>
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
};
export default AnalysisChannelBreakdownProducts;
