import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import { useOktaAuth } from "@okta/okta-react";
import { windowScrollToTop } from "../../../../app/utils/scrolling";
import { useWindowSize } from "../../../../app/hooks";
import {
  pubAnalysisSongPageStateSelector,
  fetchAnalysisSongsThunk,
  fetchMoreAnalysisSongsThunk,
  updateAnalysisSongPageStateAction,
  pubAnalysisSongsSelector,
  pubAnalysisSongStatusSelector,
  resetAnalysisSongsAction,
  pubAnalysisSongsDownload,
  pubAnalysisDownloadStatusSelector,
  pubAnalysisNoDataFlagSelector,
  PubAnalysisSongPageInitialState,
} from "./pubAnalysisSongSlice";
import {
  clearTypeBreakdownDataAction,
  clearTerritoryBreakdownDataAction,
  clearSourceBreakdownDataAction,
  clearDetailsBreakdownDataAction,
} from "./pubAnalysisSongBreakdownSlice";
import deepCompare from "../../../../app/utils/deepCompare";
import { pubClientSelectionSelector } from "../../pubroot/pubrootSlice";
import useInfiniteScroll from "../../../../app/hooks/useInfiniteScroll";
import Grid from "../../../../app/atom/Grid/Grid";
import Loader from "../../../../app/atom/Loader/Loader";
import AnalysisSongLeftSide from "../../../../app/molecules/analysis/analysisSong/AnalysisSongLeftSide";
import AnalysisSongRightSide from "../../../../app/molecules/analysis/analysisSong/AnalysisSongRightSide";
import PubAnalysisHeader from "../PubAnalysisHeader";
import Search from "../../../../app/molecules/search/Search";
import { pubAnalysisHeaderSelector } from ".././pubAnalysisHeaderSlice";
import { getNow, getToday } from "../../../../app/utils/dateTime";
import throttle from "../../../../app/utils/throttle";
import debounce from "../../../../app/utils/debounce";
import styles from "../../../../app/molecules/analysis/analysis.module.scss";

const PubAnalysisSong = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  const { width: windowWidth } = useWindowSize();

  // selectors
  const clientSelection = useAppSelector(pubClientSelectionSelector);
  const pubAnalysisSongPageState = useAppSelector(
    pubAnalysisSongPageStateSelector
  );
  const songs = useAppSelector(pubAnalysisSongsSelector);
  const pubAnalysisSongStatus = useAppSelector(pubAnalysisSongStatusSelector);
  const pubAnalysisDownloadStatus = useAppSelector(
    pubAnalysisDownloadStatusSelector
  );
  const pubAnalysisNoDataFlag = useAppSelector(pubAnalysisNoDataFlagSelector);
  const pubAnalysisHeaderState = useAppSelector(pubAnalysisHeaderSelector);

  // states
  const [selectedSong, setSelectedSong] = useState<any>(undefined);
  const [analysisSongStart, setAnalysisSongStart] = useState(
    pubAnalysisSongPageState.start
  );
  const [analysisSongSortColumn, setAnalysisSongSortColumn] = useState(
    pubAnalysisSongPageState.sortColumn
  );
  const [analysisSongAscOrder, setAnalysisSongAscOrder] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState(
    pubAnalysisSongPageState.filterText
  );
  const [loaderFlag, setLoaderFlag] = useState(true);
  const [totalAmount, setTotalAmount] = useState({
    currency: songs.data?.totalRoyalty.currency,
    value: songs.data?.totalRoyalty.formattedLong,
  });
  const [isSortingLoading, setIsSortingLoading] = useState(false);

  // observers
  const observerTopTarget = useRef<HTMLDivElement | null>(null);
  const { isOnScreen: topTargetIsOnScreen } =
    useInfiniteScroll(observerTopTarget);
  const observerBottomTarget = useRef<HTMLDivElement | null>(null);
  const {
    pageCount: bottomTargetPageCount,
    setPageCount: setBottomTargetPageCount,
  } = useInfiniteScroll(observerBottomTarget);

  // dispatch refs
  const dispatchedAnalysisSong = useRef<any>();
  const dispatchedAnalysisMoreSong = useRef<any>();
  const dispatchedDownload = useRef<any>();

  // abort functions
  const abortDispatchedDownload = useCallback(() => {
    if (dispatchedDownload.current) dispatchedDownload.current.abort();
  }, []);
  const abortDispatchedAnalysisSong = useCallback(() => {
    if (dispatchedAnalysisSong.current) dispatchedAnalysisSong.current.abort();
  }, []);
  const abortDispatchedAnalysisMoreSong = useCallback(() => {
    if (dispatchedAnalysisMoreSong.current)
      dispatchedAnalysisMoreSong.current.abort();
  }, []);

  const handleAnalysisSongsFetch = useMemo(
    () =>
      debounce((pageState: any) => {
        if (pageState.start === 0) {
          abortDispatchedAnalysisSong();
          dispatchedAnalysisSong.current = dispatch(
            fetchAnalysisSongsThunk(pageState)
          );
        } else {
          abortDispatchedAnalysisMoreSong();
          dispatchedAnalysisMoreSong.current = dispatch(
            fetchMoreAnalysisSongsThunk(pageState)
          );
        }
      }, 500),
    [abortDispatchedAnalysisMoreSong, abortDispatchedAnalysisSong, dispatch]
  );

  const clearAnalysisSongsBreakdown = useMemo(
    () =>
      throttle((state: any) => {
        dispatch(clearTypeBreakdownDataAction(state));
        dispatch(clearTerritoryBreakdownDataAction(state));
        dispatch(clearSourceBreakdownDataAction(state));
        dispatch(clearDetailsBreakdownDataAction(state));
      }, 500),
    [dispatch]
  );

  const resetAnalysisSongs = useCallback(() => {
    dispatch(resetAnalysisSongsAction({}));
  }, [dispatch]);

  const handleSearch = useCallback(
    (newSearchText: string) => {
      setLoaderFlag(true);
      resetAnalysisSongs();
      setSearchText(newSearchText);
      window?.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    [resetAnalysisSongs]
  );

  const handleSongClick = useCallback(
    (clickedSong: any) => {
      !selectedSong || (selectedSong && selectedSong.id !== clickedSong.id)
        ? setSelectedSong(clickedSong)
        : setSelectedSong(undefined);
    },
    [selectedSong]
  );

  const handlePeriodSelection = useCallback(() => {
    setSelectedSong(undefined);
  }, []);

  const fetchMoreData = useCallback(() => {
    setAnalysisSongStart(
      () => (bottomTargetPageCount - 1) * pubAnalysisSongPageState.count
    );
    setLoaderFlag(false);
  }, [pubAnalysisSongPageState.count, bottomTargetPageCount]);

  useEffect(() => {
    return () => {
      abortDispatchedAnalysisSong();
      abortDispatchedAnalysisMoreSong();
      abortDispatchedDownload();
      // clear analysis song page state (keep)
      dispatch(
        updateAnalysisSongPageStateAction(PubAnalysisSongPageInitialState)
      );
    };
  }, [
    abortDispatchedAnalysisSong,
    abortDispatchedAnalysisMoreSong,
    abortDispatchedDownload,
    dispatch,
  ]);

  useEffect(() => {
    const hasMore = songs?.data?.songDescriptions?.length < songs?.total;
    if (hasMore && bottomTargetPageCount > 1) {
      fetchMoreData();
    }
  }, [
    fetchMoreData,
    bottomTargetPageCount,
    songs?.data?.songDescriptions?.length,
    songs?.total,
  ]);

  useEffect(() => {
    if (
      !authState?.isAuthenticated ||
      clientSelection.selectedClients?.length === 0
    ) {
      return;
    }
    const pubAnalysisSongState = {
      ...pubAnalysisSongPageState,
      data: {
        periodIds: pubAnalysisHeaderState.selectedPeriods,
        clientIds: clientSelection?.selectedClients?.map((c) => c.id) || [],
        clientLists: [],
      },
      start: analysisSongStart,
      sortColumn: analysisSongSortColumn,
      filterText: searchText,
      isAsc: analysisSongAscOrder,
    };
    if (!deepCompare(pubAnalysisSongState, pubAnalysisSongPageState)) {
      dispatch(updateAnalysisSongPageStateAction(pubAnalysisSongState));
      handleAnalysisSongsFetch(pubAnalysisSongState);
      clearAnalysisSongsBreakdown(pubAnalysisSongState);
      setSelectedSong(undefined);
    }
    if (
      !deepCompare(
        //ignore start value in deep compare
        { ...pubAnalysisSongState, start: null },
        { ...pubAnalysisSongPageState, start: null }
      )
    ) {
      setAnalysisSongStart(0);
      setBottomTargetPageCount(1);
    }
  }, [
    analysisSongAscOrder,
    analysisSongSortColumn,
    analysisSongStart,
    authState?.isAuthenticated,
    clearAnalysisSongsBreakdown,
    clientSelection.selectedClients,
    dispatch,
    handleAnalysisSongsFetch,
    pubAnalysisHeaderState.selectedPeriods,
    pubAnalysisSongPageState,
    searchText,
    setBottomTargetPageCount,
  ]);

  useEffect(() => {
    const prevPageCount = analysisSongStart / pubAnalysisSongPageState.count;

    if (prevPageCount > bottomTargetPageCount)
      setBottomTargetPageCount(prevPageCount + 1);
  }, [
    analysisSongStart,
    bottomTargetPageCount,
    pubAnalysisSongPageState.count,
    setBottomTargetPageCount,
  ]);

  useEffect(() => {
    if (songs?.data?.songDescriptions?.length) {
      setIsSortingLoading(false);
    }
  }, [songs]);

  useEffect(() => {
    if (!isSortingLoading) {
      setTotalAmount({
        currency: songs?.data?.totalRoyalty?.currency,
        value: songs?.data?.totalRoyalty?.formattedLong,
      });
    }
  }, [songs, isSortingLoading]);

  const handleDownloadBtn = useCallback(() => {
    const params = {
      data: {
        clientIds: clientSelection?.selectedClients?.map((c) => c.id) || [],
        periodIds: pubAnalysisHeaderState?.selectedPeriods,
        analyzeBy: "BY_SONGS",
      },
      fileName: `${t("analysis.type.royalties")}_${t(
        "analysis.type.bySongs"
      )}_${getToday()}-${getNow()}.xlsx`,
    };
    abortDispatchedDownload();
    dispatchedDownload.current = dispatch(pubAnalysisSongsDownload(params));
  }, [
    abortDispatchedDownload,
    clientSelection.selectedClients,
    dispatch,
    pubAnalysisHeaderState?.selectedPeriods,
    t,
  ]);

  const filterAnalysisSongsWith = useCallback(
    (columnName: string) => {
      setIsSortingLoading(true);
      resetAnalysisSongs();
      setLoaderFlag(true);
      setAnalysisSongSortColumn(columnName);
      if (pubAnalysisSongPageState.sortColumn !== columnName) {
        setAnalysisSongAscOrder(true);
      } else {
        setAnalysisSongAscOrder(!analysisSongAscOrder);
      }
    },
    [
      analysisSongAscOrder,
      pubAnalysisSongPageState.sortColumn,
      resetAnalysisSongs,
    ]
  );

  return (
    <Grid>
      {(pubAnalysisSongStatus === "loading" ||
        pubAnalysisDownloadStatus === "loading") &&
        loaderFlag && <Loader />}
      <div className={styles.analysis}>
        <PubAnalysisHeader
          documentType="PUBLISHING"
          tab="songs"
          handlePeriodSelection={handlePeriodSelection}
          onDownloadBtnClick={handleDownloadBtn}
          isDownloadBtnDisabled={songs?.total === 0 ? true : false}
        />
        <div ref={observerTopTarget} id="observerTopTargetId" />
        {pubAnalysisNoDataFlag !== undefined && (
          <div
            id="tab-header"
            className={`sticky top-0 z-[5] ${styles.tabHeader} ${
              topTargetIsOnScreen === false && bottomTargetPageCount > 0
                ? "shadow-lg"
                : ""
            }`}
          >
            <div className={styles.title}>
              <p className={styles.period}>
                {
                  pubAnalysisHeaderState.tabSelectedPeriodTitle[
                    pubAnalysisHeaderState.selectedPeriodText?.titleVariant
                  ]
                }{" "}
                <span>{pubAnalysisHeaderState.selectedPeriodText.details}</span>
              </p>
              {(!!songs?.data?.songDescriptions?.length ||
                isSortingLoading) && (
                <p className={styles.totalAmount}>
                  {totalAmount.currency}
                  <strong>{` ${totalAmount.value}`}</strong>
                </p>
              )}
            </div>
            <div className={styles.actions}>
              <Search
                placeholderText={t("analysis.songs.searchTextPlaceholder")}
                searchText={pubAnalysisSongPageState.filterText}
                resultsCount={songs?.total}
                onSearchChange={handleSearch}
                showBackToTop={!topTargetIsOnScreen}
                onBackToTopClick={windowScrollToTop}
                status={pubAnalysisSongStatus}
                className={styles.search}
              />
            </div>
            {(!!songs?.data?.songDescriptions?.length || isSortingLoading) && (
              <p className={styles.info}>
                {t("analysis.songs.selectSongToGetDetails")}
              </p>
            )}
          </div>
        )}
        {pubAnalysisNoDataFlag && pubAnalysisNoDataFlag?.length && (
          <div className="pl-10 text-xs sm:text-sm">
            {t(`analysis.${pubAnalysisNoDataFlag}`)}
          </div>
        )}
        {pubAnalysisNoDataFlag === "" && (
          <div className={styles.analysisSong}>
            <div
              className={`${styles.contentContainer} ${
                selectedSong ? styles.rightSideOpen : ""
              }`}
            >
              <div
                className={`${styles.leftSide} ${
                  selectedSong ? styles.withSelection : ""
                }`}
              >
                <AnalysisSongLeftSide
                  bottomTargetPageCount={bottomTargetPageCount}
                  selectedClients={clientSelection.selectedClients}
                  filterAnalysisSongsWith={filterAnalysisSongsWith}
                  handleSongClick={handleSongClick}
                  selectedPeriods={pubAnalysisHeaderState?.selectedPeriods}
                  selectedSong={selectedSong}
                  songs={songs}
                  windowWidth={windowWidth}
                />
              </div>
              {selectedSong && windowWidth && windowWidth >= 1200 && (
                <AnalysisSongRightSide
                  selectedSong={selectedSong}
                  topTargetIsOnScreen={topTargetIsOnScreen}
                  selectedPeriods={pubAnalysisHeaderState?.selectedPeriods}
                  selectedClients={clientSelection.selectedClients.map(
                    (c) => c.id
                  )}
                  handleBreakdownClose={handleSongClick}
                />
              )}
            </div>
          </div>
        )}
        <div
          className={`${
            songs?.data?.songDescriptions?.length === songs?.total
              ? "hidden"
              : ""
          }`}
          ref={observerBottomTarget}
        ></div>
      </div>
    </Grid>
  );
};

export default PubAnalysisSong;
