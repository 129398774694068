import {
  RecSyncTabStatusEnum,
  RecSyncTabsEnum,
} from "../../../app/types/enums";
import withClientSelectionData from "../clientSelection/withClientSelectionData";
import RecSyncHOC from "./RecSyncHOC";

const RecSyncQuoting = (): JSX.Element => (
  <RecSyncHOC
    status={RecSyncTabStatusEnum.quoting}
    selectedTab={RecSyncTabsEnum.quoting}
  />
);

export default withClientSelectionData(RecSyncQuoting);
