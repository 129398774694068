import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { downloadAnalysisData, fetchAnalysisType } from "./pubAnalysisAPI";
import { RootState } from "../../../app/redux/store";

export type TabTypeAnalyzeByMainIncomeTypesEntry = {
  categoryName: string;
  color: string;
  mainIncType: string;
  revenue: {
    currency: string;
    formattedLong: string;
    formattedPercent: string;
    formattedShort: string;
    percent: number;
    raw: number;
  };
};

export type TabTypeIncomeTypeEntry = {
  category: string;
  code: number;
  currency: string;
  mainIncType: string;
  name: string;
  raw: number;
  royalties: string;
};

export type TabTypeChartData = {
  data: TabTypeAnalyzeByMainIncomeTypesEntry[];
  total: {
    currency: string;
    formattedLong: string;
    formattedPercent: string;
    formattedShort: string;
    percent: number;
    raw: number;
  };
};

export type TabTypeAnalyzeIncomeTypes = {
  color: string;
  order: number;
  categoryName: string;
  mainIncType: string;
  revenue: {
    currency: string;
    formattedLong: string;
    formattedPercent: string;
    formattedShort: string;
    percent: number;
    raw: number;
  };
};

export interface AnalysisState {
  tabType: {
    pageState: {
      data: {
        clientIds: any;
        clientLists: any;
        periodIds: any;
      };
    };
    chartStatus: "noData" | "loading" | "idle" | "failed";
    chartData: TabTypeChartData | null;
    detailsData: TabTypeIncomeTypeEntry[];
    detailsStatus: "noData" | "loading" | "idle" | "failed";
    downloadStatus: "loading" | "idle" | "failed";
  };
}

const initialState: AnalysisState = {
  tabType: {
    pageState: {
      data: {
        clientIds: [],
        clientLists: [],
        periodIds: [],
      },
    },
    chartStatus: "idle",
    chartData: null,
    detailsData: [],
    detailsStatus: "idle",
    downloadStatus: "idle",
  },
};

// todo: frank
export const fetchPubAnalysisTypeThunk = createAsyncThunk(
  "pubAnalysis/type",
  async (payload: any, thunkAPI) => {
    const response = await fetchAnalysisType(payload, thunkAPI);
    return response;
  }
);

export const pubAnalysisTypeDownload = createAsyncThunk(
  "pubAnalysis/downloadType",
  async (payload: any, thunkAPI) => {
    const response = await downloadAnalysisData(payload, thunkAPI);
    return response;
  }
);

export const pubAnalysisSlice = createSlice({
  name: "pubAnalysis",
  initialState,
  reducers: {
    updateAnalysisTypePageState: (state: AnalysisState, action: any) => {
      state.tabType.pageState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPubAnalysisTypeThunk.fulfilled, (state, action: any) => {
        state.tabType.chartStatus = "idle";
        state.tabType.chartData = {
          data: action.payload.analyzeByMainIncomeTypes,
          total: action.payload.total,
        };
        state.tabType.detailsData = action.payload.incomeTypes;
      })
      .addCase(fetchPubAnalysisTypeThunk.pending, (state) => {
        state.tabType.chartStatus = "loading";
      })
      .addCase(fetchPubAnalysisTypeThunk.rejected, (state) => {
        state.tabType.chartStatus = "failed";
      })
      .addCase(pubAnalysisTypeDownload.fulfilled, (state, action: any) => {
        state.tabType.downloadStatus = "idle";
      })
      .addCase(pubAnalysisTypeDownload.pending, (state) => {
        state.tabType.downloadStatus = "loading";
      })
      .addCase(pubAnalysisTypeDownload.rejected, (state) => {
        state.tabType.downloadStatus = "failed";
      });
  },
});

export const pubAnalysisTypeSelector = (state: RootState) =>
  state.pubAnalysis.tabType;
export const {
  updateAnalysisTypePageState: updateAnalysisTypePageStateAction,
} = pubAnalysisSlice.actions;

export default pubAnalysisSlice.reducer;
