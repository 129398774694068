import React from "react";

export interface GridProps {
  children?: React.ReactNode;
  className?: string;
}

const Grid: React.FunctionComponent<GridProps> = ({
  children,
  className = "",
}: GridProps) => (
  <div
    className={`grid w-full grid-cols-4 gap-x-5 sm:grid-cols-12 ${className}`}
  >
    {children}
  </div>
);

export default Grid;
