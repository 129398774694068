import {
  AdminSidebarIcon,
  ClientsSidebarIcon,
  ContactBigSidebarIcon,
  DashboardSidebarIcon,
  FinancialSidebarIcon,
  LogoutSidebarIcon,
  OverviewSidebarIcon,
  RecJournalSidebarIcon,
  SettingSidebarIcon,
  StatementsSidebarIcon,
  SyncSidebarIcon,
  NewsSidebarIcon,
  MailSidebarIcon,
  CalendarSidebarIcon,
  PhoneSidebarIcon,
  RegistrationSidebarIcon,
} from "./sidebarAdminIcons";
import { ROUTES } from "../routes/routes.const";

export const menus = [
  {
    name: "MyBMG",
    i18nKey: "MYBMG",
    link: `/${ROUTES.mybmg}/${ROUTES.user}`,
    icon: DashboardSidebarIcon,
    divider: true,
    isReadOnlyAdmin: true,
    isSuperAdmin: false,
  },
  // {
  //   name: "Legacy Site",
  //   i18nKey: "LEGACY_SITE",
  //   link: `${process.env.REACT_APP_LEGACY_HOST}/${ROUTES.mybmg}/${ROUTES.login}`,
  //   icon: DashboardSidebarIcon,
  //   isReadOnlyAdmin: true,
  //   isSuperAdmin: false,
  // },
  {
    name: "Pending Requests",
    i18nKey: "PENDING_REQUESTS",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.pending}`,
    icon: RecJournalSidebarIcon,
    divider: true,
    isReadOnlyAdmin: false,
    isSuperAdmin: false,
  },
  {
    name: "Request archive",
    i18nKey: "REQUESTS_ARCHIVE",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.archive}`,
    icon: FinancialSidebarIcon,
    isReadOnlyAdmin: false,
    isSuperAdmin: false,
  },
  {
    name: "Users",
    i18nKey: "USERS",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.users}`,
    icon: ClientsSidebarIcon,
    divider: true,
    isReadOnlyAdmin: true,
    isSuperAdmin: false,
  },
  {
    name: "Clients",
    i18nKey: "CLIENTS",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.clients}`,
    icon: ContactBigSidebarIcon,
    isReadOnlyAdmin: true,
    isSuperAdmin: false,
  },
  {
    name: "Groups",
    i18nKey: "GROUPS",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.usergroups}`,
    icon: ClientsSidebarIcon,
    isReadOnlyAdmin: false,
    isSuperAdmin: false,
  },
  {
    name: "Parents",
    i18nKey: "PARENTS",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.parents}`,
    icon: OverviewSidebarIcon,
    isReadOnlyAdmin: false,
    isSuperAdmin: false,
  },
  {
    name: "Admins",
    i18nKey: "ADMINS",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.admins}`,
    icon: AdminSidebarIcon,
    isReadOnlyAdmin: false,
    isSuperAdmin: true,
  },
  {
    name: "Admin groups",
    i18nKey: "ADMIN_GROUPS",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.admingroups}`,
    icon: ClientsSidebarIcon,
    isReadOnlyAdmin: false,
    isSuperAdmin: true,
  },
  {
    name: "News",
    i18nKey: "NEWS",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.news}`,
    icon: NewsSidebarIcon,
    isReadOnlyAdmin: false,
    isSuperAdmin: false,
    divider: true,
  },
  {
    name: "Activity log",
    i18nKey: "ACTIVITY_LOG",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.activitylog}`,
    icon: StatementsSidebarIcon,
    isReadOnlyAdmin: false,
    isSuperAdmin: false,
  },
  {
    name: "System Closing Dates",
    i18nKey: "SYS_CLOSING",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.duedates}`,
    icon: CalendarSidebarIcon,
    isReadOnlyAdmin: false,
    isSuperAdmin: false,
  },
  {
    name: "Email Footers",
    i18nKey: "EMAIL_FOOTERS",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.footers}`,
    icon: MailSidebarIcon,
    isReadOnlyAdmin: false,
    isSuperAdmin: false,
  },
  {
    name: "Client Communication",
    i18nKey: "CLIENT_COMM",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.communication}`,
    icon: PhoneSidebarIcon,
    isReadOnlyAdmin: false,
    isSuperAdmin: false,
  },
  {
    name: "Advance Request",
    i18nKey: "ADV_REQUEST",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.advancerequest}`,
    icon: FinancialSidebarIcon,
    isReadOnlyAdmin: false,
    isSuperAdmin: true,
  },
  {
    name: "Elasticsearch",
    i18nKey: "ELASTICSEARCH",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.elasticsearch}`,
    icon: RegistrationSidebarIcon,
    isReadOnlyAdmin: false,
    isSuperAdmin: true,
  },
  {
    name: "Misc",
    link: `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.misc}`,
    i18nKey: "MISC",
    icon: SettingSidebarIcon,
    isReadOnlyAdmin: false,
    isSuperAdmin: true,
  },
  {
    name: "LOGOUT",
    i18nKey: "MORE_LOGOUT_LINK",
    more: true,
    icon: LogoutSidebarIcon,
    action: "logOut",
    moreDivider: true,
    isReadOnlyAdmin: true,
    isSuperAdmin: false,
  },
];
