import { USER_API_RESOURCE_BASE_URLS } from "../../../../app/config/userEndpoints.const";
import request from "../../../../app/utils/requestUtils";

export function fetchTaxDocuments(params: any, thunkAPI?: any) {
  const { data } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.publishingCreditnotes}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
  });
}

export function fetchTaxDocumentsFiles(params: any, thunkAPI?: any) {
  const { referenceId, fileName } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.creditnoteFiles}?referenceId=${referenceId}`,
    {
      method: "GET",
      signal: thunkAPI?.signal,
      isDownload: true,
      fileName,
    }
  );
}
