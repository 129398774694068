import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export function fetchOverview(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.overview}`, {
    method: "POST",
    body: JSON.stringify(params.data),
    signal: thunkAPI?.signal,
  });
}

export function fetchOverviewDetail(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.balanceDetail}`, {
    method: "POST",
    body: JSON.stringify(params.data),
    signal: thunkAPI?.signal,
  });
}

export function downloadOverviewData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.overviewDownload}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
    isDownload: true,
    fileName,
  });
}
