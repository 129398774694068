import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAnalysisTerritories } from "../pubAnalysisAPI";
import { RootState } from "../../../../app/redux/store";
import { downloadAnalysisData } from "../pubAnalysisAPI";

type PubAnalysisTerritoryTotalRoyalty = {
  currency: string;
  formattedLong: string;
  formattedPercent: string;
  formattedShort: string;
  percent: number;
  raw: number;
};

type PubAnalysisTerritoryTerritoriesIncomeType = {
  mainIncType: string;
  totalRoyalties: number;
  revenue: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
  color: string;
  categoryName: string;
};

export type PubAnalysisTerritoryTerritories = {
  incomeTypes: PubAnalysisTerritoryTerritoriesIncomeType[];
  iso: string;
  name?: string;
  revenue: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
  zero: false;
};

export interface PubAnalysisTerritoryState {
  analysisTerritoryPageState: {
    data: {
      periodIds: Array<number>;
      clientIds: any;
      clientLists: any;
    };
  };
  territories: {
    totalRoyalty: PubAnalysisTerritoryTotalRoyalty | undefined;
    total: PubAnalysisTerritoryTerritories | undefined;
    territories: PubAnalysisTerritoryTerritories[];
  };
  analysisTerritoryStatus: "init" | "idle" | "loading" | "failed";
  downloadStatus: "loading" | "idle" | "failed";
}

const initialState: PubAnalysisTerritoryState = {
  analysisTerritoryPageState: {
    data: {
      periodIds: [],
      clientIds: [],
      clientLists: [],
    },
  },
  territories: {
    total: undefined,
    territories: [],
    totalRoyalty: undefined,
  },
  analysisTerritoryStatus: "init",
  downloadStatus: "idle",
};

export const fetchAnalysisTerritoriesThunk = createAsyncThunk(
  "publishing/analysisTerritories",
  async (payload: any, thunkAPI: any) => {
    const response = await fetchAnalysisTerritories(payload, thunkAPI);
    return response;
  }
);

export const pubAnalysisTerritoryDownload = createAsyncThunk(
  "pubAnalysis/downloadTerritory",
  async (payload: any, thunkAPI) => {
    const response = await downloadAnalysisData(payload, thunkAPI);
    return response;
  }
);

export const pubAnalysisTerritoriesSlice = createSlice({
  name: "pubAnalysisTerritory",
  initialState,
  reducers: {
    updateAnalysisTerritoryPageState: (state: any, action: any) => {
      state.analysisTerritoryPageState.data.periodIds =
        action.payload.data.periodIds;
      state.analysisTerritoryPageState.data.clientIds =
        action.payload.data.clientIds;
      state.analysisTerritoryPageState.data.clientLists =
        action.payload.data.clientLists;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAnalysisTerritoriesThunk.fulfilled,
        (state: any, action: any) => {
          state.analysisTerritoryStatus = "idle";
          state.territories.total = action.payload?.total;
          state.territories.territories = action.payload?.territories;
        }
      )
      // download
      .addCase(pubAnalysisTerritoryDownload.fulfilled, (state) => {
        state.downloadStatus = "idle";
      })
      .addCase(pubAnalysisTerritoryDownload.pending, (state) => {
        state.downloadStatus = "loading";
      })
      .addCase(pubAnalysisTerritoryDownload.rejected, (state) => {
        state.downloadStatus = "failed";
      })
      .addCase(fetchAnalysisTerritoriesThunk.pending, (state) => {
        state.analysisTerritoryStatus = "loading";
      })
      .addCase(fetchAnalysisTerritoriesThunk.rejected, (state) => {
        state.analysisTerritoryStatus = "failed";
      });
  },
});

export const pubAnalysisTerritoryPageStateSelector = (state: RootState) =>
  state.pubAnalysisTerritory.analysisTerritoryPageState;
export const pubAnalysisTerritoriesSelector = (state: RootState) =>
  state.pubAnalysisTerritory.territories;
export const pubAnalysisTerritoryStatusSelector = (state: RootState) =>
  state.pubAnalysisTerritory.analysisTerritoryStatus;
export const pubAnalysisDownloadStatusSelector = (state: RootState) =>
  state.pubAnalysisTerritory.downloadStatus;

export const {
  updateAnalysisTerritoryPageState: updateAnalysisTerritoryPageStateAction,
} = pubAnalysisTerritoriesSlice.actions;

export default pubAnalysisTerritoriesSlice.reducer;
