import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../../../app/redux/store";
import { CommonRequestBodyTableState } from "../../../../../../app/types/props";
import {
  GroupType,
  SortDirectionEnum,
  StatusEnum,
} from "../../../../../../app/types/enums";

import { useFetch } from "../../../../common/hooks/useFetch";
import { DEFAULT_ROWS_PER_PAGE } from "../../../../common/adminEndpoints.const";

import { tableHeadData } from "./NewsGroups.const";

import { getGroups } from "../../../Groups/GroupsAPI";

export type NewsGroupListItem = {
  id: number;
  name: string;
  description: string;
  groupType: {
    id: number;
    groupType: GroupType;
  };
};

export type NewsGroupsInitialState = {
  newsGroupsPageState: CommonRequestBodyTableState;
  tableData: {
    list: NewsGroupListItem[] | null;
    count: number | null;
    totalCount: number | null;
  };
  status: StatusEnum;
};

const initialState: NewsGroupsInitialState = {
  newsGroupsPageState: {
    filterText: "",
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn: tableHeadData?.[0].filteringInfo,
          direction: SortDirectionEnum.Ascending,
        },
      ],
    },
  },
  tableData: {
    list: null,
    count: 0,
    totalCount: 0,
  },
  status: StatusEnum.Idle,
};

export const getNewsGroupsThunk = createAsyncThunk(
  "admin/news/groups",
  async (payload: any, { rejectWithValue }) => {
    try {
      const fetchGroups = await useFetch();
      const response = await fetchGroups({
        data: payload,
        fetchFunction: getGroups,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const newsGroupsSlice = createSlice({
  name: "newsGrops",
  initialState,
  reducers: {
    updatePageState: (state, action: any) => {
      state.newsGroupsPageState.filterText = action.payload.filterText;
      state.newsGroupsPageState.pagination = action.payload.pagination;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsGroupsThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableData.list = action.payload.data;
        state.tableData.totalCount = action.payload.totalCount;
        state.tableData.count = action.payload.count;
      })
      .addCase(getNewsGroupsThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getNewsGroupsThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
        state.tableData.list = [];
        state.tableData.totalCount = null;
        state.tableData.count = null;
      });
  },
});

export const NewsGroupsSelector = (state: RootState) => state.newsGroups;
export default newsGroupsSlice.reducer;
