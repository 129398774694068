import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export const fetchMessages = (thunkAPI?: any) =>
  request(`${USER_API_RESOURCE_BASE_URLS.news}?type=WEB`, {
    method: "GET",
    signal: thunkAPI?.signal,
  });

export const softDeleteMessage = (params: any, thunkAPI?: any) =>
  request(`${USER_API_RESOURCE_BASE_URLS.news}/${params}`, {
    method: "DELETE",
    signal: thunkAPI?.signal,
  });

export const markMessageAsRead = (params: any, thunkAPI?: any) =>
  request(`${USER_API_RESOURCE_BASE_URLS.news}/${params}/read`, {
    method: "PUT",
    signal: thunkAPI?.signal,
  });
