import request from "../../../app/utils/requestUtils";
import type { ForgotPasswordPayload } from "../../../app/types/payload";
import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";

export function fetchSendForgotPasswordPayload(
  payload: ForgotPasswordPayload,
  thunkAPI?: any
) {
  return request(`${USER_API_RESOURCE_BASE_URLS.usersForgotPassword}`, {
    method: "PUT",
    body: JSON.stringify(payload),
    signal: thunkAPI?.signal,
  });
}
