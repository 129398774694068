import {
  mechanicalTableHeaderData,
  publishingTableHeaderData,
  recordingTableHeaderData,
} from "./ClientCommunication.const";
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import { SortDirectionEnum } from "../../../../app/types/enums";
import { SortingCriteria } from "../../../../app/types/props";
import { DEFAULT_ROWS_PER_PAGE } from "../../common/adminEndpoints.const";
import {
  createClientCommunication,
  createClientCommunicationPreview,
  getCompanyCodes,
  getMechanicalTableData,
  getOwningSite,
  getOwningSystem,
  getPublishingTableData,
  getRecordingTableData,
  getEmailFooter,
} from "./ClientCommunicationAPI";
import { useFetch } from "../../common/hooks/useFetch";

export type ClientCommunicationPublishingTablePageState = {
  pagination: {
    page: number;
    size: number;
    sortingCriteria: SortingCriteria[];
  };
  filterText: string;
  searchCriteria: {
    system: string;
    companyCodes?: string[];
    paymentCycles: string[];
    paymentDays: string[];
  };
};

export type ClientCommunicationRnMTablePageState = {
  pagination: {
    page: number;
    size: number;
    sortingCriteria: SortingCriteria[];
  };
  filterText: string;
  searchCriteria: {
    site: string;
    paymentCycles: string[];
    paymentDays: string[];
  };
};

export type ClientCommunicationPageState = {
  pagination: {
    page: number;
    size: number;
    sortingCriteria: SortingCriteria[];
  };
  searchCriteria: {
    site: string;
    paymentCycles: string[];
    paymentDays: string[];
  };
};

type tableDataType = {
  totalCount: number;
  count: number;
  data: [];
};

export interface clientCommunicationState {
  isLoading: boolean;
  type: string;
  allSystems: boolean;
  allMechanicalSites: boolean;
  allRecordingSites: boolean;
  companyCodes: [];
  owningSystem: [];
  owningSite: [];
  publishingTable: tableDataType;
  recordingTable: tableDataType;
  mechanicalTable: tableDataType;
  tableData: tableDataType;
  clientCommunicationPageState: ClientCommunicationPageState;
  clientCommunicationPublishingTablePageState: ClientCommunicationPublishingTablePageState;
  clientCommunicationRecordingTablePageState: ClientCommunicationRnMTablePageState;
  clientCommunicationMechanicalTablePageState: ClientCommunicationRnMTablePageState;
  excludedClientsPublishingTable: number[];
  excludedClientsRecordingTable: number[];
  excludedClientsMechanicalTable: number[];
}

const initialState: clientCommunicationState = {
  isLoading: false,
  type: "",
  allSystems: false,
  allMechanicalSites: false,
  allRecordingSites: false,
  companyCodes: [],
  owningSystem: [],
  owningSite: [],
  excludedClientsPublishingTable: [],
  excludedClientsRecordingTable: [],
  excludedClientsMechanicalTable: [],
  tableData: {} as tableDataType,
  publishingTable: {} as tableDataType,
  recordingTable: {} as tableDataType,
  mechanicalTable: {} as tableDataType,
  clientCommunicationPageState: {
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn: publishingTableHeaderData?.[0].filteringInfo,
          direction: SortDirectionEnum.Ascending,
        },
      ],
    },
    searchCriteria: {
      site: "",
      paymentCycles: [""],
      paymentDays: [""],
    },
  },
  clientCommunicationPublishingTablePageState: {
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn: publishingTableHeaderData?.[1].filteringInfo,
          direction: SortDirectionEnum.Descending,
        },
      ],
    },
    filterText: "",
    searchCriteria: {
      system: "",
      companyCodes: [""],
      paymentCycles: [""],
      paymentDays: [""],
    },
  },
  clientCommunicationRecordingTablePageState: {
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn: recordingTableHeaderData[2]?.filteringInfo,
          direction: SortDirectionEnum.Ascending,
        },
      ],
    },
    filterText: "",
    searchCriteria: {
      site: "",
      paymentCycles: [""],
      paymentDays: [""],
    },
  },
  clientCommunicationMechanicalTablePageState: {
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn: mechanicalTableHeaderData?.[2].filteringInfo,
          direction: SortDirectionEnum.Ascending,
        },
      ],
    },
    filterText: "",
    searchCriteria: {
      site: "",
      paymentCycles: [""],
      paymentDays: [""],
    },
  },
};

export const createClientCommunicationThunk = createAsyncThunk(
  "admin/client-communication",
  async (params: any, { rejectWithValue }) => {
    try {
      const _createClientCommunication = await useFetch();
      const response = await _createClientCommunication({
        data: params,
        fetchFunction: createClientCommunication,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createClientCommunicationPreviewThunk = createAsyncThunk(
  "admin/client-communication-preview",
  async (params: any, { rejectWithValue }) => {
    try {
      const _createClientCommunicationPreview = await useFetch();
      const response = await _createClientCommunicationPreview({
        data: params,
        fetchFunction: createClientCommunicationPreview,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPublishingTableDataThunk = createAsyncThunk(
  "admin/client-communication-publishing",
  async (params: any, { rejectWithValue }) => {
    try {
      const _getPublishingTableData = await useFetch();
      const response = await _getPublishingTableData({
        data: params,
        fetchFunction: getPublishingTableData,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRecordingTableDataThunk = createAsyncThunk(
  "admin/client-communication-recording",
  async (params: any, { rejectWithValue }) => {
    try {
      const _getRecordingTableData = await useFetch();
      const response = await _getRecordingTableData({
        data: params,
        fetchFunction: getRecordingTableData,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMechanicalTableDataThunk = createAsyncThunk(
  "admin/client-communication-mechanical",
  async (params: any, { rejectWithValue }) => {
    try {
      const _getMechanicalTableData = await useFetch();
      const response = await _getMechanicalTableData({
        data: params,
        fetchFunction: getMechanicalTableData,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCompanyCodesThunk = createAsyncThunk(
  "admin/company-codes",
  async (params: any, { rejectWithValue }) => {
    try {
      const _getCompanyCodes = await useFetch();
      const response = await _getCompanyCodes({
        data: params,
        fetchFunction: getCompanyCodes,
      });
      return response.resArr;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOwningSystemThunk = createAsyncThunk(
  "admin/owning-system",
  async (params: any, { rejectWithValue }) => {
    try {
      const _getOwningSystem = await useFetch();
      const response = await _getOwningSystem({
        data: params,
        fetchFunction: getOwningSystem,
      });
      return response.resArr;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOwningSiteThunk = createAsyncThunk(
  "admin/owning-site",
  async (_, { rejectWithValue }) => {
    try {
      const _getOwningSite = await useFetch();
      const response = await _getOwningSite({
        fetchFunction: getOwningSite,
      });
      return response.resArr;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getEmailFooterThunk = createAsyncThunk(
  "admin/client-communication-footer",
  async (payload: any, { rejectWithValue }) => {
    try {
      const getFooters = await useFetch();
      const response = await getFooters({
        fetchFunction: getEmailFooter,
        data: payload,
      });

      return response?.footer;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientCommunicationSlice = createSlice({
  name: "clientCommunication",
  initialState,
  reducers: {
    updatePublishingTablePageState: (state, action: any) => {
      state.clientCommunicationPublishingTablePageState.searchCriteria =
        action.payload.searchCriteria;
      state.clientCommunicationPublishingTablePageState.pagination =
        action.payload.pagination;
    },
    updateRecordingTablePageState: (state, action: any) => {
      state.clientCommunicationRecordingTablePageState.searchCriteria =
        action.payload.searchCriteria;
      state.clientCommunicationRecordingTablePageState.pagination =
        action.payload.pagination;
    },
    updateMechanicalTablePageState: (state, action: any) => {
      state.clientCommunicationMechanicalTablePageState.searchCriteria =
        action.payload.searchCriteria;
      state.clientCommunicationMechanicalTablePageState.pagination =
        action.payload.pagination;
    },
    updateExcludedClientsPublishingTable: (state, action: any) => {
      state.excludedClientsPublishingTable = action.payload;
    },
    updateExcludedClientsRecordingTable: (state, action: any) => {
      state.excludedClientsRecordingTable = action.payload;
    },
    updateExcludedClientsMechanicalTable: (state, action: any) => {
      state.excludedClientsMechanicalTable = action.payload;
    },
    updateAllSystems: (state, action: any) => {
      state.allSystems = action.payload;
    },
    updateAllMechanicalSites: (state, action: any) => {
      state.allMechanicalSites = action.payload;
    },
    updateAllRecordingSites: (state, action: any) => {
      state.allRecordingSites = action.payload;
    },
    updateType: (state, action: any) => {
      state.type = action.payload;
    },
    resetAllData: () => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(getOwningSystemThunk.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.owningSystem = action.payload;
      })
      .addCase(getOwningSystemThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOwningSystemThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getOwningSiteThunk.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.owningSite = action.payload;
      })
      .addCase(getOwningSiteThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOwningSiteThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCompanyCodesThunk.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.companyCodes = action.payload;
      })
      .addCase(getCompanyCodesThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanyCodesThunk.rejected, (state) => {
        state.isLoading = false;
      })
      // Get publishing table data
      .addCase(getPublishingTableDataThunk.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.publishingTable = action.payload;
      })
      .addCase(getPublishingTableDataThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPublishingTableDataThunk.rejected, (state) => {
        state.isLoading = false;
      })
      // Get recording table data
      .addCase(getRecordingTableDataThunk.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.recordingTable = action.payload;
      })
      .addCase(getRecordingTableDataThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRecordingTableDataThunk.rejected, (state) => {
        state.isLoading = false;
      })
      // Get mechanical table data
      .addCase(getMechanicalTableDataThunk.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.mechanicalTable = action.payload;
      })
      .addCase(getMechanicalTableDataThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMechanicalTableDataThunk.rejected, (state) => {
        state.isLoading = false;
      })
      // Get email Footer
      .addCase(getEmailFooterThunk.fulfilled, (state, action: any) => {
        state.isLoading = false;
      })
      .addCase(getEmailFooterThunk.pending, (state, action: any) => {
        state.isLoading = true;
      })
      .addCase(getEmailFooterThunk.rejected, (state, action: any) => {
        state.isLoading = false;
      })
      .addMatcher(
        isAnyOf(
          createClientCommunicationThunk.fulfilled,
          createClientCommunicationPreviewThunk.fulfilled
        ),
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          createClientCommunicationThunk.rejected,
          createClientCommunicationPreviewThunk.rejected
        ),
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          createClientCommunicationThunk.pending,
          createClientCommunicationPreviewThunk.pending
        ),
        (state) => {
          state.isLoading = true;
        }
      );
  },
});

export const clientCommunicationSelector = (state: RootState) => ({
  isLoading: state.clientCommunication.isLoading,
  type: state.clientCommunication.type,
  allSystems: state.clientCommunication.allSystems,
  allMechanicalSites: state.clientCommunication.allMechanicalSites,
  allRecordingSites: state.clientCommunication.allRecordingSites,
  owningSystem: state.clientCommunication.owningSystem,
  owningSite: state.clientCommunication.owningSite,
  companyCodes: state.clientCommunication.companyCodes,
  publishingTable: state.clientCommunication.publishingTable,
  recordingTable: state.clientCommunication.recordingTable,
  mechanicalTable: state.clientCommunication.mechanicalTable,
  excludedClientsPublishingTable:
    state.clientCommunication.excludedClientsPublishingTable,
  excludedClientsRecordingTable:
    state.clientCommunication.excludedClientsRecordingTable,
  excludedClientsMechanicalTable:
    state.clientCommunication.excludedClientsMechanicalTable,
  clientCommunicationPageState:
    state.clientCommunication.clientCommunicationPageState,
  clientCommunicationPublishingTablePageState:
    state.clientCommunication.clientCommunicationPublishingTablePageState,
  clientCommunicationRecordingTablePageState:
    state.clientCommunication.clientCommunicationRecordingTablePageState,
  clientCommunicationMechanicalTablePageState:
    state.clientCommunication.clientCommunicationMechanicalTablePageState,
});
export default clientCommunicationSlice.reducer;
