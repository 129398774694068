export declare type ImageProps = {
  src: string;
  srcSet?: string;
  alt?: string;
  className?: string;
};

export default function Image(props: ImageProps): JSX.Element {
  const { src, alt, className = "", srcSet } = props;
  return (
    <>
      {src && <img src={src} alt={alt} className={className} srcSet={srcSet} />}
    </>
  );
}
