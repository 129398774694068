import { useEffect } from "react";
import { IAuthentication } from "../../../app/types/interfaces";
import { AuthenticatorKey } from "@okta/okta-auth-js";
import LoginGAIntro from "./LoginGAIntro";
import LoginOktaVerify from "./LoginOktaVerify";
import LoginGAAuthenticator from "./LoginGAAuthenticator";
import LoginPassword from "./LoginPassword";
import noMFACheck from "../../../app/utils/noMFACheck";
import { useOktaAuth } from "@okta/okta-react";

const LoginMFA = ({
  authenticationHooks,
}: {
  authenticationHooks: IAuthentication;
}) => {
  const { oktaAuth } = useOktaAuth();
  const handleOktaGroupAdd = authenticationHooks.handleOktaGroupAdd;

  useEffect(() => {
    return () => {
      if (noMFACheck({ oktaAuth })) {
        handleOktaGroupAdd();
      }
    };
  }, [handleOktaGroupAdd, oktaAuth]);

  return (
    <>
      <div className="col-span-full flex justify-center">
        <div className="w-[380px] border border-gray-600 bg-gray-900/80 px-4 pb-8 pt-6">
          {!authenticationHooks.authenticatorKey.length &&
            authenticationHooks.introForMFA === "GA" && (
              <LoginGAIntro authenticationHooks={authenticationHooks} />
            )}
          {authenticationHooks.authenticatorKey ===
            AuthenticatorKey.OKTA_VERIFY && (
            <LoginOktaVerify authenticationHooks={authenticationHooks} />
          )}
          {authenticationHooks.authenticatorKey ===
            AuthenticatorKey.GOOGLE_AUTHENTICATOR && (
            <LoginGAAuthenticator authenticationHooks={authenticationHooks} />
          )}
          {authenticationHooks.authenticatorKey ===
            AuthenticatorKey.OKTA_PASSWORD && (
            <LoginPassword
              authenticationHooks={authenticationHooks}
            ></LoginPassword>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginMFA;
