import { requestExternal } from "../../../app/utils/requestUtils";
import { HELP_SEARCH_BASEURL } from "./help.const";

export type fetchHelpParams = {
  searchText: string;
  tag: string; // "360,publishing,recording" | "publishing" | "recording";
  page?: string;
  locale: string;
};

const headers = new Headers();
headers.append("Accept-Encoding", "gzip, deflate, br");
headers.append("Access-Control-Allow-Origin", "http://localhost");
headers.append(
  "Accept-Language",
  "de,en-US;q=0.9,en;q=0.8,es-ES;q=0.7,es;q=0.6"
);
headers.append("Cache-Control", "no-cache");
headers.append("Pragma", "no-cache");
headers.append("Connection", "keep-alive");
headers.append("Sec-Fetch-Dest", "empty");
headers.append("Sec-Fetch-Mode", "cors");
headers.append("Sec-Fetch-Site", "same-origin");

// API needs format de_DE not de
const getLocale = (locale: string) => {
  switch (locale) {
    case "de":
      return "de_DE";
    case "en":
      return "en_US";
    case "it":
      return "it_IT";
    case "es":
      return "es_ES";
    case "pt":
      return "pt_BR";
    case "fr_FR":
    case "fr":
      return "en_US";
    default:
      return locale;
  }
};

export function fetchHelpPage(params: fetchHelpParams, thunkAPI?: any) {
  const { page, tag } = params;
  // eslint-disable-next-line no-console
  console.log(params.locale);
  const locale = getLocale(params.locale);

  if (page === "all") {
    return requestExternal(`${HELP_SEARCH_BASEURL}/${locale}?tag=${tag}`, {
      method: "GET",
      signal: thunkAPI?.signal,
      ...headers,
    });
  }
  return requestExternal(
    `${HELP_SEARCH_BASEURL}/${locale}/${page}?tag=${tag}`,
    {
      method: "GET",
      signal: thunkAPI?.signal,
      ...headers,
    }
  );
}

export function fetchHelpWithSearchtext(
  params: fetchHelpParams,
  thunkAPI?: any
) {
  const { tag } = params;
  const locale = getLocale(params.locale);

  return requestExternal(
    `${HELP_SEARCH_BASEURL}/${locale}/search?tag=${tag}&q=${params.searchText}`,
    { method: "GET", signal: thunkAPI?.signal, ...headers }
  );
}
