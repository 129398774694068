import request from "../../../../app/utils/requestUtils";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export const getPublishingOwningSystems = () => {
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.emailFooters.getPublishingOwningSystems}`,
    {
      method: "GET",
    }
  );
};

export const getRecordingOrMechanicalOwningSystems = () => {
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.emailFooters.getRecordingOrMechanicalOwningSystems}`,
    { method: "GET" }
  );
};

export const getEmailFooter = (payload: any) => {
  const { clientDocumentType, owningSystem } = payload;
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.emailFooters.emailFooters}?clientDocumentType=${clientDocumentType}&owningSystem=${owningSystem}`,
    { method: "GET" }
  );
};

export const updateEmailFooter = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.emailFooters.emailFooters}`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });
};
