import React, { useState, useCallback } from "react";
import { DropdownOption, DropdownOptionProps } from "./DropdownOption";
import useOutsideClick from "../../hooks/useOutsideClick";
import Loader from "../Loader/Loader";
import Input from "../Input";
import { useTranslation } from "react-i18next";

export declare type DropdownProps = {
  id: string;
  className?: string;
  text?: string;
  disabled?: boolean;
  variant?: DropdownVariantEnum;
  label?: string | null;
  horizontalView?: boolean;
  options: Array<DropdownOptionProps>;
  selectedValues?: string[] | number[] | undefined;
  errorsMsg?: string;
  hasSearch?: boolean;
  onSelectOption_Callback(value: string, id: string | number): void;
};

export enum DropdownVariantEnum {
  selectDropdown = "selectDropdown",
  selectDropdownFull = "selectDropdownFull",
  buttonDropdown = "buttonDropdown",
  buttonDropdownComplex = "buttonDropdownComplex",
}

const Dropdown: React.FunctionComponent<DropdownProps> = ({
  id,
  className = "",
  disabled = false,
  text = "",
  label,
  horizontalView = false,
  options = [],
  onSelectOption_Callback,
  selectedValues = undefined,
  variant = DropdownVariantEnum.buttonDropdown,
  errorsMsg,
  hasSearch = true,
}: DropdownProps) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState<string>("");

  const { t } = useTranslation();
  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, []);

  useOutsideClick(dropdownRef, handleClickOutside);

  const toggleDropdown = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const onSelectOption = useCallback(
    (selectedValue: string) => {
      setSearchText("");
      toggleDropdown();
      onSelectOption_Callback(selectedValue, id);
    },
    [id, onSelectOption_Callback, toggleDropdown]
  );

  const getSelectedIds = (value: string | undefined): boolean => {
    if (value) {
      const selValuesAsString = selectedValues?.map((v) => v.toString());
      return selValuesAsString?.includes(value) || false;
    }
    return false;
  };

  const filteredOptions = options.filter((o) => {
    if (typeof o.label === "string") {
      return String(o.label).toLowerCase().startsWith(searchText.toLowerCase());
    }
    return false;
  });

  return (
    <div
      className={`dropdown ${variant} ${className} ${isOpen ? "open" : ""} ${
        errorsMsg ? "errorDropdown" : ""
      } ${horizontalView && "horizontalView"}`}
      id={id}
      ref={dropdownRef}
    >
      {label && <span className="whitespace-nowrap">{label}</span>}
      <button type="button" onClick={toggleDropdown} disabled={disabled}>
        {text} {isOpen && options.length === 0 && <Loader />}
      </button>
      {options.length !== 0 && (
        <div className="dropdown-menu">
          {hasSearch && isOpen && options.length > 4 && (
            <Input
              id="dropdownInput"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="search-input"
              placeholder={t("dropdown.placeholder")}
            />
          )}
          {filteredOptions.map((o, i) => (
            <DropdownOption
              label={o.label}
              value={o.value}
              type={o.type}
              iconLeft={o.iconLeft}
              iconRight={o.iconRight}
              isSelected={getSelectedIds(o.value)}
              onSelectCallback={onSelectOption}
              key={i}
              className={o.className}
            />
          ))}
          {filteredOptions.length === 0 && (
            <p className="noResults">{t("dropdown.noResults")}</p>
          )}
        </div>
      )}
      {errorsMsg && (
        <div className="lightErrorMessage">{errorsMsg && errorsMsg}</div>
      )}
    </div>
  );
};

export default Dropdown;
