import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import {
  fetchSongRegistration,
  fetchSongRegistrationBreakdown,
  fetchSongRegistrationDownload,
  fetchSongRegistrationSearch,
} from "./registrationAPI";
import { RegistrationSearchTagCategoryTypeEnum as SearchTagCategoryTypeEnum } from "../../../app/types/enums";

export type SongRegistrationType = {
  date: string;
  status: string;
  songCode: number;
  song: string;
  composer: string;
  territory: string;
  society: string;
  firstRegistration: string;
};

export declare type RegistrationState = {
  pageState: {
    data: {
      clientSelectionRequest: {
        clientIds: any;
        clientLists: any;
      };
      searchTags?: Array<{
        searchTerm: string;
        categoryType: SearchTagCategoryTypeEnum;
      }>;
    };
  };
  search: {
    songs: Array<string>;
    territories: Array<string>;
    societies: Array<string>;
    statuses: Array<string>;
  } | null;
  stats: {
    totalCount: number;
    sent: number;
    inProgress: number;
    pending: number;
    registered: number;
  } | null;
  recentChanges: Array<SongRegistrationType> | null;
  status: "idle" | "loading" | "failed";
  searchStatus: "init" | "idle" | "loading" | "failed";
  recentChangesStatus: "init" | "idle" | "loading" | "failed";
};

const initialState: RegistrationState = {
  pageState: {
    data: {
      clientSelectionRequest: {
        clientIds: [],
        clientLists: [],
      },
      searchTags: [],
    },
  },
  search: null,
  stats: null,
  recentChanges: null,
  status: "idle",
  searchStatus: "init",
  recentChangesStatus: "init",
};

export const fetchRegistrationSearchThunk = createAsyncThunk(
  "pub/songRegistrationSearch",
  async (payload: any, thunkAPI) => {
    const response = await fetchSongRegistrationSearch(payload, thunkAPI);
    return response;
  }
);

export const fetchRegistrationStatsThunk = createAsyncThunk(
  "pub/songRegistrationStats",
  async (payload: any, thunkAPI) => {
    const response = await fetchSongRegistrationBreakdown(payload, thunkAPI);
    return response;
  }
);

export const fetchRegistrationThunk = createAsyncThunk(
  "pub/songRegistration",
  async (payload: any, thunkAPI) => {
    const response = await fetchSongRegistration(payload, thunkAPI);
    return response.resArr;
  }
);

export const fetchRegistrationDownloadThunk = createAsyncThunk(
  "pub/songRegistrationDownload",
  async (payload: any, thunkAPI) => {
    const response = await fetchSongRegistrationDownload(payload, thunkAPI);
    return response;
  }
);

export const pubRegistrationSlice = createSlice({
  name: "pubRegistration",
  initialState,
  reducers: {
    updatePageState: (state, action: any) => {
      state.pageState.data.clientSelectionRequest.clientIds =
        action.payload.data.clientSelectionRequest.clientIds;
      state.pageState.data.clientSelectionRequest.clientLists =
        action.payload.data.clientSelectionRequest.clientLists;
      state.pageState.data.searchTags = action.payload.data.searchTags;
    },
    clearPageState: (state) => {
      state.pageState.data = initialState.pageState.data;
    },
    clearRegistrationSearch: (state) => {
      state.search = initialState.search;
      state.searchStatus = initialState.searchStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegistrationSearchThunk.fulfilled, (state, action: any) => {
        state.status = "idle";
        state.searchStatus = "idle";
        state.search = action.payload;
      })
      .addCase(fetchRegistrationStatsThunk.fulfilled, (state, action: any) => {
        state.status = "idle";
        state.stats = action.payload;
      })
      .addCase(fetchRegistrationThunk.fulfilled, (state, action: any) => {
        state.status = "idle";
        state.recentChangesStatus = "idle";
        state.recentChanges = action.payload;
      })
      .addCase(fetchRegistrationDownloadThunk.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(fetchRegistrationSearchThunk.pending, (state) => {
        state.status = "loading";
        state.searchStatus = "loading";
      })
      .addMatcher(
        isAnyOf(
          fetchRegistrationStatsThunk.pending,
          fetchRegistrationThunk.pending,
          fetchRegistrationDownloadThunk.pending
        ),
        (state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchRegistrationSearchThunk.rejected,
          fetchRegistrationStatsThunk.rejected,
          fetchRegistrationThunk.rejected,
          fetchRegistrationDownloadThunk.rejected
        ),
        (state) => {
          state.status = "failed";
        }
      );
  },
});

export const registrationPageStateSelector = (state: RootState) =>
  state.pubRegistration.pageState;
export const registrationSearchSelector = (state: RootState) =>
  state.pubRegistration.search;
export const registrationStatsSelector = (state: RootState) =>
  state.pubRegistration.stats;
export const registrationSelector = (state: RootState) =>
  state.pubRegistration.recentChanges;
export const registrationStatusSelector = (state: RootState) =>
  state.pubRegistration.status;
export const registrationSearchStatusSelector = (state: RootState) =>
  state.pubRegistration.searchStatus;
export const recentChangesStatusSelector = (state: RootState) =>
  state.pubRegistration.recentChangesStatus;

export const {
  updatePageState: updatePageStateAction,
  clearPageState: clearPageStateAction,
  clearRegistrationSearch: clearRegistrationSearchAction,
} = pubRegistrationSlice.actions;

export default pubRegistrationSlice.reducer;
