import request from "../../../../app/utils/requestUtils";
import { SingleGroup } from "../../../../app/types/props";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export function fetchGroupByID(payload: { id: number }) {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.groups}/${id}`, {
    method: "GET",
  });
}

export function addNewGroup(payload: { data: SingleGroup }) {
  const { data } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.groups}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
}

export function editGroup(payload: { id: number; data: SingleGroup }) {
  const { data, id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.groups}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
}

export function deleteGroup(payload: { id: number }) {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.groups}/${id}`, {
    method: "DELETE",
  });
}
