import {
  PubSyncTabStatusEnum,
  PubSyncTabsEnum,
} from "../../../app/types/enums";
import withClientSelectionData from "../clientSelection/withClientSelectionData";
import PubSyncHOC from "./PubSyncHOC";

const PubSyncQuoting = (): JSX.Element => (
  <PubSyncHOC
    status={PubSyncTabStatusEnum.quoting}
    selectedTab={PubSyncTabsEnum.quoting}
  />
);

export default withClientSelectionData(PubSyncQuoting);
