import React from "react";

function ClockIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      name="ClockIcon"
      {...props}
    >
      <path
        fill="#F8E71C"
        stroke="#F8E71C"
        d="M10.55 9.812c.054.04.064.114.025.166l-.002.003a.115.115 0 0 1-.095.046.117.117 0 0 1-.071-.023l-2.479-1.86a.118.118 0 0 1-.048-.095V4.33A.12.12 0 0 1 8 4.21a.12.12 0 0 1 .12.12v3.658l.2.15 2.23 1.673Z"
      />
      <path
        fill="#F8E71C"
        stroke="#F8E71C"
        d="M.5 8C.5 3.865 3.865.5 8 .5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5S.5 12.135.5 8Zm.24 0c0 4.004 3.257 7.26 7.26 7.26 4.004 0 7.26-3.256 7.26-7.26C15.26 3.997 12.005.74 8 .74 3.997.74.74 3.996.74 8Z"
      />
    </svg>
  );
}

export default ClockIcon;
