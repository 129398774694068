import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import {
  StatusEnum,
  UserAccountStatusEnum,
  UserAccountTypeEnum,
} from "../../../../app/types/enums";

import { useFetch } from "../../common/hooks/useFetch";

import { RequestArchiveListItem } from "../RequestArchive/RequestArchiveSlice";

import {
  getRequestArchiveItem,
  getRequestArchiveUser,
} from "./RequestArchiveEditPageAPI";

export type RequestArchiveEditState = {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  taxId: string;
  phone: string;
  recordingUserRole: string;
  originatingSystem: string;
  userAccountType: string;
  payees: {
    number: string;
    name: string;
    userAccountType: UserAccountTypeEnum;
  }[];
  status: UserAccountStatusEnum | null;
};

export type RequestArchiveUserDataState = {
  userAccountType: {
    id: number | null;
    accountType: UserAccountTypeEnum;
  } | null;
  username: string;
  email: string;
  id: number | null;
  newPassword: string;
  firstName: string;
  lastName: string;
  company: string;
  taxId: string;
  phone: string;
  languageId: number | null;
  country: {
    id: number | null;
    name: string;
    code: string;
  } | null;
  mechanical: boolean;
  publishingRole: string;
  groups: {
    id: number | null;
    name: string;
    description: string;
    groupType: {
      id: number | null;
      groupType: string;
    };
  }[];
  dashboardView: string;
  showPublishingAdvanceRequest: string;
  showRecordingAdvanceRequest: string;
  publishingAdvanceRequestEmail: string;
  recordingAdvanceRequestEmail: string;
  songRegistration: string;
  updateBankDetails: string;
  showPublishingPipelineIncome: string;
  showRecordingPipelineIncome: string;
};

type RequestArchiveEditInitialState = {
  formData: RequestArchiveEditState;
  userData: RequestArchiveUserDataState;
  selectedId: RequestArchiveListItem | null;
  status: StatusEnum;
};

const initialState: RequestArchiveEditInitialState = {
  formData: {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    taxId: "",
    phone: "",
    recordingUserRole: "",
    originatingSystem: "",
    userAccountType: "",
    payees: [
      {
        number: "",
        name: "",
        userAccountType: UserAccountTypeEnum.publishing,
      },
    ],
    status: null,
  },
  userData: {
    userAccountType: {
      id: null,
      accountType: UserAccountTypeEnum.publishing,
    },
    username: "",
    email: "",
    id: null,
    newPassword: "",
    firstName: "",
    lastName: "",
    company: "",
    taxId: "",
    phone: "",
    languageId: null,
    country: {
      id: null,
      name: "",
      code: "",
    },
    mechanical: true,
    publishingRole: "",
    groups: [
      {
        id: null,
        name: "",
        description: "",
        groupType: {
          id: null,
          groupType: "",
        },
      },
    ],
    dashboardView: "",
    showPublishingAdvanceRequest: "",
    showRecordingAdvanceRequest: "",
    publishingAdvanceRequestEmail: "",
    recordingAdvanceRequestEmail: "",
    songRegistration: "",
    updateBankDetails: "",
    showPublishingPipelineIncome: "",
    showRecordingPipelineIncome: "",
  },
  status: StatusEnum.Idle,
  selectedId: null,
};

export const getRequestArchiveEditThunk = createAsyncThunk(
  "admin/access-requests/id",
  async (payload: any, { rejectWithValue }) => {
    try {
      const getRequestArchiveEdit = await useFetch();
      const response = await getRequestArchiveEdit({
        data: payload,
        fetchFunction: getRequestArchiveItem,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRequestArchiveUserDataThunk = createAsyncThunk(
  "admin/access-requests/id/user",
  async (payload: any, { rejectWithValue }) => {
    try {
      const getRequestArchiveUserData = await useFetch();
      const response = await getRequestArchiveUserData({
        data: payload,
        fetchFunction: getRequestArchiveUser,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestArchiveEditSlice = createSlice({
  name: "requestArchiveItemById",
  initialState,
  reducers: {
    resetGroup: (state) => {
      state.formData = initialState.formData;
      state.userData = initialState.userData;
    },
    updateSelectedItem: (state, action: any) => {
      state.selectedId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequestArchiveEditThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.formData = action.payload;
      })
      .addCase(getRequestArchiveEditThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getRequestArchiveEditThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(
        getRequestArchiveUserDataThunk.fulfilled,
        (state, action: any) => {
          state.status = StatusEnum.Idle;
          state.userData = action.payload;
        }
      )
      .addCase(getRequestArchiveUserDataThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(
        getRequestArchiveUserDataThunk.rejected,
        (state, action: any) => {
          state.status = StatusEnum.Failed;
        }
      );
  },
});

export const RequestArchiveEditSelector = (state: RootState) =>
  state.requestArchiveEdit;

export default requestArchiveEditSlice.reducer;
