import React from "react";
function AreaChartIconChartIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M23.5 22.5h-22V.5" />
      <path d="M6.28 11.98h4.78V22.5H6.28zM15.85 8.15h4.78V22.5h-4.78z" />
      <path d="M11.07 3.37h4.78V22.5h-4.78z" />
    </svg>
  );
}
export default AreaChartIconChartIcon;
