import { REDIRECT_URI } from "./constant";

export const OKTA_CONFIG = {
  issuer: process.env.REACT_APP_ISSUER,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: [
    "openid",
    "profile",
    "email",
    "okta.myAccount.profile.read",
    "okta.myAccount.password.manage",
  ],
  // devMode: true,
};

export const OKTA_VAR = {
  MYBMG_NO_MFA_GROUP: "mybmg-no-mfa",
  MYBMG_INTERNAL_USERS_GROUP: "mybmg-internal-users",
};
