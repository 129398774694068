import request from "../../../../app/utils/requestUtils";
import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export function getCurrencies(payload: any) {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.parentsCurrency}/${payload}`, {
    method: "GET",
  });
}

export const getParentsTableData = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.parents}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const getParentsById = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.parents}/${payload}`, {
    method: "GET",
  });
};

export const deleteParentsById = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.parents}/${payload}`, {
    method: "DELETE",
  });
};

export const createParent = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.parents}`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });
};

export const updateParent = (payload: any) => {
  const { id, data } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.parents}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

export const getCsvTemplate = (payload: any) => {
  const { fileName, clientDocumentType } = payload;
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.parents}/bulk/template/${clientDocumentType}`,
    {
      method: "GET",
      isDownload: true,
      fileName,
    }
  );
};

export const uploadFile = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.parents}/bulk`, {
    method: "POST",
    body: payload,
    isContentTypeMultipartFormData: true,
  });
};
