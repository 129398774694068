import { useTranslation } from "react-i18next";
import styles from "../analysis.module.scss";

interface InfiniteTableHeaderProps {
  sortAnalysisSourceBy: (columnName: string) => void;
  documentType: "PUBLISHING" | "RECORDING";
}

const AnalysisSourceInfiniteTableHeader = (props: InfiniteTableHeaderProps) => {
  const { t } = useTranslation();
  const { sortAnalysisSourceBy, documentType } = props;

  return (
    <div id="table-header" className={styles.headContainer}>
      <table className={`w-full`}>
        <thead>
          <tr>
            <th
              className={`sortBySourceName_GTM ${styles.sourceCol}`}
              onClick={() =>
                sortAnalysisSourceBy(
                  documentType === "PUBLISHING"
                    ? "sourceName"
                    : "originalSource"
                )
              }
            >
              {t("analysis.source.source")}
            </th>
            <th
              className={`sortByRoyalties_GTM ${styles.royaltiesCol}`}
              onClick={() => sortAnalysisSourceBy("royalties")}
            >
              {t("analysis.source.royalties")}
            </th>
            <th></th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default AnalysisSourceInfiniteTableHeader;
