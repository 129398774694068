import request from "../../../app/utils/requestUtils";
import type { FetchClientsPayload } from "../../../app/types/interfaces";
import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";

export async function fetchClients(
  params: FetchClientsPayload,
  thunkAPI?: any
) {
  const {
    data,
    start = 0,
    count = 0,
    documentType = "PUBLISHING",
    sortColumn = "path",
    isAsc = true,
    filterText = "",
    // not provided by API
    currencyId = "",
  } = params;
  const currencyReq = currencyId !== "" ? `&currencySymbol=${currencyId}` : "";
  const docTypeReq = "clientDocumentType";
  // todo: currencySymbol is not supported in v3 api
  // todo: isAsc should be sortAscending (but does not work yet)
  const response = request(
    `${USER_API_RESOURCE_BASE_URLS.clients}?start=${start}&count=${count}&${docTypeReq}=${documentType}&sortColumn=${sortColumn}&isAsc=${isAsc}&filterText=${filterText}` +
      currencyReq,
    { method: "POST", body: JSON.stringify(data), signal: thunkAPI?.signal }
  );
  return response;
}

export async function fetchSubClients(params: any, thunkAPI?: any) {
  const {
    clientId = 0,
    start = 0,
    count = 1000,
    documentType = "PUBLISHING",
    isAsc = true,
    filterText = "",
  } = params;
  const docTypeReq = "documentType";
  const response = request(
    `${USER_API_RESOURCE_BASE_URLS.clients}/${clientId}/subclients?start=${start}&count=${count}&${docTypeReq}=${documentType}&isAsc=${isAsc}&filterText=${filterText}`,
    { method: "GET", signal: thunkAPI?.signal }
  );
  return response;
}
