import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonVariantEnum } from "../../../types/enums";
import { ChevronIcon } from "../../../atom/Icon";
import { useWindowSize } from "../../../hooks";
import { HorizontalPeriodSelectorProps } from "../../../types/props";
import Slider from "react-slick";
import Button from "../../../atom/Button/Button";
import styles from "./horizontalPeriodSelector.module.scss";

function generateDateOptions(props: HorizontalPeriodSelectorProps): Array<any> {
  const dates = [];
  if (props.startYear && props.endYear) {
    if (props.monthInterval)
      for (let y = props.startYear; y <= props.endYear; y++)
        for (let m = props.monthInterval; m <= 12; m += props.monthInterval)
          dates.push(m + "/" + y);
    else for (let y = props.startYear; y <= props.endYear; y++) dates.push(y);
  }
  return dates;
}

export default function HorizontalPeriodSelector(
  props: HorizontalPeriodSelectorProps
): JSX.Element {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [selectedPeriod, setSelectedPeriod] = useState(
    props.selectedPeriod ? props.selectedPeriod : ""
  );

  const sliderRef = useRef<Slider>(null);
  const slidesToShow = useRef(0);

  let dates: Array<any>;

  if (props.startYear && props.endYear) dates = generateDateOptions(props);
  else dates = props.dateArray || [];

  const handlePeriodClick = (param: string) => () => {
    setSelectedPeriod(param);
    if (props.onPeriodSelect) props.onPeriodSelect(param);
  };

  useEffect(() => {
    setSelectedPeriod(props.selectedPeriod ? props.selectedPeriod : "");
  }, [dates, props.selectedPeriod]);

  useEffect(() => {
    if (!width) return;
    if (width <= 320) slidesToShow.current = 1;
    else if (width <= 500) slidesToShow.current = 2;
    else if (width <= 600) slidesToShow.current = 3;
    else if (width <= 900) slidesToShow.current = 4;
    else if (width <= 1200) slidesToShow.current = 6;
    else if (width <= 1800) slidesToShow.current = 8;
    else if (width <= 18000) slidesToShow.current = 10;

    sliderRef.current?.forceUpdate();
  }, [width, dates]);

  if (dates.length === 0) return <></>;

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    lazyload: true,
    slidesToScroll: 1,
    slidesToShow: slidesToShow.current,
    initialSlide: dates?.length - slidesToShow.current,
    swipeToSlide: true,
    focusOnSelect: true,
    autoplay: false,
    touchThreshold: 50,
    prevArrow: <ChevronIcon />,
    nextArrow: <ChevronIcon />,
    responsive: [
      {
        breakpoint: 18000,
        settings: {
          slidesToShow: 10,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  let buttonClassName = "";

  return (
    <div className={styles.horPeriodSelector}>
      <Button
        variant={
          selectedPeriod === ""
            ? ButtonVariantEnum.primaryNarrowCta
            : ButtonVariantEnum.secondaryNarrowCta
        }
        className={styles.allPeriodsBtn}
        onClick={selectedPeriod === "" ? undefined : handlePeriodClick("")}
      >
        {t("periodSelector.allPeriods")}
      </Button>
      <div className={styles.sliderContainer}>
        <Slider ref={sliderRef} {...settings}>
          {dates.map((d, index) => {
            buttonClassName = selectedPeriod === d ? styles.currentPeriod : "";
            return (
              <Button
                key={index + "ps"}
                variant={ButtonVariantEnum.cleanCta}
                onClick={handlePeriodClick(d)}
                className={buttonClassName}
              >
                {d}
              </Button>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
