import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { StatusEnum } from "../../../../app/types/enums";

import { useFetch } from "../../common/hooks/useFetch";

import {
  AddEditNewsRequestBody,
  addNews,
  deleteNews,
  editNews,
  fetchNewsByID,
} from "./AddEditNewsAPI";

export type EditNewsProps = {
  id: number;
  data: any;
};

export interface AddEditNewsState {
  news: any;
  status: StatusEnum;
}

const initialState: AddEditNewsState = {
  news: {
    id: null,
    publicationDate: null,
    expiryDate: null,
    priority: null,
    title: null,
    text: null,
    forAllGroups: null,
    webapp: null,
    mobile: null,
    users: [],
    groups: [],
  },
  status: StatusEnum.Idle,
};

type NewsRequestData = {
  id: number;
};

type AddNewsRequestDataProps = {
  data: AddEditNewsRequestBody;
};

type EditNewsRequestDataProps = {
  data: AddEditNewsRequestBody;
  id: number;
};

export const fetchNewsByIDThunk = createAsyncThunk(
  "admin/news/fetchNewsByID",
  async (id: number, { rejectWithValue }) => {
    try {
      const requestData = {
        id,
      } as NewsRequestData;

      const fetchGroup = await useFetch();
      const response = await fetchGroup<NewsRequestData>({
        data: requestData,
        fetchFunction: fetchNewsByID,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewsThunk = createAsyncThunk(
  "admin/news/addNews",
  async (payload: any, { rejectWithValue }) => {
    try {
      const requestData = {
        data: {
          ...payload,
        },
      } as AddNewsRequestDataProps;

      const addNewGroup = await useFetch();
      const response = await addNewGroup<AddNewsRequestDataProps>({
        data: requestData,
        fetchFunction: addNews,
        customSuccessMsg: "adminSection.news.successAddMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editNewsThunk = createAsyncThunk(
  "admin/news/editNews",
  async (payload: EditNewsProps, { rejectWithValue }) => {
    try {
      const requestData = {
        data: {
          ...payload.data,
        },
        id: payload.id,
      } as EditNewsRequestDataProps;

      const editGroup = await useFetch();
      const response = await editGroup<EditNewsRequestDataProps>({
        data: requestData,
        fetchFunction: editNews,
        customSuccessMsg: "adminSection.common.successEditMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteNewsThunk = createAsyncThunk(
  "admin/news/deleteNews",
  async (id: number, { rejectWithValue }) => {
    try {
      const requestData = {
        id,
      } as NewsRequestData;

      const deleteGroup = await useFetch();
      const response = await deleteGroup<NewsRequestData>({
        data: requestData,
        fetchFunction: deleteNews,
        customSuccessMsg: "adminSection.news.successDeleteMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addEditNewsSlice = createSlice({
  name: "addEditNews",
  initialState,
  reducers: {
    resetPage: (state) => {
      state.news = initialState.news;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewsByIDThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(fetchNewsByIDThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.news.id = action.payload?.id;
        state.news.publicationDate = action.payload?.publicationDate;
        state.news.expiryDate = action.payload?.expiryDate;
        state.news.priority = action.payload?.priority;
        state.news.webapp = action.payload?.webapp;
        state.news.mobile = action.payload?.mobile;
        state.news.title = action.payload?.title;
        state.news.text = action.payload?.text;
        state.news.forAllGroups = action.payload?.forAllGroups;
        state.news.groups = action.payload?.groups;
        state.news.users = action.payload?.users;
      })
      .addCase(fetchNewsByIDThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(addNewsThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(addNewsThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(addNewsThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(editNewsThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(editNewsThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(editNewsThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(deleteNewsThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(deleteNewsThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(deleteNewsThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      });
  },
});

export const addEditNewsSelector = (state: RootState) => state.addEditNews;
export default addEditNewsSlice.reducer;
