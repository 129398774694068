function RegistrationIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 22 22"
      name="RegistrationIcon"
      {...props}
    >
      <path d="M8.044 16.659a3.045 3.045 0 1 0 1.043 2.415H9.1V3.31l6.183 5.352.694-.799L8.044 1v15.659Zm-2.004 4.29a1.988 1.988 0 1 1 .01-3.976 1.988 1.988 0 0 1-.01 3.976Z" />
      <path d="M20.073 11.975h-8.168v1.056h8.168v-1.056ZM20.073 15.496h-8.168v1.057h8.168v-1.057ZM20.073 19.018h-8.168v1.056h8.168v-1.056Z" />
    </svg>
  );
}
export default RegistrationIcon;
