import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { downloadAnalysisData, fetchAnalysisTracks } from "../recAnalysisAPI";
import { RootState } from "../../../../app/redux/store";

export const RecAnalysisTrackPageInitialState = {
  data: {
    clientIds: [],
    clientLists: [],
    periodIds: [],
  },
  start: 0,
  count: 200,
  sortColumn: "",
  sortAscending: true,
  filterText: "",
  filterColumn: [],
};

export interface RecAnalysisTrackProps {
  data: {
    tracks: Array<any>;
    totalRoyalty: Record<string, any>;
  };
  total: number;
}

export interface RecAnalysisTrackState {
  analysisTrackPageState: {
    data: {
      clientIds: Array<number>;
      clientLists: Array<any>;
      periodIds: Array<number>;
    };
    start: number;
    count: number;
    sortColumn: string;
    sortAscending: boolean;
    filterText: string;
    filterColumn: Array<string>;
  };
  tracks: RecAnalysisTrackProps;
  analysisTrackStatus: "init" | "idle" | "loading" | "failed";
  downloadStatus: "loading" | "idle" | "failed";
  noDataFlag?: "" | "noData" | "noPeriodData" | "noSearchData";
}

const initialState: RecAnalysisTrackState = {
  analysisTrackPageState: RecAnalysisTrackPageInitialState,
  tracks: {
    data: {
      tracks: [],
      totalRoyalty: {},
    },
    total: 0,
  },
  analysisTrackStatus: "init",
  downloadStatus: "idle",
  noDataFlag: undefined,
};

export const fetchAnalysisTracksThunk = createAsyncThunk(
  "recording/analysisTracks",
  async (payload: any, thunkAPI: any) => {
    const response = await fetchAnalysisTracks(payload, thunkAPI);
    return response;
  }
);

export const fetchMoreAnalysisTracksThunk = createAsyncThunk(
  "recording/moreAnalysisTracks",
  async (payload: any, thunkAPI: any) => {
    const response = await fetchAnalysisTracks(payload, thunkAPI);
    return response;
  }
);

export const recAnalysisTracksDownload = createAsyncThunk(
  "recAnalysis/downloadTracks",
  async (payload: any, thunkAPI) => {
    const response = await downloadAnalysisData(payload, thunkAPI);
    return response;
  }
);

export const recAnalysisTrackSlice = createSlice({
  name: "recAnalysisTrack",
  initialState,
  reducers: {
    resetAnalysisTracks: (state, action: any) => {
      state.tracks = initialState.tracks;
    },
    updateAnalysisTrackPageState: (state: any, action: any) => {
      state.analysisTrackPageState.data.clientIds =
        action.payload.data.clientIds;
      state.analysisTrackPageState.data.clientLists =
        action.payload.data.clientLists;
      state.analysisTrackPageState.data.periodIds =
        action.payload.data.periodIds;
      state.analysisTrackPageState.start = action.payload.start;
      state.analysisTrackPageState.count = action.payload.count;
      state.analysisTrackPageState.sortColumn = action.payload.sortColumn;
      state.analysisTrackPageState.sortAscending = action.payload.sortAscending;
      state.analysisTrackPageState.filterColumn = action.payload.filterColumn;
      state.analysisTrackPageState.filterText = action.payload.filterText;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAnalysisTracksThunk.fulfilled,
        (state: any, action: any) => {
          state.analysisTrackStatus = "idle";
          state.tracks.data = action.payload.data;
          state.tracks.total = action.payload.total;
          state.noDataFlag =
            action.payload?.total === 0
              ? state?.analysisTrackPageState?.filterText?.length > 0
                ? "noSearchData"
                : state?.analysisTrackPageState?.data?.periodIds?.length === 1
                ? "noPeriodData"
                : "noData"
              : "";
        }
      )
      .addCase(
        fetchMoreAnalysisTracksThunk.fulfilled,
        (state: any, action: any) => {
          state.analysisTrackStatus = "idle";
          state.tracks.data.tracks = [
            ...state.tracks.data.tracks,
            ...action.payload.data.tracks,
          ];
          state.tracks.data.totalRoyalty = action.payload.data.totalRoyalty;
          state.tracks.total = action.payload.total;
        }
      )
      .addCase(recAnalysisTracksDownload.fulfilled, (state) => {
        state.downloadStatus = "idle";
      })
      .addCase(recAnalysisTracksDownload.pending, (state) => {
        state.downloadStatus = "loading";
      })
      .addCase(recAnalysisTracksDownload.rejected, (state) => {
        state.downloadStatus = "failed";
      })
      .addMatcher(
        isAnyOf(
          fetchAnalysisTracksThunk.pending,
          fetchMoreAnalysisTracksThunk.pending
        ),
        (state) => {
          state.analysisTrackStatus = "loading";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchAnalysisTracksThunk.rejected,
          fetchMoreAnalysisTracksThunk.rejected
        ),
        (state) => {
          state.analysisTrackStatus = "failed";
        }
      );
  },
});

export const recAnalysisTrackPageStateSelector = (state: RootState) =>
  state.recAnalysisTrack.analysisTrackPageState;
export const recAnalysisTracksSelector = (state: RootState) =>
  state.recAnalysisTrack.tracks;
export const recAnalysisTrackPeriodIdsSelector = (state: RootState) =>
  state.recAnalysisTrack.analysisTrackPageState.data.periodIds;
export const recAnalysisDownloadStatusSelector = (state: RootState) =>
  state.recAnalysisTrack.downloadStatus;
export const recAnalysisTrackStatusSelector = (state: RootState) =>
  state.recAnalysisTrack.analysisTrackStatus;
export const recAnalysisNoDataFlagSelector = (state: RootState) =>
  state.recAnalysisTrack.noDataFlag;

export const {
  resetAnalysisTracks: resetAnalysisTracksAction,
  updateAnalysisTrackPageState: updateAnalysisTrackPageStateAction,
} = recAnalysisTrackSlice.actions;

export default recAnalysisTrackSlice.reducer;
