import request from "../../../../app/utils/requestUtils";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export const getActivityLog = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.activityLog}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const exportActivityLogCSV = (params: any, thunkAPI?: any) => {
  const { data, fileName } = params;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.activityLog}/csv`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
    isDownload: true,
    fileName,
  });
};
