import { useTranslation } from "react-i18next";
import LoginForm from "./LoginForm";
import LoginMFA from "./LoginMFA";
import styles from "../../../app/molecules/sideBasicWrapper/sideBasic.module.scss";
import { useAppSelector } from "../../../app/redux/hooks";
import { loginStatusSelector } from "./loginSlice";
import Loader from "../../../app/atom/Loader/Loader";
import useAuthentication from "../../../app/hooks/useAuthentication";

const Login = () => {
  const { t } = useTranslation();
  const loginLoadingStat = useAppSelector(loginStatusSelector);
  const authenticationHooks = useAuthentication();

  const showMFAScreens =
    !!authenticationHooks.introForMFA || !!authenticationHooks.authenticatorKey;

  return (
    <>
      {(authenticationHooks.loginLoading || loginLoadingStat === "loading") && (
        <Loader />
      )}
      <div className={styles.titleContainer}>
        <h1>{t("login.title")}</h1>
      </div>
      {!showMFAScreens && (
        <LoginForm authenticationHooks={authenticationHooks} />
      )}
      {showMFAScreens && <LoginMFA authenticationHooks={authenticationHooks} />}
    </>
  );
};

export default Login;
