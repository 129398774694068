import React from "react";
function LoginFairnessIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      name="LoginFairnessIcon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="32.5" cy="32.5" r="32.5" fill="#d20020" />
        <rect
          width="34.545"
          height="25.455"
          x="14.727"
          y="20.273"
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          rx="2"
        />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14.727 23.91h34.546M18.364 40.273l9.091-9.091 7.272 7.273 10.909-10.91"
        />
      </g>
    </svg>
  );
}

export default LoginFairnessIcon;
