import { ComponentType, useEffect, useMemo, useRef } from "react";
import {
  fetchClientSelectionClientListsThunk,
  fetchSelectedClientIdsThunk,
  fetchSelectedClientsThunk,
  pubClientSelectionSelector,
  pubSelectedClientIdsSelector,
  selectClientListThunk,
} from "../pubroot/pubrootSlice";
import { meSelector } from "../../appMain/appMainSlice";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { useOktaAuth } from "@okta/okta-react";
import debounce from "../../../app/utils/debounce";

const withClientSelectionData =
  <P extends object>(WrappedComponent: ComponentType<P>) =>
  (props: P) => {
    const dispatch = useAppDispatch();
    const { authState } = useOktaAuth();

    const { publishingCurrencies: list } = useAppSelector(meSelector);
    const selectedClientIds = useAppSelector(pubSelectedClientIdsSelector);
    const { clientLists, selectedClients } = useAppSelector(
      pubClientSelectionSelector
    );

    // refs
    const isFetched = useRef(false);
    const dispatchedSelectedClientIds = useRef<any>();
    const dispatchedSelectedClients = useRef<any>();
    const dispatchedSelectedClientList = useRef<any>();
    const dispatchedClientLists = useRef<any>();

    // abort functions
    const abortDispatchedSelectedClientIds = () => {
      if (dispatchedSelectedClientIds.current)
        dispatchedSelectedClientIds.current?.abort();
    };
    const abortDispatchedSelectedClients = () => {
      if (dispatchedSelectedClients.current)
        dispatchedSelectedClients.current?.abort();
    };
    const abortDispatchedSelectedClientList = () => {
      if (dispatchedSelectedClientList.current)
        dispatchedSelectedClientList.current?.abort();
    };
    const abortDispatchedClientLists = () => {
      if (dispatchedClientLists.current) dispatchedClientLists.current?.abort();
    };

    const handleFetches = useMemo(
      () =>
        debounce(
          (
            clientListsTotal: number,
            selectedClientsLength: number,
            selectedClients: any[]
          ) => {
            // fetch clientLists
            if (clientListsTotal === null) {
              abortDispatchedClientLists();
              dispatchedClientLists.current = dispatch(
                fetchClientSelectionClientListsThunk()
              );
            }
            // fetch selectedClients
            if (selectedClientsLength === 0) {
              abortDispatchedSelectedClientIds();
              dispatchedSelectedClientIds.current = dispatch(
                fetchSelectedClientIdsThunk({
                  clientDocumentType: "PUBLISHING",
                  selectedClients,
                })
              );
            }
            isFetched.current = true;
          },
          150
        ),
      [dispatch]
    );

    useEffect(() => {
      if (authState?.isAuthenticated && !isFetched.current) {
        handleFetches(
          clientLists?.total,
          selectedClients?.length,
          list?.length,
          selectedClients
        );
      }
    }, [
      authState?.isAuthenticated,
      clientLists?.total,
      handleFetches,
      list?.length,
      selectedClients,
      selectedClients?.length,
    ]);

    useEffect(() => {
      if (authState?.isAuthenticated) {
        if (
          selectedClientIds?.length &&
          isFetched.current &&
          !selectedClients?.length
        ) {
          abortDispatchedSelectedClients();
          dispatchedSelectedClients.current = dispatch(
            fetchSelectedClientsThunk({
              selectedClientIds,
            })
          );
        }
      }
    }, [
      authState?.isAuthenticated,
      dispatch,
      selectedClientIds,
      selectedClients?.length,
    ]);

    // Select 1st clientList (clientLists.list[0])
    // when there is no clientIds selected
    useEffect(() => {
      if (authState?.isAuthenticated) {
        if (selectedClientIds?.length === 0) {
          if (clientLists?.total) {
            abortDispatchedSelectedClientList();
            dispatchedSelectedClientList.current = dispatch(
              selectClientListThunk({
                selectedList: clientLists.list[0],
              })
            );
          }
        }
      }
    }, [
      authState?.isAuthenticated,
      clientLists.list,
      clientLists?.total,
      dispatch,
      selectedClientIds?.length,
    ]);

    return <WrappedComponent {...(props as P)} />;
  };

export default withClientSelectionData;
