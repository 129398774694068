import RecSyncHOC from "./RecSyncHOC";
import withClientSelectionData from "../clientSelection/withClientSelectionData";
import {
  RecSyncTabStatusEnum,
  RecSyncTabsEnum,
} from "../../../app/types/enums";

const RecSyncAll = (): JSX.Element => (
  <RecSyncHOC
    status={RecSyncTabStatusEnum.all}
    selectedTab={RecSyncTabsEnum.all}
  />
);

export default withClientSelectionData(RecSyncAll);
