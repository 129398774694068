import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import PubAnalysisHeader from "../PubAnalysisHeader";
import { useTranslation } from "react-i18next";
import { windowScrollToTop } from "../../../../app/utils/scrolling";
import { getNow, getToday } from "../../../../app/utils/dateTime";
import { useOktaAuth } from "@okta/okta-react";
import { pubClientSelectionSelector } from "../../pubroot/pubrootSlice";
import {
  pubAnalysisSourcePageStateSelector,
  fetchAnalysisSourceThunk,
  fetchMoreAnalysisSourceThunk,
  pubAnalysisSourceDownload,
  updateAnalysisSourcePageStateAction,
  pubAnalysisSourceSelector,
  pubAnalysisSourceDownloadStatusSelector,
  pubAnalysisSourceStatusSelector,
  resetAnalysisSourceAction,
  pubAnalysisNoDataFlagSelector,
  PubAnalysisSourcePageInitialState,
} from "./pubAnalysisSourceSlice";
import {
  clearTypeBreakdownDataAction,
  clearTrendBreakdownDataAction,
  clearTerritoryBreakdownDataAction,
} from "./pubAnalysisSourceBreakdownSlice";
import deepCompare from "../../../../app/utils/deepCompare";
import debounce from "../../../../app/utils/debounce";
import { pubAnalysisHeaderSelector } from "../pubAnalysisHeaderSlice";
import Grid from "../../../../app/atom/Grid/Grid";
import Loader from "../../../../app/atom/Loader/Loader";
import Search from "../../../../app/molecules/search/Search";
import useInfiniteScroll from "../../../../app/hooks/useInfiniteScroll";
import styles from "../../../../app/molecules/analysis/analysis.module.scss";
import throttle from "../../../../app/utils/throttle";
import AnalysisSourceRightSide from "../../../../app/molecules/analysis/analysisSource/AnalysisSourceRightSide";
import AnalysisSourceLeftSide from "../../../../app/molecules/analysis/analysisSource/AnalysisSourceLeftSide";
import { useWindowSize } from "../../../../app/hooks";

const PubAnalysisSource = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  const { width: windowWidth } = useWindowSize();

  // selectors
  const source = useAppSelector(pubAnalysisSourceSelector);
  const pubAnalysisSourceDownloadStatus = useAppSelector(
    pubAnalysisSourceDownloadStatusSelector
  );
  const pubAnalysisNoDataFlag = useAppSelector(pubAnalysisNoDataFlagSelector);
  const pubAnalysisSourceStatus = useAppSelector(
    pubAnalysisSourceStatusSelector
  );
  const pubAnalysisHeaderState = useAppSelector(pubAnalysisHeaderSelector);
  const clientSelection = useAppSelector(pubClientSelectionSelector);
  const pubAnalysisSourcePageState = useAppSelector(
    pubAnalysisSourcePageStateSelector
  );

  // states
  const [selectedSource, setSelectedSource] = useState<any>(undefined);
  const [analysisSourceStart, setAnalysisSourceStart] = useState(
    pubAnalysisSourcePageState.start
  );
  const [analysisSourceSortColumn, setAnalysisSourceSortColumn] = useState(
    pubAnalysisSourcePageState.sortColumn
  );
  const [analysisSourceAscOrder, setAnalysisSourceAscOrder] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState(
    pubAnalysisSourcePageState.filterText
  );
  const [loaderFlag, setLoaderFlag] = useState(true);
  const [totalAmount, setTotalAmount] = useState({
    currency: source.data?.totalRoyalty?.currency,
    value: source.data?.totalRoyalty?.formattedLong,
  });
  const [isSortingLoading, setIsSortingLoading] = useState(false);

  // observers
  const observerTopTarget = useRef<HTMLDivElement | null>(null);
  const { isOnScreen: topTargetIsOnScreen } =
    useInfiniteScroll(observerTopTarget);
  const observerBottomTarget = useRef<HTMLDivElement | null>(null);
  const {
    pageCount: bottomTargetPageCount,
    setPageCount: setBottomTargetPageCount,
  } = useInfiniteScroll(observerBottomTarget);

  const refSourceTable = useRef<HTMLInputElement>(null);

  // dispatch refs
  const dispatchedAnalysisSource = useRef<any>();
  const dispatchedAnalysisMoreSource = useRef<any>();
  const dispatchedDownload = useRef<any>();

  // abort functions
  const abortDispatchedDownload = useCallback(() => {
    if (dispatchedDownload.current) dispatchedDownload.current.abort();
  }, []);
  const abortDispatchedAnalysisSource = useCallback(() => {
    if (dispatchedAnalysisSource.current)
      dispatchedAnalysisSource.current.abort();
  }, []);
  const abortDispatchedAnalysisMoreSource = useCallback(() => {
    if (dispatchedAnalysisMoreSource.current)
      dispatchedAnalysisMoreSource.current.abort();
  }, []);

  const handleAnalysisSourceFetch = useMemo(
    () =>
      debounce((pageState: any) => {
        if (pageState.start === 0) {
          abortDispatchedAnalysisSource();
          dispatchedAnalysisSource.current = dispatch(
            fetchAnalysisSourceThunk(pageState)
          );
        } else {
          abortDispatchedAnalysisMoreSource();
          dispatchedAnalysisMoreSource.current = dispatch(
            fetchMoreAnalysisSourceThunk(pageState)
          );
        }
      }, 500),
    [abortDispatchedAnalysisMoreSource, abortDispatchedAnalysisSource, dispatch]
  );

  const resetAnalysisSource = useCallback(() => {
    dispatch(resetAnalysisSourceAction({}));
  }, [dispatch]);

  const handleSearch = useCallback(
    (newSearchText: string) => {
      setLoaderFlag(true);
      resetAnalysisSource();
      setSearchText(newSearchText);
      refSourceTable?.current?.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    [resetAnalysisSource]
  );

  const fetchMoreData = useCallback(() => {
    setAnalysisSourceStart(
      () => (bottomTargetPageCount - 1) * pubAnalysisSourcePageState.count
    );
    setLoaderFlag(false);
  }, [pubAnalysisSourcePageState.count, bottomTargetPageCount]);

  const clearAnalysisSourceBreakdown = useMemo(
    () =>
      throttle((state: any) => {
        dispatch(clearTypeBreakdownDataAction(state));
        dispatch(clearTrendBreakdownDataAction(state));
        dispatch(clearTerritoryBreakdownDataAction(state));
      }, 500),
    [dispatch]
  );

  const sortAnalysisSourceBy = useCallback(
    (columnName: string) => {
      setIsSortingLoading(true);
      resetAnalysisSource();
      setLoaderFlag(true);
      setAnalysisSourceSortColumn(columnName);
      if (pubAnalysisSourcePageState.sortColumn !== columnName) {
        setAnalysisSourceAscOrder(true);
      } else {
        setAnalysisSourceAscOrder(!analysisSourceAscOrder);
      }
    },
    [
      analysisSourceAscOrder,
      pubAnalysisSourcePageState.sortColumn,
      resetAnalysisSource,
    ]
  );

  useEffect(() => {
    return () => {
      abortDispatchedAnalysisSource();
      abortDispatchedAnalysisMoreSource();
      abortDispatchedDownload();
      // clear analysis song page state (keep)
      dispatch(
        updateAnalysisSourcePageStateAction(PubAnalysisSourcePageInitialState)
      );
    };
  }, [
    abortDispatchedAnalysisMoreSource,
    abortDispatchedAnalysisSource,
    abortDispatchedDownload,
    dispatch,
  ]);

  useEffect(() => {
    const hasMore = source?.data?.sources?.length < source?.total;
    if (hasMore && bottomTargetPageCount > 1) {
      fetchMoreData();
    }
  }, [
    fetchMoreData,
    bottomTargetPageCount,
    source?.data?.sources?.length,
    source?.total,
  ]);

  useEffect(() => {
    if (
      !authState?.isAuthenticated ||
      clientSelection?.selectedClients?.length === 0 ||
      pubAnalysisHeaderState.selectedPeriods.length === 0
    ) {
      return;
    }
    const pubAnalysisSourceState = {
      ...pubAnalysisSourcePageState,
      data: {
        periodIds: pubAnalysisHeaderState.selectedPeriods,
        clientIds: clientSelection?.selectedClients?.map((c) => c.id) || [],
        clientLists: [],
      },
      start: analysisSourceStart,
      sortColumn: analysisSourceSortColumn,
      filterText: searchText,
      isAsc: analysisSourceAscOrder,
    };
    if (!deepCompare(pubAnalysisSourceState, pubAnalysisSourcePageState)) {
      dispatch(updateAnalysisSourcePageStateAction(pubAnalysisSourceState));
      handleAnalysisSourceFetch(pubAnalysisSourceState);
      clearAnalysisSourceBreakdown(pubAnalysisSourceState);
      setSelectedSource(undefined);
    }
    if (
      !deepCompare(
        //ignore start value in deep compare
        { ...pubAnalysisSourceState, start: null },
        { ...pubAnalysisSourcePageState, start: null }
      )
    ) {
      setAnalysisSourceStart(0);
      setBottomTargetPageCount(1);
    }
  }, [
    analysisSourceAscOrder,
    analysisSourceSortColumn,
    analysisSourceStart,
    authState?.isAuthenticated,
    clientSelection?.selectedClients,
    dispatch,
    handleAnalysisSourceFetch,
    pubAnalysisSourcePageState,
    pubAnalysisHeaderState.selectedPeriods,
    searchText,
    setBottomTargetPageCount,
    clearAnalysisSourceBreakdown,
  ]);

  useEffect(() => {
    const prevPageCount =
      analysisSourceStart / pubAnalysisSourcePageState.count;
    if (prevPageCount > bottomTargetPageCount)
      setBottomTargetPageCount(prevPageCount + 1);
  }, [
    analysisSourceStart,
    bottomTargetPageCount,
    pubAnalysisSourcePageState.count,
    setBottomTargetPageCount,
  ]);

  useEffect(() => {
    if (source?.data?.sources?.length) {
      setIsSortingLoading(false);
    }
  }, [source]);

  useEffect(() => {
    if (!isSortingLoading) {
      setTotalAmount({
        currency: source?.data?.totalRoyalty?.currency,
        value: source?.data?.totalRoyalty?.formattedLong,
      });
    }
  }, [
    source?.data?.totalRoyalty?.currency,
    source?.data?.totalRoyalty?.formattedLong,
    isSortingLoading,
  ]);

  const handleDownloadBtn = useCallback(() => {
    const params = {
      data: {
        periodIds: pubAnalysisHeaderState.selectedPeriods,
        clientIds: clientSelection?.selectedClients?.map((c) => c.id) || [],
        analyzeBy: "BY_SOURCE",
      },
      fileName: "Royalties_BY_SOURCE_" + getToday() + "-" + getNow() + ".xlsx",
    };
    abortDispatchedDownload();
    dispatchedDownload.current = dispatch(pubAnalysisSourceDownload(params));
  }, [
    abortDispatchedDownload,
    clientSelection.selectedClients,
    dispatch,
    pubAnalysisHeaderState.selectedPeriods,
  ]);

  const handleSourceClick = useCallback(
    (clickedSource: any) => {
      !selectedSource ||
      (selectedSource && selectedSource.sourceCode !== clickedSource.sourceCode)
        ? setSelectedSource(clickedSource)
        : setSelectedSource(undefined);
    },
    [selectedSource]
  );

  return (
    <Grid>
      {(pubAnalysisSourceStatus === "loading" ||
        pubAnalysisSourceDownloadStatus === "loading") &&
        loaderFlag && <Loader />}
      <div className={styles.analysis}>
        <PubAnalysisHeader
          tab="source"
          documentType="PUBLISHING"
          onDownloadBtnClick={handleDownloadBtn}
        />
        <div ref={observerTopTarget} id="observerTopTargetId" />
        {pubAnalysisNoDataFlag !== undefined && (
          <div
            id="tab-header"
            className={`sticky top-0 z-[5] ${styles.tabHeader} ${
              topTargetIsOnScreen ? "" : "shadow-lg"
            }`}
          >
            <div className={styles.title}>
              <p className={styles.period}>
                {
                  pubAnalysisHeaderState.tabSelectedPeriodTitle[
                    pubAnalysisHeaderState.selectedPeriodText?.titleVariant
                  ]
                }{" "}
                <span>{pubAnalysisHeaderState.selectedPeriodText.details}</span>
              </p>
              {(!!source?.data?.sources?.length || isSortingLoading) && (
                <p className={styles.totalAmount}>
                  {totalAmount.currency}
                  <strong>{` ${totalAmount.value}`}</strong>
                </p>
              )}
            </div>
            <div className={styles.actions}>
              <Search
                placeholderText={t("analysis.source.searchTextPlaceholder")}
                searchText={pubAnalysisSourcePageState.filterText}
                resultsCount={source.total}
                onSearchChange={handleSearch}
                showBackToTop={!topTargetIsOnScreen}
                onBackToTopClick={windowScrollToTop}
                status={pubAnalysisSourceStatus}
                className={styles.search}
              />
            </div>
          </div>
        )}
        {pubAnalysisNoDataFlag && pubAnalysisNoDataFlag?.length && (
          <div className="pl-10 text-xs sm:text-sm">
            {t(`analysis.${pubAnalysisNoDataFlag}`)}
          </div>
        )}
        {pubAnalysisNoDataFlag === "" && (
          <div className={styles.analysisSource}>
            <div className={styles.contentContainer}>
              <div
                className={`${styles.leftSide} ${
                  selectedSource ? styles.withSelection : ""
                }`}
              >
                <AnalysisSourceLeftSide
                  bottomTargetPageCount={bottomTargetPageCount}
                  selectedClients={clientSelection.selectedClients.map(
                    (c) => c.id
                  )}
                  sortAnalysisSourceBy={sortAnalysisSourceBy}
                  handleSourceClick={handleSourceClick}
                  selectedPeriods={pubAnalysisHeaderState?.selectedPeriods}
                  selectedSource={selectedSource}
                  sources={source}
                  documentType="PUBLISHING"
                  windowWidth={windowWidth}
                />
              </div>
              {selectedSource && windowWidth && windowWidth >= 1200 && (
                <AnalysisSourceRightSide
                  selectedSource={selectedSource}
                  topTargetIsOnScreen={topTargetIsOnScreen}
                  selectedPeriods={pubAnalysisHeaderState?.selectedPeriods}
                  selectedClients={clientSelection.selectedClients.map(
                    (c) => c.id
                  )}
                  handleBreakdownClose={handleSourceClick}
                  documentType="PUBLISHING"
                />
              )}
            </div>
          </div>
        )}
        <div
          className={`${
            source?.data?.sources ||
            source?.data?.sources?.length === source?.total
              ? "hidden"
              : ""
          }`}
          ref={observerBottomTarget}
        ></div>
      </div>
    </Grid>
  );
};

export default PubAnalysisSource;
