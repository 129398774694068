import React, { useCallback, useEffect, useRef } from "react";
import styles from "./range.module.scss";
import { RangeProps } from "../../types/props";

export default function Range(props: RangeProps): JSX.Element {
  const {
    min,
    max,
    selectedStart,
    selectedEnd,
    className = "",
    showValues = false,
    onChange,
  } = props;

  const range = useRef<HTMLDivElement>(null);
  const thumbLeft = useRef<HTMLInputElement>(null);
  const thumbRight = useRef<HTMLInputElement>(null);

  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    const lowerVal = Math.min(selectedStart, selectedEnd);
    const higherVal = Math.max(selectedStart, selectedEnd);
    const minPercent = getPercent(lowerVal);
    const maxPercent = getPercent(higherVal);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [selectedStart, selectedEnd, getPercent]);

  function thumbChange(e: React.ChangeEvent<HTMLInputElement>) {
    const isLeftMoving = thumbLeft.current?.contains(e.target);
    const isRightMoving = thumbRight.current?.contains(e.target);

    let num1 = Number(thumbLeft.current?.value);
    let num2 = Number(thumbRight.current?.value);
    const newNum = Number(e.target.value);

    const isSwapNeeded =
      (isLeftMoving && newNum > num2) || (isRightMoving && newNum < num1);

    if (isLeftMoving) num1 = newNum;
    else if (isRightMoving) num2 = newNum;

    const minVal = Math.min(num1, num2);
    const maxVal = Math.max(num1, num2);

    if (isSwapNeeded) onChange(maxVal, minVal);
    else onChange(minVal, maxVal);
  }

  return (
    <div className={`${styles.range}  ${className}`}>
      <input
        type="range"
        min={min}
        max={max}
        value={selectedStart}
        onChange={thumbChange}
        className={`${styles.thumb} ${styles.thumbLeft}`}
        ref={thumbLeft}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={selectedEnd}
        onChange={thumbChange}
        className={`${styles.thumb} ${styles.thumbRight}`}
        ref={thumbRight}
      />
      <div className={styles.rangeTrack}>
        <div className={styles.trackBase} />
        <div ref={range} className={styles.trackColor} />
      </div>
      {showValues && <div className={styles.leftValue}>{selectedStart}</div>}
      {showValues && <div className={styles.rightValue}>{selectedEnd}</div>}
    </div>
  );
}
