import React from "react";
function TrashIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 13 15"
      name="TrashIcon"
      {...props}
    >
      <defs>
        <path
          d="M0.902255639,2.40601504 L1.40375807,12.4360637 C1.45898728,13.5406478 2.39191729,14.4360902 3.49787088,14.4360902 L9.13370806,14.4360902 C10.2350254,14.4360902 11.1730301,13.5318793 11.2278209,12.4360637 L11.7293233,2.40601504 L0.902255639,2.40601504 Z"
          id="path-1"
        ></path>
        <mask
          id="mask-2"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="10.8270677"
          height="12.0300752"
          fill="white"
        >
          <use xlinkHref="#path-1"></use>
        </mask>
        <polygon
          id="path-3"
          points="3.60902256 5.41353383 3.90977444 11.4285714 4.5112782 11.4285714 4.21052632 5.41353383"
        ></polygon>
        <mask
          id="mask-4"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="0.902255639"
          height="6.01503759"
          fill="white"
        >
          <use xlinkHref="#path-3"></use>
        </mask>
        <polygon
          id="path-5"
          points="3.94773555 1.20300752 0 1.20300752 0 1.80451128 12.6315789 1.80451128 12.6315789 1.20300752"
        ></polygon>
        <mask
          id="mask-6"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="12.6315789"
          height="0.601503759"
          fill="white"
        >
          <use xlinkHref="#path-5"></use>
        </mask>
        <rect
          id="path-7"
          x="4.81203008"
          y="0"
          width="3.0075188"
          height="1.80451128"
        ></rect>
        <mask
          id="mask-8"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="3.0075188"
          height="1.80451128"
          fill="white"
        >
          <use xlinkHref="#path-7"></use>
        </mask>
        <rect
          id="path-9"
          x="6.01503759"
          y="5.41353383"
          width="0.601503759"
          height="6.01503759"
        ></rect>
        <mask
          id="mask-10"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="0.601503759"
          height="6.01503759"
          fill="white"
        >
          <use xlinkHref="#path-9"></use>
        </mask>
        <polygon
          id="path-11"
          points="8.42105263 5.41353383 8.12030075 11.4285714 8.72180451 11.4285714 9.02255639 5.41353383"
        ></polygon>
        <mask
          id="mask-12"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="0.902255639"
          height="6.01503759"
          fill="white"
        >
          <use xlinkHref="#path-11"></use>
        </mask>
      </defs>
      <g
        id="Clients---desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="10.3-l-Clients---by-default---account-with-more-than-1-clients---lists-tab-with-lists"
          transform="translate(-795.000000, -338.000000)"
          stroke="currentColor"
        >
          <g
            id="All-Clients-List"
            transform="translate(190.000000, 220.000000)"
          >
            <g id="Trash" transform="translate(605.000000, 118.000000)">
              <use
                id="Rectangle-61"
                mask="url(#mask-2)"
                strokeWidth="1.6"
                xlinkHref="#path-1"
              ></use>
              <use
                id="Rectangle-62"
                mask="url(#mask-4)"
                strokeWidth="4"
                fill="#FFFFFF"
                xlinkHref="#path-3"
              ></use>
              <use
                id="Rectangle-65"
                mask="url(#mask-6)"
                strokeWidth="4"
                fill="#FFFFFF"
                xlinkHref="#path-5"
              ></use>
              <use
                id="Rectangle-66"
                mask="url(#mask-8)"
                strokeWidth="2"
                xlinkHref="#path-7"
              ></use>
              <use
                id="Rectangle-63"
                mask="url(#mask-10)"
                strokeWidth="4"
                fill="#FFFFFF"
                xlinkHref="#path-9"
              ></use>
              <use
                id="Rectangle-64"
                mask="url(#mask-12)"
                strokeWidth="2"
                fill="#FFFFFF"
                xlinkHref="#path-11"
              ></use>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TrashIcon;
