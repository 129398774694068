import AnalysisSongBreakdown from "../analysisSongBreakdown/AnalysisSongBreakdown";
import styles from "../analysis.module.scss";

interface RideSideProps {
  selectedSong: any;
  topTargetIsOnScreen: boolean;
  selectedPeriods: number[];
  selectedClients: number[];
  handleBreakdownClose?: (clickedSong: any | undefined) => void;
}

const AnalysisSongRightSide = (props: RideSideProps) => {
  const {
    selectedSong,
    topTargetIsOnScreen,
    selectedPeriods,
    selectedClients,
    handleBreakdownClose,
  } = props;

  return (
    <div className={`${styles.rightSide} ${selectedSong ? " md:flex-1" : ""}`}>
      {selectedSong && (
        <div
          className={`${styles.rightSideSelected} ${
            selectedSong ? styles.isOpen : ""
          } ${topTargetIsOnScreen ? "" : `${styles.isOpenFixed}`} `}
        >
          <AnalysisSongBreakdown
            isSelected={selectedSong}
            song={{
              songId: selectedSong.id,
              name: selectedSong.name,
              composer: selectedSong.composers,
            }}
            periodIds={selectedPeriods}
            clientIds={selectedClients}
            mobileView={false}
            handleClose={handleBreakdownClose}
          />
        </div>
      )}
    </div>
  );
};

export default AnalysisSongRightSide;
