import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { RecAnalysisAlbumProps } from "../../../../features/recording/analysis/album/recAnalysisAlbumSlice";
import { ButtonVariantEnum } from "../../../types/enums";
import Button from "../../../atom/Button";
import AnalysisAlbumBreakdown from "../analysisAlbumBreakdown/AnalysisAlbumBreakdown";
import styles from "../analysis.module.scss";
import AnalysisAlbumInfiniteTableHeader from "./AnalysisAlbumInfiniteTableHeader";

interface LeftSideProps {
  bottomTargetPageCount: number;
  selectedClients: any[];
  filterAnalysisAlbumsWith: (columnName: string) => void;
  handleAlbumClick: (clickedSong: any) => void;
  selectedPeriods: number[];
  selectedAlbum: any;
  albums: RecAnalysisAlbumProps;
  windowWidth: number | undefined;
}

const AnalysisAlbumLeftSide = (props: LeftSideProps) => {
  const { t } = useTranslation();
  const {
    bottomTargetPageCount,
    selectedClients,
    filterAnalysisAlbumsWith,
    handleAlbumClick,
    selectedPeriods,
    selectedAlbum,
    albums,
    windowWidth,
  } = props;
  return (
    <div className={styles.tabTable}>
      <AnalysisAlbumInfiniteTableHeader
        filterAnalysisAlbumsWith={filterAnalysisAlbumsWith}
      />
      <div>
        <table className={`w-full`}>
          <tbody>
            {albums?.data?.albums?.map((album: any) => (
              <Fragment key={album.productKey}>
                <tr
                  onClick={() => handleAlbumClick(album)}
                  className={`${styles.desktopRow} ${
                    selectedAlbum &&
                    album.productKey === selectedAlbum.productKey
                      ? styles.selected
                      : ""
                  }`}
                >
                  <td className={styles.albumCol}>
                    <p>{album.title}</p>
                    <p className={"font-light"}>
                      {t("analysis.albums.barCode")}: {album.barCode}
                    </p>
                    <p className={"font-light"}>
                      {t("analysis.albums.productCode")}: {album.productCode}
                    </p>
                  </td>
                  <td className={styles.configDescCol}>{album.configDesc}</td>
                  <td className={styles.artistCol}>{album.artist}</td>
                  <td className={styles.royaltiesCol}>
                    {album.formattedRoyalties?.currency}{" "}
                    {album.formattedRoyalties?.formattedLong}
                  </td>
                  <td className={styles.digitalUnitsCol}>
                    {album.digitalUnits}
                  </td>
                  <td className={styles.physicalUnitsCol}>
                    {album.physicalUnits}
                  </td>
                  <td className="text-right">
                    <Button
                      className={`${
                        selectedAlbum &&
                        album.productKey === selectedAlbum.productKey
                          ? "block"
                          : "hidden"
                      } ${styles.unselectBtn}`}
                      variant={ButtonVariantEnum.cleanCta}
                      onClick={() => handleAlbumClick(album.productKey)}
                    >
                      +
                    </Button>
                  </td>
                </tr>
                <tr
                  className={`${styles.mobileRow} ${
                    selectedAlbum?.productKey === album.productKey
                      ? styles.expanded
                      : ""
                  }`}
                  onClick={() => handleAlbumClick(album)}
                >
                  <td className={styles.mobileCol}>
                    <div className={"relative"}>
                      <p>{album.title}</p>
                      <p className={"font-light"}>
                        {t("analysis.albums.barCode")}: {album.barCode}
                      </p>
                      <p className={"font-light"}>
                        {t("analysis.albums.productCode")}: {album.productCode}
                      </p>
                      <Button
                        className={`${
                          selectedAlbum &&
                          album.productKey === selectedAlbum.productKey
                            ? "block"
                            : "hidden"
                        } ${styles.unselectBtn}`}
                        variant={ButtonVariantEnum.cleanCta}
                        onClick={() => handleAlbumClick(album.productKey)}
                      >
                        +
                      </Button>
                    </div>
                    <dl>
                      <dt>{t("analysis.albums.configuration")}</dt>
                      <dd>{album.configDesc}</dd>
                    </dl>
                    <dl>
                      <dt>{t("analysis.albums.artist")}</dt>
                      <dd>{album.artist}</dd>
                    </dl>
                    <dl>
                      <dt>{t("analysis.albums.royalties")}</dt>
                      <dd>
                        {album.formattedRoyalties?.currency}{" "}
                        {album.formattedRoyalties?.formattedLong}
                      </dd>
                    </dl>
                    <dl>
                      <dt>{t("analysis.albums.digitalUnits")}</dt>
                      <dd>{album.digitalUnits}</dd>
                    </dl>
                    <dl>
                      <dt>{t("analysis.albums.physicalUnits")}</dt>
                      <dd>{album.physicalUnits}</dd>
                    </dl>
                  </td>
                </tr>
                {selectedAlbum &&
                  selectedAlbum.productKey === album.productKey && (
                    <tr className={styles.mobileRow}>
                      <td colSpan={4}>
                        {windowWidth && windowWidth < 1200 && (
                          <AnalysisAlbumBreakdown
                            isSelected={selectedAlbum}
                            productKey={selectedAlbum.productKey}
                            periodIds={selectedPeriods}
                            clientIds={selectedClients.map((c: any) => c.id)}
                            mobileView={true}
                            handleClose={handleAlbumClick}
                          />
                        )}
                      </td>
                    </tr>
                  )}
              </Fragment>
            ))}
          </tbody>
        </table>
        {albums?.data?.albums?.length < albums?.total && (
          <h5 className="p-3">{t("app.loading")}</h5>
        )}
        {bottomTargetPageCount > 0 &&
          albums?.total !== 0 &&
          albums?.data?.albums?.length === albums?.total && (
            <p className="py-8 text-center">
              <b>{t("analysis.seenAll")}</b>
            </p>
          )}
      </div>
    </div>
  );
};

export default AnalysisAlbumLeftSide;
