import React from "react";
function DashboardIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 26 22"
      name="DashboardIcon"
      {...props}
    >
      <path d="M10.02 0H.255A.256.256 0 0 0 0 .255v9.765c0 .141.115.256.256.256h9.763a.255.255 0 0 0 .255-.256V.255A.255.255 0 0 0 10.02 0ZM.51.51h9.254v9.255H.51V.511ZM21.716 0h-9.764a.256.256 0 0 0-.256.255v9.765c0 .141.115.256.256.256h9.764a.255.255 0 0 0 .255-.256V.255A.255.255 0 0 0 21.716 0Zm-9.509.51h9.253v9.255h-9.253V.511ZM10.02 11.689H.255a.256.256 0 0 0-.256.255v9.765c0 .141.115.256.256.256h9.763a.255.255 0 0 0 .255-.256v-9.765a.255.255 0 0 0-.255-.255Zm-9.51.51h9.254v9.255H.51V12.2ZM21.716 11.689h-9.764a.256.256 0 0 0-.256.255v9.765c0 .141.115.256.256.256h9.764a.255.255 0 0 0 .255-.256v-9.765a.255.255 0 0 0-.255-.255Zm-9.509.51h9.253v9.255h-9.253V12.2Z" />
    </svg>
  );
}

export default DashboardIcon;
