import request from "../../utils/requestUtils";
import type { AdvRequestPayload } from "../../types/payload";
import { USER_API_RESOURCE_BASE_URLS } from "../../config/userEndpoints.const";

export function fetchSendContactUsPayload(payload: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.contactUs}`, {
    method: "POST",
    body: JSON.stringify(payload),
    signal: thunkAPI?.signal,
  });
}

export function fetchSendAdvRequestPayload(
  documentType: "PUBLISHING" | "RECORDING" | "MECHANICAL",
  payload: AdvRequestPayload,
  thunkAPI?: any
) {
  return request(
    `${USER_API_RESOURCE_BASE_URLS.advancerequestSend}?documentType=${documentType}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      signal: thunkAPI?.signal,
    }
  );
}
