import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { SortDirectionEnum, StatusEnum } from "../../../../app/types/enums";
import { TableColumnsVariantEnum } from "./systemClosingDates.const";

import { DEFAULT_ROWS_PER_PAGE } from "../../common/adminEndpoints.const";

import { getSystemClosingDates } from "./systemClosingDatesAPI";

import { useFetch } from "../../common/hooks/useFetch";
import { CommonRequestBodyTableState } from "../../../../app/types/props";

export type SystemClosingDatesItem = {
  id: number;
  systemCode: string;
  paymentCycle: string;
  paymentDays: string;
  systemClosingDate: string;
  newClosingDate: string;
  status: string;
  createdBy: string;
  amendedBy: string;
  amendedDate: string;
};

type SystemClosingDatesInitialState = {
  systemClosingDatesState: CommonRequestBodyTableState;
  tableData: {
    list: SystemClosingDatesItem[] | null;
    count: number | null;
    totalCount: number | null;
  };
  status: StatusEnum;
};

export const getSystemClosingDatesThunk = createAsyncThunk(
  "getSystemClosingDates",
  async (payload: any, { rejectWithValue }) => {
    try {
      const requestData = {
        ...payload,
      } as any;

      const fetchGroup = await useFetch();
      const response = await fetchGroup({
        data: requestData,
        fetchFunction: getSystemClosingDates,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: SystemClosingDatesInitialState = {
  systemClosingDatesState: {
    filterText: "",
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn: TableColumnsVariantEnum.status,
          direction: SortDirectionEnum.Ascending,
        },
      ],
    },
  },
  tableData: {
    list: null,
    count: 0,
    totalCount: 0,
  },
  status: StatusEnum.Idle,
};

export const systemClosingDatesSlice = createSlice({
  name: "systemClosingDates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSystemClosingDatesThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableData.list = action.payload.data;
        state.tableData.totalCount = action.payload.totalCount;
        state.tableData.count = action.payload.count;
      })
      .addCase(getSystemClosingDatesThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getSystemClosingDatesThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
        state.tableData.list = [];
        state.tableData.totalCount = null;
        state.tableData.count = null;
      });
  },
});
export const systemClosingDatesSelector = (state: RootState) =>
  state.systemClosingDates;
export default systemClosingDatesSlice.reducer;
