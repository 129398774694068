import request from "../../../../app/utils/requestUtils";
import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export const getPendingRequestList = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.accessRequestsPending}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const updateAccountStatus = (payload: any) => {
  const { id, data } = payload;
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.accessRequests}/${id}/status`,
    {
      method: "PUT",
      body: JSON.stringify(data),
    }
  );
};

export const getOriginatingSystem = () => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.clientOwningSystems}`, {
    method: "GET",
  });
};

export const updateAccount = (payload: any) => {
  const { id, data } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.accessRequests}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};
