import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import {
  fetchDashboardStats,
  fetchTopRoyaltySources,
  fetchTopPerformingSongs,
  fetchLatestStatements,
  fetchLatestStatementsFile,
  fetchRoyaltiesTrend,
  fetchTopPerformingTerritories,
  FetchDashboardPipelineBreakdown_Songs,
  FetchDashboardPipelineBreakdown_Sources,
  FetchDashboardPipelineBreakdown_Type,
  fetchPeriods,
} from "./dashboardAPI";
import {
  DashboardStatProps,
  PubDashboardPipelineBreakdownStateProps,
} from "../../../app/types/props";
import { FetchDashboardPipelineBreakdown_Territory } from "../../appMain/appMainAPI";
import type { RecDashboardTopPerformanceTerritoriesEntry } from "./types";

export interface DashboardState {
  dashboardPageState: {
    data: {
      periodIds: Array<number>;
      clientIds: any;
      clientLists: any;
    };
  };
  dashboardRoyaltiesTrendState: {
    data: {
      periodIds: Array<number>;
      clientIds: any;
      clientLists: any;
      currencyId: string;
    };
  };
  stats: DashboardStatProps;
  dashboardStatsStatus: "idle" | "loading" | "failed";
  dashboardStatsDownloadStatus: "idle" | "loading" | "failed";
  topRoyaltySources: {
    sources: Array<any>;
    totalRoyalty: any;
    total: number;
  };
  dashboardTopRoyaltySourcesStatus: "noData" | "idle" | "loading" | "failed";
  topPerformingSongs: {
    songs: Array<any>;
    total: number;
  };
  dashboardTopPerformingSongsStatus: "noData" | "idle" | "loading" | "failed";
  topPerformingTerritories: RecDashboardTopPerformanceTerritoriesEntry[];
  dashboardTopPerformingTerritoriesStatus:
    | "noData"
    | "idle"
    | "loading"
    | "failed";
  latestStatements: {
    list: Array<any>;
  };
  dashboardLatestStatementsStatus: "noData" | "idle" | "loading" | "failed";
  royaltiesTrend: {
    userType: string;
    periodDataList: Array<any>;
    allPeriodsData: any;
  };
  dashboardRoyaltiesTrendsStatus: "noData" | "idle" | "loading" | "failed";
  dashboardPipelineBreakdown: PubDashboardPipelineBreakdownStateProps;
  dashboardStatus: "idle" | "loading" | "failed";
  dashboardPeriods: Array<any>;
  dashboardPipelinePeriods: number | null;
  dashboardPeriodStatus: "noData" | "idle" | "loading" | "failed";
}

const initialState: DashboardState = {
  dashboardPageState: {
    data: {
      periodIds: [],
      clientIds: [],
      clientLists: [],
    },
  },
  dashboardRoyaltiesTrendState: {
    data: {
      periodIds: [],
      clientIds: [],
      clientLists: [],
      currencyId: "",
    },
  },
  stats: {
    statementBalance: undefined,
    pipelineRoyalties: undefined,
    currentBalance: undefined,
    numberOfDaysTillPayment: undefined,
  },
  dashboardStatsStatus: "idle",
  dashboardStatsDownloadStatus: "idle",
  topRoyaltySources: {
    sources: [],
    totalRoyalty: {},
    total: 0,
  },
  dashboardTopRoyaltySourcesStatus: "noData",
  topPerformingSongs: {
    songs: [],
    total: 0,
  },
  dashboardTopPerformingSongsStatus: "idle",
  topPerformingTerritories: [],
  dashboardTopPerformingTerritoriesStatus: "noData",
  latestStatements: {
    list: [],
  },
  dashboardLatestStatementsStatus: "noData",
  royaltiesTrend: {
    userType: "PUBLISHING",
    periodDataList: [],
    allPeriodsData: {},
  },
  dashboardRoyaltiesTrendsStatus: "noData",
  dashboardPipelineBreakdown: {
    typeStatus: "noData",
    typeData: null,
    territoryStatus: "noData",
    territoryData: null,
    songStatus: "noData",
    songData: null,
    sourceStatus: "noData",
    sourceData: null,
    isModalOpen: false,
  },
  dashboardStatus: "idle",
  dashboardPeriods: [],
  dashboardPipelinePeriods: null,
  dashboardPeriodStatus: "noData",
};

export const fetchDashboardStatsThunk = createAsyncThunk(
  "pubDashboard/stats",
  async (payload: any, thunkAPI) => {
    const response = await fetchDashboardStats(payload, thunkAPI);
    return response;
  }
);

export const fetchTopRoyaltySourcesThunk = createAsyncThunk(
  "pubDashboard/toproyalty",
  async (payload: any, thunkAPI) => {
    const response = await fetchTopRoyaltySources(payload, thunkAPI);
    return response;
  }
);

export const fetchTopPerformingTerritoriesThunk = createAsyncThunk(
  "pubDashboard/topPerformingTerritories",
  async (
    payload: {
      clientIds: number[];
      clientLists: any[];
      periodIds: number[];
    },
    thunkAPI
  ) => {
    const response = await fetchTopPerformingTerritories(payload, thunkAPI);
    return response?.resArr;
  }
);

export const fetchTopPerformingSongsThunk = createAsyncThunk(
  "pubDashboard/topperformingsongs",
  async (payload: any, thunkAPI) => {
    const response = await fetchTopPerformingSongs(payload, thunkAPI);
    return response;
  }
);

export const fetchLatestStatementsThunk = createAsyncThunk(
  "publishing/latestStatements",
  async (payload: any, thunkAPI) => {
    const response = await fetchLatestStatements(payload, thunkAPI);
    return response?.resArr;
  }
);

export const fetchLatestStatementsFileDownloadThunk = createAsyncThunk(
  "publishing/latestStatements/fileDownload",
  async (payload: any, thunkAPI) => {
    const response = await fetchLatestStatementsFile(payload, thunkAPI);
    return response;
  }
);

export const fetchRoyaltiesTrendThunk = createAsyncThunk(
  "pubDashboard/royaltiesTrend",
  async (payload: any, thunkAPI) => {
    const response = await fetchRoyaltiesTrend(payload, thunkAPI);
    return response;
  }
);

export const FetchDashboardPipelineBreakdown_Type_Thunk = createAsyncThunk(
  "publishing/FetchDashboardPipelineBreakdown_Type",
  async (params: any, thunkAPI) => {
    return await FetchDashboardPipelineBreakdown_Type(params, thunkAPI);
  }
);

export const FetchDashboardPipelineBreakdown_Songs_Thunk = createAsyncThunk(
  "publishing/FetchDashboardPipelineBreakdown_Songs",
  async (params: any, thunkAPI) => {
    return await FetchDashboardPipelineBreakdown_Songs(params, thunkAPI);
  }
);

export const FetchDashboardPipelineBreakdown_Territory_Thunk = createAsyncThunk(
  "publishing/FetchDashboardPipelineBreakdown_Territory",
  async (params: any, thunkAPI) => {
    return await FetchDashboardPipelineBreakdown_Territory(params, thunkAPI);
  }
);

export const FetchDashboardPipelineBreakdown_Sources_Thunk = createAsyncThunk(
  "publishing/FetchDashboardPipelineBreakdown_Sources",
  async (params: any, thunkAPI) => {
    return await FetchDashboardPipelineBreakdown_Sources(params, thunkAPI);
  }
);

export const FetchDashboardPeriods_Thunk = createAsyncThunk(
  "publishing/FetchDashboardPeriods",
  async (params: any, thunkAPI) => {
    const periodData = await fetchPeriods(params, thunkAPI);
    const allPeriods: number[] = [];
    const pipelinePeriod: number = periodData?.pipelinePeriod;

    periodData?.years?.forEach((periodYear: any) => {
      allPeriods.push(...periodYear.periods);
    });
    return { allPeriods, pipelinePeriod };
  }
);

export const pubDashboardSlice = createSlice({
  name: "pubDashboard",
  initialState,
  reducers: {
    updateDashboardPageState: (state, action: any) => {
      state.dashboardPageState.data.periodIds = action.payload.data.periodIds;
      state.dashboardPageState.data.clientIds = action.payload.data.clientIds;
      state.dashboardPageState.data.clientLists =
        action.payload.data.clientLists;
    },
    updateDashboardPeriodsToInitialState: (state) => {
      state.dashboardPeriods = initialState.dashboardPeriods;
      state.dashboardPipelinePeriods = initialState.dashboardPipelinePeriods;
    },
    updateDashboardRoyaltiesTrendPageState: (state, action: any) => {
      state.dashboardRoyaltiesTrendState.data.periodIds =
        action.payload.periodIds;
      state.dashboardRoyaltiesTrendState.data.clientIds =
        action.payload.clientIds;
      state.dashboardRoyaltiesTrendState.data.clientLists =
        action.payload.clientLists;
      state.dashboardRoyaltiesTrendState.data.currencyId =
        action.payload.currencyId;
    },
    toggleDashboardPipelineBreakdownModal: (state, action) => {
      state.dashboardPipelineBreakdown.isModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // download files
      .addCase(
        fetchLatestStatementsFileDownloadThunk.fulfilled,
        (state, action: any) => {
          state.dashboardStatsDownloadStatus = "idle";
        }
      )
      .addCase(fetchLatestStatementsFileDownloadThunk.pending, (state) => {
        state.dashboardStatsDownloadStatus = "loading";
      })
      .addCase(fetchLatestStatementsFileDownloadThunk.rejected, (state) => {
        state.dashboardStatsDownloadStatus = "failed";
      })
      .addCase(fetchDashboardStatsThunk.fulfilled, (state, action: any) => {
        state.dashboardStatus = "idle";
        state.dashboardStatsStatus = "idle";
        state.stats.currentBalance = action.payload?.currentBalance;
        state.stats.statementBalance = action.payload?.statementBalance;
        state.stats.pipelineRoyalties = action.payload?.pipelineRoyalties;
        state.stats.numberOfDaysTillPayment =
          action.payload?.numberOfDaysTillPayment;
        state.stats.newStatementDueDays = action.payload?.newStatementDueDays; //v2
        state.stats.lastStatementBalance = action.payload?.lastStatementBalance; //v2
      })
      .addCase(fetchDashboardStatsThunk.pending, (state, action: any) => {
        state.dashboardStatus = "loading";
        state.dashboardStatsStatus = "loading";
      })
      .addCase(fetchDashboardStatsThunk.rejected, (state, action: any) => {
        state.dashboardStatus = "failed";
        state.dashboardStatsStatus = "failed";
      })
      .addCase(
        fetchTopPerformingTerritoriesThunk.fulfilled,
        (state, action: any) => {
          state.dashboardStatus = "idle";
          state.dashboardTopPerformingTerritoriesStatus = "idle";
          state.topPerformingTerritories = action.payload;
        }
      )
      .addCase(fetchTopPerformingTerritoriesThunk.pending, (state) => {
        state.dashboardStatus = "loading";
        state.dashboardTopPerformingTerritoriesStatus = "loading";
      })
      .addCase(fetchTopPerformingTerritoriesThunk.rejected, (state) => {
        state.dashboardStatus = "failed";
        state.dashboardTopPerformingTerritoriesStatus = "failed";
      })
      .addCase(fetchTopRoyaltySourcesThunk.fulfilled, (state, action: any) => {
        state.dashboardStatus = "idle";
        state.dashboardTopRoyaltySourcesStatus = "idle";
        state.topRoyaltySources.sources = action.payload?.data?.sources || [];
        state.topRoyaltySources.totalRoyalty =
          action.payload?.data?.totalRoyalty;
        state.topRoyaltySources.total = action.payload?.total;
      })
      .addCase(fetchTopRoyaltySourcesThunk.pending, (state) => {
        state.dashboardStatus = "loading";
        state.dashboardTopRoyaltySourcesStatus = "loading";
      })
      .addCase(fetchTopRoyaltySourcesThunk.rejected, (state) => {
        state.dashboardStatus = "failed";
        state.dashboardTopRoyaltySourcesStatus = "failed";
      })
      .addCase(fetchTopPerformingSongsThunk.fulfilled, (state, action: any) => {
        state.dashboardTopPerformingSongsStatus = "idle";
        state.topPerformingSongs.songs = action.payload?.data?.songs;
        state.topPerformingSongs.total = action.payload?.total;
      })
      .addCase(fetchTopPerformingSongsThunk.pending, (state) => {
        state.dashboardStatus = "loading";
        state.dashboardTopPerformingSongsStatus = "loading";
      })
      .addCase(fetchTopPerformingSongsThunk.rejected, (state) => {
        state.dashboardStatus = "failed";
        state.dashboardTopPerformingSongsStatus = "failed";
      })
      .addCase(fetchLatestStatementsThunk.fulfilled, (state, action: any) => {
        state.dashboardStatus = "idle";
        state.dashboardLatestStatementsStatus = "idle";
        state.latestStatements.list = action.payload || [];
      })
      .addCase(fetchLatestStatementsThunk.pending, (state) => {
        state.dashboardStatus = "loading";
        state.dashboardLatestStatementsStatus = "loading";
        state.latestStatements.list = [];
      })
      .addCase(fetchLatestStatementsThunk.rejected, (state) => {
        state.dashboardStatus = "failed";
        state.dashboardLatestStatementsStatus = "failed";
      })
      .addCase(fetchRoyaltiesTrendThunk.fulfilled, (state, action) => {
        state.dashboardStatus = "idle";
        state.dashboardRoyaltiesTrendsStatus = "idle";
        state.royaltiesTrend.userType = action.payload?.userType;
        state.royaltiesTrend.periodDataList = action.payload?.periodDataList;
        state.royaltiesTrend.allPeriodsData = action.payload?.allPeriodsData;
      })
      .addCase(fetchRoyaltiesTrendThunk.pending, (state) => {
        state.dashboardStatus = "loading";
        state.dashboardRoyaltiesTrendsStatus = "loading";
      })
      .addCase(fetchRoyaltiesTrendThunk.rejected, (state) => {
        state.dashboardStatus = "failed";
        state.dashboardRoyaltiesTrendsStatus = "failed";
      })
      .addCase(
        FetchDashboardPipelineBreakdown_Type_Thunk.fulfilled,
        (state, action) => {
          state.dashboardStatus = "idle";
          state.dashboardPipelineBreakdown.typeStatus = "idle";
          state.dashboardPipelineBreakdown.typeData = action.payload;
        }
      )
      .addCase(FetchDashboardPipelineBreakdown_Type_Thunk.pending, (state) => {
        state.dashboardStatus = "loading";
        state.dashboardPipelineBreakdown.typeStatus = "loading";
      })
      .addCase(FetchDashboardPipelineBreakdown_Type_Thunk.rejected, (state) => {
        state.dashboardStatus = "failed";
        state.dashboardPipelineBreakdown.typeStatus = "failed";
        state.dashboardPipelineBreakdown.typeData = null;
      })
      .addCase(
        FetchDashboardPipelineBreakdown_Songs_Thunk.fulfilled,
        (state, action) => {
          state.dashboardStatus = "idle";
          state.dashboardPipelineBreakdown.songStatus = "idle";
          state.dashboardPipelineBreakdown.songData = action.payload?.data;
        }
      )
      .addCase(FetchDashboardPipelineBreakdown_Songs_Thunk.pending, (state) => {
        state.dashboardStatus = "loading";
        state.dashboardPipelineBreakdown.songStatus = "loading";
      })
      .addCase(
        FetchDashboardPipelineBreakdown_Songs_Thunk.rejected,
        (state) => {
          state.dashboardStatus = "failed";
          state.dashboardPipelineBreakdown.songStatus = "failed";
          state.dashboardPipelineBreakdown.songData = null;
        }
      )
      .addCase(
        FetchDashboardPipelineBreakdown_Territory_Thunk.fulfilled,
        (state, action) => {
          state.dashboardStatus = "idle";
          state.dashboardPipelineBreakdown.territoryStatus = "idle";
          state.dashboardPipelineBreakdown.territoryData = action.payload?.data;
        }
      )
      .addCase(
        FetchDashboardPipelineBreakdown_Territory_Thunk.pending,
        (state) => {
          state.dashboardStatus = "loading";
          state.dashboardPipelineBreakdown.territoryStatus = "loading";
        }
      )
      .addCase(
        FetchDashboardPipelineBreakdown_Territory_Thunk.rejected,
        (state) => {
          state.dashboardStatus = "failed";
          state.dashboardPipelineBreakdown.territoryStatus = "failed";
          state.dashboardPipelineBreakdown.territoryData = null;
        }
      )
      .addCase(
        FetchDashboardPipelineBreakdown_Sources_Thunk.fulfilled,
        (state, action) => {
          state.dashboardStatus = "idle";
          state.dashboardPipelineBreakdown.sourceStatus = "idle";
          state.dashboardPipelineBreakdown.sourceData = action.payload?.data;
        }
      )
      .addCase(
        FetchDashboardPipelineBreakdown_Sources_Thunk.pending,
        (state) => {
          state.dashboardStatus = "loading";
          state.dashboardPipelineBreakdown.sourceStatus = "loading";
        }
      )
      .addCase(
        FetchDashboardPipelineBreakdown_Sources_Thunk.rejected,
        (state) => {
          state.dashboardStatus = "failed";
          state.dashboardPipelineBreakdown.sourceStatus = "failed";
          state.dashboardPipelineBreakdown.sourceData = null;
        }
      )
      .addCase(FetchDashboardPeriods_Thunk.fulfilled, (state, action) => {
        state.dashboardPeriodStatus = "idle";
        state.dashboardPeriods = action.payload?.allPeriods;
        state.dashboardPipelinePeriods = action.payload?.pipelinePeriod;
      })
      .addCase(FetchDashboardPeriods_Thunk.pending, (state) => {
        state.dashboardPeriodStatus = "loading";
      })
      .addCase(FetchDashboardPeriods_Thunk.rejected, (state) => {
        state.dashboardPeriodStatus = "failed";
      });
  },
});

// status
export const dashboardStatusSelector = (state: RootState) =>
  state.pubDashboard.dashboardStatus;
export const dashboardStatsStatusSelector = (state: RootState) =>
  state.pubDashboard.dashboardStatsStatus;
export const dashboardStatsDownloadStatusSelector = (state: RootState) =>
  state.pubDashboard.dashboardStatsDownloadStatus;
export const dashboardTopRoyaltySourcesStatusSelector = (state: RootState) =>
  state.pubDashboard.dashboardTopRoyaltySourcesStatus;
export const dashboardTopPerformingSongsStatusSelector = (state: RootState) =>
  state.pubDashboard.dashboardTopPerformingSongsStatus;
export const dashboardTopPerformingTerritoriesStatusSelector = (
  state: RootState
) => state.pubDashboard.dashboardTopPerformingTerritoriesStatus;
export const dashboardLatestStatementsStatusSelector = (state: RootState) =>
  state.pubDashboard.dashboardLatestStatementsStatus;
export const dashboardRoyaltiesTrendsStatusSelector = (state: RootState) =>
  state.pubDashboard.dashboardRoyaltiesTrendsStatus;
export const dashboardPeriodStatusSelector = (state: RootState) =>
  state.pubDashboard.dashboardPeriodStatus;
// pipeline status
export const dashboardPipelineTypeStatusSelector = (state: RootState) =>
  state.pubDashboard.dashboardPipelineBreakdown.typeStatus;
export const dashboardPipelineTerritoryStatusSelector = (state: RootState) =>
  state.pubDashboard.dashboardPipelineBreakdown.territoryStatus;
export const dashboardPipelineSongStatusSelector = (state: RootState) =>
  state.pubDashboard.dashboardPipelineBreakdown.songStatus;
export const dashboardPipelineSourceStatusSelector = (state: RootState) =>
  state.pubDashboard.dashboardPipelineBreakdown.sourceStatus;
// misc
export const dashboardPageStateSelector = (state: RootState) =>
  state.pubDashboard.dashboardPageState;
export const dashboardRoyaltiesTrendStateSelector = (state: RootState) =>
  state.pubDashboard.dashboardRoyaltiesTrendState;
export const dashboardStatsSelector = (state: RootState) =>
  state.pubDashboard.stats;
export const dashboardTopRoyaltySourcesSelector = (state: RootState) =>
  state.pubDashboard.topRoyaltySources;
export const dashboardTopPerformingSongsSelector = (state: RootState) =>
  state.pubDashboard.topPerformingSongs;
export const dashboardTopPerformingTerritoriesSelector = (state: RootState) =>
  state.pubDashboard.topPerformingTerritories;
export const dashboardPeriodsSelector = (state: RootState) =>
  state.pubDashboard.dashboardPeriods;
export const dashboardLatestStatementsSelector = (state: RootState) =>
  state.pubDashboard.latestStatements;
export const dashboardRoyaltiesTrendSelector = (state: RootState) =>
  state.pubDashboard.royaltiesTrend;
export const pubDashboardPipelineBreakdownSelector = (state: RootState) =>
  state.pubDashboard.dashboardPipelineBreakdown;

export const {
  updateDashboardPageState: updateDashboardPageStateAction,
  updateDashboardPeriodsToInitialState:
    updateDashboardPeriodsToInitialStateAction,
  updateDashboardRoyaltiesTrendPageState:
    updateDashboardRoyaltiesTrendPageStateAction,
  toggleDashboardPipelineBreakdownModal:
    toggleDashboardPipelineBreakdownModalAction,
} = pubDashboardSlice.actions;

export default pubDashboardSlice.reducer;
