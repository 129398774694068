import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";
export function fetchStatements(params: any, thunkAPI?: any) {
  const {
    payeeOnly = false,
    documentType = "MECHANICAL",
    data,
    periodId,
  } = params;
  if (!periodId) return;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.statements}?isPayeeOnly=${payeeOnly}&clientDocumentType=${documentType}&periodId=${periodId}&maxResults=20`,
    { method: "POST", body: JSON.stringify(data), signal: thunkAPI?.signal }
  );
}

export function fetchStatementsFiles(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.statementsFiles}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
    isDownload: true,
    fileName,
  });
}
