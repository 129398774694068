import React from "react";
import { ExclamationIcon } from "../../app/atom/Icon";
import Image from "../../app/atom/Image";
import { SiteDown, SiteDownMobile } from "../../app/img";
import styles from "./errors.module.scss";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    if (process.env.REACT_APP_APP_ENVIRONMENT === "DEV") {
      // eslint-disable-next-line no-console
      console.error(error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={`col-span-full ${styles.errorPage}`}>
          <Image
            src={SiteDown}
            srcSet={`${SiteDownMobile} 1x, ${SiteDown} 2x`}
            alt="some error"
            className="absolute -z-10 h-full w-full object-cover"
          />
          <div className={styles.errorContent}>
            <div className={styles.roundedIcon}>
              <ExclamationIcon />
            </div>
            <h1>Error</h1>
            <span>
              Something went wrong! <a href="/">home</a>.
            </span>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
