import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import {
  fetchSendContactUsPayload,
  fetchSendAdvRequestPayload,
} from "./contactsAPI";
import type { ContactUsPayload, AdvRequestPayload } from "../../types/payload";
import type { ContactUsFormValues } from "./types";

export interface ContactUsState {
  contactUsStatus: "idle" | "loading" | "failed";
  contactUsSuccess: boolean;
  advRequestStatus: "idle" | "loading" | "failed";
  advRequestSuccess: boolean;
  errors: Partial<ContactUsFormValues> | undefined;
}

const initialState: ContactUsState = {
  contactUsStatus: "idle",
  contactUsSuccess: false,
  advRequestStatus: "idle",
  advRequestSuccess: false,
  errors: undefined,
};

export const fetchSubmitContactUsThunk = createAsyncThunk(
  "/contactUs",
  async (payload: ContactUsPayload, thunkAPI) => {
    const response = await fetchSendContactUsPayload(payload, thunkAPI);
    if (response !== null) {
      return thunkAPI.rejectWithValue(response);
    }
    return response;
  }
);

export const fetchSubmitAdvRequestThunk = createAsyncThunk(
  "/advRequest",
  async (
    payload: {
      documentType: "PUBLISHING" | "RECORDING" | "MECHANICAL";
      data: AdvRequestPayload;
    },
    thunkAPI
  ) => {
    const response = await fetchSendAdvRequestPayload(
      payload.documentType,
      payload.data,
      thunkAPI
    );
    if (response.resBody !== "") {
      return thunkAPI.rejectWithValue(response);
    }
    return response;
  }
);

export const contactUsReducer = createSlice({
  name: "contactUs",
  initialState,
  reducers: {
    resetContactUsForm: () => initialState,
    resetErrors: (state) => {
      state.errors = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      // contactUs
      .addCase(fetchSubmitContactUsThunk.fulfilled, (state) => {
        state.contactUsStatus = "idle";
        state.contactUsSuccess = true;
        state.errors = undefined;
      })
      .addCase(fetchSubmitContactUsThunk.pending, (state) => {
        state.contactUsStatus = "loading";
        state.errors = undefined;
      })
      .addCase(fetchSubmitContactUsThunk.rejected, (state, action: any) => {
        state.contactUsStatus = "failed";
        state.contactUsSuccess = false;
        // error messages appear in payload { telephone: "message here" }
        if (typeof action.payload === "object") {
          state.errors = action.payload;
        }
      })
      // advRequest
      .addCase(fetchSubmitAdvRequestThunk.fulfilled, (state) => {
        state.advRequestStatus = "idle";
        state.advRequestSuccess = true;
        state.errors = undefined;
      })
      .addCase(fetchSubmitAdvRequestThunk.pending, (state) => {
        state.advRequestStatus = "loading";
        state.errors = undefined;
      })
      .addCase(fetchSubmitAdvRequestThunk.rejected, (state, action: any) => {
        state.advRequestStatus = "failed";
        state.advRequestSuccess = false;
        // error messages appear in payload { telephone: "message here" }
        if (typeof action.payload === "object") {
          state.errors = action.payload;
        }
      });
  },
});

export const contactUsStatusSelector = (state: RootState) =>
  state.contactUs.contactUsStatus;

export const contactUsSuccessStatusSelector = (state: RootState) =>
  state.contactUs.contactUsSuccess;

export const contactUsErrorsSelector = (state: RootState) =>
  state.contactUs.errors;

export const advRequestStatusSelector = (state: RootState) =>
  state.contactUs.advRequestStatus;

export const advRequestSuccessStatusSelector = (state: RootState) =>
  state.contactUs.advRequestSuccess;

export const {
  resetContactUsForm: resetContactUsFormAction,
  resetErrors: resetErrorsAction,
} = contactUsReducer.actions;

export default contactUsReducer.reducer;
