import { useTranslation } from "react-i18next";
import type {
  RecAnalysisAlbumByTerritoryData,
  RecAnalysisAlbumByTerritoryEntry,
} from "../../../../features/recording/analysis/album/recAnalysisAlbumBreakdownAPI";
import styles from "./../analysis.module.scss";

const AnalysisAlbumBreakdownTerritory = ({
  data,
  status,
}: {
  data: RecAnalysisAlbumByTerritoryData | null;
  status: "noData" | "loading" | "idle" | "failed";
}): JSX.Element => {
  const { t } = useTranslation();

  if (status === "loading") <p className="pl-2 pt-4">{t("app.loading")}</p>;

  if (!data || !data.territories) {
    if (status === "idle")
      return (
        <p className="pl-2 pt-4">{t("analysis.songs.breakdown.noData")}</p>
      );
    else return <p className="pl-2 pt-4">{t("app.loading")}</p>;
  }

  const renderStatRow = (i: number, name: string, revenue: any) => {
    return (
      <div className={styles.statRow} key={i}>
        <div
          className={styles.bar}
          style={{ width: revenue.percent + "%" }}
        ></div>
        <div className={styles.info}>
          <span>{name} </span>
        </div>
        <div className={styles.value}>
          <span className={styles.royalty}>
            {revenue.currency} {revenue.formattedShort}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={`smallScrollbar ${styles.territoryStatsContainer}`}>
      {data.territories.map(
        (t: RecAnalysisAlbumByTerritoryEntry, i: number) => {
          return renderStatRow(i, t.countryName, t.royalty);
        }
      )}
    </div>
  );
};
export default AnalysisAlbumBreakdownTerritory;
