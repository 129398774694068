import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { store } from "./app/redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./app/styles/globals.css";
import "./app/scripts/i18n";
import { ConsentTool } from "./app/molecules/consentTool/consentTool";
import { RECAPTCHA_KEY } from "./app/config/constant";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <>
    <ConsentTool />
    <React.StrictMode>
      <Provider store={store}>
        <GoogleReCaptchaProvider
          reCaptchaKey={RECAPTCHA_KEY}
          scriptProps={{ async: true, appendTo: "body" }}
        >
          <App />
        </GoogleReCaptchaProvider>
      </Provider>
    </React.StrictMode>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
