import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import {
  FetchAnalysisAlbumBreakdown_Territory,
  FetchAnalysisAlbumBreakdown_Trend,
  FetchAnalysisAlbumBreakdown_Track,
  downloadAlbumBreakdownData,
} from "./recAnalysisAlbumBreakdownAPI";
import type {
  RecAnalysisAlbumData,
  RecAnalysisAlbumByTerritoryData,
  RecAnalysisAlbumByTrackData,
  RecAnalysisAlbumByTrendData,
} from "./recAnalysisAlbumBreakdownAPI";

export declare type RecAnalysisAlbumBreakdownProps =
  | RecAnalysisAlbumData
  | null
  | undefined;

export interface recAnalysisAlbumBreakdownState {
  albumBreakdownTerritory: {
    data: RecAnalysisAlbumByTerritoryData | null;
    status: "noData" | "idle" | "loading" | "failed";
  };
  albumBreakdownTrend: {
    data: RecAnalysisAlbumByTrendData | null;
    status: "noData" | "idle" | "loading" | "failed";
  };
  albumBreakdownTracks: {
    data: RecAnalysisAlbumByTrackData | null;
    status: "noData" | "idle" | "loading" | "failed";
  };
  albumBreakdownDownloadStatus: "idle" | "loading" | "failed";
}

const initialState: recAnalysisAlbumBreakdownState = {
  albumBreakdownTerritory: { data: null, status: "noData" },
  albumBreakdownTrend: { data: null, status: "noData" },
  albumBreakdownTracks: { data: null, status: "noData" },
  albumBreakdownDownloadStatus: "idle",
};

export const FetchRecAnalysisAlbumBreakdown_Territory_Thunk = createAsyncThunk(
  "recording/FetchRecAnalysisAlbumBreakdown_Territory",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisAlbumBreakdown_Territory(params, thunkAPI);
  }
);

export const FetchRecAnalysisAlbumBreakdown_Trend_Thunk = createAsyncThunk(
  "recording/FetchRecAnalysisAlbumBreakdown_Trend",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisAlbumBreakdown_Trend(params, thunkAPI);
  }
);

export const FetchRecAnalysisAlbumBreakdown_Track_Thunk = createAsyncThunk(
  "recording/FetchRecAnalysisAlbumBreakdown_Track",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisAlbumBreakdown_Track(params, thunkAPI);
  }
);

export const recAnalysisAlbumBreakdown_Download_Thunk = createAsyncThunk(
  "recAnalysis/breakdown/downloadSources",
  async (payload: any, thunkAPI) => {
    const response = await downloadAlbumBreakdownData(payload, thunkAPI);
    return response;
  }
);

export const recAnalysisAlbumBreakdownSlice = createSlice({
  name: "recAnalysisAlbumBreakdown",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // territory
      .addCase(
        FetchRecAnalysisAlbumBreakdown_Territory_Thunk.fulfilled,
        (state, action) => {
          state.albumBreakdownTerritory.data = action.payload;
          state.albumBreakdownTerritory.status = "idle";
        }
      )
      .addCase(
        FetchRecAnalysisAlbumBreakdown_Territory_Thunk.pending,
        (state) => {
          state.albumBreakdownTerritory.status = "loading";
        }
      )
      .addCase(
        FetchRecAnalysisAlbumBreakdown_Territory_Thunk.rejected,
        (state) => {
          state.albumBreakdownTerritory.status = "failed";
        }
      )
      // trend
      .addCase(
        FetchRecAnalysisAlbumBreakdown_Trend_Thunk.fulfilled,
        (state, action) => {
          state.albumBreakdownTrend.data = action.payload;
          state.albumBreakdownTrend.status = "idle";
        }
      )
      .addCase(FetchRecAnalysisAlbumBreakdown_Trend_Thunk.pending, (state) => {
        state.albumBreakdownTrend.status = "loading";
      })
      .addCase(FetchRecAnalysisAlbumBreakdown_Trend_Thunk.rejected, (state) => {
        state.albumBreakdownTrend.status = "failed";
      })
      // track
      .addCase(
        FetchRecAnalysisAlbumBreakdown_Track_Thunk.fulfilled,
        (state, action) => {
          state.albumBreakdownTracks.data = action.payload;
          state.albumBreakdownTracks.status = "idle";
        }
      )
      .addCase(FetchRecAnalysisAlbumBreakdown_Track_Thunk.pending, (state) => {
        state.albumBreakdownTracks.status = "loading";
      })
      .addCase(FetchRecAnalysisAlbumBreakdown_Track_Thunk.rejected, (state) => {
        state.albumBreakdownTracks.status = "failed";
      })
      // download
      .addCase(recAnalysisAlbumBreakdown_Download_Thunk.fulfilled, (state) => {
        state.albumBreakdownDownloadStatus = "idle";
      })
      .addCase(recAnalysisAlbumBreakdown_Download_Thunk.pending, (state) => {
        state.albumBreakdownDownloadStatus = "loading";
      })
      .addCase(recAnalysisAlbumBreakdown_Download_Thunk.rejected, (state) => {
        state.albumBreakdownDownloadStatus = "failed";
      });
  },
});

export const recAnalysisAlbumBreakdownTerritorySelector = (state: RootState) =>
  state.recAnalysisAlbumBreakdown.albumBreakdownTerritory;
export const recAnalysisAlbumBreakdownTrendSelector = (state: RootState) =>
  state.recAnalysisAlbumBreakdown.albumBreakdownTrend;
export const recAnalysisAlbumBreakdownTracksSelector = (state: RootState) =>
  state.recAnalysisAlbumBreakdown.albumBreakdownTracks;

export const recAnalysisAlbumBreakdownDownloadStatusSelector = (
  state: RootState
) => state.recAnalysisSourceBreakdown.sourceBreakdownDownloadStatus;

export default recAnalysisAlbumBreakdownSlice.reducer;
