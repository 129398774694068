import React, { useEffect, useState } from "react";

const BodyWrapper = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [bodyHeight, setBodyHeight] = useState("auto");
  useEffect(() => {
    const header = document.querySelector("header");
    const footer = document.querySelector("footer");

    if (header && footer) {
      const substract = header?.offsetHeight + footer?.offsetHeight;
      setBodyHeight(`calc(100vh - ${substract}px)`);
    }
  }, []);

  return (
    <main className="flex flex-col" style={{ minHeight: bodyHeight }}>
      {children}
    </main>
  );
};

export default BodyWrapper;
