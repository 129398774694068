import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export function validateEmail(email: string, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.usersValidateEmail}`, {
    method: "POST",
    body: email,
    signal: thunkAPI?.signal,
  });
}

export function fetchSendRegistrationPayload(payload: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.usersAccessRequests}`, {
    method: "POST",
    body: JSON.stringify(payload),
    signal: thunkAPI?.signal,
  });
}

export function fetchUserAccountTypes(thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.accountTypes}`, {
    method: "GET",
    signal: thunkAPI?.signal,
  });
}
