import React from "react";
function OverviewIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 29 22"
      fill="none"
      name="OverviewIcon"
      {...props}
    >
      <path d="M13.529 4.597c-2.516 0-4.563 1.973-4.563 4.397 0 2.425 2.047 4.398 4.563 4.398 2.517 0 4.564-1.973 4.564-4.398 0-2.424-2.047-4.397-4.564-4.397Zm0 7.916c-2.014 0-3.65-1.579-3.65-3.519 0-1.94 1.636-3.518 3.65-3.518 2.013 0 3.652 1.578 3.652 3.518 0 1.94-1.639 3.519-3.652 3.519Zm13.44-3.69C23.81 4.716 19.426 0 13.527 0 8.611 0 4.747 3.333.107 8.71a.425.425 0 0 0 0 .563l.394.454c3.851 4.433 7.177 8.261 13.026 8.261 5.862 0 10.241-4.991 13.14-8.294l.285-.325a.426.426 0 0 0 .017-.545Zm-1 .304c-2.955 3.368-7.004 7.982-12.442 7.982-5.424 0-8.623-3.682-12.326-7.946L1.05 8.99C5.522 3.848 9.028.88 13.527.88c5.363 0 9.48 4.315 12.495 8.19l-.052.058Z" />
    </svg>
  );
}

export default OverviewIcon;
