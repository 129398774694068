import { useTranslation } from "react-i18next";
import styles from "../analysis.module.scss";

interface InfiniteTableHeaderProps {
  filterAnalysisSongsWith: (columnName: string) => void;
}

const AnalysisSongInfiniteTableHeader = (props: InfiniteTableHeaderProps) => {
  const { t } = useTranslation();
  const { filterAnalysisSongsWith } = props;
  return (
    <div id="table-header" className={styles.headContainer}>
      <table className={`w-full`}>
        <thead>
          <tr>
            <th
              className={`sortByTitle_GTM ${styles.songCol}`}
              onClick={() => filterAnalysisSongsWith("name")}
            >
              {t("analysis.songs.song")}
            </th>
            <th
              className={`sortByComposer_GTM ${styles.composerCol}`}
              onClick={() => filterAnalysisSongsWith("composers")}
            >
              {t("analysis.songs.composer")}
            </th>
            <th
              className={`sortByRoyalties_GTM ${styles.royaltiesCol}`}
              onClick={() => filterAnalysisSongsWith("royalties")}
            >
              {t("analysis.songs.royalties")}
            </th>
            <th></th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default AnalysisSongInfiniteTableHeader;
