import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import {
  AllClientsPageStateProps,
  ClientListsPageStateProps,
} from "../../../app/types/props";
import { fetchClientLists, fetchClients } from "./clientsAPI";
import { deleteClientList, saveClientList } from "../../appMain/appMainAPI";

export interface PubClientsState {
  allClientsPageState: AllClientsPageStateProps;
  clientListsPageState: ClientListsPageStateProps;
  clients: {
    list: any;
    total: number | null;
  };
  clientLists: {
    list: any;
    total: number | null;
  };
  isSearchPerformed: boolean;
  allClientsStatus: "init" | "idle" | "loading" | "failed";
  clientListsStatus: "init" | "idle" | "loading" | "failed";
}

const initialState: PubClientsState = {
  allClientsPageState: {
    data: {
      withSubClients: true,
    },
    start: 0,
    count: 200,
    sortColumn: "path",
    filterText: "",
    isAsc: true,
  },
  clientListsPageState: {
    start: 0,
    count: 200,
    sortColumn: "name",
    filterText: "",
    isAsc: true,
  },
  clients: {
    list: [],
    total: null,
  },
  clientLists: {
    list: [],
    total: null,
  },
  isSearchPerformed: false,
  allClientsStatus: "init",
  clientListsStatus: "init",
};

export const fetchClientsThunk = createAsyncThunk(
  "publishing/allClients",
  async (payload: any, thunkAPI) => {
    const response = await fetchClients(payload, thunkAPI);
    return response;
  }
);

export const fetchClientListsThunk = createAsyncThunk(
  "publishing/clientLists",
  async (payload: any, thunkAPI) => {
    const response = await fetchClientLists(payload, thunkAPI);
    return response;
  }
);

export const fetchMoreClientsThunk = createAsyncThunk(
  "publishing/moreclients",
  async (payload: any, thunkAPI) => {
    const response = await fetchClients(payload, thunkAPI);
    return response;
  }
);

export const clientListDeleteThunk = createAsyncThunk(
  "publishing/deleteClientList",
  async (params: any, thunkAPI) => {
    const response = await deleteClientList(params, thunkAPI);
    return response;
  }
);

export const clientListCreateThunk = createAsyncThunk(
  "publishing/createClientList",
  async (params: any, thunkAPI) => {
    return await saveClientList(params, thunkAPI);
  }
);

export const pubClientsSlice = createSlice({
  name: "pubClients",
  initialState,
  reducers: {
    resetAllClients: (state, action: any) => {
      state.clients = initialState.clients;
    },
    resetClientLists: (state, action: any) => {
      state.clientLists = initialState.clientLists;
    },
    updateAllClientsPageState: (state, action: any) => {
      state.allClientsPageState.data.withSubClients =
        action.payload.data.withSubClients;
      state.allClientsPageState.start = action.payload.start;
      state.allClientsPageState.sortColumn = action.payload.sortColumn;
      state.allClientsPageState.filterText = action.payload.filterText;
      state.allClientsPageState.isAsc = action.payload.isAsc;
    },
    updateClientListsPageState: (state, action: any) => {
      state.clientListsPageState.filterText = action.payload.filterText;
      state.clientListsPageState.isAsc = action.payload.isAsc;
    },
    updateIsSearchPerformed: (state, action: any) => {
      state.isSearchPerformed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientsThunk.fulfilled, (state, action: any) => {
        state.allClientsStatus = "idle";
        state.clients.list = action.payload.data;
        state.clients.total = action.payload.total;
      })
      .addCase(fetchMoreClientsThunk.fulfilled, (state, action: any) => {
        state.allClientsStatus = "idle";
        state.clients.list = [...state.clients.list, ...action.payload.data];
        state.clients.total = action.payload.total;
      })
      .addCase(fetchClientListsThunk.fulfilled, (state, action: any) => {
        state.clientListsStatus = "idle";
        state.clientLists.list = action.payload.data;
        state.clientLists.total = action.payload.total;
      })
      .addCase(clientListDeleteThunk.fulfilled, (state, action: any) => {
        const currentClientList = [...state.clientLists.list];
        state.clientLists.list = currentClientList.filter(
          (list: any) => Number(list.id) !== action.meta.arg.id
        );
        if (state.clientLists.total) state.clientLists.total -= 1;
        state.clientListsStatus = "idle";
        state.allClientsStatus = "idle";
      })
      .addCase(clientListCreateThunk.fulfilled, (state, action: any) => {
        state.clientLists.list.push(action.payload);
        if (state.clientLists.total) state.clientLists.total += 1;
        state.allClientsStatus = "idle";
        state.clientListsStatus = "idle";
      })
      .addMatcher(
        isAnyOf(
          fetchClientsThunk.pending,
          fetchMoreClientsThunk.pending,
          clientListDeleteThunk.pending,
          clientListCreateThunk.pending
        ),
        (state) => {
          state.allClientsStatus = "loading";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchClientsThunk.rejected,
          fetchMoreClientsThunk.rejected,
          clientListDeleteThunk.rejected,
          clientListCreateThunk.rejected
        ),
        (state) => {
          state.allClientsStatus = "failed";
        }
      )
      .addMatcher(isAnyOf(fetchClientListsThunk.pending), (state) => {
        state.clientListsStatus = "loading";
      })
      .addMatcher(isAnyOf(fetchClientListsThunk.rejected), (state) => {
        state.clientListsStatus = "failed";
      });
  },
});

export const allClientsPageStateSelector = (state: RootState) =>
  state.pubClients.allClientsPageState;
export const allClientsSelector = (state: RootState) =>
  state.pubClients.clients;
export const clientListsPageStateSelector = (state: RootState) =>
  state.pubClients.clientListsPageState;
export const clientListsSelector = (state: RootState) =>
  state.pubClients.clientLists;
export const allClientsStatusSelector = (state: RootState) =>
  state.pubClients.allClientsStatus;
export const clientListsStatusSelector = (state: RootState) =>
  state.pubClients.clientListsStatus;
export const isSearchPerformedSelector = (state: RootState) =>
  state.pubClients.isSearchPerformed;

export const {
  resetAllClients: resetAllClientsAction,
  resetClientLists: resetClientListsAction,
  updateAllClientsPageState: updateAllClientsPageStateAction,
  updateClientListsPageState: updateClientListsPageStateAction,
  updateIsSearchPerformed: updateIsSearchPerformedAction,
} = pubClientsSlice.actions;

export default pubClientsSlice.reducer;
