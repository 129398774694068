import { USER_API_RESOURCE_BASE_URLS } from "../../../../app/config/userEndpoints.const";
import request from "../../../../app/utils/requestUtils";

export function FetchAnalysisChannelBreakdown_Territory(
  params: any,
  thunkAPI?: any
) {
  const data = {
    periodIds: params.periodIds,
    clientIds: params.clientIds,
    clientLists: [],
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.channelsTerritories}?distChannelKey=${params.code}&channelName=${params.category}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisChannelBreakdown_Trend(
  params: any,
  thunkAPI?: any
) {
  const data = {
    periodIds: params.periodIds,
    clientIds: params.clientIds,
    clientLists: [],
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.channelsTrends}?distChannelKey=${params.code}&channelName=${params.category}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisChannelBreakdown_Products(
  params: any,
  thunkAPI?: any
) {
  const data = {
    periodIds: params.periodIds,
    clientIds: params.clientIds,
    clientLists: [],
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.channelsProducts}?distChannelKey=${params.code}&channelName=${params.category}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function downloadChannelBreakdownData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.analysisDownload}?clientDocumentType=RECORDING`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
      isDownload: true,
      fileName,
    }
  );
}
