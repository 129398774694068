import request from "../../../../app/utils/requestUtils";
import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export type addEditSystemClosingDateRequestBody = {
  clientDocumentType: string;
  systemCode: string;
  paymentCycle: number;
  paymentDays: number;
  newClosingDate: string;
};

export const getSystemClosingDates = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.clients}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const getSystemClosingDatesOptions = (payload: any) => {
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.systemClosingDatesOptions}/${payload}`,
    {
      method: "GET",
    }
  );
};

export const systemClosingDatesPreview = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.systemClosingDatesPreview}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const linkedClients = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.clients}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const addSystemClosingDate = (
  payload: addEditSystemClosingDateRequestBody
) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.systemClosingDates}`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });
};

export const getSystemClosingDate = (id: string) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.systemClosingDates}/${id}`, {
    method: "GET",
  });
};

export const updateSystemClosingDate = (payload: any) => {
  const id = payload.id;
  const data = payload.date;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.systemClosingDates}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

export const deleteSystemClosingDate = (id: string) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.systemClosingDates}/${id}`, {
    method: "DELETE",
  });
};
