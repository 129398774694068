import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export function fetchSendForgotUsernamePayload(
  payload: string,
  thunkAPI?: any
) {
  return request(`${USER_API_RESOURCE_BASE_URLS.usersForgotUsername}`, {
    method: "PUT",
    body: payload,
    signal: thunkAPI?.signal,
  });
}
