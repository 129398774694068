import { USER_API_RESOURCE_BASE_URLS } from "../../../../app/config/userEndpoints.const";
import request from "../../../../app/utils/requestUtils";

export type FetchRecAnalysisTrackBreakdownParams = {
  productKey: number;
  clientIds: number[];
  clientLists: {
    id: string;
    type: string;
  }[];
  periodIds: number[];
};

export function FetchAnalysisTrackBreakdown_Trend(
  params: FetchRecAnalysisTrackBreakdownParams,
  thunkAPI?: any
) {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.tracksTrends}?productKey=${params.productKey}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisTrackBreakdown_Territory(
  params: FetchRecAnalysisTrackBreakdownParams,
  thunkAPI?: any
) {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.tracksTerritories}?productKey=${params.productKey}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisTrackBreakdown_Source(
  params: FetchRecAnalysisTrackBreakdownParams,
  thunkAPI?: any
) {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.tracksSources}?productKey=${params.productKey}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function downloadTrackBreakdownData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.analysisDownload}?clientDocumentType=RECORDING`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
      isDownload: true,
      fileName,
    }
  );
}
