import { USER_API_RESOURCE_BASE_URLS } from "../../../../app/config/userEndpoints.const";
import request from "../../../../app/utils/requestUtils";

export type RecAnalysisDigitalBreakdownTrendTerritoryProductsParams = {
  isSelected: boolean;
  sourceCode?: string;
  sourceName: string;
  clientIds: number[];
  clientLists?: {
    id: string;
    type: "static";
  }[];
  periodIds: number[];
};

type RecAnalysisDigitalDataEntry = {
  currency: string;
  raw: number;
  formattedShort: string;
  formattedLong: string;
  formattedPercent: string;
  percent: number;
};

export type RecAnalysisDigitalSourcesByTerritoryEntry = {
  name: string;
  iso: string;
  revenue: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
  zero: boolean;
};

export type RecAnalysisDigitalRoyaltiesPerPeriodOrYearEntry = {
  periodOrYear: string;
  royalty: RecAnalysisDigitalDataEntry;
  zero: boolean;
};

export type RecAnalysisDigitalData = {
  sourceCode?: string;
  sourceName: string;
  perYear?: boolean;
  totalRoyalty: RecAnalysisDigitalDataEntry | null;
  sourcesByTerritory?: RecAnalysisDigitalSourcesByTerritoryEntry[] | null;
  royaltiesPerPeriodOrYear?:
    | RecAnalysisDigitalRoyaltiesPerPeriodOrYearEntry[]
    | null;
};

export function FetchAnalysisDigitalBreakdown_Trend(
  params: RecAnalysisDigitalBreakdownTrendTerritoryProductsParams,
  thunkAPI?: any
): Promise<RecAnalysisDigitalData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.recordingDigitalTrends}?sourceName=${params.sourceName}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisDigitalBreakdown_Territory(
  params: RecAnalysisDigitalBreakdownTrendTerritoryProductsParams,
  thunkAPI?: any
): Promise<RecAnalysisDigitalData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.recordingDigitalTerritories}?sourceName=${params.sourceName}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisDigitalBreakdown_Products(
  params: RecAnalysisDigitalBreakdownTrendTerritoryProductsParams,
  thunkAPI?: any
): Promise<RecAnalysisDigitalData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.recordingDigitalProducts}?sourceName=${params.sourceName}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function downloadDigitalBreakdownData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.analysisDownload}?clientDocumentType=RECORDING`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
      isDownload: true,
      fileName,
    }
  );
}
