import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchRecAnalysisChannelBreakdown_Territory_Thunk,
  FetchRecAnalysisChannelBreakdown_Trend_Thunk,
  FetchRecAnalysisChannelBreakdown_Products_Thunk,
  FindRecAnalysisChannelBreakdown,
  recAnalysisChannelBreakdownTerritorySelector,
  recAnalysisChannelBreakdownTrendSelector,
  recAnalysisChannelBreakdownProductsSelector,
  recAnalysisChannelBreakdownDownloadStatusSelector,
} from "../../../../features/recording/analysis/channel/recAnalysisChannelBreakdownSlice";
import Button from "../../../atom/Button/Button";
import ChevronIcon from "../../../atom/Icon/ChevronIcon";
import Loader from "../../../atom/Loader";
import styles from "../analysis.module.scss";
import { ButtonVariantEnum } from "../../../types/enums";
import AnalysisChannelBreakdownTerritory from "./AnalysisChannelBreakdown_Territory";
import AnalysisChannelBreakdownTrend from "./AnalysisChannelBreakdown_Trend";
import AnalysisChannelBreakdownProducts from "./AnalysisChannelBreakdown_Products";
import { PeriodSelector } from "../../periodSelector/periodSelectorSlice";
import { getTranslationForChart } from "../../../../app/utils/incomeType";

export declare type AnalysisChannelBreakdownViewProps = {
  channel: {
    code: number;
    category: string;
    name: string;
    mainIncType: string;
  };
  periodIds: Array<number>;
  clientIds: Array<number>;
  mobileView: boolean;
  handleClose?: (clickedSong: any) => void;
};

const AnalysisChannelBreakdown = (props: any): JSX.Element => {
  const { channel, periodIds, clientIds, mobileView } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch<any>();

  const productsBreakdownPosRef = useRef<HTMLParagraphElement>(null);
  const territoryBreakdownPosRef = useRef<HTMLParagraphElement>(null);
  const trendBreakdownPosRef = useRef<HTMLParagraphElement>(null);

  const [productsData, setProductsData]: [any, any] = useState(null);
  const [territoryData, setTerritoryData]: [any, any] = useState(null);
  const [trendData, setTrendData]: [any, any] = useState(null);

  // dispatch refs
  const dispatchedProducts = useRef<any>();
  const dispatchedTerritory = useRef<any>();
  const dispatchedTrend = useRef<any>();
  const dispatchedDownload = useRef<any>();

  // abort functions
  const abortDispatchedProducts = useCallback(() => {
    if (dispatchedProducts.current) dispatchedProducts.current.abort();
  }, []);
  const abortDispatchedTerritory = useCallback(() => {
    if (dispatchedTerritory.current) dispatchedTerritory.current.abort();
  }, []);
  const abortDispatchedTrend = useCallback(() => {
    if (dispatchedTrend.current) dispatchedTrend.current.abort();
  }, []);
  const abortDispatchedDownload = useCallback(() => {
    if (dispatchedDownload.current) dispatchedDownload.current.abort();
  }, []);

  const allPeriods = useSelector(PeriodSelector);

  const channelBreakdownProducts = useSelector(
    recAnalysisChannelBreakdownProductsSelector
  );

  const channelBreakdownTerritory = useSelector(
    recAnalysisChannelBreakdownTerritorySelector
  );

  const channelBreakdownTrend = useSelector(
    recAnalysisChannelBreakdownTrendSelector
  );

  const channelBreakdown_DownloadsStatus = useSelector(
    recAnalysisChannelBreakdownDownloadStatusSelector
  );

  const scrollToBreakdown =
    (refObject: RefObject<HTMLParagraphElement>) => () => {
      refObject.current?.scrollIntoView({ behavior: "smooth" });
    };

  const findBreakdown = useCallback(
    (dataArray: Array<any>) => {
      return FindRecAnalysisChannelBreakdown(
        dataArray,
        channel.code,
        channel.category
      );
    },
    [channel.category, channel.code]
  );

  const assignProductsData = useCallback(() => {
    const currentProductsBreakdown = findBreakdown(
      channelBreakdownProducts.dataArray
    );
    if (currentProductsBreakdown) {
      setProductsData(currentProductsBreakdown);
    } else if (
      productsData?.code !== channel.code &&
      productsData?.category !== channel.category
    ) {
      setProductsData({
        code: channel.code,
        category: channel.category,
      });
      abortDispatchedProducts();
      dispatchedProducts.current = dispatch(
        FetchRecAnalysisChannelBreakdown_Products_Thunk({
          clientIds: clientIds,
          periodIds: periodIds,
          code: channel.code,
          category: channel.category,
        })
      );
    }
  }, [
    abortDispatchedProducts,
    channel.category,
    channel.code,
    channelBreakdownProducts.dataArray,
    clientIds,
    dispatch,
    findBreakdown,
    periodIds,
    productsData?.category,
    productsData?.code,
  ]);

  const assignTerritoryData = useCallback(() => {
    const currentTerritoryBreakdown = findBreakdown(
      channelBreakdownTerritory.dataArray
    );
    if (currentTerritoryBreakdown) {
      setTerritoryData(currentTerritoryBreakdown);
    } else if (
      territoryData?.code !== channel.code &&
      territoryData?.category !== channel.category
    ) {
      setTerritoryData({
        code: channel.code,
        category: channel.category,
      });
      abortDispatchedTerritory();
      dispatchedTerritory.current = dispatch(
        FetchRecAnalysisChannelBreakdown_Territory_Thunk({
          clientIds: clientIds,
          periodIds: periodIds,
          code: channel.code,
          category: channel.category,
        })
      );
    }
  }, [
    abortDispatchedTerritory,
    channel.category,
    channel.code,
    channelBreakdownTerritory.dataArray,
    clientIds,
    dispatch,
    findBreakdown,
    periodIds,
    territoryData,
  ]);

  const assignTrendData = useCallback(() => {
    const currentTrendBreakdown = findBreakdown(
      channelBreakdownTrend.dataArray
    );
    if (currentTrendBreakdown) {
      setTrendData(currentTrendBreakdown);
    } else if (
      trendData?.code !== channel.code &&
      trendData?.category !== channel.category
    ) {
      setTrendData({
        code: channel.code,
        category: channel.category,
      });
      abortDispatchedTrend();
      dispatchedTrend.current = dispatch(
        FetchRecAnalysisChannelBreakdown_Trend_Thunk({
          clientIds: clientIds,
          periodIds: allPeriods?.periods?.map((p) => p.periodNum) || [],
          code: channel.code,
          category: channel.category,
        })
      );
    }
  }, [
    abortDispatchedTrend,
    allPeriods.periods,
    channel.category,
    channel.code,
    channelBreakdownTrend.dataArray,
    clientIds,
    dispatch,
    findBreakdown,
    trendData?.category,
    trendData?.code,
  ]);

  useEffect(() => {
    return () => {
      abortDispatchedTerritory();
      abortDispatchedTrend();
      abortDispatchedProducts();
      abortDispatchedDownload();
    };
  }, [
    abortDispatchedDownload,
    abortDispatchedProducts,
    abortDispatchedTerritory,
    abortDispatchedTrend,
  ]);

  useEffect(() => {
    assignProductsData();
    assignTerritoryData();
    assignTrendData();
  }, [
    assignTerritoryData,
    channel.code,
    channel.catagory,
    assignProductsData,
    assignTrendData,
  ]);

  const handleCloseBreakdown = useCallback(() => {
    props.handleClose && props.handleClose("");
  }, [props]);

  return (
    <div className={styles.breakDown}>
      {(channelBreakdownTerritory.status === "loading" ||
        channelBreakdownTrend.status === "loading" ||
        channelBreakdownProducts.status === "loading" ||
        channelBreakdown_DownloadsStatus === "loading") && <Loader />}
      <header
        id="rightScrollableHeader"
        className="sticky top-0 z-[5] bg-white pt-5"
      >
        <div className="flex justify-between">
          {!mobileView && <h3 className={styles.title}>{channel.name}</h3>}
          {props.handleClose && (
            <Button
              className={`${styles.unselectBtn} ${styles.breakdownUnselectBtn} self-start md:mr-2`}
              variant={ButtonVariantEnum.cleanCta}
              onClick={handleCloseBreakdown}
            >
              +
            </Button>
          )}
        </div>
        {!props.mobileView && (
          <div className={styles.description}>
            <p>{getTranslationForChart(channel.mainIncType || "", t)}</p>
          </div>
        )}
        <div className={styles.breakdownButtons}>
          <Button
            onClick={scrollToBreakdown(productsBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={styles.scrollNavBtn}
            icon={<ChevronIcon />}
          >
            {t("analysis.channel.breakdown.products")}
          </Button>
          <Button
            onClick={scrollToBreakdown(territoryBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={styles.scrollNavBtn}
            icon={<ChevronIcon />}
          >
            {t("analysis.channel.breakdown.territory")}
          </Button>
          <Button
            onClick={scrollToBreakdown(trendBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={styles.scrollNavBtn}
            icon={<ChevronIcon />}
          >
            {t("analysis.channel.breakdown.trend")}
          </Button>
        </div>
      </header>
      <div className={`${styles.scrollable} ${styles.breakDownContent}`}>
        <section>
          <p ref={productsBreakdownPosRef} className={styles.breakdownTitle}>
            {t("analysis.channel.breakdown.breakdownByProducts")}
          </p>
          <AnalysisChannelBreakdownProducts
            data={productsData}
            status={channelBreakdownProducts.status}
          />

          <div className={styles.percentageList}>
            <p ref={territoryBreakdownPosRef} className={styles.breakdownTitle}>
              {t("analysis.channel.breakdown.breakdownByTerritory")}
            </p>
            <AnalysisChannelBreakdownTerritory
              data={territoryData}
              status={channelBreakdownTerritory.status}
            />

            <p ref={trendBreakdownPosRef} className={styles.breakdownTitle}>
              {t("analysis.channel.breakdown.breakdownByTrend")}
            </p>
            <AnalysisChannelBreakdownTrend
              data={trendData}
              status={channelBreakdownTrend.status}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default AnalysisChannelBreakdown;
