import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import { SortDirectionEnum, StatusEnum } from "../../../../app/types/enums";
import { LinkedClientsTableColumnsVariantEnum } from "./closingDateDetails.const";

import { formattedResponseOptions } from "./closingDateDetails.utils";

import { useFetch } from "../../common/hooks/useFetch";

import { DEFAULT_ROWS_PER_PAGE } from "../../common/adminEndpoints.const";

import {
  getSystemClosingDates,
  getSystemClosingDatesOptions,
  systemClosingDatesPreview,
  linkedClients,
  addSystemClosingDate,
  getSystemClosingDate,
  updateSystemClosingDate,
  deleteSystemClosingDate,
} from "./closingDateDetailsAPI";
import { CommonRequestBodyTableState } from "../../../../app/types/props";

export type LinkedClientItem = {
  id: number;
  name: string;
  email: string;
  depth: number;
};

type ClosingDateDetailsInitialState = {
  preview: {
    systemClosingDate: string;
    closingPeriod: number | string;
  };
  options: {
    paymentCycles: number[];
    paymentDays: number[];
    owningSystems: { label: string; value: string }[];
  };
  linkedClientsState: CommonRequestBodyTableState;
  tableData: {
    list: LinkedClientItem[] | null;
    count: number | null;
    totalCount: number | null;
  };
  status: StatusEnum;
};

export const fetchSystemClosingDatesThunk = createAsyncThunk(
  "getSystemClosingDates",
  async (payload: any, { rejectWithValue }) => {
    try {
      const requestData = {
        ...payload,
      } as any;

      const fetchData = await useFetch();
      const response = await fetchData({
        data: requestData,
        fetchFunction: getSystemClosingDates,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchGetSystemClosingDatesOptionsThunk = createAsyncThunk(
  "fetchGetSystemClosingDatesOptionsThunk",
  async (payload: any, { rejectWithValue }) => {
    try {
      const fetchData = useFetch();
      const response = await fetchData({
        data: payload,
        fetchFunction: getSystemClosingDatesOptions,
      });

      return formattedResponseOptions(response);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSystemClosingDatesPreviewThunk = createAsyncThunk(
  "fetchSystemClosingDatesPreviewThunk",
  async (payload: any, { rejectWithValue }) => {
    try {
      const requestData = {
        ...payload,
      } as any;

      const fetchData = await useFetch();
      const response = await fetchData({
        data: requestData,
        fetchFunction: systemClosingDatesPreview,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchlinkedClientsThunk = createAsyncThunk(
  "linkedClients",
  async (payload: any, { rejectWithValue }) => {
    try {
      const requestData = {
        ...payload,
      } as any;

      const fetchData = await useFetch();
      const response = await fetchData({
        data: requestData,
        fetchFunction: linkedClients,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAddSystemClosingDateThunk = createAsyncThunk(
  "addSystemClosingDate",
  async (payload: any, { rejectWithValue }) => {
    try {
      const requestData = {
        ...payload,
      } as any;

      const fetchData = await useFetch();
      const response = await fetchData({
        data: requestData,
        fetchFunction: addSystemClosingDate,
        customSuccessMsg: "adminSection.common.successEditMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchGetSystemClosingDateThunk = createAsyncThunk(
  "getSystemClosingDate",
  async (payload: any, { rejectWithValue }) => {
    try {
      const fetchData = useFetch();
      const response = await fetchData({
        data: payload,
        fetchFunction: getSystemClosingDate,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchUpdateSystemClosingDateThunk = createAsyncThunk(
  "updateSystemClosingDate",
  async (payload: any, { rejectWithValue }) => {
    try {
      const requestData = {
        ...payload,
      } as any;

      const fetchData = await useFetch();
      const response = await fetchData({
        data: requestData,
        fetchFunction: updateSystemClosingDate,
        customSuccessMsg: "adminSection.common.successEditMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchDeleteystemClosingDateThunk = createAsyncThunk(
  "deleteSystemClosingDate",
  async (payload: any, { rejectWithValue }) => {
    try {
      const fetchData = useFetch();
      const response = await fetchData({
        data: payload,
        fetchFunction: deleteSystemClosingDate,
        customSuccessMsg: "adminSection.common.successDeleteMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: ClosingDateDetailsInitialState = {
  preview: {
    systemClosingDate: "",
    closingPeriod: "",
  },
  options: {
    paymentCycles: [],
    paymentDays: [],
    owningSystems: [],
  },
  linkedClientsState: {
    filterText: "",
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn: LinkedClientsTableColumnsVariantEnum.name,
          direction: SortDirectionEnum.Ascending,
        },
      ],
    },
  },
  tableData: {
    list: null,
    count: 0,
    totalCount: 0,
  },
  status: StatusEnum.Idle,
};

export const ClosingDateDetailsSlice = createSlice({
  name: "closingDateDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSystemClosingDatesThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableData.list = action.payload.data;
        state.tableData.totalCount = action.payload.totalCount;
        state.tableData.count = action.payload.count;
      })
      .addCase(fetchSystemClosingDatesThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(fetchSystemClosingDatesThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
        state.tableData.list = [];
        state.tableData.totalCount = null;
        state.tableData.count = null;
      })
      .addCase(
        fetchGetSystemClosingDatesOptionsThunk.fulfilled,
        (state, action: any) => {
          state.status = StatusEnum.Idle;
          state.options = action.payload;
          state.preview = { systemClosingDate: "", closingPeriod: "" };
        }
      )
      .addCase(fetchGetSystemClosingDatesOptionsThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(fetchGetSystemClosingDatesOptionsThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(
        fetchSystemClosingDatesPreviewThunk.fulfilled,
        (state, action: any) => {
          state.status = StatusEnum.Idle;
          state.preview = action.payload;
        }
      )
      .addCase(fetchSystemClosingDatesPreviewThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(fetchSystemClosingDatesPreviewThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(fetchlinkedClientsThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableData.list = action.payload.data;
        state.tableData.totalCount = action.payload.totalCount;
        state.tableData.count = action.payload.count;
      })
      .addCase(fetchlinkedClientsThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(fetchlinkedClientsThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
        state.tableData.list = [];
        state.tableData.totalCount = null;
        state.tableData.count = null;
      })
      .addCase(
        fetchGetSystemClosingDateThunk.fulfilled,
        (state, action: any) => {
          state.status = StatusEnum.Idle;
        }
      )
      .addCase(fetchGetSystemClosingDateThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(fetchGetSystemClosingDateThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(
        fetchUpdateSystemClosingDateThunk.fulfilled,
        (state, action: any) => {
          state.status = StatusEnum.Idle;
        }
      )
      .addCase(fetchUpdateSystemClosingDateThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(fetchUpdateSystemClosingDateThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(
        fetchDeleteystemClosingDateThunk.fulfilled,
        (state, action: any) => {
          state.status = StatusEnum.Idle;
        }
      )
      .addCase(fetchDeleteystemClosingDateThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(fetchDeleteystemClosingDateThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      });
  },
});

export const ClosingDateDetailsSelector = (state: RootState) =>
  state.closingDateDetails;
export default ClosingDateDetailsSlice.reducer;
