import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./features/routes/AppRoutes";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "./features/error/ErrorBoundary";

export default function App() {
  return (
    <ErrorBoundary>
      <Router>
        <AppRoutes />
      </Router>
      <div id="modal-root"></div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
      />
    </ErrorBoundary>
  );
}
